import { cleanUrl } from './url';

interface AgentData {
  id: string;
  name: string;
  city?: string;
  clean_city?: string;
  country?: string;
  clean_country?: string;
}

/**
 * Maximum URL segment length to prevent overly long URLs
 * This helps with SEO and user experience
 */
const MAX_SLUG_LENGTH = 50;
const MAX_SHORT_SLUG_LENGTH = 20; // For shorter, cleaner slugs

/**
 * Formats a clean URL slug from text, with length limits
 * @param text Text to convert to URL-friendly slug
 * @param maxLength Maximum length for the slug
 */
export function formatUrlSlug(text?: string, maxLength = MAX_SLUG_LENGTH): string {
  if (!text) return '';
  
  // First decode any URL-encoded characters
  let decoded = text;
  try {
    decoded = decodeURIComponent(text);
  } catch (e) {
    // If decoding fails, use the original text
    console.warn('Failed to decode URL component:', text);
  }
  
  // Clean the URL and limit length
  const slug = decoded
    .toLowerCase()
    .replace(/[&.,'":;!@#$%^*()+={}[\]|\\/<>?~`]/g, '') // Remove most special characters
    .replace(/\s+/g, '-')                               // Replace spaces with hyphens
    .replace(/[^a-z0-9-]/g, '')                         // Remove any remaining non-alphanumeric chars except hyphens
    .replace(/-+/g, '-')                                // Replace multiple hyphens with single
    .replace(/^-|-$/g, '');                             // Remove leading/trailing hyphens
  
  // Limit length, but avoid cutting in the middle of a word if possible
  if (slug.length <= maxLength) return slug;
  
  // Try to cut at a dash to avoid partial words
  const truncated = slug.substring(0, maxLength);
  const lastDashPos = truncated.lastIndexOf('-');
  if (lastDashPos > maxLength * 0.7) {  // Only cut at dash if it's far enough
    return truncated.substring(0, lastDashPos);
  }
  
  return truncated;
}

/**
 * LEGACY: Generates agent URL in the old format for backward compatibility
 * Format: /travel-agents/[agent-slug]-[id]/[city-slug]
 */
export function generateLegacyAgentUrl(agent: AgentData): string {
  if (!agent || !agent.id) return '';
  
  const nameSlug = formatUrlSlug(agent.name, 40);
  const citySlug = formatUrlSlug(agent.clean_city || agent.city);
  
  return `/travel-agents/${nameSlug}-${agent.id}/${citySlug}`;
}

/**
 * Generates a simplified agent profile URL using only agent ID for maximum stability
 * Format: /agent/[id]/[short-name-slug]
 * 
 * This approach:
 * 1. Creates much shorter, cleaner URLs
 * 2. Ensures perfect URL stability with the ID
 * 3. Prevents collisions between agents with similar names
 * 4. Completely eliminates URL complexity issues
 * 5. Still maintains some SEO benefit with the optional short name slug
 */
export function generateAgentUrl(agent: AgentData): string {
  if (!agent || !agent.id) return '';
  
  // Create a very short name slug for SEO benefit
  const shortNameSlug = formatUrlSlug(agent.name, MAX_SHORT_SLUG_LENGTH);
  
  // Main ID-based URL with optional short name for SEO
  return shortNameSlug ? `/agent/${agent.id}/${shortNameSlug}` : `/agent/${agent.id}`;
}

/**
 * Parse an agent URL to extract the agent ID
 * This handles both new and legacy URL formats
 */
export function parseAgentUrl(url: string): { agentId: string | null, citySlug: string | null } {
  // First try the new ID-based format: /agent/[id] or /agent/[id]/[name]
  let matches = url.match(/\/agent\/([a-zA-Z0-9]+)(?:\/([^/]+))?/);
  
  if (matches) {
    return {
      agentId: matches[1],
      citySlug: null // New format doesn't use city slugs
    };
  }
  
  // If not found, try the legacy format
  matches = url.match(/\/travel-agents\/(.+)-([a-zA-Z0-9]+)\/([^/]+)/);
  
  if (!matches) return { agentId: null, citySlug: null };
  
  return {
    agentId: matches[2],
    citySlug: matches[3]
  };
}

/**
 * Generate a canonical URL for an agent
 * This ensures we always use the same URL format for the agent
 * Uses the simplified ID-based format
 */
export function getAgentCanonicalUrl(agent: AgentData): string {
  const baseUrl = 'https://agentrav.com';
  // Always use the ID-only version as canonical
  return `${baseUrl}/agent/${agent.id}`;
}

/**
 * Generate a canonical URL for a city page
 */
export function getCityCanonicalUrl(cityName: string): string {
  const baseUrl = 'https://agentrav.com';
  const citySlug = formatUrlSlug(cityName);
  return `${baseUrl}/travel-agents/${citySlug}`;
}

/**
 * Generate a relative URL path for a city page
 * This version is meant to be used with React Router's Link component
 */
export function getCityRoutePath(cityName: string): string {
  const citySlug = formatUrlSlug(cityName);
  return `/travel-agents/${citySlug}`;
}

/**
 * Generate a canonical URL for a country/destination page
 */
export function getDestinationCanonicalUrl(countryName: string): string {
  const baseUrl = 'https://agentrav.com';
  const countrySlug = formatUrlSlug(countryName);
  return `${baseUrl}/destination/${countrySlug}`;
} 