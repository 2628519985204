import { supabase } from '../../../lib/supabase';
import { storage } from '../../../lib/storage';
import { HeavyDataLoader } from '../../../lib/heavyDataLoader';
import { CityStats, CountryStats, ProcessedCity, SliderImage, Destination } from '../types';

/**
 * Fetches slider images from storage
 */
export async function fetchSliderImages(): Promise<SliderImage[]> {
  try {
    const { data: files } = await storage.listBucketContents('home-slider');
    
    if (files && files.length > 0) {
      const processedImages = files.map((file: any) => {
        const baseUrl = storage.getPublicUrl('home-slider', file.name);
        
        // Create optimized URLs for different sizes
        const mobileUrl = baseUrl + '?w=480&h=720&q=70&fm=webp&fit=crop';
        const tinyUrl = baseUrl + '?w=16&h=24&q=10&blur=20&fm=webp';
        const tabletUrl = baseUrl + '?w=1024&h=1366&q=80&fm=webp&fit=crop';
        const desktopUrl = baseUrl + '?w=1920&h=1080&q=85&fm=webp&fit=crop';

        return {
          url: baseUrl,
          position: 'center',
          mobile: mobileUrl,
          tiny: tinyUrl,
          tablet: tabletUrl,
          desktop: desktopUrl
        };
      });

      return processedImages;
    }
    
    // Return an empty array if no images found
    return [];
  } catch (error) {
    console.error('Error loading slider images:', error);
    return [];
  }
}

/**
 * Fetches destinations and cities data
 */
export async function fetchDestinationsAndCities(): Promise<{
  destinations: Destination[];
  cities: CityStats[];
}> {
  try {
    // Create a data loader instance
    const dataLoader = new HeavyDataLoader();
    
    // Get visibility settings first
    const { data: settings, error: settingsError } = await supabase
      .from('country_settings')
      .select('clean_country, is_visible')
      .eq('is_visible', true);

    if (settingsError) throw settingsError;

    // Create a list of visible countries
    const visibleCountries = settings?.map(s => s.clean_country.toLowerCase()) || [];

    // Get all agents
    const { data: agents, error: agentsError } = await supabase
      .from('agents')
      .select('clean_country, country, is_featured, clean_city, rating, lat, lng')
      .not('clean_country', 'is', null)
      .not('clean_city', 'is', null);

    if (agentsError) throw agentsError;

    // Get country images
    const { data: imageData } = await supabase
      .from('country_images')
      .select('clean_country, country, listing_image_url, listing_image_credit');

    // Process data in Web Worker
    const { destinations, cities: processedCities } = await dataLoader.processData(
      agents || [],
      visibleCountries,
      imageData || []
    );

    // Load city details and images
    const citiesWithDetails = await Promise.all(
      processedCities.map(async (city: ProcessedCity) => {
        try {
          // Get city details including image, using normalized city name
          const { data: cityData } = await supabase
            .from('cities')
            .select('*')
            .ilike('name', city.city)
            .eq('country', city.country)
            .single();

          if (cityData) {
            return {
              ...city,
              description: cityData.description,
              highlights: cityData.highlights,
              image: {
                url: cityData.featured_image_url || cityData.image_url || 'https://images.unsplash.com/photo-1477959858617-67f85cf4f1df',
                credit: cityData.image_credit,
                position: cityData.image_position || 'center',
                isLoading: false
              }
            };
          }
          
          return {
            ...city,
            image: {
              url: 'https://images.unsplash.com/photo-1477959858617-67f85cf4f1df',
              position: 'center',
              isLoading: false
            }
          };
        } catch (err) {
          console.error(`Error loading data for ${city.city}:`, err);
          return {
            ...city,
            image: {
              ...city.image,
              isLoading: false
            }
          };
        }
      })
    );

    // Clean up worker when done
    dataLoader.terminate();
    
    return {
      destinations,
      cities: citiesWithDetails
    };
  } catch (error) {
    console.error('Error loading data:', error);
    
    return {
      destinations: [],
      cities: []
    };
  }
}

/**
 * Get region for a country
 */
export function getRegion(country: string): string {
  const regions: Record<string, string> = {
    'France': 'Europe',
    'Italy': 'Europe',
    'Spain': 'Europe',
    'United Kingdom': 'Europe',
    'Germany': 'Europe',
    'Japan': 'Asia',
    'China': 'Asia',
    'Thailand': 'Asia',
    'Singapore': 'Asia',
    'United Arab Emirates': 'Middle East',
    'Saudi Arabia': 'Middle East',
    'Qatar': 'Middle East',
    'United States': 'Americas',
    'Canada': 'Americas',
    'Mexico': 'Americas',
    'Brazil': 'Americas',
    'Argentina': 'Americas',
    'Australia': 'Oceania',
    'New Zealand': 'Oceania'
  };
  return regions[country] || 'Other';
} 