/**
 * Web Search Service using Tavily API
 * This service enables Marco to search the web for up-to-date information
 * about travel destinations, weather, events, and more.
 */

import { type Message } from "../types";

// Travel-specific search topics for better results
const TRAVEL_TOPICS = [
  "travel",
  "tourism",
  "vacation",
  "destination",
  "hotel",
  "flight",
  "restaurant",
  "attraction",
  "weather",
  "event",
  "tour",
  "activity",
  "news"
];

// Configuration
const MAX_RETRIES = 2;
const INITIAL_BACKOFF_MS = 500;

// Static fallback results for when the API is unavailable
const FALLBACK_RESULTS = {
  results: [
    {
      title: "Travel.State.Gov - Travel Advisories",
      content: "Official travel advisories and information for international travelers.",
      url: "https://travel.state.gov/content/travel/en/traveladvisories/traveladvisories.html"
    },
    {
      title: "Wikitravel - Free Travel Guide",
      content: "Collaborative travel guide with information on destinations worldwide.",
      url: "https://wikitravel.org/"
    },
    {
      title: "Lonely Planet Travel Guides",
      content: "Comprehensive travel information, guides and reviews for destinations around the world.",
      url: "https://www.lonelyplanet.com/"
    }
  ],
  answer: "Due to technical limitations, I'm providing some general travel resources that might help with your question."
};

export class WebSearchService {
  private apiKey: string | undefined;
  private enabled: boolean = true;
  private retryCount = 0;
  private lastErrorTime: number = 0;
  private consecutiveErrors: number = 0;

  constructor() {
    try {
      this.apiKey = import.meta.env.VITE_TAVILY_API_KEY;
      
      if (!this.apiKey) {
        console.error("TAVILY_API_KEY is not configured in environment variables");
        this.enabled = false;
        return;
      }
      
      console.log("Web search service initialized successfully with Tavily API");
    } catch (error) {
      console.error("Failed to initialize web search service:", error);
      this.enabled = false;
    }
  }

  /**
   * Check if the query would benefit from web search
   * @param query The user query
   * @returns True if the query likely needs real-time information
   */
  shouldUseWebSearch(query: string): boolean {
    // Don't attempt web search if we've had too many consecutive errors recently
    const errorCooldownMinutes = 5;
    const now = Date.now();
    if (this.consecutiveErrors > 3 && (now - this.lastErrorTime) < (errorCooldownMinutes * 60 * 1000)) {
      console.log(`Web search temporarily disabled due to consecutive errors. Will retry after cooldown.`);
      return false;
    }
    
    // Skip search for very short queries
    if (query.length < 10) return false;
    
    // Skip search for basic greeting messages
    if (/^(hi|hello|hey|how are you|what's up|good morning|good afternoon)/i.test(query)) {
      return false;
    }
    
    const lowerQuery = query.toLowerCase();
    
    // Keywords that suggest needing real-time information
    const realTimeKeywords = [
      "latest", "current", "today", "now", "recent",
      "weather", "forecast", "news", "update", "event",
      "price", "cost", "fee", "open", "hour", "schedule", 
      "restriction", "review", "rating", "best",
      "this year", "this month", "this week", "covid", "requirement"
    ];
    
    // Time-based patterns that suggest needing current information
    const timePatterns = [
      /\b(today|tomorrow|this week|this month|this year|currently|right now)\b/i,
      /\bin (\d{4}|\d{1,2}\/\d{1,2}\/\d{2,4})\b/i, // Dates like "in 2023" or "in 05/20/2023"
      /\bupcoming\b/i
    ];
    
    // Check for real-time keywords
    const hasRealTimeKeywords = realTimeKeywords.some(keyword => 
      lowerQuery.includes(keyword)
    );
    
    // Check for time patterns
    const hasTimePatterns = timePatterns.some(pattern => 
      pattern.test(query)
    );
    
    // Check for question patterns that often require current info
    const isCurrentInfoQuestion = /\b(what is|what are|is there|are there|how is|when is|tell me about)\b.*\b(now|current|today)\b/i.test(query);
    
    // Check if it's a travel-related query
    const isTravelQuery = TRAVEL_TOPICS.some(topic => 
      lowerQuery.includes(topic)
    );
    
    return (hasRealTimeKeywords || hasTimePatterns || isCurrentInfoQuestion) && isTravelQuery;
  }
  
  /**
   * Perform a web search and format the results for use in the assistant's response
   * @param query The search query
   * @returns Formatted search results
   */
  async search(query: string): Promise<string> {
    // Reset retry count for each new search
    this.retryCount = 0;
    
    if (!this.enabled || !this.apiKey) {
      return "I'm sorry, web search is currently unavailable. I'll answer based on my existing knowledge.";
    }
    
    return this.executeSearch(query);
  }
  
  /**
   * Execute search with retry logic
   */
  private async executeSearch(query: string): Promise<string> {
    try {
      // Add travel-specific context to get better results
      const enhancedQuery = `${query} travel information`;
      
      console.log(`Performing web search for: "${enhancedQuery}"`);
      
      // Try the main search endpoint first
      try {
        const response = await fetch("/api/search", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            query: enhancedQuery
          })
        });
        
        if (!response.ok) {
          throw new Error(`Search API error: ${response.status} ${response.statusText}`);
        }
        
        const data = await response.json();
        
        // Reset consecutive errors on success
        this.consecutiveErrors = 0;
        
        if (!data || !data.results || data.results.length === 0) {
          return "I couldn't find current information about that. Let me answer based on what I know.";
        }
        
        // Format the results
        const formattedResults = data.results.map((result: any, index: number) => {
          const title = result.title || "Search Result";
          const snippet = result.content || "";
          const url = result.url || "";
          
          return `[${index + 1}] "${title}": ${snippet} (Source: ${url})`;
        }).join("\n\n");
        
        // Include the answer summary if available
        const answerSummary = data.answer 
          ? `Summary: ${data.answer}\n\n` 
          : "";
        
        return `${answerSummary}Details from the web:\n\n${formattedResults}`;
      } catch (primaryError) {
        // If the primary API fails, use the direct fallback mechanism
        console.error("Primary search failed, using fallback:", primaryError);
        
        if (this.retryCount < MAX_RETRIES) {
          this.retryCount++;
          
          // Calculate backoff time with exponential backoff
          const backoffTime = INITIAL_BACKOFF_MS * Math.pow(2, this.retryCount - 1);
          console.log(`Retrying web search (attempt ${this.retryCount}) after ${backoffTime}ms backoff...`);
          
          // Wait for backoff period
          await new Promise(resolve => setTimeout(resolve, backoffTime));
          
          // Use the static fallback results for immediate response
          const fallbackResults = FALLBACK_RESULTS;
          
          // Format the fallback results
          const formattedResults = fallbackResults.results.map((result: any, index: number) => {
            return `[${index + 1}] "${result.title}": ${result.content} (Source: ${result.url})`;
          }).join("\n\n");
          
          return `${fallbackResults.answer}\n\nGeneral travel resources:\n\n${formattedResults}`;
        }
      }
    } catch (error) {
      console.error("Error performing web search:", error);
      
      // Update error tracking
      this.lastErrorTime = Date.now();
      this.consecutiveErrors++;
    }
    
    // Final fallback message
    return "I tried to search for current information but encountered some technical difficulties. Let me answer based on my existing knowledge instead.";
  }
  
  /**
   * Reset the error cooldown
   * Call this method when you want to reset the error tracking
   */
  resetErrorCooldown() {
    this.consecutiveErrors = 0;
    this.lastErrorTime = 0;
  }
}

// Create singleton instance
export const webSearchService = new WebSearchService(); 