import { ChatAnthropic } from '@langchain/anthropic';
import { 
  SystemMessagePromptTemplate,
  HumanMessagePromptTemplate,
  ChatPromptTemplate,
  MessagesPlaceholder
} from '@langchain/core/prompts';
import { RunnableSequence } from '@langchain/core/runnables';
import { ChatPromptValue } from '@langchain/core/prompt_values';
import { StringOutputParser } from '@langchain/core/output_parsers';
import { ConversationChain } from 'langchain/chains';
import { BufferMemory } from 'langchain/memory';

/**
 * LangChain service for Marco AI chat feature
 */
export class LangChainService {
  private model: ChatAnthropic;
  private chain: ConversationChain;
  private memory: BufferMemory;
  private systemPrompt: string;

  constructor() {
    console.log('Initializing LangChain service for chat...');
    
    if (!import.meta.env.VITE_ANTHROPIC_API_KEY) {
      console.error('ANTHROPIC_API_KEY is not set in environment variables');
      throw new Error('ANTHROPIC_API_KEY is required');
    }

    try {
      this.model = new ChatAnthropic({
        modelName: 'claude-3-haiku-20240307',
        temperature: 0.5,
        anthropicApiKey: import.meta.env.VITE_ANTHROPIC_API_KEY,
        maxTokens: 150,
      });

      this.memory = new BufferMemory({
        returnMessages: true,
        memoryKey: "chat_history",
      });

      this.systemPrompt = `You are Marco, a travel assistant. I prefer responses that are clear, helpful, and concise (typically under 2-3 sentences unless detail is required).

      Core Capabilities:
      - I can now search the web for real-time travel information such as weather, current events, travel advisories, opening hours, and more. When I use web search, I should cite sources.
      - I can help with hotel searches, activity recommendations, and travel planning.
      - I provide accurate, up-to-date travel advice and recommendations.

      Communication Style:
      - Be friendly, knowledgeable and conversational.
      - For simple questions, keep responses brief and to the point.
      - For complex requests, be thorough but organized.
      - When appropriate, cite sources from web searches to help users verify information.

      Hotel/Tour Search Rules:
      - If the user asks for hotels/tours and does NOT provide a location, ask ONLY for the location. Example: "Sure, which city are you interested in?"
      - If the user asks for hotels/tours and DOES provide a location (like "hotels in Paris"), confirm I'm searching. Example: "Searching for hotels in Paris now..."
      - Never invent hotels, prices, or availability - only provide results from actual searches.

      Web Search Use:
      - I should use web search for questions about current conditions, prices, events, etc.
      - When using web search results, I should integrate the information naturally and cite sources.
      - For travel questions that benefit from current information, I should rely on web search results.`;

      const chatPrompt = ChatPromptTemplate.fromPromptMessages([
        SystemMessagePromptTemplate.fromTemplate(this.systemPrompt),
        new MessagesPlaceholder("chat_history"),
        HumanMessagePromptTemplate.fromTemplate("{input}")
      ]);

      this.chain = new ConversationChain({
        memory: this.memory,
        prompt: chatPrompt,
        llm: this.model,
      });

      console.log('LangChain service for chat initialized successfully');
    } catch (error) {
      console.error('Error initializing LangChain service:', error);
      throw error;
    }
  }

  /**
   * Generate a response to a user message
   * @param message The user's message
   * @param context Optional context to include
   * @returns The AI response
   */
  async generateResponse(message: string, context: string = "") {
    try {
      console.log('Generating chat response for:', message);
      
      const response = await this.chain.call({
        input: message,
        context: context || undefined
      });
      
      return response.response as string;
    } catch (error) {
      console.error('Error generating chat response:', error);
      throw error;
    }
  }

  /**
   * Create a single prompt without conversation history
   * @param message The user's message
   * @param context Optional context to include
   * @returns The AI response
   */
  async generateSingleResponse(message: string, context: string = "") {
    try {
      // Create a standalone chain without memory
      const prompt = ChatPromptTemplate.fromMessages([
        ["system", this.systemPrompt + (context ? `\n\nContext: ${context}` : '')],
        ["human", message]
      ]);
      
      const chain = prompt.pipe(this.model).pipe(new StringOutputParser());
      const response = await chain.invoke({});
      
      return response;
    } catch (error) {
      console.error('Error generating single response:', error);
      throw error;
    }
  }

  /**
   * Reset the conversation history
   */
  resetConversation() {
    this.memory.clear();
  }
}

// Export a singleton instance
export const langchainService = new LangChainService(); 