import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight, Users } from 'lucide-react';
import { Carousel, CarouselCard } from '../../../components/ui/apple-cards-carousel';
import { Destination } from '../types';
import { optimizeImageUrl, IMAGE_SIZES } from '../services/imageService';

interface PopularDestinationsSectionProps {
  destinations: Destination[];
  loading: boolean;
}

const PopularDestinationsSection: React.FC<PopularDestinationsSectionProps> = ({ 
  destinations, 
  loading 
}) => {
  if (loading || destinations.length === 0) {
    return null;
  }

  const destinationCards = destinations.map((destination, index) => {
    // Create clean URL-safe version of country name, handling both clean_country and regular country
    const cleanCountry = (destination.clean_country || destination.country)
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/-+/g, '-')  // Replace multiple hyphens with single hyphen
      .replace(/^-|-$/g, '');  // Remove leading/trailing hyphens
    
    // Optimize image URL, handle potential missing image
    const imgSrc = destination.image || 'https://source.unsplash.com/random/?travel';
    const optimizedSrc = optimizeImageUrl(imgSrc, IMAGE_SIZES.CARD);
    
    return (
      <Link
        key={destination.id}
        to={`/destination/${cleanCountry}`}
        className="block"
      >
        <CarouselCard
          card={{
            category: destination.region || 'Destination',
            title: destination.country,
            src: optimizedSrc,
            content: (
              <div className="absolute inset-0 flex flex-col justify-end p-8 bg-gradient-to-t from-black/90 via-black/50 to-transparent">
                <div className="space-y-4">
                  <div className="flex items-center justify-between">
                    <h3 className="text-2xl sm:text-3xl font-medium text-white">
                      {destination.country}
                    </h3>
                    {destination.featuredCount > 0 && (
                      <span className="px-3 py-1.5 bg-white/10 backdrop-blur-sm text-white/90 text-xs font-medium tracking-wide rounded-full">
                        {destination.featuredCount} Featured
                      </span>
                    )}
                  </div>
                  
                  <div className="flex items-center gap-6 text-sm text-white/60">
                    <div className="flex items-center">
                      <Users className="w-4 h-4 mr-1.5 opacity-50" />
                      <span>{destination.agentCount} Agents</span>
                    </div>
                  </div>

                  <div className="transform translate-y-8 opacity-0 group-hover:translate-y-0 group-hover:opacity-100 transition-all duration-300">
                    <span className="inline-flex items-center text-white text-sm">
                      View Destination
                      <ArrowRight className="w-4 h-4 ml-2 opacity-50" />
                    </span>
                  </div>
                </div>
              </div>
            ),
          }}
          index={index}
        />
      </Link>
    );
  });

  return (
    <div className="w-full py-16 sm:py-20 lg:py-24 bg-black">
      <div className="max-w-7xl mx-auto px-3 sm:px-4">
        <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-3 sm:gap-4 mb-4 sm:mb-6">
          <div className="space-y-1 sm:space-y-2">
            <h2 className="text-xl sm:text-2xl lg:text-[36px] font-display font-medium tracking-tight text-white leading-none">
              Popular Destinations
            </h2>
            <p className="text-white/60 text-sm sm:text-base font-light leading-relaxed max-w-[480px]">
              Explore destinations with our expert travel specialists
            </p>
          </div>
          <Link
            to="/destinations"
            className="group inline-flex items-center justify-center px-4 sm:px-8 py-2 sm:py-3 bg-white/[0.08] hover:bg-white/[0.12] text-white text-sm sm:text-base font-light tracking-wide rounded-lg sm:rounded-xl backdrop-blur-sm transition-all duration-300 active:scale-95 hover:scale-[1.02] w-full sm:w-auto"
          >
            View More Destinations
            <ArrowRight className="ml-2 w-4 h-4 opacity-60 group-hover:opacity-100 group-hover:translate-x-0.5 transition-all duration-300" />
          </Link>
        </div>
      </div>
      <div className="mb-8 sm:mb-12 lg:mb-16">
        <Carousel items={destinationCards} />
      </div>
    </div>
  );
};

export default PopularDestinationsSection; 