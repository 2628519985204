import { HotelType, OfferType, City } from '../types/chatTypes';
import { handleError } from '../../../../lib/utils/error';
import { searchHotels, getHotelOffers } from '../../../hotels/services/hotelSearchService';
import { SimplifiedHotel } from '../../types';

/**
 * Service for hotel-related functionality
 */
export class HotelService {
  /**
   * Convert a location string to a City object
   */
  async locationStringToCity(locationString: string): Promise<City | null> {
    try {
      console.log(`[locationStringToCity] Converting "${locationString}" to City object`);
      
      // Filter out invalid or vague location queries
      if (locationString.length < 2 || locationString.split(' ').length > 5) {
        console.log(`[locationStringToCity] Invalid location string: "${locationString}"`);
        return null;
      }
      
      // Try to lookup this location using the searchLocations function
      const { searchLocations } = await import('../../../hotels/services/hotelSearchService');
      const locations = await searchLocations(locationString);
      
      if (locations && locations.length > 0) {
        console.log(`[locationStringToCity] Found ${locations.length} matching locations, using first match`);
        return locations[0];
      }
      
      // Fallback: For common cities, provide hardcoded coordinates
      const commonCityCoords: Record<string, {lat: number, lng: number, country: string}> = {
        'new york': {lat: 40.7128, lng: -74.0060, country: 'USA'},
        'london': {lat: 51.5074, lng: -0.1278, country: 'UK'},
        'paris': {lat: 48.8566, lng: 2.3522, country: 'France'},
        'tokyo': {lat: 35.6762, lng: 139.6503, country: 'Japan'},
        'rome': {lat: 41.9028, lng: 12.4964, country: 'Italy'},
        'sydney': {lat: -33.8688, lng: 151.2093, country: 'Australia'}
      };
      
      const lowercaseLocation = locationString.toLowerCase();
      for (const [city, coords] of Object.entries(commonCityCoords)) {
        if (lowercaseLocation.includes(city)) {
          console.log(`[locationStringToCity] Using hardcoded coordinates for "${city}"`);
          return {
            name: city.charAt(0).toUpperCase() + city.slice(1),
            country: coords.country,
            latitude: coords.lat,
            longitude: coords.lng
          };
        }
      }
      
      console.log(`[locationStringToCity] No locations found, unable to resolve city`);
      return null;
    } catch (error) {
      console.error(`[locationStringToCity] Error converting location:`, error);
      return null;
    }
  }

  /**
   * Search for hotels and return simplified results
   */
  async searchHotels(location: string): Promise<{ 
    simplifiedHotels: SimplifiedHotel[]; 
    conversationalResponse: string;
    error?: string;
  }> {
    console.log(`[searchAndDispatchHotels] Starting search for location: "${location}"`);
    
    const checkInDate = new Date();
    // Set default dates (e.g., a week from now for 2 nights)
    checkInDate.setDate(checkInDate.getDate() + 7);
    const checkOutDate = new Date(checkInDate);
    checkOutDate.setDate(checkOutDate.getDate() + 2);
    const formattedCheckIn = checkInDate.toISOString().split('T')[0];
    const formattedCheckOut = checkOutDate.toISOString().split('T')[0];
    const adults = 2; // Default adults

    try {
      console.log(`Searching hotels in ${location} from ${formattedCheckIn} to ${formattedCheckOut}`);
      
      // Convert string location to City object
      const city = await this.locationStringToCity(location);
      if (!city) {
        console.log(`Could not convert "${location}" to a valid City object`);
        return {
          simplifiedHotels: [],
          conversationalResponse: `I couldn't find "${location}" as a valid destination. Could you please provide a specific city name? For example, "New York", "London", or "Tokyo".`,
          error: 'invalid_location'
        };
      }
      
      // Validate coordinates to avoid the "Cannot search hotels without IATA code or geocoordinates" error
      if ((!city.latitude || !city.longitude) && !city.iataCode) {
        console.log(`[searchAndDispatchHotels] Missing coordinates or IATA code for "${city.name}"`);
        return {
          simplifiedHotels: [],
          conversationalResponse: `I couldn't find enough location information for "${city.name}". Could you please provide a more specific city name?`,
          error: 'missing_coordinates'
        };
      }

      // Create properly formatted parameters for searchHotels
      const searchParams = {
        city: city,
        checkIn: formattedCheckIn,
        checkOut: formattedCheckOut,
        adults: adults
      };
      
      console.log('[searchAndDispatchHotels] Calling searchHotels with params:', JSON.stringify(searchParams));
      const result = await searchHotels(searchParams);
      const hotels = result.hotels || [];
      console.log(`[searchAndDispatchHotels] searchHotels returned ${hotels?.length ?? 0} results.`);

      if (!hotels || hotels.length === 0) {
        console.log(`No hotels found for ${location}`);
        return {
          simplifiedHotels: [],
          conversationalResponse: `I couldn't find any available hotels in ${location} for those dates.`,
          error: 'no_hotels_found'
        };
      }

      console.log(`Found ${hotels.length} hotels. Fetching offers...`);

      // Fetch actual offers
      const hotelIds = hotels.map(h => h.hotelId);
      console.log(`[searchAndDispatchHotels] Calling getHotelOffers for ${hotelIds.length} hotel(s).`);
      const offersData = await getHotelOffers(hotelIds, formattedCheckIn, formattedCheckOut, adults, 1, 'USD');

      console.log(`Received offers for ${Object.keys(offersData).length} hotels.`);

      // Map results
      const simplifiedHotels: SimplifiedHotel[] = hotels.slice(0, 3).map(hotel => { // Limit to top 3 results for chat display
        // Get offers for this hotel if they exist
        const hotelOffers = (offersData[hotel.hotelId] || []) as unknown as OfferType[];
        
        // Convert AmadeusHotel to Hotel format expected by mapToSimplifiedHotel
        const hotelForMapping: HotelType = {
          hotelId: hotel.hotelId,
          name: hotel.name,
          rating: hotel.rating !== undefined ? String(hotel.rating) : undefined,
          address: hotel.address,
          media: hotel.media,
          amenities: hotel.amenities
        };
        
        // Pass the converted hotel object
        return this.mapToSimplifiedHotel(hotelForMapping, hotelOffers);
      });

      console.log(`[searchAndDispatchHotels] Mapped to ${simplifiedHotels.length} simplified hotels for display.`);

      // Cast offersData to the expected type for extract functions
      const offersDataForSummary = offersData as Record<string, OfferType[]>;
      const roomPriceSummary = this.extractRoomPriceSummary(offersDataForSummary, hotels);
      
      // Create a conversational response
      let conversationalResponse = '';
      
      if (simplifiedHotels.length > 0) {
        // Get price range info
        const prices = this.extractPriceRanges(offersDataForSummary);
        
        // Build conversational response
        conversationalResponse = `I found ${Math.min(hotels.length, 3)} hotels in ${location} for your dates (${formattedCheckIn} to ${formattedCheckOut}).`;
        
        if (prices.minPrice && prices.maxPrice) {
          conversationalResponse += ` Prices range from $${prices.minPrice} to $${prices.maxPrice} per night.`;
        }
        
        if (roomPriceSummary) {
          conversationalResponse += ` ${roomPriceSummary}`;
        }
        
        return {
          simplifiedHotels,
          conversationalResponse
        };
      } else {
        // This might happen if hotels were found but no offers were available
        return {
          simplifiedHotels: [],
          conversationalResponse: `I found some hotels in ${location}, but couldn't get pricing details for those dates right now.`,
          error: 'no_offers'
        };
      }
    } catch (error) {
      console.error(`[searchAndDispatchHotels] Error during search/offer fetch for ${location}:`, error);
      handleError(error, `Hotel search failed for ${location}`);
      return {
        simplifiedHotels: [],
        conversationalResponse: `Sorry, I encountered an error trying to find hotel details for ${location}. Please try again later.`,
        error: 'search_error'
      };
    }
  }

  /**
   * Map from API hotel object to simplified hotel object
   */
  mapToSimplifiedHotel(hotel: HotelType, offers?: OfferType[]): SimplifiedHotel {
    let priceRange = 'N/A';
    let minPriceDetails = '';

    // Check if offers exist and is an array
    if (offers && Array.isArray(offers) && offers.length > 0) {
        // Safely access price details, assuming structure like { price: { total: string, currency: string } }
        const prices = offers
            .map(offer => {
                try {
                    // Check if price and total exist
                    if (offer?.price?.total) {
                        return parseFloat(offer.price.total);
                    }
                    return NaN;
                } catch {
                    return NaN; // Handle potential errors during parsing
                }
            })
            .filter(p => !isNaN(p)); // Filter out invalid prices

        if (prices.length > 0) {
            const minPrice = Math.min(...prices);
            const currency = offers[0]?.price?.currency || 'USD'; // Default currency

            // Determine price range symbols
            if (minPrice < 100) priceRange = '$';
            else if (minPrice < 200) priceRange = '$$';
            else if (minPrice < 300) priceRange = '$$$';
            else priceRange = '$$$$';

            // Add price details string
            minPriceDetails = ` (from ${currency} ${minPrice.toFixed(0)})`;
            priceRange += minPriceDetails;
        }
    }

    // Ensure rating is parsed correctly if it's a string
    const ratingValue = typeof hotel.rating === 'string' ? parseFloat(hotel.rating) : hotel.rating;

    // Extract room information from offers
    const roomInfo = this.extractRoomInfo(offers) || [];
    
    // Extract amenities from hotel if available
    const amenities = hotel.amenities || [];

    return {
        id: hotel.hotelId,
        name: hotel.name || 'Unknown Hotel Name', // Add fallback
        // Use parsed rating, ensure it's valid number
        rating: ratingValue !== undefined && !isNaN(ratingValue) ? ratingValue : undefined,
        priceRange: priceRange,
        // Safely access image URL
        imageUrl: hotel.media?.[0]?.uri,
        // Safely access city name
        city: hotel.address?.cityName,
        // Add extracted amenities
        amenities: amenities.length > 0 ? amenities : undefined,
        // Add extracted room info
        roomInfo: roomInfo.length > 0 ? roomInfo : undefined,
    };
  }

  /**
   * Extract room information from offers
   */
  extractRoomInfo(offers?: OfferType[]): SimplifiedHotel['roomInfo'] {
    const roomInfo: SimplifiedHotel['roomInfo'] = [];
    
    if (!offers || !Array.isArray(offers) || offers.length === 0) {
        return roomInfo;
    }
    
    // Create a Map to deduplicate room types with proper type for room info
    const roomTypeMap = new Map<string, {
      type: string;
      price?: number;
      currency?: string;
      bedType?: string;
      description?: string;
    }>();
    
    offers.forEach(offer => {
        if (!offer?.room?.typeEstimated?.category) return;
        
        const roomType = offer.room.typeEstimated.category;
        const bedType = offer.room.typeEstimated.bedType || undefined;
        const price = offer.price?.total ? parseFloat(offer.price.total) : undefined;
        const currency = offer.price?.currency || 'USD';
        const description = offer.room?.description?.text || '';
        
        // Use room type as key to deduplicate
        const key = `${roomType}-${bedType || ''}`;
        
        if (!roomTypeMap.has(key) || (price && (!roomTypeMap.get(key)?.price || price < (roomTypeMap.get(key)?.price || Infinity)))) {
            roomTypeMap.set(key, {
                type: roomType,
                price,
                currency,
                bedType,
                description
            });
        }
    });
    
    // Convert Map to array
    roomTypeMap.forEach(room => roomInfo.push(room));
    
    return roomInfo;
  }

  /**
   * Extract price ranges from offer data
   */
  extractPriceRanges(offersData: Record<string, OfferType[]>): { minPrice: number | null; maxPrice: number | null } {
    let minPrice: number | null = null;
    let maxPrice: number | null = null;
    
    // Iterate through all offers for all hotels
    Object.values(offersData).forEach((hotelOffers: OfferType[]) => {
      if (!Array.isArray(hotelOffers) || hotelOffers.length === 0) return;
      
      hotelOffers.forEach(offer => {
        if (!offer?.price?.total) return;
        
        const price = parseFloat(offer.price.total);
        if (isNaN(price)) return;
        
        // Update min/max prices
        if (minPrice === null || price < minPrice) minPrice = price;
        if (maxPrice === null || price > maxPrice) maxPrice = price;
      });
    });
    
    return { minPrice, maxPrice };
  }
  
  /**
   * Extract room types and create a summary
   */
  extractRoomPriceSummary(offersData: Record<string, OfferType[]>, hotels: any[]): string {
    // Get all room types and categories
    const roomTypes = new Set<string>();
    const roomCategories = new Map<string, number[]>();
    
    Object.entries(offersData).forEach(([_hotelId, hotelOffers]: [string, OfferType[]]) => {
      if (!Array.isArray(hotelOffers) || hotelOffers.length === 0) return;
      
      hotelOffers.forEach(offer => {
        if (!offer?.room?.typeEstimated?.category) return;
        
        const roomCategory = offer.room.typeEstimated.category;
        const price = parseFloat(offer.price?.total || '0');
        
        roomTypes.add(roomCategory);
        
        if (!roomCategories.has(roomCategory)) {
          roomCategories.set(roomCategory, [price]);
        } else {
          roomCategories.get(roomCategory)?.push(price);
        }
      });
    });
    
    // Create summary text
    if (roomTypes.size === 0) return '';
    
    let summary = 'Room options include ';
    const roomDetails: string[] = [];
    
    roomCategories.forEach((prices, category) => {
      if (prices.length > 0) {
        const avgPrice = prices.reduce((sum, price) => sum + price, 0) / prices.length;
        roomDetails.push(`${category} (avg. $${Math.round(avgPrice)}/night)`);
      } else {
        roomDetails.push(category);
      }
    });
    
    summary += roomDetails.join(', ') + '.';
    return summary;
  }
} 