import React, { useEffect, useState } from 'react';
import { X, MapPin, Clock, Calendar, DollarSign, Star, Users, Languages, Info, Tag, ChevronLeft, ChevronRight } from 'lucide-react';
import { Tour } from '../types';

interface TourModalProps {
  tour: Tour;
  onClose: () => void;
}

export default function TourModal({ tour, onClose }: TourModalProps) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [modalOpacity, setModalOpacity] = useState(0);
  
  // Handle image navigation
  const nextImage = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (tour.images && tour.images.length > 1) {
      setCurrentImageIndex((prevIndex) => 
        prevIndex === tour.images!.length - 1 ? 0 : prevIndex + 1
      );
    }
  };
  
  const prevImage = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (tour.images && tour.images.length > 1) {
      setCurrentImageIndex((prevIndex) => 
        prevIndex === 0 ? tour.images!.length - 1 : prevIndex - 1
      );
    }
  };

  // Add keyboard event listener for Escape key
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      } else if (event.key === 'ArrowRight' && tour.images && tour.images.length > 1) {
        setCurrentImageIndex((prevIndex) => 
          prevIndex === tour.images!.length - 1 ? 0 : prevIndex + 1
        );
      } else if (event.key === 'ArrowLeft' && tour.images && tour.images.length > 1) {
        setCurrentImageIndex((prevIndex) => 
          prevIndex === 0 ? tour.images!.length - 1 : prevIndex - 1
        );
      }
    };
    
    window.addEventListener('keydown', handleKeyDown);
    
    // Disable scroll on body
    document.body.style.overflow = 'hidden';
    
    // Fade in effect
    setTimeout(() => {
      setModalOpacity(1);
    }, 10);
    
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      // Re-enable scroll on body
      document.body.style.overflow = 'auto';
    };
  }, [onClose, tour.images]);

  // Format price for display
  const price = parseFloat(tour.price.amount);
  const formattedPrice = isNaN(price) ? '---' : price.toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  });
  
  // Get current image
  const currentImage = tour.images && tour.images.length > 0 
    ? tour.images[currentImageIndex] 
    : '/placeholder-tour.jpg';

  // Handle close with animation
  const handleClose = () => {
    setModalOpacity(0);
    setTimeout(onClose, 300);
  };

  return (
    <div 
      className="fixed inset-0 bg-black/80 backdrop-blur-sm z-50 overflow-y-auto transition-opacity duration-300"
      style={{ opacity: modalOpacity }}
      onClick={handleClose}
    >
      <div 
        className="flex min-h-full items-center justify-center p-4 py-6 sm:p-6 sm:py-8"
        onClick={(e) => e.stopPropagation()}
      >
        <div 
          className="w-full max-w-4xl bg-gradient-to-b from-gray-800/90 to-gray-900/90 rounded-xl overflow-hidden shadow-xl border border-gray-700/30 relative transition-all duration-300"
          style={{ 
            transform: `translateY(${modalOpacity ? '0' : '10px'})`
          }}
        >
          {/* Close button */}
          <button
            onClick={handleClose}
            className="absolute top-3 right-3 z-20 bg-black/40 text-white p-1.5 rounded-full hover:bg-black/60 transition-colors duration-200 border border-white/10"
            aria-label="Close modal"
          >
            <X className="h-5 w-5" />
          </button>
          
          {/* Tour Image Header with Gallery Navigation */}
          <div className="relative h-56 sm:h-72 w-full overflow-hidden bg-gray-900">
            <img 
              src={currentImage} 
              alt={tour.name} 
              className="w-full h-full object-cover transition-opacity duration-300"
              style={{ objectPosition: 'center' }}
            />
            <div className="absolute inset-0 bg-gradient-to-t from-gray-900 via-gray-900/40 to-transparent"></div>
            
            {/* Image gallery navigation */}
            {tour.images && tour.images.length > 1 && (
              <>
                <button 
                  onClick={prevImage}
                  className="absolute left-3 top-1/2 transform -translate-y-1/2 bg-black/50 text-white p-1.5 rounded-full hover:bg-black/70 transition-colors border border-white/10"
                  aria-label="Previous image"
                >
                  <ChevronLeft className="h-5 w-5" />
                </button>
                <button 
                  onClick={nextImage}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 bg-black/50 text-white p-1.5 rounded-full hover:bg-black/70 transition-colors border border-white/10"
                  aria-label="Next image"
                >
                  <ChevronRight className="h-5 w-5" />
                </button>
                
                {/* Image counter */}
                <div className="absolute bottom-20 right-4 bg-black/60 text-white text-xs py-1 px-2 rounded-full">
                  {currentImageIndex + 1} / {tour.images.length}
                </div>
              </>
            )}
            
            <div className="absolute bottom-4 left-4 right-4">
              <div className="flex items-center mb-1.5">
                <MapPin className="h-4 w-4 text-primary mr-1.5" />
                <span className="text-sm text-gray-300">{tour.location.city}, {tour.location.country}</span>
              </div>
              <h2 className="text-2xl sm:text-3xl font-semibold text-white leading-tight tracking-tight">{tour.name}</h2>
            </div>
          </div>
          
          {/* Content area */}
          <div className="p-5 sm:p-7">
            {/* Tour info section */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-7 mb-7">
              <div className="space-y-5">
                <div className="flex items-start">
                  <Clock className="h-5 w-5 text-primary mr-3 mt-0.5" />
                  <div>
                    <h3 className="text-white font-medium mb-1">Duration</h3>
                    <p className="text-gray-300 text-sm">{tour.duration}</p>
                  </div>
                </div>
                
                {tour.rating && (
                  <div className="flex items-start">
                    <Star className="h-5 w-5 text-primary mr-3 mt-0.5" />
                    <div>
                      <h3 className="text-white font-medium mb-1">Rating</h3>
                      <div className="flex items-center">
                        <div className="flex items-center">
                          {[...Array(5)].map((_, i) => (
                            <Star 
                              key={i} 
                              className={`h-3.5 w-3.5 ${i < Math.round(tour.rating || 0) 
                                ? 'text-primary fill-primary' 
                                : 'text-gray-500'}`} 
                            />
                          ))}
                        </div>
                        <span className="text-gray-300 text-sm ml-2">
                          {tour.rating?.toFixed(1)} {tour.reviewCount ? `(${tour.reviewCount})` : ''}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                
                {(tour.minimumParticipants || tour.maximumParticipants) && (
                  <div className="flex items-start">
                    <Users className="h-5 w-5 text-primary mr-3 mt-0.5" />
                    <div>
                      <h3 className="text-white font-medium mb-1">Group Size</h3>
                      <p className="text-gray-300 text-sm">
                        {tour.minimumParticipants === tour.maximumParticipants 
                          ? `${tour.minimumParticipants} participants`
                          : `${tour.minimumParticipants || 1} - ${tour.maximumParticipants || 'unlimited'} participants`
                        }
                      </p>
                    </div>
                  </div>
                )}
              </div>
              
              <div className="space-y-5">
                {tour.languages && tour.languages.length > 0 && (
                  <div className="flex items-start">
                    <Languages className="h-5 w-5 text-primary mr-3 mt-0.5" />
                    <div>
                      <h3 className="text-white font-medium mb-1">Languages</h3>
                      <p className="text-gray-300 text-sm">
                        {tour.languages.join(', ')}
                      </p>
                    </div>
                  </div>
                )}
                
                {tour.categories && tour.categories.length > 0 && (
                  <div className="flex items-start">
                    <Tag className="h-5 w-5 text-primary mr-3 mt-0.5" />
                    <div>
                      <h3 className="text-white font-medium mb-1">Categories</h3>
                      <div className="flex flex-wrap gap-2 mt-1">
                        {tour.categories.map((category, idx) => (
                          <span key={idx} className="px-2.5 py-1 bg-black/20 text-gray-300 text-xs rounded-full border border-gray-700/30">
                            {category.replace(/_/g, ' ')}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
                
                <div className="flex items-start">
                  <DollarSign className="h-5 w-5 text-primary mr-3 mt-0.5" />
                  <div>
                    <h3 className="text-white font-medium mb-1">Price</h3>
                    <p className="text-gray-300 text-sm">
                      <span className="text-primary font-semibold text-lg">${formattedPrice}</span>
                      <span className="text-gray-400 ml-1">per person</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            
            {/* Highlights */}
            {tour.highlights && tour.highlights.length > 0 && (
              <div className="mb-7">
                <h3 className="text-white text-lg font-medium mb-3 flex items-center">
                  <span className="inline-block w-6 h-0.5 bg-primary mr-2"></span>
                  Highlights
                </h3>
                <div className="bg-black/20 rounded-lg p-5 border border-gray-700/30">
                  <ul className="space-y-3">
                    {tour.highlights.map((highlight, idx) => (
                      <li key={idx} className="flex items-start">
                        <span className="text-primary mr-2.5">•</span>
                        <span className="text-gray-300 text-sm">{highlight}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
            
            {/* Description */}
            <div className="mb-7">
              <h3 className="text-white text-lg font-medium mb-3 flex items-center">
                <span className="inline-block w-6 h-0.5 bg-primary mr-2"></span>
                Description
              </h3>
              <div className="bg-black/20 rounded-lg p-5 border border-gray-700/30">
                <p className="text-gray-300 text-sm whitespace-pre-line leading-relaxed">
                  {tour.description || tour.shortDescription || 'No description available.'}
                </p>
              </div>
            </div>
            
            {/* Action buttons */}
            <div className="mt-7 flex flex-col sm:flex-row gap-3 sm:gap-4">
              {tour.bookingLink ? (
                <a 
                  href={tour.bookingLink} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="bg-primary text-black py-3 px-6 rounded-lg font-medium hover:bg-primary/90 transition-all duration-200 flex-grow text-center"
                >
                  Book Now
                </a>
              ) : (
                <button 
                  className="bg-primary text-black py-3 px-6 rounded-lg font-medium hover:bg-primary/90 transition-all duration-200 flex-grow"
                >
                  Book Now
                </button>
              )}
              
              <button 
                onClick={handleClose}
                className="bg-black/30 text-white hover:bg-black/40 py-3 px-6 rounded-lg font-medium transition-colors duration-200 border border-gray-700/50"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      
      <style>
        {`
          @keyframes fadeInUp {
            from {
              opacity: 0;
              transform: translateY(10px);
            }
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }
        `}
      </style>
    </div>
  );
} 