import { Helmet } from 'react-helmet-async';
import { LocalBusiness } from '../types/schema';

export interface SEOProps {
  title: string;
  description: string;
  schema?: LocalBusiness | Record<string, any> | Array<LocalBusiness | Record<string, any>>;
  meta?: Array<{
    name: string;
    content?: string;
  }>;
  type?: string;
  image?: string;
  publishedTime?: string;
  modifiedTime?: string;
  author?: string;
  canonicalUrl?: string;
  keywords?: string[];
}

export default function SEO({ 
  title, 
  description, 
  schema, 
  meta,
  type,
  image,
  publishedTime,
  modifiedTime,
  author,
  canonicalUrl,
  keywords
}: SEOProps) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      {keywords && <meta name="keywords" content={keywords.join(', ')} />}
      {meta && meta.map(item => <meta key={item.name} name={item.name} content={item.content} />)}
      
      {/* OpenGraph */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {type && <meta property="og:type" content={type} />}
      {image && <meta property="og:image" content={image} />}
      {publishedTime && <meta property="article:published_time" content={publishedTime} />}
      {modifiedTime && <meta property="article:modified_time" content={modifiedTime} />}
      {author && <meta property="article:author" content={author} />}
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      
      {/* Twitter */}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {image && <meta name="twitter:image" content={image} />}
      
      {/* Schema.org JSON-LD */}
      {schema && (
        <script type="application/ld+json">
          {JSON.stringify(Array.isArray(schema) ? schema : [schema])}
        </script>
      )}
    </Helmet>
  );
} 