import { AmadeusService, HotelOfferSearch } from '../../../services/amadeus';
import { BookingFormData } from '../components/HotelBookingForm';

// Reuse the AmadeusService instance from hotelSearchService
const amadeusService = new AmadeusService(
  "zyQPNk4iZeGyOAlPoT6dArzsESJi1vOo",  // Production API Key
  "3SwjJ7dCPwxUMHoz",  // Production API Secret
  true  // Set to true for production
);

/**
 * Interface for the response from the booking API
 */
export interface BookingResponse {
  id: string;
  hotelBookings: {
    id: string;
    bookingStatus: string;
    confirmationNumber?: string;
    hotelId: string;
    hotelName: string;
    checkInDate: string;
    checkOutDate: string;
    roomType: string;
    total: string;
    currency: string;
  }[];
  success: boolean;
  message?: string;
}

/**
 * Create a hotel booking using the Amadeus API
 */
export async function createHotelBooking(
  offer: HotelOfferSearch, 
  bookingData: BookingFormData
): Promise<BookingResponse> {
  try {
    // For demo purposes, we're mocking the response instead of making a real API call
    // WARNING: Do not perform test bookings in production as mentioned in the API docs
    // In a real implementation, this would call the Amadeus booking endpoint
    
    // Normally, we would make a call like this:
    // const response = await amadeusService.bookHotelOffer({
    //   data: {
    //     type: "hotel-order",
    //     guests: bookingData.guests,
    //     roomAssociations: [
    //       {
    //         guestReferences: bookingData.guests.map(guest => ({ guestReference: guest.tid.toString() })),
    //         hotelOfferId: offer.id
    //       }
    //     ],
    //     payment: {
    //       method: bookingData.payment.method,
    //       paymentCard: {
    //         paymentCardInfo: {
    //           vendorCode: bookingData.payment.cardInfo.vendorCode,
    //           cardNumber: bookingData.payment.cardInfo.cardNumber,
    //           expiryDate: bookingData.payment.cardInfo.expiryDate,
    //           holderName: bookingData.payment.cardInfo.holderName
    //         }
    //       }
    //     }
    //   }
    // });
    
    // Instead, we'll mock a successful response
    console.log('Creating hotel booking (mock):', { offer, bookingData });
    
    // Get the first offer for the details
    const hotelOffer = offer.offers?.[0];
    
    // Generate a mock confirmation ID
    const mockConfirmationId = 'HB' + Math.floor(Math.random() * 1000000).toString().padStart(6, '0');
    
    // Simulate API latency
    await new Promise(resolve => setTimeout(resolve, 1500));
    
    // Return a mock response
    return {
      id: mockConfirmationId,
      hotelBookings: [
        {
          id: 'BK' + Math.floor(Math.random() * 1000000).toString().padStart(6, '0'),
          bookingStatus: 'CONFIRMED',
          confirmationNumber: mockConfirmationId,
          hotelId: offer.hotel?.hotelId || 'UNKNOWN',
          hotelName: offer.hotel?.name || 'Hotel Name',
          checkInDate: hotelOffer?.checkInDate || new Date().toISOString().split('T')[0],
          checkOutDate: hotelOffer?.checkOutDate || new Date().toISOString().split('T')[0],
          roomType: hotelOffer?.room?.type || 'STANDARD',
          total: hotelOffer?.price?.total || '0',
          currency: hotelOffer?.price?.currency || 'USD'
        }
      ],
      success: true
    };
  } catch (error) {
    console.error('Error creating hotel booking:', error);
    
    // Return error information
    return {
      id: '',
      hotelBookings: [],
      success: false,
      message: error instanceof Error ? error.message : 'An unknown error occurred'
    };
  }
}

/**
 * Modify or cancel an existing booking
 */
export async function cancelHotelBooking(bookingId: string): Promise<BookingResponse> {
  try {
    // For demo purposes, we're mocking the response
    console.log('Cancelling hotel booking (mock):', bookingId);
    
    // Simulate API latency
    await new Promise(resolve => setTimeout(resolve, 1500));
    
    // Return a mock response
    return {
      id: bookingId,
      hotelBookings: [],
      success: true,
      message: 'Booking cancelled successfully'
    };
  } catch (error) {
    console.error('Error cancelling hotel booking:', error);
    
    // Return error information
    return {
      id: bookingId,
      hotelBookings: [],
      success: false,
      message: error instanceof Error ? error.message : 'An unknown error occurred'
    };
  }
}

/**
 * Retrieve booking details
 */
export async function getHotelBooking(bookingId: string): Promise<BookingResponse> {
  try {
    // For demo purposes, we're mocking the response
    console.log('Retrieving hotel booking (mock):', bookingId);
    
    // Simulate API latency
    await new Promise(resolve => setTimeout(resolve, 1500));
    
    // Check if the booking ID is valid (has a proper format)
    if (!bookingId || !bookingId.startsWith('HB')) {
      throw new Error('Invalid booking ID');
    }
    
    // Return a mock response with mock data
    return {
      id: bookingId,
      hotelBookings: [
        {
          id: 'BK' + bookingId.substring(2),
          bookingStatus: 'CONFIRMED',
          confirmationNumber: bookingId,
          hotelId: 'MOCKHOTEL',
          hotelName: 'Grand Hotel Mock',
          checkInDate: '2023-12-20',
          checkOutDate: '2023-12-25',
          roomType: 'DELUXE',
          total: '750.00',
          currency: 'USD'
        }
      ],
      success: true
    };
  } catch (error) {
    console.error('Error retrieving hotel booking:', error);
    
    // Return error information
    return {
      id: bookingId,
      hotelBookings: [],
      success: false,
      message: error instanceof Error ? error.message : 'An unknown error occurred'
    };
  }
} 