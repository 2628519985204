import React, { useState, useEffect } from 'react';
import { DateRange } from 'react-day-picker';
import { Grid, List, Map as MapIcon, AlertCircle, Compass, Search, Globe } from 'lucide-react';
import SearchForm from './components/SearchForm';
import FilterOptions, { FilterState } from './components/FilterOptions';
import TourCard from './components/TourCard';
import TourModal from './components/TourModal';
import TourMap from './components/TourMap';
import { searchTours } from './services/tourSearchService';
import { City, Tour, TourSearchParams, POPULAR_CITIES, MALDIVES_ISLANDS } from './types';

export default function ToursPage() {
  // State
  const [searchParams, setSearchParams] = useState<TourSearchParams>({});
  const [results, setResults] = useState<Tour[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [viewMode, setViewMode] = useState<'grid' | 'list' | 'map'>('grid');
  const [selectedTour, setSelectedTour] = useState<Tour | null>(null);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(9);
  const [fadeIn, setFadeIn] = useState(false);
  
  // Image mapping for popular destinations
  const cityImages: Record<string, string> = {
    'New York': 'https://images.unsplash.com/photo-1496442226666-8d4d0e62e6e9?q=80&w=1000',
    'London': 'https://images.unsplash.com/photo-1513635269975-59663e0ac1ad?q=80&w=1000',
    'Paris': 'https://images.unsplash.com/photo-1499856871958-5b9627545d1a?q=80&w=1000',
    'Tokyo': 'https://images.unsplash.com/photo-1536098561742-ca998e48cbcc?q=80&w=1000',
    'Rome': 'https://images.unsplash.com/photo-1552832230-c0197dd311b5?q=80&w=1000',
    'Sydney': 'https://images.unsplash.com/photo-1506973035872-a4ec16b8e8d9?q=80&w=1000',
    'Maldives': 'https://images.unsplash.com/photo-1573843981267-be1999ff37cd?q=80&w=1000',
    // Add images for Maldives islands
    'Maafushi': 'https://images.unsplash.com/photo-1514282401047-d79a71a590e8?q=80&w=1400',
    'Thulusdhoo': 'https://images.unsplash.com/photo-1544550581-1bcabf842b77?q=80&w=1400',
    'Fulidhoo': 'https://images.unsplash.com/photo-1586861635167-e5223aadc9fe?q=80&w=1400',
    'Gulhi': 'https://images.unsplash.com/photo-1618245318763-453cd5a5d6c0?q=80&w=1400',
    'Dhiffushi': 'https://images.unsplash.com/photo-1578922746465-3a80a228f223?q=80&w=1400'
  };
  
  // Apply fade-in effect on component mount
  useEffect(() => {
    setFadeIn(true);
  }, []);
  
  // Perform search when parameters change
  useEffect(() => {
    if (searchParams.city) {
      performSearch();
    }
  }, [searchParams, currentPage, pageSize]);
  
  const performSearch = async () => {
    if (!searchParams.city) {
      return;
    }
    
    setLoading(true);
    setError(null);
    
    try {
      // Convert dates to formatted strings if they exist
      const startDate = searchParams.startDate 
        ? searchParams.startDate.toISOString().split('T')[0] 
        : undefined;
      
      const endDate = searchParams.endDate 
        ? searchParams.endDate.toISOString().split('T')[0] 
        : undefined;
      
      // Call the search service using area-based search by default
      const { tours, totalCount } = await searchTours({
        city: searchParams.city,
        startDate,
        endDate,
        participants: searchParams.participants,
        filters: {
          priceRange: searchParams.priceRange,
          ratings: searchParams.ratings,
          duration: searchParams.duration,
          categories: searchParams.categories
        },
        page: currentPage,
        pageSize,
        useSquareSearch: true, // Use area-based search
        squareSize: 25 // Use a 25km square area for better coverage
      });
      
      // If no results found with square search and this is the first attempt,
      // try again with a larger area
      if (tours.length === 0 && !searchParams.retried) {
        console.log('No results found, trying with a larger search area');
        
        const { tours: retriedTours, totalCount: retriedCount } = await searchTours({
          city: searchParams.city,
          startDate,
          endDate,
          participants: searchParams.participants,
          filters: {
            priceRange: searchParams.priceRange,
            ratings: searchParams.ratings,
            duration: searchParams.duration,
            categories: searchParams.categories
          },
          page: currentPage,
          pageSize,
          useSquareSearch: true,
          squareSize: 50 // Double the search area
        });
        
        setResults(retriedTours);
        setTotalCount(retriedCount);
        // Mark that we've tried a retry
        setSearchParams({
          ...searchParams,
          retried: true
        });
      } else {
        setResults(tours);
        setTotalCount(totalCount);
      }
    } catch (error) {
      console.error('Error searching tours:', error);
      setError('Failed to search for tours. Please try again later.');
      setResults([]);
    } finally {
      setLoading(false);
    }
  };
  
  const handleSearch = (params: {
    city: City;
    dateRange: DateRange | undefined;
    participants: number;
  }) => {
    // Reset any previous search state
    setError(null);
    setResults([]);
    setTotalCount(0);
    
    setSearchParams({
      city: params.city,
      startDate: params.dateRange?.from,
      endDate: params.dateRange?.to,
      participants: params.participants
    });
    
    // Reset pagination when performing a new search
    setCurrentPage(1);
    
    // Reset selected tour
    setSelectedTour(null);
  };
  
  const handleFilterChange = (filters: FilterState) => {
    setSearchParams({
      ...searchParams,
      priceRange: filters.priceRange,
      ratings: filters.ratings,
      duration: filters.duration,
      categories: filters.categories
    });
    
    // Reset pagination when filters change
    setCurrentPage(1);
  };
  
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  
  const handleViewModeChange = (mode: 'grid' | 'list' | 'map') => {
    setViewMode(mode);
  };
  
  const handleTourSelect = (tour: Tour) => {
    setSelectedTour(tour);
  };
  
  return (
    <div className={`container mx-auto py-8 px-4 sm:px-6 lg:px-8 max-w-7xl transition-opacity duration-700 ${fadeIn ? 'opacity-100' : 'opacity-0'}`}>
      {!searchParams.city && (
        <div className="relative mb-12 rounded-xl overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-r from-black via-transparent to-black z-10"></div>
          <img 
            src="https://images.unsplash.com/photo-1469854523086-cc02fe5d8800?w=1200&auto=format" 
            alt="Travel the world" 
            className="w-full h-64 sm:h-80 object-cover rounded-xl"
          />
          <div className="absolute inset-0 z-20 flex flex-col justify-center p-6 sm:p-10">
            <h1 className="text-3xl sm:text-4xl md:text-5xl font-display font-semibold text-white mb-3 tracking-tight">
              Explore <span className="text-primary">Experiences</span> Worldwide
            </h1>
            <p className="text-gray-300 max-w-xl text-sm sm:text-base">
              Discover unforgettable tours, activities, and experiences in amazing destinations around the globe
            </p>
          </div>
        </div>
      )}
      
      {searchParams.city ? (
        <div className="mb-8">
          <h1 className="text-2xl sm:text-3xl font-display font-semibold text-white mb-2">
            Tours & Activities in {searchParams.city.name}
          </h1>
          <p className="text-gray-400 flex items-center">
            <Globe className="h-4 w-4 mr-2 text-primary/80" />
            Discover authentic experiences and unforgettable memories
          </p>
        </div>
      ) : (
        <div className="mb-8 flex items-center">
          <div className="p-2 bg-black/30 rounded-full mr-3 border border-gray-700/30">
            <Search className="h-5 w-5 text-primary" />
          </div>
          <div>
            <h2 className="text-xl sm:text-2xl font-display font-semibold text-white mb-1">
              Find Your Perfect Experience
            </h2>
            <p className="text-gray-400 text-sm">
              Search by destination, dates and number of participants
            </p>
          </div>
        </div>
      )}
      
      {/* Search Form */}
      <div className="mb-8">
        <SearchForm
          onSearch={handleSearch}
          initialParticipants={2}
        />
      </div>
      
      {/* Tour Modal */}
      {selectedTour && (
        <TourModal
          tour={selectedTour}
          onClose={() => setSelectedTour(null)}
        />
      )}
      
      {/* Main Content */}
      {searchParams.city ? (
        <div className="flex flex-col md:flex-row gap-6">
          {/* Sidebar with Filters */}
          <div className="w-full md:w-72 flex-shrink-0">
            <FilterOptions
              onFilterChange={handleFilterChange}
              initialFilters={{
                priceRange: searchParams.priceRange,
                ratings: searchParams.ratings,
                duration: searchParams.duration,
                categories: searchParams.categories
              }}
            />
          </div>
          
          {/* Results */}
          <div className="flex-1">
            {/* Results Header */}
            {!loading && results.length > 0 && (
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6">
                <div className="text-white mb-4 sm:mb-0">
                  <span className="font-medium">{totalCount}</span> tours found in <span className="font-medium">{searchParams.city.name}</span>
                </div>
                
                {/* View Mode Switcher */}
                <div className="bg-black/30 backdrop-blur-sm rounded-full p-1 flex border border-gray-700/30 shadow-md">
                  <button
                    onClick={() => handleViewModeChange('grid')}
                    className={`p-2 rounded-full transition-all duration-200 ${viewMode === 'grid' ? 'bg-gray-800 text-primary' : 'text-gray-400 hover:text-white'}`}
                    aria-label="Grid view"
                  >
                    <Grid className="w-4 h-4" />
                  </button>
                  <button
                    onClick={() => handleViewModeChange('list')}
                    className={`p-2 rounded-full transition-all duration-200 ${viewMode === 'list' ? 'bg-gray-800 text-primary' : 'text-gray-400 hover:text-white'}`}
                    aria-label="List view"
                  >
                    <List className="w-4 h-4" />
                  </button>
                  <button
                    onClick={() => handleViewModeChange('map')}
                    className={`p-2 rounded-full transition-all duration-200 ${viewMode === 'map' ? 'bg-gray-800 text-primary' : 'text-gray-400 hover:text-white'}`}
                    aria-label="Map view"
                  >
                    <MapIcon className="w-4 h-4" />
                  </button>
                </div>
              </div>
            )}
            
            {/* Loading State */}
            {loading && (
              <div className="flex items-center justify-center py-20">
                <div className="relative w-16 h-16">
                  <div className="absolute inset-0 rounded-full border-t-2 border-primary opacity-20 animate-spin"></div>
                  <div className="absolute inset-0 rounded-full border-t-2 border-r-2 border-primary opacity-60 animate-spin" style={{ animationDuration: '1.5s' }}></div>
                  <div className="absolute inset-0 rounded-full border-t-2 border-primary opacity-100 animate-spin" style={{ animationDuration: '1s' }}></div>
                </div>
                <span className="ml-4 text-gray-400 text-sm tracking-wide">Finding experiences...</span>
              </div>
            )}
            
            {/* Error State */}
            {error && (
              <div className="text-center py-16 px-6 bg-black/20 rounded-lg border border-red-900/20 backdrop-blur-sm">
                <AlertCircle className="w-8 h-8 text-red-400 mx-auto mb-3" />
                <p className="text-red-300 mb-2">{error}</p>
                <button 
                  onClick={performSearch} 
                  className="mt-3 px-4 py-2 bg-black/40 text-gray-300 hover:bg-black/60 rounded-lg text-sm border border-gray-700/40 transition-colors"
                >
                  Try Again
                </button>
              </div>
            )}
            
            {/* No Results */}
            {!loading && !error && results.length === 0 && searchParams.city && (
              <div className="text-center py-16 px-6 bg-black/20 rounded-lg border border-gray-700/30 backdrop-blur-sm">
                <div className="inline-block p-3 bg-black/40 rounded-full mb-3 border border-gray-700/40">
                  <AlertCircle className="w-6 h-6 text-gray-400" />
                </div>
                <p className="text-gray-300 mb-2">No tours found for {searchParams.city.name}</p>
                <p className="text-gray-500 text-sm max-w-md mx-auto">Try adjusting your search filters or dates to find more experiences</p>
              </div>
            )}
            
            {/* Results Grid */}
            {!loading && !error && results.length > 0 && viewMode !== 'map' && (
              <div className={`grid gap-6 ${viewMode === 'grid' ? 'grid-cols-1 sm:grid-cols-2 lg:grid-cols-3' : 'grid-cols-1'}`}>
                {results.map((tour) => (
                  <TourCard
                    key={tour.id}
                    tour={tour}
                    onClick={() => handleTourSelect(tour)}
                  />
                ))}
              </div>
            )}
            
            {/* Map View */}
            {!loading && !error && results.length > 0 && viewMode === 'map' && (
              <div className="relative rounded-xl overflow-hidden">
                <TourMap 
                  tours={results}
                  selectedTour={selectedTour}
                  onSelectTour={handleTourSelect}
                  height="600px"
                  width="100%"
                  center={searchParams.city ? [searchParams.city.longitude, searchParams.city.latitude] : undefined}
                />
                
                {/* Selected Tour Preview */}
                {selectedTour && (
                  <div className="absolute bottom-4 left-4 right-4 bg-black/80 backdrop-blur-sm rounded-lg shadow-lg border border-gray-700/50 overflow-hidden">
                    <div className="flex">
                      <div className="w-1/3 h-32 bg-cover bg-center" style={{ backgroundImage: `url(${selectedTour.images[0]})` }}></div>
                      <div className="w-2/3 p-4">
                        <h3 className="font-medium text-white text-lg mb-1">{selectedTour.name}</h3>
                        <div className="flex items-center gap-2 text-sm text-gray-300 mb-2">
                          <span>{selectedTour.duration}</span>
                          {selectedTour.rating && (
                            <div className="flex items-center">
                              <span className="mx-2">•</span>
                              <span className="text-yellow-400">★</span>
                              <span className="ml-1">{selectedTour.rating.toFixed(1)}</span>
                            </div>
                          )}
                        </div>
                        <div className="flex justify-between items-center mt-3">
                          <span className="text-primary font-medium">
                            {selectedTour.price.currencyCode === 'USD' ? '$' : ''}{parseFloat(selectedTour.price.amount).toFixed(2)}
                          </span>
                          <button 
                            onClick={() => handleTourSelect(selectedTour)}
                            className="px-3 py-1 bg-primary text-black text-sm rounded-full hover:bg-primary/90 transition"
                          >
                            View Details
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            
            {/* Pagination */}
            {!loading && results.length > 0 && (
              <div className="mt-8 flex justify-center">
                <div className="flex space-x-1 bg-black/20 p-1 rounded-full border border-gray-700/30">
                  <button
                    disabled={currentPage === 1}
                    onClick={() => handlePageChange(currentPage - 1)}
                    className={`px-4 py-2 rounded-full transition-all duration-200 ${
                      currentPage === 1
                        ? 'bg-transparent text-gray-500 cursor-not-allowed'
                        : 'text-white hover:bg-black/40'
                    }`}
                  >
                    Previous
                  </button>
                  
                  {/* Page Numbers */}
                  {Array.from({ length: Math.min(5, Math.ceil(totalCount / pageSize)) }, (_, i) => (
                    <button
                      key={i + 1}
                      onClick={() => handlePageChange(i + 1)}
                      className={`w-10 h-10 flex items-center justify-center rounded-full transition-all duration-200 ${
                        currentPage === i + 1
                          ? 'bg-primary text-black font-medium'
                          : 'text-white hover:bg-black/40'
                      }`}
                    >
                      {i + 1}
                    </button>
                  ))}
                  
                  <button
                    disabled={currentPage === Math.ceil(totalCount / pageSize)}
                    onClick={() => handlePageChange(currentPage + 1)}
                    className={`px-4 py-2 rounded-full transition-all duration-200 ${
                      currentPage === Math.ceil(totalCount / pageSize)
                        ? 'bg-transparent text-gray-500 cursor-not-allowed'
                        : 'text-white hover:bg-black/40'
                    }`}
                  >
                    Next
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        // Popular Cities - Initial State with improved design
        <div className="mt-6">
          <div className="flex items-center mb-6">
            <div className="p-2 bg-black/30 rounded-full mr-3 border border-gray-700/30">
              <Compass className="h-5 w-5 text-primary" />
            </div>
            <h2 className="text-xl font-display font-semibold text-white">
              Popular Destinations
            </h2>
            <div className="ml-3 h-px bg-gray-700/50 flex-grow"></div>
          </div>
          
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5">
            {/* First show Maldives islands for better visibility */}
            {MALDIVES_ISLANDS.slice(0, 2).map((city) => (
              <div
                key={city.name}
                className="group cursor-pointer bg-black/20 rounded-xl overflow-hidden border border-gray-700/30 hover:border-gray-700/50 transition-all duration-300 hover:translate-y-[-4px] hover:shadow-xl"
                onClick={() => {
                  handleSearch({
                    city,
                    dateRange: undefined,
                    participants: 2
                  });
                }}
              >
                <div className="h-36 overflow-hidden relative">
                  <img 
                    src={cityImages[city.name] || 'https://images.unsplash.com/photo-1573843981267-be1999ff37cd?q=80&w=1000'} 
                    alt={city.name} 
                    className="w-full h-full object-cover group-hover:scale-110 transition-transform duration-700"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black via-black/50 to-transparent opacity-80"></div>
                  <div className="absolute bottom-3 left-4 right-4">
                    <h3 className="text-lg font-medium text-white group-hover:text-primary transition-colors">
                      {city.name}
                    </h3>
                    <p className="text-gray-300 text-xs">{city.country}</p>
                  </div>
                </div>
                <div className="p-4">
                  <p className="text-gray-400 text-sm line-clamp-2 min-h-[40px]">
                    {city.description}
                  </p>
                  <div className="mt-3 flex justify-between items-center">
                    <span className="text-xs text-gray-500">
                      {Math.floor(Math.random() * 20) + 5}+ tours available
                    </span>
                    <button className="flex items-center text-xs text-primary bg-black/30 px-3 py-1.5 rounded-full group-hover:bg-black/50 transition-all duration-200 border border-primary/20">
                      Explore 
                      <svg className="w-3 h-3 ml-1 transform group-hover:translate-x-1 transition-transform duration-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            ))}
            
            {/* Then show popular cities */}
            {POPULAR_CITIES.map((city) => (
              <div
                key={city.name}
                className="group cursor-pointer bg-black/20 rounded-xl overflow-hidden border border-gray-700/30 hover:border-gray-700/50 transition-all duration-300 hover:translate-y-[-4px] hover:shadow-xl"
                onClick={() => {
                  handleSearch({
                    city,
                    dateRange: undefined,
                    participants: 2
                  });
                }}
              >
                <div className="h-36 overflow-hidden relative">
                  <img 
                    src={cityImages[city.name] || 'https://images.unsplash.com/photo-1469854523086-cc02fe5d8800?w=600&auto=format'} 
                    alt={city.name} 
                    className="w-full h-full object-cover group-hover:scale-110 transition-transform duration-700"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black via-black/50 to-transparent opacity-80"></div>
                  <div className="absolute bottom-3 left-4 right-4">
                    <h3 className="text-lg font-medium text-white group-hover:text-primary transition-colors">
                      {city.name}
                    </h3>
                    <p className="text-gray-300 text-xs">{city.country}</p>
                  </div>
                </div>
                <div className="p-4">
                  <p className="text-gray-400 text-sm line-clamp-2 min-h-[40px]">
                    {city.description}
                  </p>
                  <div className="mt-3 flex justify-between items-center">
                    <span className="text-xs text-gray-500">
                      {Math.floor(Math.random() * 50) + 10}+ tours available
                    </span>
                    <button className="flex items-center text-xs text-primary bg-black/30 px-3 py-1.5 rounded-full group-hover:bg-black/50 transition-all duration-200 border border-primary/20">
                      Explore 
                      <svg className="w-3 h-3 ml-1 transform group-hover:translate-x-1 transition-transform duration-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
} 