/**
 * Standardized error handling for consistent logging and tracking
 */
export function handleError(error: unknown, context: string): void {
  const errorMessage = error instanceof Error ? error.message : String(error);
  console.error(`${context}: ${errorMessage}`, error);
  
  // In production, we might want to send this to a monitoring service
  if (import.meta.env.PROD) {
    // TODO: Send to error tracking service like Sentry
    // reportErrorToMonitoring(error, context);
  }
} 