import { supabase } from './supabase';

const UNSPLASH_ACCESS_KEY = import.meta.env.VITE_UNSPLASH_ACCESS_KEY;

// Add proxy function for fetching images
async function fetchImageWithProxy(originalImageUrl: string): Promise<Blob> {
  try {
    // If it's an Unsplash image, use the <img> tag approach to bypass CSP restrictions
    if (originalImageUrl.includes('unsplash.com')) {
      console.log('Processing Unsplash image using img tag approach:', originalImageUrl);
      
      // We'll use an Image element which is allowed by the img-src CSP directive
      return new Promise((resolve, reject) => {
        const img = new Image();
        
        img.crossOrigin = 'anonymous'; // This is needed for canvas operations
        
        img.onload = () => {
          try {
            // Create a canvas and draw the image to it
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            
            const ctx = canvas.getContext('2d');
            if (!ctx) {
              reject(new Error('Could not get canvas context'));
              return;
            }
            
            // Draw the image to canvas
            ctx.drawImage(img, 0, 0);
            
            // Get the image data as blob - this works with the CSP because we're using canvas
            canvas.toBlob((blob) => {
              if (blob) {
                console.log('Successfully converted image to blob via canvas');
                resolve(blob);
              } else {
                reject(new Error('Failed to convert canvas to blob'));
              }
            }, 'image/jpeg', 0.9);
          } catch (e) {
            reject(e);
          }
        };
        
        img.onerror = (e) => {
          console.error('Error loading image:', e);
          reject(new Error('Failed to load image'));
        };
        
        // Set the source - this will trigger loading
        // Since img-src allows images.unsplash.com, this will work!
        img.src = originalImageUrl;
      });
    }

    // For non-Unsplash images, try direct fetch with appropriate headers
    const directResponse = await fetch(originalImageUrl, {
      headers: {
        'Accept': 'image/*'
      }
    });
    
    if (!directResponse.ok) {
      throw new Error('Failed to fetch image');
    }
    
    return directResponse.blob();
  } catch (error) {
    console.error('Error fetching image:', error);
    throw error;
  }
}

// Add utility function for WebP conversion
async function convertToWebP(blob: Blob): Promise<Blob> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      // Create canvas with max dimensions to optimize file size
      const maxDimension = 1200; // Reasonable size for city images
      let width = img.width;
      let height = img.height;
      
      // Calculate new dimensions while maintaining aspect ratio
      if (width > height && width > maxDimension) {
        height = Math.round((height * maxDimension) / width);
        width = maxDimension;
      } else if (height > maxDimension) {
        width = Math.round((width * maxDimension) / height);
        height = maxDimension;
      }
      
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      
      // Draw image on canvas
      const ctx = canvas.getContext('2d');
      if (!ctx) {
        reject(new Error('Failed to get canvas context'));
        return;
      }
      ctx.drawImage(img, 0, 0, width, height);
      
      // Convert to WebP with optimized settings
      canvas.toBlob(
        (webpBlob) => {
          if (webpBlob) {
            resolve(webpBlob);
          } else {
            reject(new Error('Failed to convert to WebP'));
          }
        },
        'image/webp',
        0.80  // Slightly reduced quality for better compression while maintaining good visuals
      );
    };
    img.onerror = () => reject(new Error('Failed to load image'));
    img.src = URL.createObjectURL(blob);
  });
}

export const getPublicImageUrl = (path: string) => {
  const { data } = supabase.storage
    .from('public')
    .getPublicUrl(path);
  return data.publicUrl;
};

// Add interface for city image data
interface CityImageData {
  imageUrl: string;
  cityName: string;
  country: string;
  cityId?: string;  // Optional ID to link with city record
}

// Add interface for bulk operation result
interface BulkOperationResult {
  success: boolean;
  cityName: string;
  url?: string;
  error?: string;
}

// Add new interfaces
interface ImageUploadResult {
  data: {
    publicUrl: string;
  };
}

type ImageType = 'slider' | 'city' | 'destination';

export const storage = {
  async createCityImagesBucket(): Promise<void> {
    try {
      const { data: buckets } = await supabase.storage.listBuckets();
      const bucketExists = buckets?.some(bucket => bucket.id === 'city-images');

      if (!bucketExists) {
        const { error } = await supabase.storage.createBucket('city-images', {
          public: true,
          fileSizeLimit: 10485760, // 10MB
          allowedMimeTypes: ['image/jpeg', 'image/png', 'image/webp']
        });

        if (error) throw error;
        console.log('Created city-images bucket');
      }
    } catch (error) {
      console.error('Error creating city-images bucket:', error);
      throw error;
    }
  },

  async ensureCityImagesBucket(): Promise<void> {
    try {
      const { data: buckets } = await supabase.storage.listBuckets();
      const bucketExists = buckets?.some(bucket => bucket.id === 'city-images');

      if (!bucketExists) {
        await this.createCityImagesBucket();
      }
    } catch (error) {
      console.error('Error ensuring city-images bucket exists:', error);
      throw error;
    }
  },

  async ensureEventImagesBucket(): Promise<void> {
    try {
      const { data: buckets } = await supabase.storage.listBuckets();
      const bucketExists = buckets?.some(bucket => bucket.id === 'expo-images');

      if (!bucketExists) {
        const { error } = await supabase.storage.createBucket('expo-images', {
          public: true,
          fileSizeLimit: 5242880, // 5MB
          allowedMimeTypes: ['image/*']
        });

        if (error) throw error;
      }
    } catch (error) {
      console.error('Error ensuring expo-images bucket exists:', error);
      throw error;
    }
  },

  async uploadEventImage(file: File, eventId: string): Promise<string> {
    try {
      // Ensure bucket exists before upload
      await this.ensureEventImagesBucket();

      // Validate file
      if (!file.type.startsWith('image/')) {
        throw new Error('Please upload an image file');
      }

      if (file.size > 5 * 1024 * 1024) {
        throw new Error('Image size should be less than 5MB');
      }

      // Generate unique filename
      const fileExt = file.name.split('.').pop();
      const fileName = `${crypto.randomUUID()}.${fileExt}`;
      const filePath = `events/${eventId}/${fileName}`;

      // Upload file
      const { error: uploadError } = await supabase.storage
        .from('expo-images')
        .upload(filePath, file, {
          cacheControl: '3600',
          upsert: true
        });

      if (uploadError) throw uploadError;

      // Get public URL
      const { data: { publicUrl } } = supabase.storage
        .from('expo-images')
        .getPublicUrl(filePath);

      return publicUrl;
    } catch (error) {
      console.error('Error uploading image:', error);
      throw error;
    }
  },

  async deleteEventImage(imageUrl: string): Promise<void> {
    try {
      // Extract path from URL
      const path = imageUrl.split('expo-images/')[1];
      if (!path) return;

      await this.ensureEventImagesBucket();

      const { error } = await supabase.storage
        .from('expo-images')
        .remove([path]);

      if (error) throw error;
    } catch (error) {
      console.error('Error deleting image:', error);
      throw error;
    }
  },

  async uploadCityImage(imageUrl: string, cityName: string, country: string): Promise<string> {
    try {
      await this.ensureCityImagesBucket();

      // Fetch the image using proxy
      let blob;
      try {
        blob = await fetchImageWithProxy(imageUrl);
      } catch (error) {
        console.warn('Error with fetchImageWithProxy, trying direct fetch:', error);
        // Fallback to direct fetch
        const response = await fetch(imageUrl, {
          headers: {
            'Accept': 'image/*'
          }
        });
        
        if (!response.ok) {
          throw new Error('Failed to fetch image both via proxy and directly');
        }
        
        blob = await response.blob();
      }

      // Generate a clean filename
      const fileExt = imageUrl.split('.').pop()?.split('?')[0] || 'jpg';
      const cleanName = `${cityName}-${country}`.toLowerCase().replace(/[^a-z0-9]+/g, '-');
      const fileName = `${cleanName}.${fileExt}`;

      // Upload to Supabase
      const { error: uploadError } = await supabase.storage
        .from('city-images')
        .upload(fileName, blob, {
          cacheControl: '31536000', // Cache for 1 year
          upsert: true
        });

      if (uploadError) throw uploadError;

      // Get public URL
      const { data: { publicUrl } } = supabase.storage
        .from('city-images')
        .getPublicUrl(fileName);

      return publicUrl;
    } catch (error) {
      console.error('Error uploading city image:', error);
      throw error;
    }
  },

  async uploadCityImageAsWebP(imageUrl: string, cityName: string, country: string): Promise<string> {
    try {
      await this.ensureCityImagesBucket();

      // Fetch the image using proxy
      let originalBlob;
      try {
        originalBlob = await fetchImageWithProxy(imageUrl);
      } catch (error) {
        console.warn('Error with fetchImageWithProxy in WebP conversion, trying direct fetch:', error);
        // Fallback to direct fetch
        const response = await fetch(imageUrl, {
          headers: {
            'Accept': 'image/*'
          }
        });
        
        if (!response.ok) {
          throw new Error('Failed to fetch image both via proxy and directly for WebP conversion');
        }
        
        originalBlob = await response.blob();
      }

      // Convert to WebP with optimization
      const webpBlob = await convertToWebP(originalBlob);

      // Generate a clean filename with timestamp to avoid conflicts
      const cleanName = `${cityName}-${country}`.toLowerCase().replace(/[^a-z0-9]+/g, '-');
      const timestamp = new Date().getTime();
      const fileName = `${cleanName}-${timestamp}.webp`;

      // Upload to Supabase with optimized caching and metadata
      const { error: uploadError, data } = await supabase.storage
        .from('city-images')
        .upload(fileName, webpBlob, {
          contentType: 'image/webp',
          cacheControl: '31536000', // Cache for 1 year
          upsert: true,
          duplex: 'half',
          metadata: {
            originalUrl: imageUrl,
            convertedAt: new Date().toISOString(),
            cityName,
            country
          }
        });

      if (uploadError) {
        console.error('Upload error:', uploadError);
        throw uploadError;
      }

      if (!data?.path) {
        throw new Error('Upload successful but path is missing');
      }

      // Get public URL
      const { data: { publicUrl } } = supabase.storage
        .from('city-images')
        .getPublicUrl(fileName);

      return publicUrl;
    } catch (error) {
      console.error('Error uploading city image as WebP:', error);
      throw error;
    }
  },

  async migrateImageToWebP(imageUrl: string): Promise<string | null> {
    try {
      // Skip if already WebP
      if (imageUrl.endsWith('.webp')) return imageUrl;

      // Fetch the image using proxy
      let originalBlob;
      try {
        originalBlob = await fetchImageWithProxy(imageUrl);
      } catch (error) {
        console.warn('Error with fetchImageWithProxy in migration, trying direct fetch:', error);
        // Fallback to direct fetch
        const response = await fetch(imageUrl, {
          headers: {
            'Accept': 'image/*'
          }
        });
        
        if (!response.ok) {
          console.error('Failed to fetch image both via proxy and directly for migration');
          return null;
        }
        
        originalBlob = await response.blob();
      }

      // Convert to WebP
      const webpBlob = await convertToWebP(originalBlob);

      // Extract original path and generate new WebP path
      const urlParts = imageUrl.split('/');
      const originalFilename = urlParts[urlParts.length - 1];
      const newFilename = originalFilename.split('.')[0] + '.webp';

      // Upload to Supabase
      const { error: uploadError } = await supabase.storage
        .from('city-images')
        .upload(newFilename, webpBlob, {
          contentType: 'image/webp',
          cacheControl: '31536000',
          upsert: true
        });

      if (uploadError) return null;

      // Get public URL
      const { data: { publicUrl } } = supabase.storage
        .from('city-images')
        .getPublicUrl(newFilename);

      return publicUrl;
    } catch (error) {
      console.error('Error migrating image to WebP:', error);
      return null;
    }
  },

  async bulkSaveCityImages(images: CityImageData[]): Promise<BulkOperationResult[]> {
    try {
      await this.ensureCityImagesBucket();
      const results: BulkOperationResult[] = [];

      // Process images in batches to avoid overwhelming the system
      const batchSize = 3;
      for (let i = 0; i < images.length; i += batchSize) {
        const batch = images.slice(i, i + batchSize);
        const batchPromises = batch.map(async (image) => {
          try {
            // Generate consistent filename for the city
            const cleanName = `${image.cityName}-${image.country}`.toLowerCase().replace(/[^a-z0-9]+/g, '-');
            const fileName = `${cleanName}${image.cityId ? `-${image.cityId}` : ''}.webp`;

            // Check if image already exists
            const { data: existingFiles } = await supabase.storage
              .from('city-images')
              .list('', {
                search: fileName
              });

            if (existingFiles?.some(file => file.name === fileName)) {
              return {
                success: true,
                cityName: image.cityName,
                url: supabase.storage.from('city-images').getPublicUrl(fileName).data.publicUrl,
                error: 'Image already exists'
              };
            }

            // Fetch and convert image
            const originalBlob = await fetchImageWithProxy(image.imageUrl);
            const webpBlob = await convertToWebP(originalBlob);

            // Upload with metadata
            const { error: uploadError, data } = await supabase.storage
              .from('city-images')
              .upload(fileName, webpBlob, {
                contentType: 'image/webp',
                cacheControl: '31536000', // Cache for 1 year
                upsert: true,
                metadata: {
                  cityName: image.cityName,
                  country: image.country,
                  cityId: image.cityId,
                  originalUrl: image.imageUrl,
                  convertedAt: new Date().toISOString()
                }
              });

            if (uploadError) throw uploadError;

            const publicUrl = supabase.storage
              .from('city-images')
              .getPublicUrl(fileName)
              .data.publicUrl;

            return {
              success: true,
              cityName: image.cityName,
              url: publicUrl
            };
          } catch (error) {
            console.error(`Error processing ${image.cityName}:`, error);
            return {
              success: false,
              cityName: image.cityName,
              error: error instanceof Error ? error.message : 'Unknown error'
            };
          }
        });

        // Wait for batch to complete and collect results
        const batchResults = await Promise.all(batchPromises);
        results.push(...batchResults);

        // Add a small delay between batches to prevent rate limiting
        if (i + batchSize < images.length) {
          await new Promise(resolve => setTimeout(resolve, 1000));
        }
      }

      return results;
    } catch (error) {
      console.error('Error in bulk save operation:', error);
      throw error;
    }
  },

  async getCityImageUrl(cityName: string, country: string, cityId?: string): Promise<string | null> {
    try {
      const cleanName = `${cityName}-${country}`.toLowerCase().replace(/[^a-z0-9]+/g, '-');
      const fileName = `${cleanName}${cityId ? `-${cityId}` : ''}.webp`;

      const { data: existingFiles } = await supabase.storage
        .from('city-images')
        .list('', {
          search: fileName
        });

      if (!existingFiles?.length) return null;

      return supabase.storage
        .from('city-images')
        .getPublicUrl(fileName)
        .data.publicUrl;
    } catch (error) {
      console.error('Error getting city image URL:', error);
      return null;
    }
  },

  async uploadImage(file: File, fileName: string, type: ImageType): Promise<ImageUploadResult> {
    try {
      let bucketName: string;
      switch (type) {
        case 'slider':
          bucketName = 'home-slider';
          break;
        case 'destination':
          bucketName = 'destinations';
          break;
        default:
          bucketName = 'city-images';
      }

      // Ensure bucket exists
      await this.ensureBucket(bucketName);

      // Convert to WebP before upload if not already WebP
      let uploadFile = file;
      if (!file.type.includes('webp')) {
        const blob = await file.arrayBuffer();
        const originalBlob = new Blob([blob], { type: file.type });
        const webpBlob = await convertToWebP(originalBlob);
        uploadFile = new File([webpBlob], fileName.replace(/\.[^/.]+$/, '.webp'), {
          type: 'image/webp'
        });
      }

      // Upload file
      const { error: uploadError } = await supabase.storage
        .from(bucketName)
        .upload(fileName, uploadFile, {
          cacheControl: '31536000',
          upsert: true,
          contentType: 'image/webp'
        });

      if (uploadError) throw uploadError;

      // Get public URL
      const { data } = supabase.storage
        .from(bucketName)
        .getPublicUrl(fileName);

      return { data: { publicUrl: data.publicUrl } };
    } catch (error) {
      console.error('Error uploading image:', error);
      throw error;
    }
  },

  async deleteImage(imageUrl: string, type: ImageType): Promise<void> {
    try {
      let bucketName: string;
      switch (type) {
        case 'slider':
          bucketName = 'home-slider';
          break;
        case 'destination':
          bucketName = 'destinations';
          break;
        default:
          bucketName = 'city-images';
      }

      // Extract path from URL
      const path = imageUrl.split(`${bucketName}/`)[1];
      if (!path) return;

      const { error } = await supabase.storage
        .from(bucketName)
        .remove([path]);

      if (error) throw error;
    } catch (error) {
      console.error('Error deleting image:', error);
      throw error;
    }
  },

  async convertToWebP(imageUrl: string, type: ImageType): Promise<string | null> {
    try {
      // Skip if already WebP
      if (imageUrl.endsWith('.webp')) return imageUrl;

      const originalBlob = await fetchImageWithProxy(imageUrl);
      const webpBlob = await convertToWebP(originalBlob);

      let bucketName: string;
      switch (type) {
        case 'slider':
          bucketName = 'home-slider';
          break;
        case 'destination':
          bucketName = 'destinations';
          break;
        default:
          bucketName = 'city-images';
      }

      // Generate new filename
      const urlParts = imageUrl.split('/');
      const originalFilename = urlParts[urlParts.length - 1];
      const newFilename = originalFilename.split('.')[0] + '.webp';

      const { error: uploadError } = await supabase.storage
        .from(bucketName)
        .upload(newFilename, webpBlob, {
          contentType: 'image/webp',
          cacheControl: '31536000',
          upsert: true
        });

      if (uploadError) return null;

      const { data: { publicUrl } } = supabase.storage
        .from(bucketName)
        .getPublicUrl(newFilename);

      return publicUrl;
    } catch (error) {
      console.error('Error converting to WebP:', error);
      return null;
    }
  },

  async ensureBucket(bucketName: string): Promise<void> {
    try {
      const { data: buckets } = await supabase.storage.listBuckets();
      const bucketExists = buckets?.some(bucket => bucket.id === bucketName);

      if (!bucketExists) {
        const { error } = await supabase.storage.createBucket(bucketName, {
          public: true,
          fileSizeLimit: 10485760, // 10MB
          allowedMimeTypes: ['image/jpeg', 'image/png', 'image/webp']
        });

        if (error) throw error;
        console.log(`Created ${bucketName} bucket`);
      }
    } catch (error) {
      console.error(`Error ensuring ${bucketName} bucket exists:`, error);
      throw error;
    }
  },

  async listBucketContents(bucketName: string) {
    try {
      await this.ensureBucket(bucketName);
      const { data, error } = await supabase.storage
        .from(bucketName)
        .list();

      if (error) throw error;
      return { data };
    } catch (error) {
      console.error(`Error listing contents of ${bucketName}:`, error);
      return { data: null };
    }
  },

  getPublicUrl(bucketName: string, path: string) {
    const { data } = supabase.storage
      .from(bucketName)
      .getPublicUrl(path);
    return data.publicUrl;
  }
}; 