import { useEffect, useRef, useState } from 'react';
import { useChat } from '../../contexts/ChatContext';
import ChatHeader from './ChatHeader';
import ActivityPanel from './ActivityPanel';
import { Loader2, MapPin, X } from 'lucide-react';
import { cn } from '../../lib/utils';
import { QuotationService } from '../../services/QuotationService';
import type { AmadeusActivity } from '../../services/amadeus';
import { ActivityCard } from '../ActivityCard';

interface EnhancedChatInterfaceProps {
  activities?: AmadeusActivity[];
  cityName?: string;
}

function WelcomeMessage({ cityName, activitiesCount }: { cityName?: string; activitiesCount?: number }) {
  return (
    <div className="absolute inset-0 flex items-center justify-center text-center p-6">
      <div className="space-y-5 max-w-sm">
        <div className="w-16 h-16 rounded-full bg-gradient-to-br from-teal-400 to-emerald-500 flex items-center justify-center mx-auto shadow-lg shadow-teal-500/20">
          <svg
            className="w-8 h-8 text-white"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
        <div className="space-y-4">
          <h3 className="text-xl font-medium text-white">
            Hi, I'm Marco
          </h3>
          <p className="text-sm text-white/70 leading-relaxed">
            Your personal travel assistant. I'm here to help plan your journey and find amazing experiences.
          </p>
          
          {cityName && activitiesCount && (
            <div className="mt-4 p-3 bg-teal-500/10 rounded-xl border border-teal-500/20">
              <p className="text-sm text-teal-300">
                I can help you explore {activitiesCount} activities in {cityName}!
              </p>
            </div>
          )}
          
          <div className="pt-2">
            <p className="text-xs text-white/50 mb-2">Try asking me:</p>
            <div className="flex flex-wrap gap-2 justify-center">
              {cityName ? (
                <>
                  <Pill>Show me top activities in {cityName}</Pill>
                  <Pill>Find food tours</Pill>
                  <Pill>Cultural experiences</Pill>
                </>
              ) : (
                <>
                  <Pill>Quote for my vacation</Pill>
                  <Pill>Best time to visit Bali</Pill>
                  <Pill>Luxury getaway ideas</Pill>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// Helper component for suggestion pills
function Pill({ children }: { children: React.ReactNode }) {
  return (
    <div className="px-3 py-1.5 text-xs bg-white/10 hover:bg-white/15 text-white/80 rounded-full transition-colors cursor-pointer">
      {children}
    </div>
  );
}

function ChatMessages({ messagesEndRef, onScrollNeeded, activities, cityName }: {
  messagesEndRef: React.RefObject<HTMLDivElement>;
  onScrollNeeded: (behavior: 'auto' | 'smooth') => void;
  activities?: AmadeusActivity[];
  cityName?: string;
}) {
  const { state } = useChat();
  
  return (
    <div className="flex-1 overflow-y-auto p-4 space-y-5 scrollbar-thin scrollbar-thumb-white/10 scrollbar-track-transparent">
      {state.messages.map((message, index) => (
        <div
          key={message.id || index}
          className={cn(
            'flex items-end space-x-2',
            message.role === 'user' ? 'justify-end' : 'justify-start'
          )}
        >
          {message.role === 'assistant' && (
            <div className="flex-shrink-0 w-8 h-8 rounded-full bg-gradient-to-br from-teal-400 to-emerald-500 flex items-center justify-center shadow-md">
              <svg className="w-4 h-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
              </svg>
            </div>
          )}

          <div
            className={cn(
              'px-4 py-3 rounded-2xl max-w-[85%] md:max-w-[75%] shadow-sm',
              message.role === 'user'
                ? 'bg-gradient-to-br from-teal-400 to-emerald-500 text-white rounded-tr-none'
                : 'bg-white/10 backdrop-blur-sm border border-white/10 text-white/90 rounded-bl-none'
            )}
          >
            {message.content}
          </div>

          {message.role === 'user' && (
            <div className="flex-shrink-0 w-8 h-8 rounded-full bg-white/20 flex items-center justify-center shadow-md backdrop-blur-sm">
              <svg className="w-4 h-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
              </svg>
            </div>
          )}
        </div>
      ))}

      {/* Typing Indicator */}
      {state.isTyping && (
        <div className="flex items-end space-x-2 justify-start">
          <div className="flex-shrink-0 w-8 h-8 rounded-full bg-gradient-to-br from-teal-400 to-emerald-500 flex items-center justify-center shadow-md">
            <svg className="w-4 h-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
            </svg>
          </div>
          <div className="px-4 py-3 rounded-2xl bg-white/10 backdrop-blur-sm border border-white/10 text-white/90 rounded-bl-none shadow-sm flex items-center space-x-1">
            <div className="w-2 h-2 bg-white/70 rounded-full animate-pulse" style={{ animationDelay: '0ms' }}></div>
            <div className="w-2 h-2 bg-white/70 rounded-full animate-pulse" style={{ animationDelay: '300ms' }}></div>
            <div className="w-2 h-2 bg-white/70 rounded-full animate-pulse" style={{ animationDelay: '600ms' }}></div>
          </div>
        </div>
      )}

      {/* Invisible div to scroll to */}
      <div ref={messagesEndRef} />
    </div>
  );
}

function ChatInput({ onSubmit, suggestedPrompts }: {
  onSubmit: (message: string) => Promise<void>;
  suggestedPrompts?: string[];
}) {
  const [input, setInput] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const { state } = useChat();

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${Math.min(textareaRef.current.scrollHeight, 120)}px`;
    }
  }, [input]);

  const handleSubmit = async () => {
    if (!input.trim() || state.isTyping || !state.isInitialized || isSubmitting) return;
    
    try {
      setIsSubmitting(true);
      const message = input.trim();
      setInput('');
      
      if (textareaRef.current) {
        textareaRef.current.style.height = 'auto';
      }
      
      await onSubmit(message);
    } catch (error) {
      console.error('Failed to send message:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  };

  const isDisabled = !state.isInitialized || state.isTyping || isSubmitting;
  const placeholder = !state.isInitialized
    ? "Initializing..."
    : state.isTyping
    ? "Marco is typing..."
    : "Message Marco...";

  return (
    <div className="relative">
      <div className="p-3 border-t border-white/5">
        <div className="relative flex items-end gap-2">
          <div className="relative flex-1 overflow-hidden rounded-xl bg-white/5 border border-white/10">
            <textarea
              ref={textareaRef}
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder={placeholder}
              disabled={isDisabled}
              className={cn(
                "w-full py-3 px-4",
                "bg-transparent text-white placeholder-white/40",
                "text-sm resize-none max-h-[120px] focus:outline-none",
                isDisabled && "opacity-50 cursor-not-allowed"
              )}
              style={{ height: '40px' }}
              rows={1}
            />
          </div>
          
          <button
            onClick={handleSubmit}
            disabled={!input.trim() || isDisabled}
            className={cn(
              "p-3 rounded-xl",
              "bg-gradient-to-br from-teal-400 to-emerald-500",
              "text-white transition-all duration-200",
              "shadow-md hover:shadow-lg hover:shadow-teal-500/20",
              "disabled:opacity-50 disabled:cursor-not-allowed disabled:shadow-none"
            )}
            aria-label="Send message"
          >
            {isSubmitting ? (
              <Loader2 className="w-4 h-4 animate-spin" />
            ) : (
              <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
              </svg>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

export default function ChatInterface({ activities, cityName }: EnhancedChatInterfaceProps) {
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const { state, dispatch, sendMessage } = useChat();
  const [showActivityPanel, setShowActivityPanel] = useState(false);

  const handleScrollNeeded = (behavior: 'auto' | 'smooth') => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior });
    }
  };

  useEffect(() => {
    if (messagesEndRef.current && state.messages.length > 0) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [state.messages]);

  const handleMessageSubmit = async (message: string) => {
    const quotationKeywords = ['quote', 'booking', 'price', 'cost', 'package', 'plan'];
    const isQuotationRequest = quotationKeywords.some(keyword => 
      message.toLowerCase().includes(keyword)
    );

    if (isQuotationRequest) {
      dispatch({
        type: 'START_WORKFLOW',
        workflow: {
          type: 'quoteRequest',
          step: 'initial',
          data: {
            initialQuery: message
          }
        }
      });
    }

    await sendMessage(message);
  };

  if (!state.isOpen) return null;

  return (
    <div className="fixed inset-0 sm:inset-auto sm:bottom-4 sm:right-4 w-full sm:w-[450px] z-50">
      <div className={cn(
        "flex flex-col bg-black/40 backdrop-blur-lg border border-white/10",
        "rounded-none sm:rounded-2xl overflow-hidden shadow-xl",
        "transition-all duration-300 ease-in-out transform",
        state.isMinimized ? "h-[48px]" : "h-full sm:h-[600px]",
        state.isOpen ? "opacity-100 translate-y-0" : "opacity-0 translate-y-4"
      )}>
        {/* Loading Overlay */}
        {!state.isInitialized && (
          <div className="absolute inset-0 bg-black/70 backdrop-blur-sm flex items-center justify-center z-50">
            <div className="text-center space-y-3 p-4">
              <Loader2 className="w-6 h-6 animate-spin text-teal-400 mx-auto" />
              <p className="text-sm font-medium text-white/90">
                Setting up your personal travel assistant...
              </p>
            </div>
          </div>
        )}

        {/* Error Message */}
        {state.error && (
          <div className={cn(
            "absolute top-0 left-0 right-0",
            "bg-red-500/10 border-b border-red-500/20",
            "px-4 py-2 text-sm text-red-400",
            "animate-in slide-in-from-top duration-300"
          )}>
            <div className="flex items-center gap-2">
              <span className="text-xs">{state.error}</span>
            </div>
          </div>
        )}

        <ChatHeader 
          cityName={cityName}
          activitiesCount={activities?.length}
        />
        
        {!state.isMinimized && (
          <>
            {/* Activity Context Banner */}
            {activities && cityName && (
              <div className="bg-teal-500/10 px-4 py-2 border-b border-white/5">
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-2">
                    <MapPin className="w-4 h-4 text-teal-400" />
                    <span className="text-sm text-teal-300">
                      {activities.length} activities in {cityName}
                    </span>
                  </div>
                  <button 
                    onClick={() => setShowActivityPanel(true)}
                    className="text-xs text-teal-400 hover:text-teal-300 transition-colors"
                  >
                    View All
                  </button>
                </div>
              </div>
            )}

            <div className="relative flex-1 overflow-hidden">
              {/* Welcome Message */}
              {state.messages.length === 0 && state.isInitialized && (
                <WelcomeMessage 
                  cityName={cityName}
                  activitiesCount={activities?.length}
                />
              )}
              
              <ChatMessages 
                messagesEndRef={messagesEndRef} 
                onScrollNeeded={handleScrollNeeded}
                activities={activities}
                cityName={cityName}
              />
            </div>

            <ChatInput 
              onSubmit={handleMessageSubmit}
              suggestedPrompts={cityName ? [
                `Show me the top-rated activities in ${cityName}`,
                `What are the best cultural experiences in ${cityName}?`,
                `Find food tours in ${cityName}`,
                `What activities are available in the morning?`
              ] : [
                "Quote for my vacation",
                "Best time to visit Bali",
                "Luxury getaway ideas"
              ]}
            />
          </>
        )}

        {/* Activity Panel */}
        <ActivityPanel 
          isOpen={showActivityPanel}
          onClose={() => setShowActivityPanel(false)}
          activities={activities}
          cityName={cityName}
        />
      </div>
    </div>
  );
}