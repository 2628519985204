/**
 * Utilities for extracting context from user messages
 */

/**
 * Extract a location mentioned in the user's message
 */
export function getLocationContext(query: string): string | undefined {
  // First, try to match common location patterns
  // Modified patterns with more specific boundaries to avoid capturing extra content
  const locationPatterns = [
    /(?:in|near|for|at)\s+([A-Za-z\s-]+?)(?:\s+is|\s+are|\s+has|\s+will|\s+during|\?|$|\.|,|\s+and|\s+with|\s+from|\s+to)/i,
    /hotels?\s+(?:in|near|at)\s+([A-Za-z\s-]+?)(?:\s+is|\s+are|\s+has|\s+will|\s+during|\?|$|\.|,|\s+and|\s+with|\s+from|\s+to)/i,
    /(?:visit|visiting|travel\s+to|traveling\s+to)\s+([A-Za-z\s-]+?)(?:\s+is|\s+are|\s+has|\s+will|\s+during|\?|$|\.|,|\s+and|\s+with|\s+from|\s+to)/i
  ];
  
  for (const pattern of locationPatterns) {
    const match = query.match(pattern);
    if (match && match[1]) {
      const location = match[1].trim();
      // Filter out invalid locations that are likely not actual places
      const invalidLocations = ['the', 'a', 'my', 'your', 'our', 'their', 'these', 'those', 'this', 'that', 'all', 'some', 'any', 'no', 'each', 'every', 'few', 'many', 'dates', 'date'];
      if (location.length > 1 && !invalidLocations.includes(location.toLowerCase())) {
        // Clean up the location before returning it
        const cleanedLocation = cleanLocationString(location);
        console.log(`[getLocationContext] Extracted location: "${cleanedLocation}"`);
        return cleanedLocation;
      }
    }
  }
  
  // Handle specific cities with known name formats
  const maldivesPattern = /\b(?:the\s+)?(maldives)\b/i;
  const maldivesMatch = query.match(maldivesPattern);
  if (maldivesMatch && maldivesMatch[1]) {
    console.log(`[getLocationContext] Found specific location: "${maldivesMatch[1]}"`);
    return maldivesMatch[1];
  }
  
  // If no location found with patterns, try to check if query contains specific city names
  const commonCities = ['new york', 'los angeles', 'chicago', 'houston', 'phoenix', 'philadelphia', 'san antonio', 'san diego', 'dallas', 'san jose', 'london', 'paris', 'tokyo', 'dubai', 'singapore', 'barcelona', 'rome', 'amsterdam', 'berlin', 'vienna', 'bangkok', 'hong kong', 'madrid', 'sydney', 'miami', 'toronto', 'istanbul'];
  
  const lowerQuery = query.toLowerCase();
  for (const city of commonCities) {
    if (lowerQuery.includes(city)) {
      console.log(`[getLocationContext] Found common city name: "${city}"`);
      return city;
    }
  }
  
  return undefined;
}

/**
 * Clean up location strings by removing time references
 */
export function cleanLocationString(location: string): string {
  // List of months, seasons, and time periods to remove from location names
  const timeTerms = [
    'january', 'february', 'march', 'april', 'may', 'june', 
    'july', 'august', 'september', 'october', 'november', 'december',
    'winter', 'spring', 'summer', 'fall', 'autumn',
    'morning', 'afternoon', 'evening', 'night',
    'today', 'tomorrow', 'yesterday', 'next week', 'last week',
    'this week', 'weekend', 'weekday'
  ];
  
  // Create a regex to match any of the time terms with word boundaries
  const timePattern = new RegExp(`\\b(in\\s+)?(${timeTerms.join('|')})\\b`, 'gi');
  
  // Remove time references
  const cleaned = location.replace(timePattern, '').trim();
  
  // Places that commonly include "the" in their formal name
  const placesWithThe = [
    'maldives', 'netherlands', 'philippines', 'uk', 'usa', 
    'united states', 'united kingdom', 'bahamas', 'caribbean',
    'dominican republic', 'uae', 'united arab emirates'
  ];
  
  // Check if we should preserve "the" in this location name
  const lowerLocation = cleaned.toLowerCase();
  const shouldPreserveThe = placesWithThe.some(place => 
    lowerLocation === `the ${place}` || placesWithThe.includes(lowerLocation)
  );
  
  // Remove "the" from the beginning if present and not in our exception list
  const withoutThe = shouldPreserveThe ? cleaned : cleaned.replace(/^the\s+/i, '');
  
  // Trim any extra whitespace that might have been created
  const result = withoutThe.replace(/\s+/g, ' ').trim();
  
  // Log if we made changes
  if (result !== location) {
    console.log(`[cleanLocationString] Cleaned "${location}" to "${result}"`);
  }
  
  return result || location; // Return original if cleaning resulted in empty string
}

/**
 * Extract travel preferences from user message
 */
export function extractItineraryPreferences(context: string): string[] {
  const preferences: string[] = [];
  if (context.includes('family') || context.includes('kids') || context.includes('children')) {
    preferences.push('family-friendly');
  }
  if (context.includes('budget') || context.includes('cheap')) {
    preferences.push('budget-friendly');
  }
  if (context.includes('luxury') || context.includes('high-end')) {
    preferences.push('luxury');
  }
  if (context.includes('romantic') || context.includes('couple')) {
    preferences.push('romantic');
  }
  if (context.includes('adventure') || context.includes('hiking') || context.includes('outdoors')) {
    preferences.push('adventure');
  }
  const durationMatch = context.match(/(\d+)\s*day/i);
  if (durationMatch && durationMatch[1]) {
      preferences.push(`${durationMatch[1]} day duration`);
  }

  return preferences;
}

/**
 * Determine user intent from their message
 */
export function determineIntent(query: string, fullContextQuery: string, needsWebSearch: boolean): 'hotel' | 'activity' | 'itinerary' | 'websearch' | 'general' {
  const lowerQuery = query.toLowerCase();
  
  // Check for hotel intent
  const hotelRegex = /hotel/i;
  if (hotelRegex.test(lowerQuery)) {
    return 'hotel';
  }
  
  // Check for activity intent
  const activityRegex = /\b(activity|tour|things to do|excursion)\b/i;
  if (activityRegex.test(lowerQuery)) {
    return 'activity';
  }
  
  // Check for itinerary intent
  const itineraryRegex = /\b(itinerary|plan|schedule|trip for)\b/i;
  if (itineraryRegex.test(lowerQuery)) {
    return 'itinerary';
  }
  
  // Check if needs web search
  if (needsWebSearch) {
    return 'websearch';
  }
  
  // Check context for follow-up itinerary generation
  if (/\b(itinerary|plan|schedule)\b/i.test(fullContextQuery)) {
    return 'itinerary';
  }
  
  // Default to general intent
  return 'general';
} 