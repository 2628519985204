import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight, Users, Star, MessageSquare, Globe, Building2, Compass, Sparkles } from 'lucide-react';
import { cn } from '../../../utils/cn';

interface RequestManagementSectionProps {
  onOpenRequestModal: () => void;
  onMarcoChat: () => void;
}

// Minimal icon button for tabs - Adjust size for mobile
const TabButton = ({ icon: Icon, label, isActive, onClick, activeColorClass }: { icon: React.ElementType, label: string, isActive: boolean, onClick: () => void, activeColorClass: string }) => (
  <button
    onClick={onClick}
    className={cn(
      // Adjusted size and padding
      "flex flex-col items-center justify-center p-2 sm:p-4 rounded-lg sm:rounded-xl w-full h-16 sm:w-24 sm:h-24 transition-all duration-300 group relative overflow-hidden",
      isActive
        ? `bg-white/[0.08] ${activeColorClass}`
        : "bg-white/[0.02] hover:bg-white/[0.04] text-white/50 hover:text-white/80"
    )}
    aria-label={label}
  >
    {/* Subtle background glow on active/hover */}
    <div className={cn(
      "absolute inset-0 opacity-0 transition-opacity duration-300",
      isActive ? `${activeColorClass.replace('text-', 'bg-')}/10 opacity-100` : `group-hover:bg-white/5 group-hover:opacity-100`
    )}></div>
    {/* Adjusted icon size */}
    <Icon className={cn("w-5 h-5 sm:w-7 sm:h-7 mb-1 sm:mb-1.5 transition-colors", isActive ? 'text-white' : '')} />
    {/* Adjusted text size */}
    <span className={cn("text-[9px] sm:text-xs font-medium tracking-wide transition-colors", isActive ? 'text-white' : '')}>{label}</span>
  </button>
);

const RequestManagementSection: React.FC<RequestManagementSectionProps> = ({
  onOpenRequestModal,
  onMarcoChat
}) => {
  const [activeTab, setActiveTab] = useState('agents');

  const tabs = [
    { id: 'agents', label: 'Agents', icon: Users, color: 'text-blue-400' },
    { id: 'hotels', label: 'Hotels', icon: Building2, color: 'text-purple-400' },
    { id: 'tours', label: 'Tours', icon: Compass, color: 'text-amber-400' },
    { id: 'ai', label: 'Marco AI', icon: MessageSquare, color: 'text-cyan-400' },
  ];

  const renderContent = () => {
    switch (activeTab) {
      case 'agents':
        return (
          // Adjusted text sizes
          <div className="space-y-3 sm:space-y-4">
            <h3 className="text-lg sm:text-xl font-medium text-white flex items-center">
              <Users className="w-4 h-4 sm:w-5 sm:h-5 mr-2 text-blue-400" /> Find Travel Agents
            </h3>
            <p className="text-white/60 text-xs sm:text-sm leading-relaxed">
              Connect with 41,800+ verified travel specialists across 150+ countries.
              Get personalized itineraries and quotes for your next journey.
            </p>
            <Link
              to="/travel-agents"
              className="inline-flex items-center text-blue-400 hover:text-blue-300 text-xs sm:text-sm font-medium mt-1 sm:mt-2 group"
            >
              Explore Agents
              <ArrowRight className="w-3 h-3 sm:w-4 sm:h-4 ml-1 group-hover:translate-x-1 transition-transform" />
            </Link>
          </div>
        );
      case 'hotels':
        return (
          <div className="space-y-3 sm:space-y-4">
            <h3 className="text-lg sm:text-xl font-medium text-white flex items-center">
              <Building2 className="w-4 h-4 sm:w-5 sm:h-5 mr-2 text-purple-400" /> Luxury Hotels
            </h3>
            <p className="text-white/60 text-xs sm:text-sm leading-relaxed">
              Discover and book premium accommodations worldwide. From boutique stays to
              world-class resorts with special rates and exclusive perks.
            </p>
            <Link
              to="/hotels"
              className="inline-flex items-center text-purple-400 hover:text-purple-300 text-xs sm:text-sm font-medium mt-1 sm:mt-2 group"
            >
              Browse Hotels
              <ArrowRight className="w-3 h-3 sm:w-4 sm:h-4 ml-1 group-hover:translate-x-1 transition-transform" />
            </Link>
          </div>
        );
      case 'tours':
        return (
          <div className="space-y-3 sm:space-y-4">
            <h3 className="text-lg sm:text-xl font-medium text-white flex items-center">
              <Compass className="w-4 h-4 sm:w-5 sm:h-5 mr-2 text-amber-400" /> Curated Tours
            </h3>
            <p className="text-white/60 text-xs sm:text-sm leading-relaxed">
              Explore 1000+ handcrafted tours and unique experiences across the globe.
              From cultural immersions to adventure expeditions.
            </p>
            <Link
              to="/tours"
              className="inline-flex items-center text-amber-400 hover:text-amber-300 text-xs sm:text-sm font-medium mt-1 sm:mt-2 group"
            >
              Discover Tours
              <ArrowRight className="w-3 h-3 sm:w-4 sm:h-4 ml-1 group-hover:translate-x-1 transition-transform" />
            </Link>
          </div>
        );
      case 'ai':
        return (
          <div className="space-y-3 sm:space-y-4">
            <h3 className="text-lg sm:text-xl font-medium text-white flex items-center">
              <MessageSquare className="w-4 h-4 sm:w-5 sm:h-5 mr-2 text-cyan-400" /> Marco AI Assistant
            </h3>
            <p className="text-white/60 text-xs sm:text-sm leading-relaxed">
              Get personalized travel recommendations, itinerary suggestions, and answers
              to your travel questions from our AI assistant.
            </p>
            <button
              onClick={onMarcoChat}
              className="inline-flex items-center text-cyan-400 hover:text-cyan-300 text-xs sm:text-sm font-medium mt-1 sm:mt-2 group"
            >
              Chat with Marco
              <ArrowRight className="w-3 h-3 sm:w-4 sm:h-4 ml-1 group-hover:translate-x-1 transition-transform" />
            </button>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    // Adjusted vertical padding
    <div className="w-full py-16 sm:py-20 lg:py-24 bg-gradient-to-b from-black via-neutral-950 to-black">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Adjusted gap */}
        <div className="grid lg:grid-cols-2 gap-8 sm:gap-12 lg:gap-20 items-center">
          {/* Content Side */}
          <div className="space-y-6 sm:space-y-10"> {/* Adjusted base spacing */}
            {/* Header - Adjusted text sizes */}
            <div className="space-y-2 sm:space-y-3">
              <h2 className="text-2xl sm:text-4xl lg:text-[42px] font-display font-medium tracking-tight text-white leading-tight">
                Your Journey, Simplified
              </h2>
              <p className="text-white/60 text-sm sm:text-lg font-light leading-relaxed max-w-lg">
                Access everything you need for seamless travel planning – expert agents, curated stays, unique tours, and AI assistance.
              </p>
            </div>

            {/* Feature Tabs - Adjusted gap */}
            <div className="grid grid-cols-4 gap-1.5 sm:gap-3">
              {tabs.map(tab => (
                <TabButton
                  key={tab.id}
                  icon={tab.icon}
                  label={tab.label}
                  isActive={activeTab === tab.id}
                  onClick={() => setActiveTab(tab.id)}
                  activeColorClass={tab.color}
                />
              ))}
            </div>

            {/* Tab Content Area - Adjusted padding and min-height */}
            <div className="bg-white/[0.02] border border-white/[0.05] rounded-xl p-4 sm:p-6 min-h-[140px] sm:min-h-[180px] flex items-center relative overflow-hidden">
              {/* Animated Content Wrapper */}
              <div 
                key={activeTab}
                className="w-full animate-fade-in"
              >
                {renderContent()}
              </div>
            </div>

            {/* CTA Buttons - Adjusted height, padding, text size */}
            <div className="flex flex-col sm:flex-row gap-3 sm:gap-4 pt-2">
              <button
                onClick={onOpenRequestModal}
                className="flex-1 inline-flex items-center justify-center h-11 sm:h-12 px-5 sm:px-6 bg-white hover:bg-white/90 text-black text-xs sm:text-sm font-medium tracking-wide rounded-lg sm:rounded-xl transition-all duration-300 active:scale-[0.98] hover:scale-[1.02] shadow-lg hover:shadow-white/10"
              >
                Submit RFQ
                <ArrowRight className="ml-1.5 sm:ml-2 w-3 h-3 sm:w-4 sm:h-4 opacity-70" />
              </button>
              <Link
                to="/map"
                className="flex-1 inline-flex items-center justify-center h-11 sm:h-12 px-5 sm:px-6 bg-white/[0.03] hover:bg-white/[0.06] text-white text-xs sm:text-sm font-light tracking-wide rounded-lg sm:rounded-xl backdrop-blur-sm transition-all duration-300 active:scale-[0.98] hover:scale-[1.02] border border-white/[0.05]"
              >
                <Globe className="w-4 h-4 mr-1.5 sm:mr-2 opacity-70" />
                Explore Map
              </Link>
            </div>
          </div>

          {/* Visual element - Complex Futuristic Animated Graphic */}
          {/* Increased height significantly */}
          <div className="hidden lg:flex justify-center items-center relative h-[550px] aspect-square">
            {/* Base container for perspective */}
            <div className="relative w-full h-full perspective">
              {/* Rotating elements container */}
              <div className="absolute inset-0 transform-style-3d">
                {/* Element Group 1 (Slowest, Outer) */}
                <div className="absolute inset-[5%] animate-spin-slowest transform-style-3d">
                  <div className="absolute inset-0 border border-purple-600/10 rounded-full transform rotate-x-65 rotate-y-15 opacity-30"></div>
                  <div className="absolute inset-[15%] border border-emerald-600/10 rounded-xl transform rotate-x-80 rotate-y-40 opacity-25"></div>
                </div>

                {/* Element Group 2 (Medium Speed) */}
                <div className="absolute inset-[18%] animate-spin-medium transform-style-3d">
                  <div className="absolute top-0 left-0 w-full h-full border border-cyan-500/20 rounded-full transform rotate-x-70 opacity-50"></div>
                  <div className="absolute inset-[20%] border border-purple-500/20 rounded-lg transform rotate-y-60 opacity-40"></div>
                </div>

                {/* Element Group 3 (Fastest, Inner) */}
                <div className="absolute inset-[30%] animate-spin-fast transform-style-3d">
                  <div className="absolute top-1/4 left-1/4 w-1/2 h-1/2 border-2 border-blue-400/40 rounded-md transform rotate-x-50 rotate-y-20 opacity-70"></div>
                  <div className="absolute top-[35%] left-[35%] w-[30%] h-[30%] border border-emerald-400/40 rounded-full transform rotate-x-80 rotate-y-40 opacity-60"></div>
                </div>

                 {/* Central Pulsating Core - Slightly larger */}
                 <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-28 h-28">
                    <div className="absolute inset-0 rounded-full bg-gradient-to-br from-white/15 via-cyan-400/15 to-blue-500/25 animate-pulse-glow blur-2xl"></div>
                    <div className="absolute inset-2 rounded-full border border-white/20 backdrop-blur-md"></div>
                    {/* Increased sparkles size */}
                    <Sparkles className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-8 h-8 text-cyan-300/80 animate-pulse-subtle" />
                 </div>

                 {/* Subtle Star Particles (Static but appear to move due to perspective) */}
                 {/* Added a few particle divs */}
                 <div className="absolute w-1 h-1 bg-white/30 rounded-full top-[20%] left-[30%] transform translate-z-50 opacity-50"></div>
                 <div className="absolute w-1.5 h-1.5 bg-cyan-400/20 rounded-full bottom-[25%] right-[35%] transform translate-z-80 opacity-40"></div>
                 <div className="absolute w-1 h-1 bg-purple-400/20 rounded-full top-[40%] right-[15%] transform translate-z-120 opacity-30"></div>
                 <div className="absolute w-0.5 h-0.5 bg-white/40 rounded-full bottom-[15%] left-[20%] transform translate-z-30 opacity-60"></div>

              </div>

              {/* Enhanced Gradient overlay for depth */}
              <div className="absolute inset-0 bg-gradient-radial from-transparent via-black/40 to-black/95 pointer-events-none"></div>
            </div>
          </div>
        </div>
      </div>
      {/* Add custom CSS for perspective and animation */}
      <style>{`
        .perspective {
          perspective: 1200px; /* Even more perspective */
        }
        .transform-style-3d {
          transform-style: preserve-3d;
        }
        /* Added slowest spin */
        @keyframes spin-slowest {
          from { transform: rotateY(0deg) rotateX(5deg) rotateZ(-3deg); }
          to { transform: rotateY(360deg) rotateX(5deg) rotateZ(-3deg); }
        }
        @keyframes spin-medium {
          from { transform: rotateY(0deg) rotateX(12deg) rotateZ(8deg); } /* Adjusted angles */
          to { transform: rotateY(360deg) rotateX(12deg) rotateZ(8deg); }
        }
        @keyframes spin-fast {
          from { transform: rotateY(0deg) rotateX(-8deg) rotateZ(-10deg); } /* Adjusted angles */
          to { transform: rotateY(-360deg) rotateX(-8deg) rotateZ(-10deg); }
        }
         /* Pulse animation for the core */
        @keyframes pulse-glow {
           0%, 100% { opacity: 0.8; transform: scale(1); } /* Slightly increased base opacity */
           50% { opacity: 1.1; transform: scale(1.15); } /* Increased pulse intensity */
        }
        @keyframes pulse-subtle {
           0%, 100% { opacity: 0.7; } /* Increased base opacity */
           50% { opacity: 1.0; }
        }
        /* Added animation class */
        .animate-spin-slowest {
          animation: spin-slowest 90s linear infinite; /* Slower duration */
        }
        .animate-spin-medium {
          animation: spin-medium 55s linear infinite; /* Adjusted duration */
        }
        .animate-spin-fast {
          animation: spin-fast 30s linear infinite; /* Adjusted duration */
        }
        .animate-pulse-glow {
           animation: pulse-glow 4.5s cubic-bezier(0.4, 0, 0.6, 1) infinite; /* Slightly faster pulse */
        }
        .animate-pulse-subtle {
           animation: pulse-subtle 4.5s ease-in-out infinite; /* Slightly faster pulse */
        }
        .bg-gradient-radial {
           background-image: radial-gradient(circle at center, var(--tw-gradient-stops));
        }
      `}</style>
      {/* Add fade-in animation */}
      <style>{`
        @keyframes fade-in {
          from { opacity: 0; transform: translateY(10px); }
          to { opacity: 1; transform: translateY(0); }
        }
        .animate-fade-in {
          animation: fade-in 0.3s ease-out forwards;
        }
      `}</style>
    </div>
  );
};

export default RequestManagementSection; 