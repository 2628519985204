import { ChevronLeft, ChevronRight } from 'lucide-react';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

export default function Pagination({ currentPage, totalPages, onPageChange }: PaginationProps) {
  // Don't render pagination if there's only one page
  if (totalPages <= 1) return null;

  // Handle next and previous page navigation
  const handlePrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  // Generate array of page numbers to display
  const getPageNumbers = () => {
    const pageNumbers = [];
    
    // On mobile, show fewer page numbers
    const isMobile = window.innerWidth < 640;
    const maxPagesToShow = isMobile ? 3 : 5;
    
    if (totalPages <= maxPagesToShow) {
      // If total pages are less than max to show, display all
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      // Always include first page
      pageNumbers.push(1);
      
      // Calculate start and end page numbers to show
      let startPage = Math.max(2, currentPage - Math.floor((maxPagesToShow - 2) / 2));
      let endPage = Math.min(totalPages - 1, startPage + maxPagesToShow - 3);
      
      // Adjust if at the start or end
      if (currentPage <= Math.ceil(maxPagesToShow / 2)) {
        endPage = maxPagesToShow - 1;
      } else if (currentPage >= totalPages - Math.floor(maxPagesToShow / 2)) {
        startPage = totalPages - (maxPagesToShow - 1);
      }
      
      // Add ellipsis if needed
      if (startPage > 2) {
        pageNumbers.push('...');
      } else if (startPage === 2) {
        pageNumbers.push(2);
      }
      
      // Add middle pages
      for (let i = startPage; i <= endPage; i++) {
        if (i > 1 && i < totalPages && !pageNumbers.includes(i)) {
          pageNumbers.push(i);
        }
      }
      
      // Add ellipsis if needed
      if (endPage < totalPages - 1) {
        pageNumbers.push('...');
      } else if (endPage === totalPages - 1) {
        pageNumbers.push(totalPages - 1);
      }
      
      // Always include last page
      pageNumbers.push(totalPages);
    }
    
    return pageNumbers;
  };

  const pageNumbers = getPageNumbers();

  return (
    <div className="flex items-center justify-center mt-8 sm:mt-10">
      <nav className="flex items-center gap-1 sm:gap-2 bg-gradient-to-b from-gray-800/60 to-gray-900/60 p-1.5 rounded-xl border border-gray-700/30 shadow-lg">
        {/* Previous button */}
        <button
          onClick={handlePrevious}
          disabled={currentPage === 1}
          className={`min-w-10 h-10 sm:h-11 flex items-center justify-center rounded-lg transition-all duration-200 ${
            currentPage === 1
              ? 'text-gray-400 cursor-not-allowed'
              : 'text-white hover:bg-gray-700/50 active:scale-95'
          }`}
          aria-label="Previous page"
        >
          <ChevronLeft className="w-5 h-5" />
        </button>

        {/* Page numbers - Hide on mobile if too many pages */}
        <div className="flex items-center">
          {pageNumbers.map((page, index) => (
            typeof page === 'number' ? (
              <button
                key={index}
                onClick={() => onPageChange(page)}
                className={`min-w-10 w-10 h-10 sm:h-11 flex items-center justify-center rounded-lg mx-0.5 sm:mx-1 transition-all duration-200 ${
                  currentPage === page
                    ? 'bg-gradient-to-r from-primary to-primary/80 text-black font-medium transform scale-105 shadow-md'
                    : 'text-gray-300 hover:bg-gray-700/50 active:scale-95'
                }`}
                aria-label={`Page ${page}`}
                aria-current={currentPage === page ? 'page' : undefined}
              >
                {page}
              </button>
            ) : (
              <span
                key={index}
                className="w-6 sm:w-8 h-10 sm:h-11 flex items-center justify-center text-gray-400"
              >
                {page}
              </span>
            )
          ))}
        </div>

        {/* Next button */}
        <button
          onClick={handleNext}
          disabled={currentPage === totalPages}
          className={`min-w-10 h-10 sm:h-11 flex items-center justify-center rounded-lg transition-all duration-200 ${
            currentPage === totalPages
              ? 'text-gray-400 cursor-not-allowed'
              : 'text-white hover:bg-gray-700/50 active:scale-95'
          }`}
          aria-label="Next page"
        >
          <ChevronRight className="w-5 h-5" />
        </button>
      </nav>
      
      {/* Simple page indicator for mobile */}
      <div className="flex md:hidden ml-3 text-gray-400 text-sm">
        <span className="whitespace-nowrap">
          Page {currentPage} of {totalPages}
        </span>
      </div>
    </div>
  );
} 