import { ChatAnthropic } from '@langchain/anthropic';
import { ChatPromptTemplate } from '@langchain/core/prompts';
import { StringOutputParser } from '@langchain/core/output_parsers';
import { handleError } from '../../../../lib/utils/error';

/**
 * Service for itinerary generation functionality
 */
export class ItineraryService {
  /**
   * Generate an itinerary for a given location
   */
  async generateItinerary(location: string, preferences: string[]): Promise<string> {
    console.log(`[generateItinerary] Starting generation for location: "${location}", Prefs: ${preferences.join(', ')}`);
    
    try {
      // Use a separate, potentially more powerful model instance for generation
      const generationModel = new ChatAnthropic({
        anthropicApiKey: import.meta.env.VITE_ANTHROPIC_API_KEY,
        // Consider Opus or Sonnet for better generation, but Haiku might work for simple ones
        modelName: "claude-3-haiku-20240307",
        temperature: 0.7, // Slightly higher temp for creativity
        maxTokens: 800, // More tokens for a decent itinerary
      });

      const preferenceText = preferences.length > 0 ? `Keep these preferences in mind: ${preferences.join(', ')}.` : '';
      // Simple duration extraction (can be improved)
      const durationMatch = preferences.find(p => p.match(/(\d+)\s*day/i));
      const duration = durationMatch ? parseInt(durationMatch, 10) : 3; // Default to 3 days

      const itineraryPrompt = ChatPromptTemplate.fromMessages([
        ["system", `You are Marco, a helpful travel assistant. Generate a concise, day-by-day travel itinerary based on the user's request. Format the output clearly using markdown (e.g., Day 1, Day 2...). Focus on key activities and sights. Keep descriptions brief. DO NOT add introductory or concluding remarks, just the itinerary itself.`],
        ["human", `Create a ${duration}-day itinerary for ${location}. ${preferenceText}`]
      ]);

      // Create a simple chain for this specific task
      const itineraryChain = itineraryPrompt.pipe(generationModel).pipe(new StringOutputParser());

      const itineraryResult = await itineraryChain.invoke({});
      console.log(`[generateItinerary] Received result length: ${itineraryResult?.length ?? 0}`);

      if (itineraryResult) {
        return itineraryResult;
      } else {
        console.warn(`[generateItinerary] Received empty result for ${location}`);
        return `I couldn't generate an itinerary for ${location} right now.`;
      }
    } catch (error) {
      console.error(`[generateItinerary] Error generating itinerary for ${location}:`, error);
      handleError(error, `Error generating itinerary for ${location}`);
      return `Sorry, I had trouble creating an itinerary for ${location}.`;
    }
  }
} 