import { Star, MapPin, Wifi, Coffee, Waves, Car } from 'lucide-react';
import { AmadeusHotel, HotelOfferSearch } from '../../../services/amadeus';

interface HotelCardProps {
  hotel: AmadeusHotel;
  offers?: HotelOfferSearch[];
  onClick?: () => void;
  expanded?: boolean;
}

const DEFAULT_IMAGE = 'https://images.unsplash.com/photo-1566073771259-6a8506099945?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80';

export default function HotelCard({ hotel, offers, onClick, expanded = false }: HotelCardProps) {
  // Get hotel image
  const hotelImage = hotel.media?.[0]?.uri || DEFAULT_IMAGE;

  // Get price from offers if available
  const lowestOffer = offers?.[0]?.offers?.[0];
  const price = lowestOffer?.price?.total;
  const currency = lowestOffer?.price?.currency || 'USD';

  // Get common amenities
  const amenities = hotel.amenities || [];
  const hasWifi = amenities.includes('WIFI');
  const hasPool = amenities.includes('POOL');
  const hasRestaurant = amenities.includes('RESTAURANT');
  const hasParking = amenities.includes('PARKING');

  // Format amenities for display
  const displayAmenities = [
    hasWifi && { icon: <Wifi className="w-4 h-4" />, label: 'WiFi' },
    hasPool && { icon: <Waves className="w-4 h-4" />, label: 'Pool' },
    hasRestaurant && { icon: <Coffee className="w-4 h-4" />, label: 'Restaurant' },
    hasParking && { icon: <Car className="w-4 h-4" />, label: 'Parking' }
  ].filter(Boolean);

  // Safety check for address and city name
  const addressLine = hotel.address?.lines?.[0] || '';
  const cityName = hotel.address?.cityName || '';
  const countryCode = hotel.address?.countryCode || '';

  return (
    <div 
      className={`bg-gradient-to-b from-gray-800/50 to-gray-900/60 rounded-lg overflow-hidden hover:from-gray-800/70 hover:to-gray-900/80 transition-all ${expanded ? 'border border-gray-700/30' : 'cursor-pointer group border border-transparent hover:border-gray-700/30'}`}
      onClick={expanded ? undefined : onClick}
    >
      <div className="flex flex-col sm:flex-row md:flex-col">
        {/* Hotel Image */}
        <div className={`relative ${expanded ? 'h-56' : 'h-40 sm:h-full sm:w-2/5 md:w-full md:h-48'} overflow-hidden`}>
          <img 
            src={hotelImage} 
            alt={hotel.name} 
            className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-700"
          />
          {hotel.rating && (
            <div className="absolute top-3 right-3 bg-gradient-to-r from-primary to-primary/80 text-black px-3 py-1 rounded-full text-sm font-semibold flex items-center shadow-md">
              <Star className="mr-1 h-4 w-4 fill-current" />
              {hotel.rating}
            </div>
          )}
        </div>

        {/* Hotel Info */}
        <div className="p-4 sm:p-5 sm:w-3/5 md:w-full">
          <h3 className={`${expanded ? 'text-xl sm:text-2xl' : 'text-lg sm:text-xl'} font-semibold text-white mb-1 ${expanded ? '' : 'line-clamp-1'}`}>
            {hotel.name}
          </h3>
          
          <div className="flex items-center text-gray-400 mb-2 sm:mb-3">
            <MapPin className="h-4 w-4 mr-1 flex-shrink-0 text-primary/80" />
            <span className={`text-xs sm:text-sm ${expanded ? '' : 'line-clamp-1'}`}>
              {addressLine && cityName ? `${addressLine}, ${cityName}${countryCode ? `, ${countryCode}` : ''}` : 'Address unavailable'}
            </span>
          </div>

          {/* Amenities - Show fewer on mobile */}
          {displayAmenities.length > 0 && (
            <div className="flex flex-wrap gap-2 sm:gap-3 mb-3 sm:mb-4">
              {displayAmenities.slice(0, expanded ? 4 : 2).map((amenity, index) => amenity && (
                <div key={index} className="flex items-center bg-gray-700/30 px-2 py-1 rounded-md text-gray-300 text-xs sm:text-sm border border-gray-600/30">
                  <span className="mr-1 text-primary">{amenity.icon}</span>
                  {amenity.label}
                </div>
              ))}
              {!expanded && displayAmenities.length > 2 && (
                <div className="bg-gray-700/30 px-2 py-1 rounded-md text-gray-300 text-xs sm:text-sm border border-gray-600/30">
                  +{displayAmenities.length - 2} more
                </div>
              )}
            </div>
          )}

          {/* Extra details when expanded */}
          {expanded && (
            <div className="mt-4 mb-6">
              {/* Show all amenities */}
              {amenities.length > 0 && (
                <div className="mb-4 p-3 bg-gradient-to-br from-gray-700/30 to-gray-700/20 rounded-lg border border-gray-700/30">
                  <h4 className="text-white font-medium mb-2">Amenities</h4>
                  <div className="grid grid-cols-2 gap-2">
                    {amenities.slice(0, 8).map((amenity, index) => (
                      <div key={index} className="text-gray-400 text-sm">
                        • {amenity.toLowerCase().replace(/_/g, ' ')}
                      </div>
                    ))}
                  </div>
                  {amenities.length > 8 && (
                    <div className="text-primary text-sm mt-1 hover:underline cursor-pointer">+ {amenities.length - 8} more</div>
                  )}
                </div>
              )}
              
              {/* Location details */}
              {hotel.geoCode && (
                <div className="mb-4 p-3 bg-gradient-to-br from-gray-700/30 to-gray-700/20 rounded-lg border border-gray-700/30">
                  <h4 className="text-white font-medium mb-2">Location</h4>
                  <div className="text-gray-400 text-sm">
                    <div>Latitude: {hotel.geoCode.latitude}</div>
                    <div>Longitude: {hotel.geoCode.longitude}</div>
                  </div>
                </div>
              )}
            </div>
          )}

          {/* Price and CTA */}
          <div className="flex items-center justify-between mt-auto">
            {price ? (
              <div>
                <span className="text-primary text-lg sm:text-xl font-bold">
                  ${isNaN(parseFloat(price)) ? '---' : parseFloat(price).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                </span>
                <span className="text-gray-400 text-xs sm:text-sm ml-1">/ night</span>
              </div>
            ) : (
              <div className="text-gray-400 text-xs sm:text-sm">Check availability</div>
            )}
            
            <button className={`${expanded 
              ? 'bg-gradient-to-r from-primary to-primary/80 text-black hover:from-primary/90 hover:to-primary/70'
              : 'bg-gradient-to-r from-primary/10 to-primary/20 text-primary hover:from-primary/20 hover:to-primary/30'
            } px-3 sm:px-4 py-2 rounded-lg text-xs sm:text-sm font-medium min-w-[80px] sm:min-w-[100px] flex items-center justify-center transition-all duration-300 border border-transparent ${expanded ? '' : 'hover:border-primary/20'}`}>
              {expanded ? 'Book Now' : 'View Details'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
} 