import { useState, useEffect, useRef } from 'react';
import { Search, MapPin, Clock, Loader2 } from 'lucide-react';
import useDebounce from '../hooks/useDebounce';

interface SearchResult {
  place_id: string;
  display_name: string;
  lat: string;
  lon: string;
  type: string;
  importance: number;
  address?: {
    city?: string;
    state?: string;
    country?: string;
  };
}

interface LocationSearchProps {
  onLocationSelect: (
    lat: number, 
    lng: number, 
    name: string,
    address?: {
      city?: string;
      state?: string;
      country?: string;
    }
  ) => void;
  placeholder?: string;
  className?: string;
}

const MAX_RECENT_SEARCHES = 5;
const NOMINATIM_API = 'https://nominatim.openstreetmap.org';
const RATE_LIMIT_MS = 1000; // 1 second between requests

export default function LocationSearch({ 
  onLocationSelect, 
  placeholder = "Search locations...",
  className = ""
}: LocationSearchProps) {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState<SearchResult[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showResults, setShowResults] = useState(false);
  const [recentSearches, setRecentSearches] = useState<Array<{
    name: string;
    lat: number;
    lng: number;
    address?: { city?: string; state?: string; country?: string };
  }>>([]);
  
  const debouncedQuery = useDebounce(query, 300);
  const searchContainerRef = useRef<HTMLDivElement>(null);
  const abortControllerRef = useRef<AbortController | null>(null);
  const lastRequestRef = useRef<number>(0);

  useEffect(() => {
    try {
      const saved = localStorage.getItem('recentSearches');
      if (saved) {
        const parsed = JSON.parse(saved);
        if (Array.isArray(parsed)) {
          setRecentSearches(parsed);
        }
      }
    } catch (err) {
      console.error('Error loading recent searches:', err);
      localStorage.removeItem('recentSearches');
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (searchContainerRef.current && !searchContainerRef.current.contains(event.target as Node)) {
        setShowResults(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    const searchLocation = async () => {
      if (!debouncedQuery.trim()) {
        setResults([]);
        return;
      }

      try {
        setLoading(true);
        setError('');

        // Cancel previous request if it exists
        if (abortControllerRef.current) {
          abortControllerRef.current.abort();
        }

        // Rate limiting
        const now = Date.now();
        const timeSinceLastRequest = now - lastRequestRef.current;
        if (timeSinceLastRequest < RATE_LIMIT_MS) {
          await new Promise(resolve => setTimeout(resolve, RATE_LIMIT_MS - timeSinceLastRequest));
        }

        // Create new abort controller for this request
        abortControllerRef.current = new AbortController();
        lastRequestRef.current = Date.now();
        
        const response = await fetch(
          `${NOMINATIM_API}/search?` + new URLSearchParams({
            q: debouncedQuery,
            format: 'json',
            addressdetails: '1',
            limit: '5',
            'accept-language': 'en'
          }), {
            headers: {
              'Accept-Language': 'en',
              'User-Agent': 'AgenTrav Travel Directory (https://agentrav.com)'
            },
            signal: abortControllerRef.current.signal
          }
        );
        
        if (!response.ok) {
          throw new Error(`Search failed: ${response.statusText}`);
        }
        
        const data = await response.json();
        
        // Validate response data
        if (!Array.isArray(data)) {
          throw new Error('Invalid response format');
        }

        // Filter out results with invalid coordinates
        const validResults = data.filter(result => {
          const lat = parseFloat(result.lat);
          const lon = parseFloat(result.lon);
          return !isNaN(lat) && !isNaN(lon) && 
                 lat >= -90 && lat <= 90 && 
                 lon >= -180 && lon <= 180;
        });

        setResults(validResults);
        if (validResults.length === 0 && data.length > 0) {
          setError('No valid locations found');
        }
      } catch (err) {
        if (err instanceof Error) {
          if (err.name === 'AbortError') {
            return;
          }
          setError(err.message);
        } else {
          setError('Failed to search location. Please try again.');
        }
        setResults([]);
      } finally {
        setLoading(false);
      }
    };

    searchLocation();

    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [debouncedQuery]);

  const handleSelectLocation = (result: SearchResult) => {
    const lat = parseFloat(result.lat);
    const lng = parseFloat(result.lon);
    
    if (isNaN(lat) || isNaN(lng) || 
        lat < -90 || lat > 90 || 
        lng < -180 || lng > 180) {
      setError('Invalid coordinates received');
      return;
    }
    
    const address = {
      city: result.address?.city,
      state: result.address?.state,
      country: result.address?.country
    };
    
    const newSearch = {
      name: result.display_name,
      lat,
      lng,
      address
    };
    
    const updated = [newSearch, ...recentSearches
      .filter(item => item.name !== result.display_name)
      .slice(0, MAX_RECENT_SEARCHES - 1)
    ];
    
    setRecentSearches(updated);
    try {
      localStorage.setItem('recentSearches', JSON.stringify(updated));
    } catch (err) {
      console.error('Error saving recent searches:', err);
    }
    
    onLocationSelect(lat, lng, result.display_name, address);
    setQuery('');
    setShowResults(false);
  };

  const handleRecentSearchSelect = (search: {
    name: string;
    lat: number;
    lng: number;
    address?: { city?: string; state?: string; country?: string };
  }) => {
    if (isNaN(search.lat) || isNaN(search.lng) || 
        search.lat < -90 || search.lat > 90 || 
        search.lng < -180 || search.lng > 180) {
      setError('Invalid coordinates in saved search');
      return;
    }
    
    onLocationSelect(search.lat, search.lng, search.name, search.address);
    setShowResults(false);
  };

  const clearRecentSearches = () => {
    setRecentSearches([]);
    localStorage.removeItem('recentSearches');
  };

  return (
    <div ref={searchContainerRef} className={`relative ${className}`}>
      <div className="relative">
        <input
          type="text"
          placeholder={placeholder}
          value={query}
          onChange={(e) => {
            setQuery(e.target.value);
            setShowResults(true);
            setError('');
          }}
          onFocus={() => setShowResults(true)}
          className="w-full pl-10 pr-4 py-3 bg-dark-gray/50 backdrop-blur-sm border border-gray-700 rounded-lg focus:border-off-white transition-colors"
        />
        <div className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400">
          {loading ? (
            <Loader2 className="w-5 h-5 animate-spin" />
          ) : (
            <Search className="w-5 h-5" />
          )}
        </div>
      </div>

      {showResults && (query.trim() || recentSearches.length > 0) && (
        <div className="absolute w-full mt-2 bg-dark-gray border border-gray-800 rounded-lg shadow-lg overflow-hidden z-50">
          {error && (
            <div className="p-3 text-red-400 text-sm bg-red-900/20">
              {error}
            </div>
          )}

          {results.length > 0 && (
            <div className="max-h-[300px] overflow-y-auto">
              {results.map((result) => (
                <button
                  key={result.place_id}
                  onClick={() => handleSelectLocation(result)}
                  className="w-full px-4 py-3 text-left hover:bg-gray-800 transition-colors flex items-start space-x-3"
                >
                  <MapPin className="w-5 h-5 text-gray-400 mt-0.5 flex-shrink-0" />
                  <div>
                    <span className="text-sm line-clamp-2">{result.display_name}</span>
                    {result.address?.country && (
                      <span className="text-xs text-gray-400 block mt-0.5">
                        {[result.address.city, result.address.state, result.address.country]
                          .filter(Boolean)
                          .join(', ')}
                      </span>
                    )}
                  </div>
                </button>
              ))}
            </div>
          )}

          {query.trim() && !loading && !error && results.length === 0 && (
            <div className="p-4 text-sm text-gray-400">
              No locations found for "{query}"
            </div>
          )}

          {!query.trim() && recentSearches.length > 0 && (
            <div>
              <div className="px-4 py-2 flex items-center justify-between border-b border-gray-800">
                <span className="text-sm text-gray-400">Recent Searches</span>
                <button
                  onClick={clearRecentSearches}
                  className="text-xs text-gray-500 hover:text-gray-300 transition-colors"
                >
                  Clear All
                </button>
              </div>
              {recentSearches.map((search, index) => (
                <button
                  key={index}
                  onClick={() => handleRecentSearchSelect(search)}
                  className="w-full px-4 py-3 text-left hover:bg-gray-800 transition-colors flex items-start space-x-3"
                >
                  <Clock className="w-5 h-5 text-gray-400 mt-0.5 flex-shrink-0" />
                  <div>
                    <span className="text-sm line-clamp-2">{search.name}</span>
                    {search.address?.country && (
                      <span className="text-xs text-gray-400 block mt-0.5">
                        {[search.address.city, search.address.state, search.address.country]
                          .filter(Boolean)
                          .join(', ')}
                      </span>
                    )}
                  </div>
                </button>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}