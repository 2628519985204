import { AmadeusHotel, HotelOfferSearch } from '../../services/amadeus';

export interface City {
  name: string;
  country: string;
  latitude: number;
  longitude: number;
  iataCode?: string;
}

export interface PopularCity extends City {
  image?: string;
  description?: string;
}

export interface HotelSearchParams {
  city?: City;
  checkIn?: Date;
  checkOut?: Date;
  guests?: number;
  rooms?: number;
  priceRange?: {
    min?: number;
    max?: number;
  };
  ratings?: number[];
  amenities?: string[];
  sortBy?: SortOption;
}

export type SortOption = 
  | 'price-asc' 
  | 'price-desc' 
  | 'rating-desc' 
  | 'rating-asc' 
  | 'name-asc' 
  | 'name-desc' 
  | 'distance-asc';

export interface HotelSearchState {
  searchParams: HotelSearchParams;
  results: AmadeusHotel[];
  offers: Record<string, HotelOfferSearch[]>;
  loading: boolean;
  error: string | null;
  totalCount: number;
  currentPage: number;
  pageSize: number;
  viewMode: 'grid' | 'list' | 'map';
}

export interface HotelFilter {
  type: string;
  label: string;
  options: {
    value: string;
    label: string;
  }[];
}

export const POPULAR_CITIES: PopularCity[] = [
  { 
    name: 'New York', 
    country: 'USA', 
    latitude: 40.7128, 
    longitude: -74.0060,
    description: 'Experience the city that never sleeps with iconic landmarks and world-class hotels'
  },
  { 
    name: 'London', 
    country: 'UK', 
    latitude: 51.5074, 
    longitude: -0.1278,
    description: 'Discover luxury accommodations in the heart of historic London'
  },
  { 
    name: 'Paris', 
    country: 'France', 
    latitude: 48.8566, 
    longitude: 2.3522,
    description: 'Find romantic stays in the City of Light with views of the Eiffel Tower'
  },
  { 
    name: 'Tokyo', 
    country: 'Japan', 
    latitude: 35.6762, 
    longitude: 139.6503,
    description: 'Explore a blend of traditional ryokans and modern luxury hotels'
  },
  { 
    name: 'Rome', 
    country: 'Italy', 
    latitude: 41.9028, 
    longitude: 12.4964,
    description: 'Stay near ancient ruins and experience Italian hospitality at its finest'
  },
  { 
    name: 'Sydney', 
    country: 'Australia', 
    latitude: -33.8688, 
    longitude: 151.2093,
    description: 'Enjoy breathtaking harbor views from world-class accommodations'
  },
];

export const AMENITY_FILTERS: HotelFilter = {
  type: 'amenities',
  label: 'Amenities',
  options: [
    { value: 'WIFI', label: 'WiFi' },
    { value: 'POOL', label: 'Swimming Pool' },
    { value: 'PARKING', label: 'Parking' },
    { value: 'RESTAURANT', label: 'Restaurant' },
    { value: 'FITNESS_CENTER', label: 'Fitness Center' },
    { value: 'SPA', label: 'Spa' },
    { value: 'ROOM_SERVICE', label: 'Room Service' },
    { value: 'BUSINESS_CENTER', label: 'Business Center' },
  ]
};

export const RATING_FILTERS: HotelFilter = {
  type: 'ratings',
  label: 'Star Rating',
  options: [
    { value: '5', label: '5 Stars' },
    { value: '4', label: '4+ Stars' },
    { value: '3', label: '3+ Stars' },
    { value: '2', label: '2+ Stars' },
    { value: '1', label: '1+ Star' },
  ]
};

export const SORT_OPTIONS: HotelFilter = {
  type: 'sortBy',
  label: 'Sort By',
  options: [
    { value: 'rating-desc', label: 'Highest Rating' },
    { value: 'rating-asc', label: 'Lowest Rating' },
    { value: 'price-asc', label: 'Price: Low to High' },
    { value: 'price-desc', label: 'Price: High to Low' },
    { value: 'name-asc', label: 'Name: A to Z' },
    { value: 'name-desc', label: 'Name: Z to A' },
    { value: 'distance-asc', label: 'Distance: Nearest' },
  ]
}; 