import { useState, useEffect, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { format } from 'date-fns';
import { DateRange } from 'react-day-picker';
import { Loader2, Map as MapIcon, Grid2X2, List, Search, Hotel, Globe2, Calendar } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { Helmet } from 'react-helmet-async';
import Navigation from '../../components/Navigation';
import Footer from '../../components/Footer';
import SearchForm from './components/SearchForm';
import FilterOptions, { FilterState } from './components/FilterOptions';
import HotelDetailView from './components/HotelDetailView';
import PopularDestinations from './components/PopularDestinations';
import HotelCard from './components/HotelCard';
import { City, HotelSearchParams, SortOption } from './types';
import { searchHotels, getHotelOffers } from './services/hotelSearchService';
import { AmadeusHotel, HotelOfferSearch } from '../../services/amadeus';
import Pagination from './components/Pagination';
import HotelMap from './components/HotelMap';
import MapErrorBoundary from '../../components/MapErrorBoundary';

export default function HotelSearchPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedCity, setSelectedCity] = useState<City | undefined>(undefined);
  const [dateRange, setDateRange] = useState<DateRange | undefined>(undefined);
  const [guests, setGuests] = useState<number>(2);
  const [rooms, setRooms] = useState<number>(1);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<AmadeusHotel[]>([]);
  const [hotelOffers, setHotelOffers] = useState<Record<string, HotelOfferSearch[]>>({});
  const [totalResults, setTotalResults] = useState<number>(0);
  const [viewMode, setViewMode] = useState<'grid' | 'list' | 'map'>('grid');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [error, setError] = useState<string | null>(null);
  const [limitDisplay, setLimitDisplay] = useState<boolean>(true);
  const MAX_DISPLAYED_HOTELS = 20;
  const [selectedHotel, setSelectedHotel] = useState<AmadeusHotel | null>(null);
  const [selectedHotelOffers, setSelectedHotelOffers] = useState<HotelOfferSearch[] | null>(null);
  const [showDatePrompt, setShowDatePrompt] = useState<boolean>(false);
  const [filters, setFilters] = useState<FilterState>({
    boardType: undefined,
    paymentPolicy: 'NONE',
    includeClosed: false,
    priceRange: { min: 0, max: 5000 },
    language: 'EN',
    bestRateOnly: false
  });

  // Extract search parameters from URL
  useEffect(() => {
    const cityName = searchParams.get('city');
    const countryName = searchParams.get('country');
    const lat = searchParams.get('lat');
    const lng = searchParams.get('lng');
    const checkIn = searchParams.get('checkIn');
    const checkOut = searchParams.get('checkOut');
    const guestsParam = searchParams.get('guests');
    const roomsParam = searchParams.get('rooms');
    
    // Set date range if available
    if (checkIn && checkOut) {
      setDateRange({
        from: new Date(checkIn),
        to: new Date(checkOut)
      });
    }
    
    // Set guests and rooms if available
    if (guestsParam) setGuests(parseInt(guestsParam));
    if (roomsParam) setRooms(parseInt(roomsParam));
    
    // Set city if all parameters are available
    if (cityName && countryName && lat && lng) {
      setSelectedCity({
        name: cityName,
        country: countryName,
        latitude: parseFloat(lat),
        longitude: parseFloat(lng)
      });
      // Auto switch to map view when city is set from URL
      setViewMode('map');
    }
  }, [searchParams]);

  // Update search params when search criteria change
  useEffect(() => {
    if (selectedCity) {
      const params: Record<string, string> = {
        city: selectedCity.name,
        country: selectedCity.country,
        lat: selectedCity.latitude.toString(),
        lng: selectedCity.longitude.toString()
      };
      
      if (dateRange?.from) {
        params.checkIn = format(dateRange.from, 'yyyy-MM-dd');
      }
      
      if (dateRange?.to) {
        params.checkOut = format(dateRange.to, 'yyyy-MM-dd');
      }
      
      params.guests = guests.toString();
      params.rooms = rooms.toString();
      
      setSearchParams(params, { replace: true });
    }
  }, [selectedCity, dateRange, guests, rooms, setSearchParams]);

  // Perform hotel search when criteria change
  useEffect(() => {
    if (selectedCity) {
      performSearch();
    }
  }, [selectedCity, dateRange, currentPage, filters]);

  const performSearch = async () => {
    if (!selectedCity) return;
    
    setIsSearching(true);
    setError(null);
    
    try {
      const checkInDate = dateRange?.from ? format(dateRange.from, 'yyyy-MM-dd') : undefined;
      const checkOutDate = dateRange?.to ? format(dateRange.to, 'yyyy-MM-dd') : undefined;
      
      // First, get hotel listings (limited to 20 per request)
      const result = await searchHotels({
        city: selectedCity,
        checkIn: checkInDate,
        checkOut: checkOutDate,
        adults: guests,
        rooms: rooms,
        page: currentPage,
        pageSize: 20, // Explicitly limit to 20 hotels per page
        // Add enhanced filters as part of the filters object
        filters: {
          priceRange: filters.priceRange && filters.priceRange.max && filters.priceRange.max > 0 
            ? filters.priceRange 
            : undefined,
          // Don't pass boardType as amenities anymore
        }
      });
      
      setSearchResults(result.hotels);
      setTotalResults(result.totalCount);
      
      // Then, fetch offers if dates are selected (only for the limited set of hotels)
      if (checkInDate && checkOutDate && result.hotels.length > 0) {
        try {
          // Get hotel IDs for displayed hotels only
          const hotelIds = result.hotels.map(hotel => hotel.hotelId);
          const offers = await getHotelOffers(
            hotelIds, 
            checkInDate, 
            checkOutDate, 
            guests, 
            rooms, 
            'USD', // Using currency as string parameter
            filters.bestRateOnly !== undefined ? filters.bestRateOnly : true, // Pass the bestRateOnly filter
            filters // Pass all filters to handle boardType properly
          );
          setHotelOffers(offers);
        } catch (offerError) {
          console.error('Error fetching hotel offers, but continuing with hotel results:', offerError);
          // Do not set the main error state, as we still have hotel results to show
        }
      }
    } catch (error: any) {
      console.error('Error searching hotels:', error);
      setError('An error occurred while searching for hotels. Please try again.');
    } finally {
      setIsSearching(false);
    }
  };

  const handleSearch = (params: {
    city: City;
    dateRange: DateRange | undefined;
    guests: number;
    rooms: number;
  }) => {
    setSelectedCity(params.city);
    setDateRange(params.dateRange);
    setGuests(params.guests);
    setRooms(params.rooms);
    setCurrentPage(1); // Reset to first page on new search
    setViewMode('map'); // Automatically switch to map view when searching
  };
  
  // Handle filter changes
  const handleFilterChange = useCallback((newFilters: any) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...newFilters
    }));
    
    // No need to manually trigger search as it's handled by the useEffect
  }, []);

  const handleCitySelect = (city: City) => {
    setSelectedCity(city);
    setCurrentPage(1);
    setViewMode('map');

    // Show the date prompt to guide user to select dates
    setShowDatePrompt(true);
    
    // Auto-scroll to the search form
    const searchFormElement = document.getElementById('search-form');
    if (searchFormElement) {
      searchFormElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Quick search function that handles both city and date selection at once
  const handleQuickSearch = (city: City, dateRange: DateRange) => {
    setSelectedCity(city);
    setDateRange(dateRange);
    setCurrentPage(1);
    setViewMode('map');
    
    // Scroll to results
    setTimeout(() => {
      const resultsElement = document.getElementById('hotel-results');
      if (resultsElement) {
        resultsElement.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };

  const handleViewModeChange = (mode: 'grid' | 'list' | 'map') => {
    setViewMode(mode);
  };

  const handleHotelSelect = (hotel: AmadeusHotel) => {
    setSelectedHotel(hotel);
    // Also set the selected hotel offers if available
    if (hotel.hotelId && hotelOffers[hotel.hotelId]) {
      setSelectedHotelOffers(hotelOffers[hotel.hotelId]);
    } else {
      setSelectedHotelOffers(null);
    }
  };

  // Check if search is active
  const isSearchActive = Boolean(selectedCity);

  // Calculate total pages
  const totalPages = Math.ceil(totalResults / 9); // 9 is our pageSize

  return (
    <div className="min-h-screen bg-black flex flex-col">
      <Helmet>
        <title>
          {selectedCity 
            ? `Hotels in ${selectedCity.name} | Find the Best Rates` 
            : 'Hotel Search | Find the Best Hotel Rates'}
        </title>
        <meta 
          name="description" 
          content="Search for hotels worldwide and find the best rates for your next trip" 
        />
      </Helmet>

      <Navigation />

      <main className="flex-grow">
        {/* Search Section */}
        <section id="search-form" className="pt-24 px-4 sm:px-6 lg:px-8 pb-10 bg-gradient-to-b from-gray-900 to-black">
          <div className="max-w-7xl mx-auto">
            <div className="mb-8">
              {!selectedCity ? (
                <motion.div 
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                  className="text-center max-w-2xl mx-auto"
                >
                  <Hotel className="w-12 h-12 mx-auto mb-4 text-primary" />
                  <h1 className="text-4xl sm:text-5xl font-display font-bold text-white mb-4">
                    Find Your Perfect Stay
                  </h1>
                  <p className="text-xl text-gray-300">
                    Search hotels worldwide and find the best rates for your next trip
                  </p>
                </motion.div>
              ) : (
                <div className="flex items-center justify-between">
                  <div>
                    <h1 className="text-3xl sm:text-4xl font-display font-bold text-white mb-2">
                      Hotels in {selectedCity.name}
                    </h1>
                    <div className="flex items-center text-gray-400 text-sm">
                      <Globe2 className="h-4 w-4 mr-1" />
                      <span>{selectedCity.country}</span>
                      {dateRange?.from && dateRange?.to && (
                        <span className="ml-3">
                          {format(dateRange.from, 'MMM dd')} - {format(dateRange.to, 'MMM dd, yyyy')}
                        </span>
                      )}
                    </div>
                  </div>
                  {viewMode === 'map' && (
                    <button
                      onClick={() => setViewMode('grid')}
                      className="text-blue-400 hover:text-blue-300 flex items-center text-sm"
                    >
                      <Grid2X2 className="w-4 h-4 mr-1" />
                      Show as Grid
                    </button>
                  )}
                </div>
              )}
            </div>

            <SearchForm
              initialCity={selectedCity}
              initialDateRange={dateRange}
              initialGuests={guests}
              initialRooms={rooms}
              onSearch={handleSearch}
            />
            
            {/* Date selection prompt */}
            {showDatePrompt && !dateRange && (
              <div className="mt-4 rounded-lg bg-gradient-to-r from-primary/20 to-primary/10 p-4 border border-primary/30 flex items-center animate-fadeIn">
                <Calendar className="h-5 w-5 text-primary mr-2" />
                <p className="text-sm text-white">
                  <span className="font-medium">Now select your dates</span> to find available hotels in {selectedCity?.name}
                </p>
                <button 
                  onClick={() => setShowDatePrompt(false)} 
                  className="ml-auto text-gray-400 hover:text-white"
                >
                  <span className="sr-only">Dismiss</span>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </button>
              </div>
            )}
          </div>
        </section>

        {/* Add style for animate-fadeIn */}
        <style>
          {`
            @keyframes fadeIn {
              0% { opacity: 0; transform: translateY(-10px); }
              100% { opacity: 1; transform: translateY(0); }
            }
            .animate-fadeIn {
              animation: fadeIn 0.4s ease-out forwards;
            }
          `}
        </style>

        {/* Results Section */}
        <AnimatePresence mode="wait">
          {isSearchActive ? (
            <motion.section
              key="search-results"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.4 }}
              className="px-4 sm:px-6 lg:px-8 py-10"
            >
              <div className="max-w-7xl mx-auto">
                {/* Results Header */}
                <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6 gap-4">
                  <div>
                    <h2 className="text-2xl font-display font-semibold text-white">
                      {isSearching ? 'Searching hotels...' : 
                        (selectedCity && searchResults.length > 0) ? 
                        `Showing ${Math.min(searchResults.length, MAX_DISPLAYED_HOTELS)} of ${totalResults} Hotels in ${selectedCity.name}` : 
                        'Search Results'}
                    </h2>
                    {dateRange?.from && dateRange?.to && (
                      <p className="text-gray-400 mt-1">
                        {format(dateRange.from, 'MMM dd, yyyy')} - {format(dateRange.to, 'MMM dd, yyyy')}
                        {' · '}{guests} {guests === 1 ? 'guest' : 'guests'}{' · '}{rooms} {rooms === 1 ? 'room' : 'rooms'}
                      </p>
                    )}
                    {totalResults > MAX_DISPLAYED_HOTELS && (
                      <p className="text-yellow-500 text-sm mt-1">Results limited to {MAX_DISPLAYED_HOTELS} hotels to improve performance</p>
                    )}
                  </div>

                  {/* View Toggle */}
                  <div className="flex items-center bg-gray-800 rounded-lg p-1">
                    <button
                      onClick={() => handleViewModeChange('grid')}
                      className={`p-2 rounded-md ${viewMode === 'grid' ? 'bg-gray-700 text-primary' : 'text-gray-400'}`}
                      title="Grid View"
                    >
                      <Grid2X2 className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => handleViewModeChange('list')}
                      className={`p-2 rounded-md ${viewMode === 'list' ? 'bg-gray-700 text-primary' : 'text-gray-400'}`}
                      title="List View"
                    >
                      <List className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => handleViewModeChange('map')}
                      className={`p-2 rounded-md ${viewMode === 'map' ? 'bg-gray-700 text-primary' : 'text-gray-400'}`}
                      title="Map View"
                    >
                      <MapIcon className="h-5 w-5" />
                    </button>
                  </div>
                </div>

                {/* Loading State */}
                {isSearching && (
                  <div className="flex justify-center py-12">
                    <Loader2 className="w-12 h-12 animate-spin text-primary" />
                  </div>
                )}

                {/* Error State */}
                {error && !isSearching && (
                  <div className="bg-red-900/20 border border-red-700 rounded-lg p-6 text-center">
                    <p className="text-red-400">{error}</p>
                    <button
                      onClick={performSearch}
                      className="mt-4 bg-primary text-black font-medium px-6 py-2 rounded-lg"
                    >
                      Try Again
                    </button>
                  </div>
                )}

                {/* Empty Results */}
                {!isSearching && !error && searchResults.length === 0 && (
                  <div className="bg-gray-800/50 rounded-lg p-10 text-center">
                    <h3 className="text-xl font-medium text-white mb-2">No hotels found</h3>
                    <p className="text-gray-400 mb-6">Try adjusting your search criteria or check a different destination.</p>
                  </div>
                )}

                {/* Map and Results Display */}
                {!isSearching && !error && searchResults.length > 0 && selectedCity && (
                  <div className="grid md:grid-cols-3 gap-6">
                    {/* Left Column: Filters and Options */}
                    <div className="md:col-span-1">
                      {/* Filter Options Panel */}
                      <FilterOptions 
                        onFilterChange={handleFilterChange}
                        initialFilters={filters}
                      />
                    </div>

                    {/* Main Content Area: Map or Grid */}
                    <div className="md:col-span-2">
                      {viewMode === 'map' ? (
                        <div className="h-[600px] rounded-lg overflow-hidden">
                          <MapErrorBoundary>
                            <HotelMap
                              hotels={searchResults}
                              offers={hotelOffers}
                              centerLat={selectedCity.latitude}
                              centerLng={selectedCity.longitude}
                              limitMarkers={limitDisplay}
                              onHotelSelect={handleHotelSelect}
                            />
                          </MapErrorBoundary>
                        </div>
                      ) : (
                        <>
                          {/* Hotel Cards Grid/List View */}
                          <div className={`grid ${viewMode === 'grid' ? 'grid-cols-1 md:grid-cols-2 lg:grid-cols-2' : 'grid-cols-1'} gap-6`}>
                            {(limitDisplay ? searchResults.slice(0, MAX_DISPLAYED_HOTELS) : searchResults).map((hotel) => (
                              <div 
                                id={`hotel-${hotel.hotelId}`} 
                                key={hotel.hotelId}
                                className="transition-all duration-300"
                              >
                                <HotelCard
                                  hotel={hotel}
                                  offers={hotelOffers[hotel.hotelId]}
                                  onClick={() => {
                                    handleHotelSelect(hotel);
                                    setSelectedHotelOffers(hotelOffers[hotel.hotelId] || null);
                                    
                                    // Create and dispatch a custom event to fly to this hotel
                                    if (hotel.geoCode) {
                                      const event = new CustomEvent('flyToHotel', {
                                        detail: {
                                          hotelId: hotel.hotelId,
                                          lng: hotel.geoCode.longitude,
                                          lat: hotel.geoCode.latitude
                                        }
                                      });
                                      document.dispatchEvent(event);
                                    }
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                          
                          {/* Show All Button */}
                          {limitDisplay && searchResults.length > MAX_DISPLAYED_HOTELS && (
                            <div className="flex justify-center mt-6">
                              <button 
                                onClick={() => setLimitDisplay(false)} 
                                className="bg-primary hover:bg-primary/90 text-black font-medium py-2 px-6 rounded-lg transition-colors"
                              >
                                Show All {searchResults.length} Hotels
                              </button>
                            </div>
                          )}
                        </>
                      )}
                      {/* Pagination */}
                      <Pagination 
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={setCurrentPage}
                      />
                    </div>
                  </div>
                )}
              </div>
            </motion.section>
          ) : (
            <motion.section
              key="popular-destinations"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.4 }}
              className="px-4 sm:px-6 lg:px-8 py-12"
            >
              <div className="max-w-7xl mx-auto">
                <PopularDestinations 
                  onSelectCity={handleCitySelect} 
                  onQuickSearch={handleQuickSearch}
                />
              </div>
            </motion.section>
          )}
        </AnimatePresence>
      </main>

      <Footer />

      {/* Show Hotel Detail View when a hotel is selected */}
      {selectedHotel && selectedHotel.hotelId && (
        <HotelDetailView 
          hotel={selectedHotel} 
          offers={selectedHotelOffers || undefined} 
          onClose={() => {
            setSelectedHotel(null);
            setSelectedHotelOffers(null);
          }} 
        />
      )}
    </div>
  );
} 