import { useState, useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { AmadeusHotel, HotelOfferSearch } from '../../../services/amadeus';
import { Loader2, Map, Building } from 'lucide-react';

// Set Mapbox token from environment variables
mapboxgl.accessToken = import.meta.env.VITE_MAPBOX_TOKEN || 'YOUR_MAPBOX_TOKEN_HERE';

// Satellite view style
const SATELLITE_STYLE = 'mapbox://styles/mapbox/satellite-streets-v12';

interface HotelMapProps {
  hotels: AmadeusHotel[];
  offers?: Record<string, HotelOfferSearch[]>;
  centerLat: number;
  centerLng: number;
  onHotelSelect?: (hotel: AmadeusHotel) => void;
  limitMarkers?: boolean;
}

export default function HotelMap({ 
  hotels, 
  offers, 
  centerLat, 
  centerLng, 
  onHotelSelect,
  limitMarkers = true 
}: HotelMapProps) {
  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const [mapInitialized, setMapInitialized] = useState(false);
  const markersRef = useRef<mapboxgl.Marker[]>([]);
  const markersDataRef = useRef<{[key: string]: mapboxgl.Marker}>({});
  const [showAllMarkers, setShowAllMarkers] = useState<boolean>(!limitMarkers);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isMapCompact, setIsMapCompact] = useState<boolean>(false);
  const [is3DEnabled, setIs3DEnabled] = useState(true);
  
  const MAX_MARKERS = 20;
  
  // Check if Mapbox token is valid
  useEffect(() => {
    if (!mapboxgl.accessToken || mapboxgl.accessToken === 'YOUR_MAPBOX_TOKEN_HERE') {
      console.warn(
        'Mapbox token not found. Please add your token to the .env file as VITE_MAPBOX_TOKEN=your_token_here'
      );
    }
  }, []);
  
  // Check if on mobile
  useEffect(() => {
    const checkIfMobile = () => {
      setIsMapCompact(window.innerWidth < 768);
    };
    
    // Initial check
    checkIfMobile();
    
    // Add resize listener
    window.addEventListener('resize', checkIfMobile);
    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  // Helper function to enable 3D buildings
  const enable3DBuildings = () => {
    const mapInstance = map.current;
    if (!mapInstance) return;
    
    // Use a type guard to fix TypeScript error
    const style = mapInstance.getStyle();
    if (!style || !style.layers) return;
    
    // Check if 3D buildings are already added
    if (mapInstance.getLayer('3d-buildings')) return;
    
    // Check if all required sources are loaded
    const compositeSource = mapInstance.getSource('composite');
    if (!compositeSource) {
      console.log('Waiting for sources to load before adding 3D buildings...');
      setTimeout(enable3DBuildings, 500); // Try again later
      return;
    }

    try {
      // Find a suitable layer to insert before
      const layers = style.layers;
      
      // Find a label layer to insert before - different styles have different label layers
      let labelLayerId;
      for (const layer of layers) {
        if (layer.type === 'symbol' && layer.layout && layer.layout['text-field']) {
          labelLayerId = layer.id;
          break;
        }
      }

      // Add 3D building layer - add at the end if no suitable layer is found
      mapInstance.addLayer({
        'id': '3d-buildings',
        'source': 'composite',
        'source-layer': 'building',
        'filter': ['>', 'height', 0], // Use height instead of extrude for better compatibility
        'type': 'fill-extrusion',
        'minzoom': 14,
        'paint': {
          'fill-extrusion-color': [
            'interpolate',
            ['linear'],
            ['get', 'height'],
            0, 'rgba(255, 255, 255, 0.4)',
            50, 'rgba(255, 255, 255, 0.5)',
            100, 'rgba(235, 235, 235, 0.6)',
            200, 'rgba(215, 215, 215, 0.7)'
          ],
          'fill-extrusion-height': ['get', 'height'],
          'fill-extrusion-base': ['get', 'min_height'],
          'fill-extrusion-opacity': 0.7
        }
      }, labelLayerId);
      
      // Adjust pitch to see 3D buildings better
      mapInstance.setPitch(45);
    } catch (err) {
      console.error('Error adding 3D buildings layer:', err);
      
      // Fallback approach - add the layer without specifying a beforeId
      try {
        if (!mapInstance.getLayer('3d-buildings')) {
          mapInstance.addLayer({
            'id': '3d-buildings',
            'source': 'composite',
            'source-layer': 'building',
            'filter': ['>', 'height', 0],
            'type': 'fill-extrusion',
            'minzoom': 14,
            'paint': {
              'fill-extrusion-color': [
                'interpolate',
                ['linear'],
                ['get', 'height'],
                0, 'rgba(255, 255, 255, 0.4)',
                50, 'rgba(255, 255, 255, 0.5)',
                100, 'rgba(235, 235, 235, 0.6)',
                200, 'rgba(215, 215, 215, 0.7)'
              ],
              'fill-extrusion-height': ['get', 'height'],
              'fill-extrusion-base': ['get', 'min_height'],
              'fill-extrusion-opacity': 0.7
            }
          });
          
          mapInstance.setPitch(45);
        }
      } catch (fallbackErr) {
        console.error('Fallback 3D buildings approach also failed:', fallbackErr);
      }
    }
  };
  
  // Helper function to disable 3D buildings
  const disable3DBuildings = () => {
    const mapInstance = map.current;
    if (!mapInstance) return;
    
    if (mapInstance.getLayer('3d-buildings')) {
      mapInstance.removeLayer('3d-buildings');
      mapInstance.setPitch(0);
    }
  };
  
  // Initialize map
  useEffect(() => {
    if (!mapContainer.current) return;

    try {
      setIsLoading(true);
      
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: SATELLITE_STYLE,
        center: [centerLng, centerLat],
        zoom: 13,
        pitch: is3DEnabled ? 45 : 0, // Start with pitched view for 3D
        bearing: 0,
        attributionControl: false
      });

      // Use compact navigation controls on mobile
      map.current.addControl(new mapboxgl.NavigationControl({
        showCompass: !isMapCompact,
        showZoom: true,
        visualizePitch: true
      }), 'top-right');
      
      map.current.addControl(new mapboxgl.AttributionControl({
        compact: true
      }));
      
      // Add geolocate control for mobile users
      map.current.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true
          },
          trackUserLocation: true,
          showUserHeading: true
        }),
        'top-right'
      );
      
      map.current.on('load', () => {
        setMapInitialized(true);
        setIsLoading(false);
        
        // Enable 3D buildings if needed
        if (is3DEnabled) {
          enable3DBuildings();
        }
      });
      
      // Add error handling
      map.current.on('error', (e) => {
        console.error('Mapbox error:', e.error);
        setError('Error loading map. Please check your connection and try again.');
        setIsLoading(false);
      });

    } catch (err) {
      console.error('Error initializing map:', err);
      setError('Failed to initialize map. Please check your browser compatibility.');
      setIsLoading(false);
    }

    return () => {
      if (map.current) {
        map.current.remove();
        map.current = null;
      }
    };
  }, [centerLat, centerLng, isMapCompact, is3DEnabled]);

  // Toggle 3D buildings
  useEffect(() => {
    if (!map.current || !mapInitialized) return;
    
    if (is3DEnabled) {
      enable3DBuildings();
    } else {
      disable3DBuildings();
    }
  }, [is3DEnabled, mapInitialized]);

  // Update map center when coordinates change
  useEffect(() => {
    if (map.current && map.current.loaded()) {
      map.current.flyTo({
        center: [centerLng, centerLat],
        zoom: 13,
        speed: 1.2,
        essential: true
      });
    }
  }, [centerLat, centerLng]);

  // Function to add markers to map
  const addMarkersToMap = () => {
    if (!map.current || !mapInitialized || !hotels.length) return;

    // Clear existing markers
    markersRef.current.forEach(marker => marker.remove());
    markersRef.current = [];
    markersDataRef.current = {};

    // Limit hotels for markers if needed
    const hotelsToDisplay = showAllMarkers ? hotels : hotels.slice(0, MAX_MARKERS);

    // Create marker elements
    hotelsToDisplay.forEach(hotel => {
      if (!hotel.geoCode || !map.current) return;

      try {
        // Get price if available
        const hotelOffers = offers?.[hotel.hotelId];
        const price = hotelOffers?.[0]?.offers?.[0]?.price?.total;
        const currencyCode = hotelOffers?.[0]?.offers?.[0]?.price?.currency || 'USD';

        // Create custom element for marker
        const el = document.createElement('div');
        el.className = 'hotel-marker';
        el.setAttribute('data-hotel-id', hotel.hotelId);
        
        // Create larger touch targets for mobile
        const markerSize = isMapCompact ? 'h-10 w-auto px-3' : 'h-8 w-auto px-2';
        const circleSize = isMapCompact ? 'w-10 h-10' : 'w-8 h-8';
        
        // Marker with price or rating
        if (price) {
          el.innerHTML = `
            <div class="flex items-center justify-center ${markerSize} rounded-full bg-gradient-to-r from-primary to-primary/80 text-black text-xs font-semibold shadow-lg transform transition-transform hover:scale-110 cursor-pointer border-2 border-gray-900">
              ${currencyCode} ${parseFloat(price).toFixed(0)}
            </div>
          `;
        } else {
          el.innerHTML = `
            <div class="${circleSize} rounded-full bg-gradient-to-r from-primary to-primary/80 text-black flex items-center justify-center font-bold text-xs cursor-pointer transform hover:scale-110 transition-transform border-2 border-gray-900">
              ${hotel.rating || '?'}
            </div>
          `;
        }

        // Create popup with hotel details - modified for better mobile experience
        const popup = new mapboxgl.Popup({ 
          offset: 25, 
          closeButton: false,
          maxWidth: isMapCompact ? '280px' : '300px',
          className: isMapCompact ? 'hotel-popup-mobile' : 'hotel-popup'
        }).setHTML(`
          <div class="p-3">
            <h3 class="font-semibold text-sm mb-1">${hotel.name}</h3>
            <div class="text-xs text-gray-600 mb-2">
              ${hotel.address.cityName}${hotel.address.lines ? ', ' + hotel.address.lines[0] : ''}
            </div>
            ${price ? 
              `<div class="font-bold text-primary text-sm">${currencyCode} ${parseFloat(price).toFixed(2)}/night</div>` : 
              `<div class="text-xs">Price unavailable</div>`
            }
            ${hotel.amenities && hotel.amenities.length > 0 ? 
              `<div class="mt-1 text-xs text-gray-500">${hotel.amenities.slice(0, 3).join(' • ')}</div>` : 
              ''
            }
            <button class="mt-2 w-full text-xs bg-gradient-to-r from-primary to-primary/80 text-black py-2 px-2 rounded hover:from-primary/90 hover:to-primary/70 transition-colors font-semibold">
              View Details
            </button>
          </div>
        `);

        // Create marker
        const marker = new mapboxgl.Marker(el)
          .setLngLat([hotel.geoCode.longitude, hotel.geoCode.latitude])
          .setPopup(popup)
          .addTo(map.current);

        // Add click handler
        el.addEventListener('click', () => {
          if (onHotelSelect) {
            onHotelSelect(hotel);
          }
          
          // Highlight marker
          el.classList.add('scale-125');
          setTimeout(() => {
            el.classList.remove('scale-125');
          }, 1000);
          
          // Open popup
          marker.togglePopup();
        });

        markersRef.current.push(marker);
        markersDataRef.current[hotel.hotelId] = marker;
      } catch (err) {
        console.error('Error creating marker for hotel:', hotel.hotelId, err);
      }
    });

    // Fit bounds to include all markers if there are multiple hotels
    if (hotelsToDisplay.length > 1 && map.current) {
      try {
        const bounds = new mapboxgl.LngLatBounds();
        let validBoundsPoints = 0;
        
        hotelsToDisplay.forEach(hotel => {
          if (hotel.geoCode) {
            bounds.extend([hotel.geoCode.longitude, hotel.geoCode.latitude]);
            validBoundsPoints++;
          }
        });
        
        if (validBoundsPoints > 1) {
          map.current.fitBounds(bounds, {
            padding: 50,
            maxZoom: 15,
            duration: 1000
          });
        }
      } catch (err) {
        console.error('Error fitting bounds:', err);
      }
    }
  };

  // Add markers when hotels data changes or map is initialized
  useEffect(() => {
    addMarkersToMap();
  }, [hotels, offers, mapInitialized, onHotelSelect, showAllMarkers, isMapCompact]);

  // Highlight marker when hotel is selected
  const handleCardClick = (event: any) => {
    if (!event?.detail) return;
    
    if (map.current && event.detail && event.detail.hotelId) {
      const marker = markersDataRef.current[event.detail.hotelId];
      if (marker) {
        // Get the marker element
        const el = marker.getElement();
        
        // Highlight marker
        el.classList.add('scale-125');
        setTimeout(() => {
          el.classList.remove('scale-125');
        }, 1000);
        
        // Center map on this hotel with flyTo for smooth animation
        const hotel = hotels.find(h => h.hotelId === event.detail.hotelId);
        if (hotel && hotel.geoCode) {
          map.current.flyTo({
            center: [hotel.geoCode.longitude, hotel.geoCode.latitude],
            zoom: 15,
            pitch: is3DEnabled ? 50 : 0, // Increase pitch for better 3D view
            bearing: 30, // Add slight bearing for perspective
            speed: 1.2,
            curve: 1.5,
            easing: (t) => t,
            essential: true
          });
        }
        
        // Open popup
        marker.togglePopup();
      }
    }
  };
  
  // Listen for hotel-selected events
  useEffect(() => {
    document.addEventListener('hotel-selected', handleCardClick);
    return () => {
      document.removeEventListener('hotel-selected', handleCardClick);
    };
  }, [hotels, showAllMarkers, mapInitialized, is3DEnabled]);

  // Show more markers button
  const toggleShowAllMarkers = () => {
    setShowAllMarkers(prev => !prev);
  };

  return (
    <div className="relative h-full w-full rounded-lg overflow-hidden">
      {/* Map Container */}
      <div ref={mapContainer} className="w-full h-full rounded-lg" />
      
      {/* Loading indicator */}
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-black/50 backdrop-blur-sm">
          <div className="text-white flex flex-col items-center">
            <Loader2 className="w-8 h-8 animate-spin mb-2" />
            <span>Loading map...</span>
          </div>
        </div>
      )}
      
      {/* Error message */}
      {error && (
        <div className="absolute inset-0 flex items-center justify-center bg-red-900/30 backdrop-blur-sm">
          <div className="bg-white rounded-lg p-4 max-w-xs shadow-lg">
            <h3 className="font-bold text-red-600 mb-2">Map Error</h3>
            <p className="text-sm text-gray-700">{error}</p>
          </div>
        </div>
      )}
      
      {/* Map controls */}
      <div className="absolute bottom-4 left-4 flex flex-col space-y-2 z-10">
        {/* 3D toggle */}
        <button 
          onClick={() => setIs3DEnabled(prev => !prev)}
          className={`p-2 rounded-full shadow-lg transition-colors ${
            is3DEnabled 
              ? 'bg-primary text-black' 
              : 'bg-gray-700 text-white hover:bg-gray-600'
          }`}
          title={is3DEnabled ? 'Disable 3D buildings' : 'Enable 3D buildings'}
        >
          <Building className="w-5 h-5" />
        </button>
        
        {/* Show all markers toggle - only show if limitMarkers is true */}
        {limitMarkers && hotels.length > MAX_MARKERS && (
          <button 
            onClick={toggleShowAllMarkers}
            className={`p-2 rounded-full shadow-lg transition-colors ${
              showAllMarkers 
                ? 'bg-primary text-black' 
                : 'bg-gray-700 text-white hover:bg-gray-600'
            }`}
            title={showAllMarkers ? 'Show fewer hotels' : 'Show all hotels'}
          >
            <Map className="w-5 h-5" />
            <span className="sr-only">{showAllMarkers ? 'Show fewer hotels' : 'Show all hotels'}</span>
          </button>
        )}
      </div>
    </div>
  );
} 