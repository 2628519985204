import { Star, MapPin, Clock, Users, Tag, Calendar } from 'lucide-react';
import { Tour } from '../types';

interface TourCardProps {
  tour: Tour;
  onClick?: () => void;
  expanded?: boolean;
}

const DEFAULT_IMAGE = 'https://images.unsplash.com/photo-1493246507139-91e8fad9978e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80';

export default function TourCard({ tour, onClick, expanded = false }: TourCardProps) {
  // Get tour image
  const tourImage = tour.images?.[0] || DEFAULT_IMAGE;

  // Format price for display
  const price = parseFloat(tour.price.amount);
  const formattedPrice = isNaN(price) ? '---' : price.toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  });
  
  // Extract categories for display - making sure categories are properly formatted
  const displayCategories = tour.categories?.map(cat => 
    cat.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())
  ).slice(0, expanded ? 3 : 2) || [];

  // Format duration for display
  const durationText = tour.duration || 'Duration not specified';
  
  // Format rating display
  const ratingDisplay = tour.rating 
    ? tour.rating.toFixed(1) 
    : null;
    
  // Get review count display
  const reviewCountDisplay = tour.reviewCount 
    ? `(${tour.reviewCount})` 
    : '';

  return (
    <div 
      className={`bg-gradient-to-b from-gray-800/50 to-gray-900/60 rounded-lg overflow-hidden transition-all duration-300 ${
        expanded 
          ? 'border border-gray-700/30' 
          : 'cursor-pointer group border border-transparent hover:border-gray-700/40 hover:shadow-md hover:shadow-black/20 hover:translate-y-[-2px]'
      }`}
      onClick={expanded ? undefined : onClick}
    >
      <div className="flex flex-col sm:flex-row md:flex-col">
        {/* Tour Image with improved transitions */}
        <div className={`relative ${expanded ? 'h-56' : 'h-40 sm:h-full sm:w-2/5 md:w-full md:h-48'} overflow-hidden`}>
          <img 
            src={tourImage} 
            alt={tour.name} 
            className={`w-full h-full object-cover transition-transform duration-700 ${expanded ? 'scale-100' : 'group-hover:scale-105'}`}
            onError={(e) => {
              // Fallback if image fails to load
              (e.target as HTMLImageElement).src = DEFAULT_IMAGE;
            }}
          />
          {/* Rating badge with refined styling */}
          {ratingDisplay && (
            <div className="absolute top-3 right-3 bg-black/70 backdrop-blur-sm text-white px-3 py-1 rounded-full text-sm font-medium flex items-center shadow-lg border border-white/10">
              <Star className="mr-1 h-4 w-4 text-primary" />
              {ratingDisplay} <span className="text-gray-300 text-xs ml-1">{reviewCountDisplay}</span>
            </div>
          )}
        </div>

        {/* Tour Info with improved spacing and typography */}
        <div className="p-4 sm:p-5 sm:w-3/5 md:w-full">
          <h3 className={`${expanded ? 'text-xl sm:text-2xl' : 'text-lg sm:text-xl'} font-semibold text-white mb-1.5 ${expanded ? '' : 'line-clamp-1'} tracking-tight`}>
            {tour.name}
          </h3>
          
          <div className="flex items-center text-gray-400 mb-3">
            <MapPin className="h-4 w-4 mr-1.5 flex-shrink-0 text-primary/80" />
            <span className={`text-xs sm:text-sm ${expanded ? '' : 'line-clamp-1'}`}>
              {tour.location.city}, {tour.location.country}
            </span>
          </div>

          {/* Tour details with refined styling */}
          <div className="flex flex-wrap gap-2 sm:gap-2.5 mb-3 sm:mb-4">
            {/* Duration */}
            <div className="flex items-center bg-gray-800/80 px-2.5 py-1 rounded-full text-gray-300 text-xs border border-gray-700/40">
              <Clock className="w-3.5 h-3.5 mr-1.5 text-primary/90" />
              {durationText}
            </div>
            
            {/* Participants if available */}
            {(tour.minimumParticipants || tour.maximumParticipants) && (
              <div className="flex items-center bg-gray-800/80 px-2.5 py-1 rounded-full text-gray-300 text-xs border border-gray-700/40">
                <Users className="w-3.5 h-3.5 mr-1.5 text-primary/90" />
                {tour.minimumParticipants === tour.maximumParticipants 
                  ? `${tour.minimumParticipants} pax`
                  : `${tour.minimumParticipants || 1}-${tour.maximumParticipants || '∞'} pax`
                }
              </div>
            )}
            
            {/* Display categories with refined styling */}
            {displayCategories.map((category, index) => (
              <div key={index} className="flex items-center bg-gray-800/80 px-2.5 py-1 rounded-full text-gray-300 text-xs border border-gray-700/40">
                <Tag className="w-3.5 h-3.5 mr-1.5 text-primary/90" />
                {category}
              </div>
            ))}
            
            {/* Show "more" indicator if there are additional categories */}
            {!expanded && tour.categories && tour.categories.length > 2 && (
              <div className="bg-gray-800/80 px-2.5 py-1 rounded-full text-gray-300 text-xs border border-gray-700/40">
                +{tour.categories.length - 2} more
              </div>
            )}
          </div>

          {/* Extra details when expanded with improved container styling */}
          {expanded && (
            <div className="mt-4 mb-6 space-y-4">
              {/* Show highlights */}
              {tour.highlights && tour.highlights.length > 0 && (
                <div className="p-4 bg-black/20 rounded-lg border border-gray-700/30">
                  <h4 className="text-white font-medium mb-3 text-sm tracking-wide uppercase">Highlights</h4>
                  <div className="grid grid-cols-1 gap-2.5">
                    {tour.highlights.slice(0, 3).map((highlight, index) => (
                      <div key={index} className="text-gray-300 text-sm flex">
                        <span className="text-primary mr-2">•</span>
                        <span>{highlight}</span>
                      </div>
                    ))}
                  </div>
                  {tour.highlights.length > 3 && (
                    <button className="text-primary text-sm mt-2 hover:underline cursor-pointer flex items-center">
                      + {tour.highlights.length - 3} more highlights
                    </button>
                  )}
                </div>
              )}
              
              {/* Languages if available with improved styling */}
              {tour.languages && tour.languages.length > 0 && (
                <div className="mb-4">
                  <h4 className="text-white font-medium mb-2 text-sm tracking-wide uppercase">Languages</h4>
                  <div className="flex flex-wrap gap-2">
                    {tour.languages.map((lang, idx) => (
                      <span key={idx} className="px-2.5 py-1 text-xs bg-black/20 text-gray-300 rounded-full border border-gray-700/30">
                        {lang}
                      </span>
                    ))}
                  </div>
                </div>
              )}
              
              {/* Short description with improved typography */}
              {tour.shortDescription && (
                <div className="text-gray-300 text-sm leading-relaxed line-clamp-3 p-4 bg-black/20 rounded-lg border border-gray-700/30">
                  <span className="italic">{tour.shortDescription}</span>
                </div>
              )}
              
              {/* Booking link if available with improved styling */}
              {tour.bookingLink && (
                <div className="mb-4">
                  <a 
                    href={tour.bookingLink} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="text-primary text-sm hover:text-primary/80 transition-colors duration-200 flex items-center"
                  >
                    <Calendar className="w-4 h-4 mr-1.5" />
                    View availability on provider's site
                  </a>
                </div>
              )}
            </div>
          )}

          {/* Price and CTA with improved styling */}
          <div className="flex items-center justify-between mt-auto">
            <div>
              <span className="text-primary text-lg sm:text-xl font-bold">
                ${formattedPrice}
              </span>
              <span className="text-gray-400 text-xs sm:text-sm ml-1">/ person</span>
            </div>
            
            <button className={`${expanded 
              ? 'bg-primary text-black hover:bg-primary/90'
              : 'bg-black/30 text-white hover:bg-black/40 border border-gray-700/50 hover:border-primary/50'
            } px-4 py-2 rounded-lg text-xs sm:text-sm font-medium min-w-[90px] sm:min-w-[110px] flex items-center justify-center transition-all duration-300`}>
              {expanded ? 'Book Now' : 'View Details'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
} 