import React, { useEffect, useRef, useState, createContext, useContext } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { X as IconX, ArrowLeft as IconArrowNarrowLeft, ArrowRight as IconArrowNarrowRight } from "lucide-react";
import { cn } from "../../lib/utils";
import { getResponsiveImageSrc } from "../../utils/imageOptimization";

interface CarouselProps {
  items: JSX.Element[];
  initialScroll?: number;
}

export type Card = {
  src: string;
  title: string;
  category: string;
  content: React.ReactNode;
  href?: string;
  preventPreview?: boolean;
  onClick?: () => void;
  isTour?: boolean;
};

export const CarouselContext = createContext<{
  onCardClose: (index: number) => void;
  currentIndex: number;
}>({
  onCardClose: () => {},
  currentIndex: 0,
});

export const Carousel = ({ items, initialScroll = 0 }: CarouselProps) => {
  const carouselRef = React.useRef<HTMLDivElement>(null);
  const [canScrollLeft, setCanScrollLeft] = React.useState(false);
  const [canScrollRight, setCanScrollRight] = React.useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (carouselRef.current) {
      carouselRef.current.scrollLeft = initialScroll;
      checkScrollability();
    }
  }, [initialScroll]);

  const checkScrollability = () => {
    if (carouselRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft < scrollWidth - clientWidth);
    }
  };

  const scrollLeft = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({ left: -300, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({ left: 300, behavior: "smooth" });
    }
  };

  const handleCardClose = (index: number) => {
    if (carouselRef.current) {
      const cardWidth = window.innerWidth < 768 ? 230 : 384;
      const gap = window.innerWidth < 768 ? 4 : 8;
      const scrollPosition = (cardWidth + gap) * (index + 1);
      carouselRef.current.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });
      setCurrentIndex(index);
    }
  };

  return (
    <CarouselContext.Provider value={{ onCardClose: handleCardClose, currentIndex }}>
      <div className="relative w-full">
        <div
          className="flex w-full overflow-x-scroll overscroll-x-auto py-10 md:py-20 scroll-smooth [scrollbar-width:none]"
          ref={carouselRef}
          onScroll={checkScrollability}
        >
          <div className={cn("absolute right-0 z-[1000] h-auto w-[5%] overflow-hidden bg-gradient-to-l")}></div>

          <div className={cn("flex flex-row justify-start gap-4 pl-4", "max-w-7xl mx-auto")}>
            {items.map((item, index) => (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{
                  opacity: 1,
                  y: 0,
                  transition: {
                    duration: 0.5,
                    delay: 0.2 * index,
                    ease: "easeOut",
                  },
                }}
                key={"card" + index}
                className="last:pr-[5%] md:last:pr-[33%] rounded-3xl"
              >
                {item}
              </motion.div>
            ))}
          </div>
        </div>
        <div className="flex justify-end gap-2 mr-10">
          <button
            className="relative z-40 h-10 w-10 rounded-full bg-gray-100 flex items-center justify-center disabled:opacity-50"
            onClick={scrollLeft}
            disabled={!canScrollLeft}
          >
            <IconArrowNarrowLeft className="h-6 w-6 text-gray-500" />
          </button>
          <button
            className="relative z-40 h-10 w-10 rounded-full bg-gray-100 flex items-center justify-center disabled:opacity-50"
            onClick={scrollRight}
            disabled={!canScrollRight}
          >
            <IconArrowNarrowRight className="h-6 w-6 text-gray-500" />
          </button>
        </div>
      </div>
    </CarouselContext.Provider>
  );
};

// Add styles for blur animation
const BlurStyles = () => (
  <style>
    {`
      @keyframes unblur {
        0% {
          filter: blur(20px);
          transform: scale(1.05);
        }
        40% {
          filter: blur(10px);
          transform: scale(1.025);
        }
        80% {
          filter: blur(5px);
          transform: scale(1.0125);
        }
        100% {
          filter: blur(0);
          transform: scale(1);
        }
      }

      .blur-loading {
        filter: blur(20px);
        transform: scale(1.05);
        transition: all 0.3s ease-out;
      }

      .unblur {
        animation: unblur 0.7s cubic-bezier(0.4, 0, 0.2, 1);
      }
    `}
  </style>
);

// Define optimal image sizes based on card dimensions
const IMAGE_SIZES = {
  mobile: {
    width: 224, // w-56
    height: 320, // h-80
    quality: 85
  },
  desktop: {
    width: 384, // w-96
    height: 640, // h-[40rem]
    quality: 90
  },
  tiny: {
    width: 20,
    height: 30,
    quality: 10
  }
} as const;

// Helper to generate optimized Unsplash URLs
const getOptimizedImageUrl = (url: string, size: typeof IMAGE_SIZES[keyof typeof IMAGE_SIZES]) => {
  if (!url.includes('unsplash.com')) return url;
  
  const urlObj = new URL(url);
  urlObj.searchParams.set('w', size.width.toString());
  urlObj.searchParams.set('h', size.height.toString());
  urlObj.searchParams.set('q', size.quality.toString());
  urlObj.searchParams.set('fit', 'crop');
  urlObj.searchParams.set('auto', 'format,compress');
  urlObj.searchParams.set('fm', 'webp');
  
  return urlObj.toString();
};

export const CarouselCard = ({ card, index }: { card: Card; index: number }) => {
  const [open, setOpen] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const { onCardClose } = useContext(CarouselContext);
  const isMobile = window.innerWidth < 768;

  // Generate optimized image URLs
  const imageSources = {
    tiny: getOptimizedImageUrl(card.src, IMAGE_SIZES.tiny),
    mobile: getOptimizedImageUrl(card.src, IMAGE_SIZES.mobile),
    desktop: getOptimizedImageUrl(card.src, IMAGE_SIZES.desktop)
  };

  useEffect(() => {
    function onKeyDown(event: KeyboardEvent) {
      if (event.key === "Escape") {
        handleClose();
      }
    }

    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    window.addEventListener("keydown", onKeyDown);
    return () => window.removeEventListener("keydown", onKeyDown);
  }, [open]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        handleClose();
      }
    }

    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [open]);

  const handleOpen = () => {
    if (card.preventPreview) return;
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    onCardClose(index);
  };

  return (
    <>
      <BlurStyles />
      <AnimatePresence>
        {open && (
          <div className="fixed inset-0 h-screen z-50 overflow-hidden">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="bg-black/80 backdrop-blur-lg h-full w-full fixed inset-0"
            />
            <motion.div
              initial={isMobile ? { y: "100%" } : { opacity: 0, y: 20 }}
              animate={isMobile ? { y: 0 } : { opacity: 1, y: 0 }}
              exit={isMobile ? { y: "100%" } : { opacity: 0, y: 20 }}
              transition={isMobile ? { type: "spring", bounce: 0.2 } : { type: "spring", duration: 0.5 }}
              ref={containerRef}
              className={cn(
                "bg-dark-gray border border-gray-800 h-[90vh] w-full z-[60] overflow-y-auto relative shadow-2xl",
                isMobile 
                  ? "rounded-t-3xl fixed bottom-0" 
                  : "rounded-3xl max-w-5xl mx-auto my-10 p-4 md:p-10"
              )}
            >
              {/* Mobile Pull Indicator */}
              {isMobile && (
                <div className="sticky top-0 left-0 right-0 flex justify-center pt-3 pb-2 bg-dark-gray">
                  <div className="w-12 h-1.5 rounded-full bg-gray-700" />
                </div>
              )}

              {/* Close Button */}
              <button
                className={cn(
                  "h-8 w-8 bg-black/50 hover:bg-black/70 rounded-full flex items-center justify-center transition-colors",
                  isMobile 
                    ? "absolute top-4 right-4" 
                    : "sticky top-4 right-0 ml-auto"
                )}
                onClick={handleClose}
              >
                <IconX className="h-6 w-6 text-gray-400" />
              </button>

              {/* Content */}
              <div className={cn(
                "space-y-6",
                isMobile ? "px-4 pt-2 pb-8" : ""
              )}>
                {card.category && (
                  <p className="text-base font-medium text-primary">
                    {card.category}
                  </p>
                )}
                <h2 className="text-2xl md:text-4xl font-display text-white">
                  {card.title}
                </h2>
                <div className="divide-y divide-gray-800">
                  {card.content}
                </div>
              </div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>
      <button
        onClick={card.onClick || handleOpen}
        className="rounded-3xl bg-dark-gray border border-gray-800 h-80 w-56 md:h-[40rem] md:w-96 overflow-hidden flex flex-col items-start justify-start relative z-10 group hover:border-primary transition-colors"
      >
        <div className="absolute h-full top-0 inset-x-0 bg-gradient-to-b from-black/50 via-transparent to-transparent z-30 pointer-events-none" />
        <div className="relative z-40 p-8">
          <p className="text-white text-sm md:text-base font-medium font-sans text-left">
            {card.category}
          </p>
          <p className="text-white text-xl md:text-3xl font-display max-w-xs text-left [text-wrap:balance] mt-2">
            {card.title}
          </p>
        </div>
        <picture className="absolute z-10 inset-0 w-full h-full">
          {/* Tiny preview image with blur */}
          <img
            src={imageSources.tiny}
            alt=""
            aria-hidden="true"
            width={IMAGE_SIZES.tiny.width}
            height={IMAGE_SIZES.tiny.height}
            className={cn(
              "absolute inset-0 w-full h-full object-cover",
              "blur-loading",
              imageLoaded ? "opacity-0" : "opacity-100"
            )}
            style={{
              transitionProperty: "opacity",
              transitionDuration: "0.5s",
              transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)"
            }}
          />
          {/* Main image with responsive sources */}
          <source
            media="(min-width: 768px)"
            srcSet={imageSources.desktop}
            width={IMAGE_SIZES.desktop.width}
            height={IMAGE_SIZES.desktop.height}
            type="image/webp"
          />
          <img
            src={imageSources.mobile}
            alt={card.title}
            width={IMAGE_SIZES.mobile.width}
            height={IMAGE_SIZES.mobile.height}
            className={cn(
              "object-cover w-full h-full",
              "group-hover:scale-110",
              imageLoaded ? "unblur opacity-100" : "opacity-0 blur-loading",
              "transition-[transform,opacity]",
              "duration-700"
            )}
            style={{
              willChange: "filter, transform"
            }}
            onLoad={() => {
              setTimeout(() => setImageLoaded(true), 50);
            }}
            loading="lazy"
            {...({ fetchpriority: "high" } as React.HTMLProps<HTMLImageElement>)}
            decoding="async"
          />
        </picture>
      </button>
    </>
  );
}; 