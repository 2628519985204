import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Tour } from '../types';

// Set Mapbox token from environment variables
mapboxgl.accessToken = import.meta.env.VITE_MAPBOX_TOKEN || '';

interface TourMapProps {
  tours: Tour[];
  selectedTour?: Tour | null;
  onSelectTour?: (tour: Tour) => void;
  height?: string;
  width?: string;
  zoom?: number;
  center?: [number, number];
}

const TourMap: React.FC<TourMapProps> = ({
  tours,
  selectedTour,
  onSelectTour,
  height = '400px',
  width = '100%',
  zoom = 12,
  center
}) => {
  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const markersRef = useRef<mapboxgl.Marker[]>([]);
  const popupsRef = useRef<mapboxgl.Popup[]>([]);
  
  // Initialize map when component mounts
  useEffect(() => {
    if (!mapboxgl.accessToken) {
      console.error('Mapbox token is missing. Please check your environment variables.');
      return;
    }
    
    if (mapContainer.current && !map.current) {
      // Calculate center based on tours or use provided center
      let initialCenter = center;
      
      if (!initialCenter && tours.length > 0) {
        // Use first tour as center if no center provided
        initialCenter = [
          tours[0].location.longitude,
          tours[0].location.latitude
        ];
      } else if (!initialCenter) {
        // Default to Maldives if no tours and no center provided
        initialCenter = [73.5, 3.9];
      }
      
      // Create the map
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/dark-v11', // Dark theme matches the UI
        center: initialCenter,
        zoom: zoom
      });
      
      // Add navigation controls
      map.current.addControl(new mapboxgl.NavigationControl(), 'top-right');
      
      // Set loaded state when map is ready
      map.current.on('load', () => {
        setMapLoaded(true);
      });
    }
    
    // Cleanup on unmount
    return () => {
      if (map.current) {
        map.current.remove();
        map.current = null;
      }
    };
  }, []);
  
  // Add markers when tours or map changes
  useEffect(() => {
    if (!map.current || !mapLoaded || tours.length === 0) return;
    
    // Clear existing markers
    markersRef.current.forEach(marker => marker.remove());
    markersRef.current = [];
    
    // Clear existing popups
    popupsRef.current.forEach(popup => popup.remove());
    popupsRef.current = [];
    
    // Create a bounds object to fit the map to all markers
    const bounds = new mapboxgl.LngLatBounds();
    
    // Add markers for each tour
    tours.forEach(tour => {
      // Skip if missing coordinates
      if (!tour.location?.latitude || !tour.location?.longitude) return;
      
      // Create a custom marker element
      const markerEl = document.createElement('div');
      markerEl.className = 'tour-marker';
      markerEl.innerHTML = `
        <div class="${
          selectedTour && selectedTour.id === tour.id 
            ? 'marker-icon marker-selected' 
            : 'marker-icon'
        }">
          <span class="marker-price">
            ${tour.price.currencyCode === 'USD' ? '$' : ''}${parseFloat(tour.price.amount).toFixed(0)}
          </span>
        </div>
      `;
      
      // Create popup
      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
        offset: 25,
        className: 'tour-popup',
        maxWidth: '300px'
      })
        .setHTML(`
          <div class="tour-popup-content">
            <div class="tour-popup-image" style="background-image: url('${tour.images[0]}')"></div>
            <div class="tour-popup-info">
              <h3>${tour.name}</h3>
              <div class="tour-popup-meta">
                <span class="tour-popup-duration">${tour.duration}</span>
                ${tour.rating ? `
                  <span class="tour-popup-rating">
                    <span class="star">★</span> ${tour.rating.toFixed(1)}
                  </span>
                ` : ''}
              </div>
              <div class="tour-popup-price">
                ${tour.price.currencyCode === 'USD' ? '$' : ''}${parseFloat(tour.price.amount).toFixed(2)}
              </div>
            </div>
          </div>
        `);
      
      // Create the marker
      const marker = new mapboxgl.Marker(markerEl)
        .setLngLat([tour.location.longitude, tour.location.latitude])
        .setPopup(popup)
        .addTo(map.current!);
      
      // Add click handler if onSelectTour provided
      if (onSelectTour) {
        markerEl.addEventListener('click', () => {
          onSelectTour(tour);
        });
      }
      
      // Show popup on hover
      markerEl.addEventListener('mouseenter', () => {
        if (map.current) {
          popup.addTo(map.current);
        }
      });
      
      markerEl.addEventListener('mouseleave', () => {
        popup.remove();
      });
      
      // Save the marker in refs
      markersRef.current.push(marker);
      popupsRef.current.push(popup);
      
      // Extend bounds to include this marker
      bounds.extend([tour.location.longitude, tour.location.latitude]);
    });
    
    // Fit map to bounds if we have more than one tour
    if (tours.length > 1) {
      map.current.fitBounds(bounds, {
        padding: { top: 50, bottom: 50, left: 50, right: 50 },
        maxZoom: 15
      });
    }
    
    // Highlight selected tour
    if (selectedTour) {
      const selectedIndex = tours.findIndex(tour => tour.id === selectedTour.id);
      if (selectedIndex >= 0 && markersRef.current[selectedIndex]) {
        const marker = markersRef.current[selectedIndex];
        
        // Center map on selected tour
        map.current.flyTo({
          center: [
            selectedTour.location.longitude,
            selectedTour.location.latitude
          ],
          zoom: 14,
          duration: 1000
        });
        
        // Show popup for selected tour
        const popup = marker.getPopup();
        if (map.current && popup) {
          popup.addTo(map.current);
        }
      }
    }
  }, [tours, mapLoaded, selectedTour]);
  
  return (
    <>
      <div 
        ref={mapContainer} 
        className="tour-map-container" 
        style={{ height, width }}
      />
      <style>
        {`
         .tour-map-container {
           border-radius: 0.75rem;
           overflow: hidden;
           box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
         }
         
         .tour-marker {
           cursor: pointer;
         }
         
         .marker-icon {
           background-color: rgba(0, 0, 0, 0.7);
           border: 2px solid var(--primary-color, #22d3ee);
           color: white;
           border-radius: 50%;
           width: 36px;
           height: 36px;
           display: flex;
           align-items: center;
           justify-content: center;
           font-weight: bold;
           font-size: 12px;
           box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
           transition: all 0.2s ease;
         }
         
         .marker-icon:hover,
         .marker-selected {
           transform: scale(1.1);
           background-color: var(--primary-color, #22d3ee);
           color: black;
         }
         
         .tour-popup {
           max-width: 300px;
         }
         
         .tour-popup-content {
           display: flex;
           flex-direction: column;
         }
         
         .tour-popup-image {
           height: 120px;
           background-size: cover;
           background-position: center;
           border-radius: 4px 4px 0 0;
         }
         
         .tour-popup-info {
           padding: 12px;
           background-color: #1f2937;
         }
         
         .tour-popup-info h3 {
           margin: 0 0 8px 0;
           font-size: 14px;
           font-weight: 600;
           color: white;
         }
         
         .tour-popup-meta {
           display: flex;
           justify-content: space-between;
           font-size: 12px;
           color: #9ca3af;
           margin-bottom: 8px;
         }
         
         .tour-popup-rating {
           display: flex;
           align-items: center;
         }
         
         .tour-popup-rating .star {
           color: #fbbf24;
           margin-right: 2px;
         }
         
         .tour-popup-price {
           font-weight: 600;
           color: var(--primary-color, #22d3ee);
         }
         
         .mapboxgl-popup-content {
           padding: 0;
           border-radius: 8px;
           overflow: hidden;
           background-color: #1f2937;
           box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
           border: 1px solid rgba(255, 255, 255, 0.1);
         }
         
         .mapboxgl-popup-close-button {
           color: white;
           font-size: 16px;
           padding: 5px;
           right: 5px;
           top: 5px;
           background-color: rgba(0, 0, 0, 0.5);
           border-radius: 50%;
           width: 24px;
           height: 24px;
           display: flex;
           align-items: center;
           justify-content: center;
           line-height: 1;
         }
         
         .mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
           border-bottom-color: #1f2937;
         }
         
         .mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
           border-top-color: #1f2937;
         }
         
         .mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
           border-right-color: #1f2937;
         }
         
         .mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
           border-left-color: #1f2937;
         }
        `}
      </style>
    </>
  );
};

export default TourMap; 