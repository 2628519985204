import * as React from "react";
import { format, addDays, differenceInDays } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import { DateRange } from "react-day-picker";
import { cn } from "../../lib/utils";
import { Button } from "./button";
import { Calendar } from "./calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "./popover";

interface DatePickerWithRangeProps {
  className?: string;
  date?: DateRange | undefined;
  onDateChange?: (date: DateRange | undefined) => void;
}

export function DatePickerWithRange({
  className,
  date,
  onDateChange,
}: DatePickerWithRangeProps) {
  const [selectedRange, setSelectedRange] = React.useState<DateRange | undefined>(date);
  const [open, setOpen] = React.useState(false);
  
  // Date range limits
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Reset time to start of day
  
  // Sync with parent component's state
  React.useEffect(() => {
    setSelectedRange(date);
  }, [date]);
  
  // Calendar selection handler
  const handleCalendarSelect = (range: DateRange | undefined) => {
    if (!range) {
      setSelectedRange(undefined);
      onDateChange?.(undefined);
      return;
    }
    
    // Create new date objects to prevent reference issues
    const newRange: DateRange = {
      from: range.from ? new Date(range.from) : undefined,
      to: range.to ? new Date(range.to) : undefined
    };
    
    setSelectedRange(newRange);
    onDateChange?.(newRange);
    
    // Only auto-close when a complete range is selected
    if (newRange.from && newRange.to) {
      setTimeout(() => setOpen(false), 300);
    }
  };
  
  const handleClear = () => {
    setSelectedRange(undefined);
    onDateChange?.(undefined);
  };
  
  // Quick selection options
  const quickSelect = (days: number) => {
    const from = new Date(today);
    const to = addDays(today, days);
    const newRange = { from, to };
    setSelectedRange(newRange);
    onDateChange?.(newRange);
    setTimeout(() => setOpen(false), 300);
  };

  return (
    <div className={cn("grid gap-2", className)}>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={"outline"}
            className={cn(
              "w-full justify-start text-left font-normal bg-[#1c2733]/80 border-gray-700/50 text-white hover:bg-[#1f2d3d] hover:text-white hover:border-gray-600 px-3 py-2 h-10",
              !date && "text-gray-400",
              className
            )}
            onClick={() => setOpen(true)}
          >
            <CalendarIcon className="mr-2 h-4 w-4 opacity-70" />
            {date?.from ? (
              date.to ? (
                <span>
                  {format(date.from, "MMM d")} - {format(date.to, "MMM d, yyyy")}
                  {date.from && date.to && (
                    <span className="ml-2 text-xs opacity-70">
                      ({differenceInDays(date.to, date.from)} nights)
                    </span>
                  )}
                </span>
              ) : (
                format(date.from, "MMM d, yyyy")
              )
            ) : (
              <span>Select dates</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="bg-[#0e1621] border border-gray-700/30 p-0 shadow-xl rounded-lg w-auto">
          <div className="px-3 py-2 border-b border-gray-700/50 flex items-center justify-between">
            <span className="text-sm font-medium text-white">
              {!selectedRange?.from 
                ? "When will you travel?" 
                : !selectedRange.to 
                  ? "Select checkout date" 
                  : "Your dates"}
            </span>
            <Button 
              variant="ghost" 
              className="h-7 px-2 text-xs text-gray-400 hover:text-white hover:bg-transparent"
              onClick={handleClear}
            >
              Reset
            </Button>
          </div>
          
          {/* Quick date selection buttons */}
          <div className="px-4 pt-3 flex flex-wrap gap-2">
            <button
              onClick={() => quickSelect(2)}
              className="px-3 py-1 text-xs bg-gray-800 hover:bg-gray-700 text-white rounded-full transition-colors"
            >
              Weekend
            </button>
            <button
              onClick={() => quickSelect(3)}
              className="px-3 py-1 text-xs bg-gray-800 hover:bg-gray-700 text-white rounded-full transition-colors"
            >
              3 Nights
            </button>
            <button
              onClick={() => quickSelect(7)}
              className="px-3 py-1 text-xs bg-gray-800 hover:bg-gray-700 text-white rounded-full transition-colors"
            >
              1 Week
            </button>
            <button
              onClick={() => quickSelect(14)}
              className="px-3 py-1 text-xs bg-gray-800 hover:bg-gray-700 text-white rounded-full transition-colors"
            >
              2 Weeks
            </button>
          </div>
          
          {/* Calendar */}
          <div className="p-3">
            <Calendar
              mode="range"
              defaultMonth={today}
              selected={selectedRange}
              onSelect={handleCalendarSelect}
              numberOfMonths={2}
              disabled={{ before: today }}
              className="dark-calendar"
              classNames={{
                day_selected: "bg-primary text-black font-medium",
                day_range_middle: "bg-primary/20 text-white",
                day_range_end: "bg-primary text-black font-medium"
              }}
            />
          </div>
          
          {/* Footer with date display */}
          <div className="p-3 border-t border-gray-700/50 flex justify-between items-center text-xs text-gray-400">
            {selectedRange?.from && (
              <div>
                <span className="font-medium text-white">
                  {format(selectedRange.from, "MMM d, yyyy")}
                </span>
                {selectedRange.to && (
                  <>
                    <span className="mx-2">→</span>
                    <span className="font-medium text-white">
                      {format(selectedRange.to, "MMM d, yyyy")}
                    </span>
                  </>
                )}
              </div>
            )}
            
            {!selectedRange?.from && (
              <span>We are happy to help you! Please select your travel dates.</span>
            )}
            
            {selectedRange?.from && !selectedRange?.to && (
              <span className="ml-auto">Now select your checkout date</span>
            )}
            
            {selectedRange?.from && selectedRange?.to && (
              <span className="ml-auto">
                {differenceInDays(selectedRange.to, selectedRange.from)} nights
              </span>
            )}
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
} 