import { useState, useEffect, useCallback } from 'react';
import { Search, MapPin, Calendar, Users } from 'lucide-react';
import { DateRange } from 'react-day-picker';
import { DatePickerWithRange } from '../../../components/ui/date-range-picker';
import { format } from 'date-fns';
import { City } from '../types';
import { searchCities } from '../services/hotelSearchService';
import { Combobox } from '@headlessui/react';
import debounce from 'lodash/debounce';

interface SearchFormProps {
  initialCity?: City;
  initialDateRange?: DateRange;
  initialGuests?: number;
  initialRooms?: number;
  onSearch: (params: {
    city: City;
    dateRange: DateRange | undefined;
    guests: number;
    rooms: number;
  }) => void;
}

export default function SearchForm({
  initialCity,
  initialDateRange,
  initialGuests = 2,
  initialRooms = 1,
  onSearch
}: SearchFormProps) {
  const [cityQuery, setCityQuery] = useState<string>(initialCity ? `${initialCity.name}, ${initialCity.country}` : '');
  const [cityResults, setCityResults] = useState<City[]>([]);
  const [selectedCity, setSelectedCity] = useState<City | undefined>(initialCity);
  const [dateRange, setDateRange] = useState<DateRange | undefined>(initialDateRange);
  const [guests, setGuests] = useState<number>(initialGuests);
  const [rooms, setRooms] = useState<number>(initialRooms);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [dateError, setDateError] = useState<string | null>(null);
  const [isFormExpanded, setIsFormExpanded] = useState<boolean>(false);

  // Update selected city when initialCity changes
  useEffect(() => {
    if (initialCity) {
      setSelectedCity(initialCity);
      setCityQuery(`${initialCity.name}, ${initialCity.country}`);
    }
  }, [initialCity]);

  // Create a debounced search function
  const debouncedSearch = useCallback(
    debounce((query: string) => {
      setIsSearching(true);
      searchCities(query)
        .then(results => {
          setCityResults(results);
        })
        .catch(error => {
          console.error('Error searching cities:', error);
        })
        .finally(() => {
          setIsSearching(false);
        });
    }, 300),
    []
  );

  useEffect(() => {
    if (cityQuery.trim().length < 2) {
      setCityResults([]);
      return;
    }

    debouncedSearch(cityQuery);
  }, [cityQuery, debouncedSearch]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    // Don't submit if there's a date error
    if (dateError) {
      return;
    }
    
    if (selectedCity) {
      onSearch({
        city: selectedCity,
        dateRange,
        guests,
        rooms
      });
      // Collapse form on mobile after search
      setIsFormExpanded(false);
    }
  };

  const selectCity = (city: City | null) => {
    if (!city) {
      setSelectedCity(undefined);
      return;
    }
    
    setSelectedCity(city);
    setCityQuery(`${city.name}, ${city.country}`);
    setCityResults([]);
  };

  const handleDateChange = (range: DateRange | undefined) => {
    setDateError(null);
    
    if (range?.from && range?.to) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      
      // Set max date to 330 days in the future (common industry standard)
      const maxDate = new Date();
      maxDate.setDate(today.getDate() + 330);
      
      if (range.from < today) {
        setDateError("Check-in date cannot be in the past");
        return;
      }
      
      if (range.from > maxDate || range.to > maxDate) {
        setDateError("Dates cannot be more than 330 days in the future");
        return;
      }
      
      if (range.to <= range.from) {
        setDateError("Check-out date must be after check-in date");
        return;
      }
      
      // Calculate stay duration
      const timeInMs = range.to.getTime() - range.from.getTime();
      const nightsCount = Math.round(timeInMs / (1000 * 60 * 60 * 24));
      
      if (nightsCount > 30) {
        setDateError("Maximum stay duration is 30 nights");
        return;
      }
    }
    
    setDateRange(range);
  };

  // Format city name with IATA code if available
  const formatCityName = (city: City): string => {
    if (!city || !city.name) return '';
    
    // Check if the city name already has an IATA code in parentheses
    if (/\([A-Z]{3}\)/.test(city.name)) {
      return city.name;
    }
    
    // Extract IATA code from common cities
    const cityToIATA: Record<string, string> = {
      'New York': 'NYC',
      'London': 'LON',
      'Paris': 'PAR',
      'Tokyo': 'TYO',
      'Rome': 'ROM',
      'Sydney': 'SYD',
      'Barcelona': 'BCN',
      'Berlin': 'BER',
      'Dubai': 'DXB',
      'Hong Kong': 'HKG',
      'Los Angeles': 'LAX',
      'Chicago': 'CHI',
      'Miami': 'MIA',
      'Bangkok': 'BKK',
      'Amsterdam': 'AMS',
      'Madrid': 'MAD',
      'Singapore': 'SIN'
    };
    
    const cityName = city.name;
    const iataCode = cityToIATA[cityName];
    
    return iataCode ? `${cityName} (${iataCode})` : cityName;
  };

  // Format date range for mobile display
  const getFormattedDateRange = () => {
    if (!dateRange?.from) return 'Select dates';
    
    if (!dateRange.to) {
      return format(dateRange.from, 'MMM dd, yyyy');
    }
    
    return `${format(dateRange.from, 'MMM dd')} - ${format(dateRange.to, 'MMM dd, yyyy')}`;
  };

  // Format guests and rooms for mobile display
  const getFormattedGuestsRooms = () => {
    return `${guests} Guest${guests !== 1 ? 's' : ''}, ${rooms} Room${rooms !== 1 ? 's' : ''}`;
  };

  return (
    <div className="bg-gray-800/50 backdrop-blur-sm rounded-xl p-4 md:p-6">
      {/* Mobile View */}
      <div className="md:hidden">
        {!isFormExpanded ? (
          <div className="flex flex-col space-y-4">
            {/* Collapsed form with current selection summary */}
            <div 
              onClick={() => setIsFormExpanded(true)}
              className="flex items-center justify-between bg-gray-800 border border-gray-700 rounded-lg p-3 cursor-pointer"
            >
              <div className="flex items-center">
                <MapPin className="text-primary h-5 w-5 mr-2" />
                <div className="text-white truncate max-w-[200px]">
                  {selectedCity ? `${selectedCity.name}, ${selectedCity.country}` : 'Select destination'}
                </div>
              </div>
              <div className="text-gray-400">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </div>
            </div>
            
            {/* Date range summary */}
            <div 
              onClick={() => setIsFormExpanded(true)}
              className="flex items-center justify-between bg-gray-800 border border-gray-700 rounded-lg p-3 cursor-pointer"
            >
              <div className="flex items-center">
                <Calendar className="text-primary h-5 w-5 mr-2" />
                <div className="text-white truncate max-w-[200px]">
                  {getFormattedDateRange()}
                </div>
              </div>
              <div className="text-gray-400">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </div>
            </div>
            
            {/* Guests/Rooms summary */}
            <div 
              onClick={() => setIsFormExpanded(true)}
              className="flex items-center justify-between bg-gray-800 border border-gray-700 rounded-lg p-3 cursor-pointer"
            >
              <div className="flex items-center">
                <Users className="text-primary h-5 w-5 mr-2" />
                <div className="text-white">
                  {getFormattedGuestsRooms()}
                </div>
              </div>
              <div className="text-gray-400">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </div>
            </div>
            
            {/* Search button */}
            <button
              onClick={handleSubmit}
              disabled={!selectedCity}
              className="w-full px-6 py-3 bg-primary text-black rounded-lg flex items-center justify-center font-medium disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <Search className="mr-2 h-5 w-5" />
              Search Hotels
            </button>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="space-y-5">
            {/* Back button */}
            <div className="flex justify-between items-center mb-2">
              <h3 className="text-white font-medium">Search Hotels</h3>
              <button 
                type="button" 
                onClick={() => setIsFormExpanded(false)}
                className="text-gray-400 hover:text-white"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </button>
            </div>
            
            {/* Destination */}
            <div>
              <label htmlFor="city-search" className="block text-sm font-medium mb-2 text-gray-300">
                Destination
              </label>
              <Combobox value={selectedCity} onChange={selectCity} nullable>
                <div className="relative">
                  <MapPin className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-500 h-5 w-5" />
                  <Combobox.Input
                    displayValue={(city: City | undefined | null) => 
                      city && city.name ? `${city.name}, ${city.country}` : cityQuery
                    }
                    onChange={(e) => {
                      setCityQuery(e.target.value);
                      if (e.target.value.length >= 2) {
                        debouncedSearch(e.target.value);
                      }
                    }}
                    placeholder="Enter a city name..."
                    className="w-full pl-10 bg-gray-800 text-white py-3 px-4 rounded-lg border border-gray-700 focus:border-primary focus:ring-1 focus:ring-primary outline-none placeholder:text-gray-500"
                  />
                </div>

                {/* City search results */}
                <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-gray-800 py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm">
                  {isSearching ? (
                    <div className="relative cursor-default select-none py-2 px-4 text-gray-400">
                      Searching cities...
                    </div>
                  ) : cityResults.length === 0 ? (
                    <div className="relative cursor-default select-none py-2 px-4 text-gray-400">
                      No cities found
                    </div>
                  ) : (
                    cityResults.map((city) => (
                      <Combobox.Option
                        key={`${city.name}-${city.country}`}
                        value={city}
                        className={({ active }) =>
                          `relative cursor-pointer select-none py-3 px-4 ${
                            active ? 'bg-primary/20 text-white' : 'text-gray-300'
                          }`
                        }
                      >
                        {({ selected }) => (
                          <div className="flex flex-col">
                            <div className="flex items-center">
                              <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                                {formatCityName(city)}
                              </span>
                              {selected && (
                                <span className="ml-2 text-primary">
                                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                                  </svg>
                                </span>
                              )}
                            </div>
                            <span className="text-xs text-gray-500">{city.country}</span>
                          </div>
                        )}
                      </Combobox.Option>
                    ))
                  )}
                </Combobox.Options>
              </Combobox>
            </div>
            
            {/* Date Range */}
            <div>
              <label className="block text-sm font-medium mb-2 text-gray-300">
                Check-in / Check-out
              </label>
              <div className="relative bg-gray-800 border border-gray-700 rounded-lg text-white">
                <DatePickerWithRange 
                  date={dateRange}
                  onDateChange={handleDateChange}
                  className="bg-transparent" 
                />
              </div>
              {dateError && (
                <p className="text-red-500 text-xs mt-1">{dateError}</p>
              )}
            </div>
            
            {/* Guests/Rooms */}
            <div>
              <label className="block text-sm font-medium mb-2 text-gray-300">
                Guests & Rooms
              </label>
              <div className="flex gap-2 bg-gray-800 border border-gray-700 rounded-lg p-2">
                <div className="relative w-1/2">
                  <Users className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-500 h-4 w-4" />
                  <select
                    value={guests}
                    onChange={(e) => setGuests(parseInt(e.target.value))}
                    className="w-full pl-9 pr-4 py-3 bg-transparent text-white appearance-none focus:outline-none"
                  >
                    {[1, 2, 3, 4, 5, 6].map((num) => (
                      <option key={num} value={num}>
                        {num} {num === 1 ? 'Guest' : 'Guests'}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="relative w-1/2">
                  <select
                    value={rooms}
                    onChange={(e) => setRooms(parseInt(e.target.value))}
                    className="w-full pl-4 pr-4 py-3 bg-transparent text-white appearance-none focus:outline-none"
                  >
                    {[1, 2, 3, 4, 5].map((num) => (
                      <option key={num} value={num}>
                        {num} {num === 1 ? 'Room' : 'Rooms'}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            
            {/* Search Button */}
            <button
              type="submit"
              disabled={!selectedCity}
              className="w-full px-6 py-3 bg-primary text-black rounded-lg flex items-center justify-center font-medium disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <Search className="mr-2 h-5 w-5" />
              Search Hotels
            </button>
          </form>
        )}
      </div>
      
      {/* Desktop View */}
      <form onSubmit={handleSubmit} className="hidden md:block">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          {/* City Search */}
          <div className="relative">
            <label htmlFor="city-search" className="block text-sm font-medium mb-2 text-gray-300">
              Destination
            </label>
            <Combobox value={selectedCity} onChange={selectCity} nullable>
              <div className="relative">
                <MapPin className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-500 h-5 w-5" />
                <Combobox.Input
                  displayValue={(city: City | undefined | null) => 
                    city && city.name ? `${city.name}, ${city.country}` : cityQuery
                  }
                  onChange={(e) => {
                    setCityQuery(e.target.value);
                    if (e.target.value.length >= 2) {
                      debouncedSearch(e.target.value);
                    }
                  }}
                  placeholder="Enter a city name..."
                  className="w-full pl-10 bg-gray-800 text-white py-3 px-4 rounded-lg border border-gray-700 focus:border-primary focus:ring-1 focus:ring-primary outline-none placeholder:text-gray-500"
                />
              </div>

              {/* City search results */}
              <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-gray-800 py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm">
                {isSearching ? (
                  <div className="relative cursor-default select-none py-2 px-4 text-gray-400">
                    Searching cities...
                  </div>
                ) : cityResults.length === 0 ? (
                  <div className="relative cursor-default select-none py-2 px-4 text-gray-400">
                    No cities found
                  </div>
                ) : (
                  cityResults.map((city) => (
                    <Combobox.Option
                      key={`${city.name}-${city.country}`}
                      value={city}
                      className={({ active }) =>
                        `relative cursor-pointer select-none py-2 px-4 ${
                          active ? 'bg-primary/20 text-white' : 'text-gray-300'
                        }`
                      }
                    >
                      {({ selected }) => (
                        <div className="flex flex-col">
                          <div className="flex items-center">
                            <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                              {formatCityName(city)}
                            </span>
                            {selected && (
                              <span className="ml-2 text-primary">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                  <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                                </svg>
                              </span>
                            )}
                          </div>
                          <span className="text-xs text-gray-500">{city.country}</span>
                        </div>
                      )}
                    </Combobox.Option>
                  ))
                )}
              </Combobox.Options>
            </Combobox>
          </div>

          {/* Date Range */}
          <div>
            <label className="block text-sm font-medium mb-2 text-gray-300">
              Check-in / Check-out
            </label>
            <div className="relative bg-gray-800 border border-gray-700 rounded-lg text-white">
              <DatePickerWithRange 
                date={dateRange}
                onDateChange={handleDateChange}
                className="bg-transparent" 
              />
            </div>
            {dateError && (
              <p className="text-red-500 text-xs mt-1">{dateError}</p>
            )}
          </div>

          {/* Guests/Rooms */}
          <div>
            <label className="block text-sm font-medium mb-2 text-gray-300">
              Guests & Rooms
            </label>
            <div className="flex gap-2 bg-gray-800 border border-gray-700 rounded-lg p-2">
              <div className="relative w-1/2">
                <Users className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-500 h-4 w-4" />
                <select
                  value={guests}
                  onChange={(e) => setGuests(parseInt(e.target.value))}
                  className="w-full pl-9 pr-4 py-2 bg-transparent text-white appearance-none focus:outline-none"
                >
                  {[1, 2, 3, 4, 5, 6].map((num) => (
                    <option key={num} value={num}>
                      {num} {num === 1 ? 'Guest' : 'Guests'}
                    </option>
                  ))}
                </select>
              </div>
              <div className="relative w-1/2">
                <select
                  value={rooms}
                  onChange={(e) => setRooms(parseInt(e.target.value))}
                  className="w-full pl-4 pr-4 py-2 bg-transparent text-white appearance-none focus:outline-none"
                >
                  {[1, 2, 3, 4, 5].map((num) => (
                    <option key={num} value={num}>
                      {num} {num === 1 ? 'Room' : 'Rooms'}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          {/* Search Button */}
          <div className="flex items-end">
            <button
              type="submit"
              disabled={!selectedCity}
              className="w-full px-6 py-3 bg-primary text-black rounded-lg flex items-center justify-center font-medium disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <Search className="mr-2 h-5 w-5" />
              Search Hotels
            </button>
          </div>
        </div>
      </form>
    </div>
  );
} 