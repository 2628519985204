import React from 'react';
import { ImageSize } from '../types';
import { optimizeImageUrl } from '../services/imageService';

interface OptimizedImageProps { 
  src: string; 
  alt: string; 
  size: ImageSize; 
  className?: string;
  style?: React.CSSProperties;
}

/**
 * Optimized image component that provides different image formats based on browser support
 */
const OptimizedImage: React.FC<OptimizedImageProps> = ({ 
  src, 
  alt, 
  size, 
  className,
  style
}) => {
  const webpSrc = optimizeImageUrl(src, { ...size, format: 'webp' });
  const avifSrc = optimizeImageUrl(src, { ...size, format: 'avif' });
  const jpegSrc = optimizeImageUrl(src, { ...size, format: 'jpeg' });

  return (
    <picture>
      <source 
        srcSet={avifSrc} 
        type="image/avif" 
      />
      <source 
        srcSet={webpSrc} 
        type="image/webp" 
      />
      <img
        src={jpegSrc}
        alt={alt}
        className={className}
        style={style}
        loading="lazy"
        width={size.width}
        height={size.height}
      />
    </picture>
  );
};

export default OptimizedImage; 