import { createContext, useContext, useReducer, ReactNode, useEffect, useCallback, useMemo, useState } from 'react';
import { Message, ChatState, ChatAction, UserPreferences, WorkflowState } from '../pages/chat/types';
import { ChatService } from '../pages/chat/services';

interface ChatContextType {
  state: ChatState;
  dispatch: React.Dispatch<ChatAction>;
  sendMessage: (content: string, metadata?: Message['metadata']) => Promise<void>;
  setActivityContext: (cityName: string, latitude: number, longitude: number) => void;
  toggleChat: () => void;
}

const ChatContext = createContext<ChatContextType | undefined>(undefined);

const initialState: ChatState = {
  isOpen: false,
  isMinimized: false,
  isInitialized: false,
  isTyping: false,
  isSearching: false,
  messages: [],
  error: null,
  currentWorkflow: undefined,
  preferences: {},
};

function chatReducer(state: ChatState, action: ChatAction): ChatState {
  switch (action.type) {
    case 'INITIALIZE_CHAT':
      return { ...initialState, messages: [], isInitialized: false, error: null };
    case 'INITIALIZE_SUCCESS':
      return { ...state, isInitialized: true, error: null };
    case 'INITIALIZE_ERROR':
      return { ...state, error: action.error, isInitialized: false };
    case 'OPEN_CHAT':
      return { ...state, isOpen: true, isMinimized: false, error: null };
    case 'CLOSE_CHAT':
      return { ...state, isOpen: false, isMinimized: false };
    case 'MINIMIZE_CHAT':
      return { ...state, isMinimized: true };
    case 'MAXIMIZE_CHAT':
      return { ...state, isMinimized: false };
    case 'SET_TYPING':
      return { ...state, isTyping: action.isTyping };
    case 'SET_SEARCHING':
      return { ...state, isSearching: action.isSearching };
    case 'ADD_MESSAGE':
      if (state.messages.some(msg => msg.id === action.message.id)) {
        return state;
      }
      return {
        ...state,
        messages: [...state.messages, action.message],
        error: null,
        isTyping: action.message.role === 'user' ? true : false,
      };
    case 'SET_ERROR':
      return { ...state, error: action.error, isTyping: false };
    case 'CLEAR_ERROR':
      return { ...state, error: null };
    case 'SET_WORKFLOW':
      return { ...state, currentWorkflow: action.workflow };
    case 'UPDATE_PREFERENCES':
      return {
        ...state,
        preferences: {
          ...state.preferences,
          ...action.preferences,
        },
      };
    case 'START_WORKFLOW':
      return { ...state, currentWorkflow: action.workflow };
    default:
      return state;
  }
}

export function ChatProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(chatReducer, initialState);
  const [chatServiceInstance, setChatServiceInstance] = useState<ChatService | null>(null);

  useEffect(() => {
    let service: ChatService | null = null;
    try {
      service = new ChatService(dispatch);
      setChatServiceInstance(service);
      dispatch({ type: 'INITIALIZE_SUCCESS' });
      console.log("ChatService initialized successfully.");
    } catch (error) {
      console.error('Failed to initialize chat service:', error);
      dispatch({ type: 'INITIALIZE_ERROR', error: error instanceof Error ? error.message : 'Failed to initialize chat' });
    }
  }, []);

  const setActivityContext = useCallback((cityName: string, latitude: number, longitude: number) => {
    if (chatServiceInstance) {
      chatServiceInstance.setActivityContext({
        cityName,
        coordinates: { latitude, longitude }
      });
      console.log("Activity context set:", { cityName, latitude, longitude });
    } else {
       console.warn("Chat service not ready to set activity context.");
    }
  }, [chatServiceInstance]);

  const toggleChat = useCallback(() => {
    dispatch({ type: state.isOpen ? 'CLOSE_CHAT' : 'OPEN_CHAT' });
  }, [state.isOpen]);

  const sendMessage = useCallback(async (content: string, metadata?: Message['metadata']) => {
    if (!chatServiceInstance) {
      dispatch({
        type: 'SET_ERROR',
        error: 'Chat service not initialized. Please wait or refresh.'
      });
      console.error("sendMessage called before ChatService was initialized.");
      return;
    }

    const userMessage: Message = {
      id: `user-${Date.now()}-${Math.random().toString(16).substring(2, 8)}`,
      role: 'user',
      content,
      timestamp: Date.now(),
      metadata: {
        ...metadata,
        workflow: state.currentWorkflow,
      },
    };
    dispatch({ type: 'ADD_MESSAGE', message: userMessage });

    try {
      await chatServiceInstance.processUserMessage(userMessage, state.messages);

    } catch (error) {
      console.error('Error sending message via ChatService:', error);
      const errorMessage = error instanceof Error
        ? error.message
        : 'An unexpected error occurred while processing your message';
      dispatch({ type: 'SET_ERROR', error: errorMessage });
      dispatch({
         type: 'ADD_MESSAGE',
         message: {
           id: `err-${Date.now()}`,
           role: 'assistant',
           content: `Sorry, I encountered an error: ${errorMessage}`,
           timestamp: Date.now()
         }
      });
    }
  }, [chatServiceInstance, state.currentWorkflow, state.messages]);

  const value = useMemo(() => ({
    state,
    dispatch,
    sendMessage,
    setActivityContext,
    toggleChat
  }), [state, dispatch, sendMessage, setActivityContext, toggleChat]);

  return (
    <ChatContext.Provider value={value}>
      {children}
    </ChatContext.Provider>
  );
}

export function useChat() {
  const context = useContext(ChatContext);
  if (!context) {
    console.warn('useChat must be used within a ChatProvider or ChatProvider is not ready.');
    return {
      state: initialState,
      dispatch: () => console.warn('Chat dispatch is not available yet.'),
      sendMessage: async () => console.warn('Chat sendMessage is not available yet.'),
      setActivityContext: () => console.warn('Chat setActivityContext is not available yet.'),
      toggleChat: () => console.warn('Chat toggleChat is not available yet.'),
    } as ChatContextType;
  }
  return context;
} 