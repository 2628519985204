import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MapPin, User, Bed, Route, Ticket, Bot, Search } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import LocationSearch from '../../../components/LocationSearch';
import OptimizedImage from './OptimizedImage';
import { IMAGE_SIZES, HERO_BACKGROUNDS } from '../services/imageService';
import { SliderImage } from '../types';
import { cn } from '../../../utils/cn';

interface HeroSectionProps {
  onLocationSelect: (lat: number, lng: number, name: string, address?: { city?: string; state?: string; country?: string; }) => void;
  onMarcoChat: () => void;
  sliderImages: SliderImage[];
}

// Minimal CTA Button Component
const HeroCtaButton = ({ to, icon: Icon, label, colorClass, onClick }: { to?: string, icon: React.ElementType, label: string, colorClass: string, onClick?: () => void }) => {
  const commonProps = {
    className: cn(
      "flex-1 inline-flex items-center justify-center px-3 py-3 bg-white/[0.03] hover:bg-white/[0.06] text-white/80 hover:text-white text-xs sm:text-sm font-light tracking-wide rounded-lg backdrop-blur-sm transition-all duration-300 active:scale-[0.97] hover:scale-[1.03] border border-white/[0.05] group whitespace-nowrap",
      `hover:shadow-[0_0_15px_rgba(${colorClass},0.4)] hover:border-${colorClass}-500/30`
    ),
  };
  const content = (
    <>
      <Icon className={cn("w-4 h-4 mr-1.5 sm:mr-2 transition-colors", `text-${colorClass}-400 group-hover:text-${colorClass}-300`)} />
      {label}
    </>
  );

  if (to) {
    return <Link to={to} {...commonProps}>{content}</Link>;
  }
  return <button onClick={onClick} {...commonProps}>{content}</button>;
};

// Make OptimizedImage compatible with motion
const MotionOptimizedImage = motion(OptimizedImage);

const HeroSection: React.FC<HeroSectionProps> = ({
  onLocationSelect,
  onMarcoChat,
  sliderImages
}) => {
  const [currentBgIndex, setCurrentBgIndex] = useState(0);
  const backgroundImages = sliderImages.length > 0 ? sliderImages : HERO_BACKGROUNDS;

  // Background image rotation effect
  useEffect(() => {
    const rotateBackground = () => {
      setCurrentBgIndex((prevIndex) =>
        (prevIndex + 1) % backgroundImages.length
      );
    };

    const intervalId = setInterval(rotateBackground, 15000);
    return () => clearInterval(intervalId);
  }, [backgroundImages.length]);

  const ctaButtons = [
    { to: "/map", icon: MapPin, label: "Map", color: "emerald" },
    { to: "/travel-agents", icon: User, label: "Agents", color: "blue" },
    { to: "/hotels", icon: Bed, label: "Hotels", color: "purple" },
    { to: "/tours", icon: Route, label: "Tours", color: "amber" },
    { to: "/expo", icon: Ticket, label: "Expo", color: "pink" },
    { onClick: onMarcoChat, icon: Bot, label: "Marco AI", color: "cyan" },
  ];

  // Animation variants for text content
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { staggerChildren: 0.15, delayChildren: 0.2 }
    }
  };
  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0, 
      transition: { type: 'spring', stiffness: 100, damping: 12 }
    }
  };

  // Animation variants for background image cross-fade
  const imageVariants = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 0.5, // Target opacity when visible
      transition: { duration: 1.5, ease: "easeInOut" }, // Fade-in duration
    },
    exit: {
      opacity: 0,
      transition: { duration: 1.5, ease: "easeInOut" }, // Fade-out duration
    },
  };

  return (
    <div className="relative overflow-hidden min-h-screen flex items-center lg:items-start justify-center lg:justify-start text-center lg:text-left">
      {/* Solid black background */}
      <div className="absolute inset-0 bg-black"></div>

      {/* Background Image with Cross-fade */}
      <div className="absolute inset-0">
        <AnimatePresence initial={false}> {/* Use AnimatePresence */}
          <MotionOptimizedImage
            key={currentBgIndex} // Key change triggers enter/exit
            src={backgroundImages[currentBgIndex].url}
            alt=""
            size={IMAGE_SIZES.HERO}
            className={`absolute inset-0 w-full h-full object-cover`} // Removed opacity/transition classes
            style={{
              objectPosition: backgroundImages[currentBgIndex].position
            }}
            variants={imageVariants} // Apply variants
            initial="initial"
            animate="animate"
            exit="exit"
          />
        </AnimatePresence>

        {/* Enhanced Gradient overlay */}
        <div
          className="absolute inset-0 bg-gradient-to-b from-black/80 via-black/70 to-black lg:bg-gradient-to-r lg:from-black/90 lg:via-black/70 lg:to-transparent"
          aria-hidden="true"
        ></div>
      </div>

      {/* Hero content - Use motion.div and variants */}
      <motion.div 
        className="relative w-full max-w-2xl lg:max-w-3xl mx-auto lg:mx-0 lg:ml-[8%] px-4 sm:px-6 lg:px-8 py-10 sm:py-16 lg:pt-40 z-10"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        {/* Logo - Optional, consider moving or hiding on large screens for minimalism */}
        <motion.div variants={itemVariants} className="flex justify-center lg:justify-start mb-2 sm:mb-3">
          <img 
            src="/images/herologo.svg" 
            alt="AgenTrav Logo" 
            className="w-28 sm:w-36 md:w-40 h-auto"
          />
        </motion.div>
        
        {/* Subtle decorative elements */}
        <div className="absolute left-1/4 top-1/4 w-32 h-32 sm:w-48 sm:h-48 bg-blue-500/5 rounded-full blur-3xl -z-10 animate-pulse"></div>
        <div className="absolute right-1/4 bottom-1/4 w-40 h-40 sm:w-60 sm:h-60 bg-emerald-500/5 rounded-full blur-3xl -z-10 animate-pulse" style={{ animationDelay: '1s' }}></div>

        {/* Main Content */}
        <div className="space-y-3 sm:space-y-5 mb-8 sm:mb-10">
          <motion.h1 variants={itemVariants} className="relative">
            <span className="text-4xl sm:text-6xl md:text-7xl font-display font-medium tracking-tight text-white leading-tight inline-block">
              AI-Powered <br />
              <span className="text-white/80 font-light">Travel Planning</span>
            </span>
            {/* Glow effect might need repositioning or removal for left-align */}
             <span className="absolute -inset-4 sm:-inset-6 bg-gradient-to-r from-blue-500/10 via-cyan-500/10 to-emerald-500/10 blur-2xl opacity-40 rounded-full transform scale-125 -z-10"></span> 
          </motion.h1>
          
          <motion.p variants={itemVariants} className="text-white/70 text-base sm:text-lg font-light leading-relaxed max-w-xl mx-auto lg:mx-0">
            Connect with verified agents, explore curated tours, book luxury stays, or chat with Marco, your AI travel assistant.
          </motion.p>
        </div>

        {/* Search and CTA Container */}
        <motion.div 
          variants={itemVariants} 
          className="backdrop-blur-md bg-black/25 rounded-2xl p-3 sm:p-4 space-y-3 sm:space-y-4 border border-white/[0.07] shadow-lg max-w-2xl mx-auto lg:mx-0"
        >
          {/* Location Search */}
          <div className="relative group">
            {/* Subtle border glow on focus */}
            <div className="absolute -inset-px bg-gradient-to-r from-blue-500/30 via-cyan-500/30 to-emerald-500/30 opacity-0 group-focus-within:opacity-100 rounded-lg blur-sm transition-opacity duration-300"></div>
            <div className="relative flex items-center">
              <Search className="absolute left-3 sm:left-4 w-4 h-4 text-white/40 pointer-events-none z-10" />
              <LocationSearch
                onLocationSelect={onLocationSelect}
                className="bg-black/40 border border-transparent focus:border-transparent focus:ring-0 rounded-lg transition-all duration-300 w-full pl-10 sm:pl-11 pr-4 py-2.5 sm:py-3 text-white placeholder-white/40"
                placeholder="Search destinations or agents..."
              />
            </div>
          </div>

          {/* CTA buttons grid */}
          <div className="grid grid-cols-3 sm:grid-cols-6 gap-2 sm:gap-2.5">
            {ctaButtons.map((btn, index) => (
              <HeroCtaButton
                key={index}
                to={btn.to}
                icon={btn.icon}
                label={btn.label}
                colorClass={btn.color}
                onClick={btn.onClick}
              />
            ))}
          </div>

          {/* Minimal Stats Bar */}
          <div className="flex justify-center lg:justify-start gap-4 sm:gap-8 text-[10px] sm:text-sm tracking-wide text-white/50 font-light pt-3 sm:pt-4 border-t border-white/[0.05]">
             <div className="flex flex-col items-center">
               <span className="text-white font-medium text-xs sm:text-base">41.8K+</span>
               <span className="opacity-70">Agents</span>
             </div>
             <div className="flex flex-col items-center">
               <span className="text-white font-medium text-xs sm:text-base">150+</span>
               <span className="opacity-70">Countries</span>
             </div>
             <div className="flex flex-col items-center">
               <span className="text-white font-medium text-xs sm:text-base">1000+</span>
               <span className="opacity-70">Tours</span>
             </div>
             <div className="flex flex-col items-center">
               <span className="text-white font-medium text-xs sm:text-base">AI</span>
               <span className="opacity-70">Assistance</span>
             </div>
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default HeroSection;