import { AmadeusActivity } from '../../services/amadeus';

export interface City {
  name: string;
  country: string;
  latitude: number;
  longitude: number;
  iataCode?: string;
}

export interface PopularCity extends City {
  image?: string;
  description?: string;
}

export interface Tour {
  id: string;
  name: string;
  description: string;
  shortDescription?: string;
  duration: string; // e.g., "3 days", "5 hours"
  price: {
    amount: string;
    currencyCode: string;
  };
  rating?: number;
  reviewCount?: number;
  location: {
    city: string;
    country: string;
    latitude: number;
    longitude: number;
  };
  highlights?: string[];
  itinerary?: TourDay[];
  included?: string[];
  notIncluded?: string[];
  images: string[];
  startTimes?: string[];
  bookingLink?: string;
  minimumParticipants?: number;
  maximumParticipants?: number;
  categories?: string[];
  languages?: string[];
  cancellationPolicy?: string;
}

export interface TourDay {
  day: number;
  title: string;
  description: string;
  activities?: string[];
  meals?: ('breakfast' | 'lunch' | 'dinner')[];
  accommodation?: string;
}

export interface TourSearchParams {
  city?: City;
  startDate?: Date;
  endDate?: Date;
  participants?: number;
  priceRange?: {
    min?: number;
    max?: number;
  };
  ratings?: number[];
  duration?: string[];
  categories?: string[];
  sortBy?: SortOption;
  retried?: boolean;
}

export type SortOption = 
  | 'price-asc' 
  | 'price-desc' 
  | 'rating-desc' 
  | 'rating-asc' 
  | 'duration-asc'
  | 'duration-desc'
  | 'popularity-desc';

export interface TourSearchState {
  searchParams: TourSearchParams;
  results: Tour[];
  loading: boolean;
  error: string | null;
  totalCount: number;
  currentPage: number;
  pageSize: number;
  viewMode: 'grid' | 'list' | 'map';
}

export interface TourFilter {
  type: string;
  label: string;
  options: {
    value: string;
    label: string;
  }[];
}

export const POPULAR_CITIES: PopularCity[] = [
  { 
    name: 'New York', 
    country: 'USA', 
    latitude: 40.7128, 
    longitude: -74.0060,
    description: 'Explore the Big Apple with guided tours of iconic landmarks and hidden gems'
  },
  { 
    name: 'London', 
    country: 'UK', 
    latitude: 51.5074, 
    longitude: -0.1278,
    description: 'Discover London\'s rich history and culture with expert local guides'
  },
  { 
    name: 'Paris', 
    country: 'France', 
    latitude: 48.8566, 
    longitude: 2.3522,
    description: 'Experience the romance of Paris with specialized tours and day trips'
  },
  { 
    name: 'Tokyo', 
    country: 'Japan', 
    latitude: 35.6762, 
    longitude: 139.6503,
    description: 'Navigate Tokyo\'s vibrant streets and traditions with guided cultural experiences'
  },
  { 
    name: 'Rome', 
    country: 'Italy', 
    latitude: 41.9028, 
    longitude: 12.4964,
    description: 'Walk through the ancient history of Rome with expert archaeological tours'
  },
  { 
    name: 'Cairo', 
    country: 'Egypt', 
    latitude: 30.0444, 
    longitude: 31.2357,
    description: 'Explore ancient pyramids and Egyptian history with knowledgeable guides'
  },
  { 
    name: 'Maldives', 
    country: 'Maldives', 
    latitude: 3.2028, 
    longitude: 73.2207,
    description: 'Experience paradise on earth with turquoise waters, luxury resorts and vibrant marine life'
  },
];

export const MALDIVES_ISLANDS: PopularCity[] = [
  { 
    name: "Maafushi", 
    country: "Maldives", 
    latitude: 3.9432, 
    longitude: 73.5389,
    description: "Experience local island life and water sports on this popular budget-friendly destination"
  },
  { 
    name: "Thulusdhoo", 
    country: "Maldives", 
    latitude: 4.3744, 
    longitude: 73.6500,
    description: "Famous for surfing and water activities with beautiful beaches and local culture"
  },
  { 
    name: "Fulidhoo", 
    country: "Maldives", 
    latitude: 3.6813, 
    longitude: 73.4170,
    description: "Pristine beaches and traditional Maldivian culture on this peaceful small island"
  },
  { 
    name: "Gulhi", 
    country: "Maldives", 
    latitude: 3.9893, 
    longitude: 73.5065,
    description: "Quiet island known for pristine beaches and excellent snorkeling opportunities"
  },
  { 
    name: "Dhiffushi", 
    country: "Maldives", 
    latitude: 4.4417, 
    longitude: 73.7125,
    description: "Beautiful beaches and water activities on this serene local island getaway"
  }
];

export const CATEGORY_FILTERS: TourFilter = {
  type: 'categories',
  label: 'Categories',
  options: [
    { value: 'CULTURAL', label: 'Cultural' },
    { value: 'ADVENTURE', label: 'Adventure' },
    { value: 'NATURE', label: 'Nature & Wildlife' },
    { value: 'FOOD', label: 'Food & Culinary' },
    { value: 'HISTORICAL', label: 'Historical' },
    { value: 'SIGHTSEEING', label: 'Sightseeing' },
    { value: 'PHOTOGRAPHY', label: 'Photography' },
    { value: 'PRIVATE', label: 'Private Tours' },
  ]
};

export const DURATION_FILTERS: TourFilter = {
  type: 'duration',
  label: 'Duration',
  options: [
    { value: 'HALF_DAY', label: 'Half Day (up to 4 hours)' },
    { value: 'FULL_DAY', label: 'Full Day (4-8 hours)' },
    { value: 'MULTI_DAY_SHORT', label: '2-3 Days' },
    { value: 'MULTI_DAY_MEDIUM', label: '4-7 Days' },
    { value: 'MULTI_DAY_LONG', label: '1+ Week' },
  ]
};

export const RATING_FILTERS: TourFilter = {
  type: 'ratings',
  label: 'Rating',
  options: [
    { value: '5', label: '5 Stars' },
    { value: '4', label: '4+ Stars' },
    { value: '3', label: '3+ Stars' },
    { value: '2', label: '2+ Stars' },
    { value: '1', label: '1+ Star' },
  ]
};

export const SORT_OPTIONS: TourFilter = {
  type: 'sortBy',
  label: 'Sort By',
  options: [
    { value: 'rating-desc', label: 'Highest Rating' },
    { value: 'price-asc', label: 'Price: Low to High' },
    { value: 'price-desc', label: 'Price: High to Low' },
    { value: 'duration-asc', label: 'Duration: Shortest First' },
    { value: 'duration-desc', label: 'Duration: Longest First' },
    { value: 'popularity-desc', label: 'Most Popular' },
  ]
}; 