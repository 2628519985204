import { useState, useEffect, lazy, Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import Navigation from '../../components/Navigation';
import SEO from '../../components/SEO';
import { useChat } from '../../contexts/ChatContext';
import { fetchSliderImages, fetchDestinationsAndCities } from './services/dataService';
import { SliderImage, CityStats, Destination } from './types';

// Import components
import HeroSection from './components/HeroSection';
import FeatureSection from './components/FeatureSection';
import FeaturedCitiesSection from './components/FeaturedCitiesSection';
import PopularDestinationsSection from './components/PopularDestinationsSection';
import RequestManagementSection from './components/RequestManagementSection';
// import TestimonialsSection from './components/TestimonialsSection'; // Remove direct import

// Lazy load heavy components
const B2BMarketing = lazy(() => import('../../components/B2BMarketing'));
const Footer = lazy(() => import('../../components/Footer'));
const RequestAgentsModal = lazy(() => import('../../components/RequestAgentsModal').then(module => ({ default: module.RequestAgentsModal })));
const TestimonialsSection = lazy(() => import('./components/TestimonialsSection')); // Lazy load TestimonialsSection

export default function PublicHome() {
  const [searchLocation, setSearchLocation] = useState('');
  const [destinations, setDestinations] = useState<Destination[]>([]);
  const [cities, setCities] = useState<CityStats[]>([]);
  const [loading, setLoading] = useState(true);
  const [isRequestModalOpen, setIsRequestModalOpen] = useState(false);
  const [sliderImages, setSliderImages] = useState<SliderImage[]>([]);
  const navigate = useNavigate();
  const { dispatch } = useChat();

  // Load data - slider images, destinations, and cities
  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        
        // Load slider images
        const images = await fetchSliderImages();
        setSliderImages(images);
        
        // Load destinations and cities
        const { destinations, cities } = await fetchDestinationsAndCities();
        setDestinations(destinations);
        setCities(cities);
      } catch (error) {
        console.error('Error loading data:', error);
      } finally {
        setLoading(false);
      }
    };
    
    loadData();
  }, []);

  // Handle location selection
  const handleLocationSelect = (
    lat: number, 
    lng: number, 
    name: string,
    address?: { 
      city?: string; 
      state?: string; 
      country?: string; 
    }
  ) => {
    setSearchLocation(name);
    const searchParams = new URLSearchParams({
      lat: lat.toString(),
      lng: lng.toString(),
      location: name,
      country: address?.country || '',
      zoom: '8',
      search: name.split(',')[0].trim()
    });
    
    navigate(`/map?${searchParams.toString()}`);
  };

  // Function to open Marco AI chat
  const openMarcoChat = () => {
    dispatch({ type: 'OPEN_CHAT' });
  };

  return (
    <div className="min-h-screen bg-black flex flex-col">
      <SEO 
        title="Find Travel Agents Near You | Global Travel Specialists | AgenTrav"
        description="Connect with 41,800+ verified travel agents across 150+ countries. Find local travel specialists, luxury travel planners, and destination experts. Free travel agent search."
        keywords={[
          'find travel agent',
          'travel agent near me',
          'luxury travel specialist',
          'destination expert',
          'travel planner',
          'professional travel agent',
          'local travel specialist',
          'international travel agent'
        ]}
        schema={{
          "@context": "https://schema.org",
          "@type": "TravelAgency",
          "name": "AgenTrav",
          "description": "Global network of verified travel agents and specialists",
          "areaServed": {
            "@type": "GeoCircle",
            "geoMidpoint": {
              "@type": "GeoCoordinates",
              "latitude": "0",
              "longitude": "0"
            },
            "geoRadius": "20000 km"
          },
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "4.8",
            "reviewCount": "41800"
          }
        }}
      />
      <Navigation />

      <main className="flex-grow flex flex-col bg-black antialiased selection:bg-white/10">
        {/* Hero Section */}
        <HeroSection 
          onLocationSelect={handleLocationSelect} 
          onMarcoChat={openMarcoChat}
          sliderImages={sliderImages}
        />

        {/* Feature Section */}
        <FeatureSection />

        {/* Featured Cities Carousel */}
        <FeaturedCitiesSection 
          cities={cities} 
          loading={loading} 
        />

        {/* Popular Destinations Carousel */}
        <PopularDestinationsSection 
          destinations={destinations} 
          loading={loading} 
        />

        {/* Request Management Feature */}
        <RequestManagementSection 
          onOpenRequestModal={() => setIsRequestModalOpen(true)}
          onMarcoChat={openMarcoChat}
        />
        
        {/* Testimonials Section with 3D Marquee - Wrap in Suspense */}
        <Suspense fallback={null}> 
          <TestimonialsSection />
        </Suspense>
      </main>

      <Suspense fallback={null}>
        <B2BMarketing />
      </Suspense>
      
      <Suspense fallback={null}>
        <Footer />
      </Suspense>

      <Suspense fallback={null}>
        <RequestAgentsModal
          isOpen={isRequestModalOpen}
          onClose={() => setIsRequestModalOpen(false)}
          city=""
          country=""
          type="bulk_quote"
        />
      </Suspense>
    </div>
  );
} 