import React, { useState, useEffect } from 'react';
import { AmadeusHotel, HotelOfferSearch } from '../../../services/amadeus';
import { CreditCard, User, Calendar, X, Check, Lock, AlertCircle, Umbrella, Building2, Landmark, Mountain, UtensilsCrossed, Hotel } from 'lucide-react';
import { format } from 'date-fns';

interface HotelBookingFormProps {
  hotel: AmadeusHotel;
  offer: HotelOfferSearch;
  onClose: () => void;
  onSubmit: (bookingData: BookingFormData) => void;
  isSubmitting: boolean;
}

export interface BookingFormData {
  guests: {
    tid: number;
    title: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  }[];
  payment: {
    method: string;
    cardInfo: {
      vendorCode: string;
      cardNumber: string;
      expiryDate: string;
      holderName: string;
      securityCode: string;
    };
  };
  specialRequests?: string;
  acceptTerms: boolean;
}

const cardTypes = [
  { code: 'VI', name: 'Visa' },
  { code: 'MC', name: 'Mastercard' },
  { code: 'AX', name: 'American Express' },
  { code: 'DC', name: 'Diners Club' },
  { code: 'CA', name: 'Maestro' },
];

// Define hotel themes with minimal dark style
const hotelThemes = {
  beach: {
    bgColor: "bg-gray-900",
    headerBg: "bg-black",
    cardBg: "bg-gray-800/70",
    accentColor: "text-blue-400",
    borderColor: "border-gray-700",
    icon: <Umbrella className="w-5 h-5 mr-2 text-blue-400" />,
    title: "Beachfront Stay",
    gradientBg: "bg-gray-900",
    gradientHeader: "bg-black",
    highlightColor: "bg-gray-800",
    buttonGradient: "bg-gray-800 hover:bg-gray-700"
  },
  urban: {
    bgColor: "bg-gray-900",
    headerBg: "bg-black",
    cardBg: "bg-gray-800/70",
    accentColor: "text-indigo-400",
    borderColor: "border-gray-700",
    icon: <Building2 className="w-5 h-5 mr-2 text-indigo-400" />,
    title: "Urban Experience",
    gradientBg: "bg-gray-900",
    gradientHeader: "bg-black",
    highlightColor: "bg-gray-800",
    buttonGradient: "bg-gray-800 hover:bg-gray-700"
  },
  historic: {
    bgColor: "bg-gray-900",
    headerBg: "bg-black",
    cardBg: "bg-gray-800/70",
    accentColor: "text-amber-400",
    borderColor: "border-gray-700",
    icon: <Landmark className="w-5 h-5 mr-2 text-amber-400" />,
    title: "Historic Accommodation",
    gradientBg: "bg-gray-900",
    gradientHeader: "bg-black",
    highlightColor: "bg-gray-800",
    buttonGradient: "bg-gray-800 hover:bg-gray-700"
  },
  nature: {
    bgColor: "bg-gray-900",
    headerBg: "bg-black",
    cardBg: "bg-gray-800/70",
    accentColor: "text-emerald-400",
    borderColor: "border-gray-700",
    icon: <Mountain className="w-5 h-5 mr-2 text-emerald-400" />,
    title: "Nature Retreat",
    gradientBg: "bg-gray-900",
    gradientHeader: "bg-black",
    highlightColor: "bg-gray-800",
    buttonGradient: "bg-gray-800 hover:bg-gray-700"
  },
  luxury: {
    bgColor: "bg-gray-900",
    headerBg: "bg-black",
    cardBg: "bg-gray-800/70",
    accentColor: "text-rose-400",
    borderColor: "border-gray-700",
    icon: <UtensilsCrossed className="w-5 h-5 mr-2 text-rose-400" />,
    title: "Luxury Experience",
    gradientBg: "bg-gray-900",
    gradientHeader: "bg-black",
    highlightColor: "bg-gray-800",
    buttonGradient: "bg-gray-800 hover:bg-gray-700"
  },
  default: {
    bgColor: "bg-gray-900",
    headerBg: "bg-black",
    cardBg: "bg-gray-800/70",
    accentColor: "text-gray-400",
    borderColor: "border-gray-700",
    icon: <Hotel className="w-5 h-5 mr-2 text-gray-400" />,
    title: "Hotel Booking",
    gradientBg: "bg-gray-900",
    gradientHeader: "bg-black",
    highlightColor: "bg-gray-800",
    buttonGradient: "bg-gray-800 hover:bg-gray-700"
  }
};

export default function HotelBookingForm({
  hotel,
  offer,
  onClose,
  onSubmit,
  isSubmitting
}: HotelBookingFormProps) {
  const [formData, setFormData] = useState<BookingFormData>({
    guests: [
      {
        tid: 1,
        title: 'MR',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
      }
    ],
    payment: {
      method: 'CREDIT_CARD',
      cardInfo: {
        vendorCode: 'VI',
        cardNumber: '',
        expiryDate: '',
        holderName: '',
        securityCode: '',
      }
    },
    specialRequests: '',
    acceptTerms: false
  });

  const [errors, setErrors] = useState<Record<string, string>>({});
  const [theme, setTheme] = useState(hotelThemes.default);
  
  // Determine hotel theme based on hotel name or description
  useEffect(() => {
    if (!hotel?.name) return;
    
    const name = hotel.name.toLowerCase();
    const description = (hotel.description?.text || '').toLowerCase();
    const amenities = (hotel.amenities || []).map(a => a.toLowerCase()).join(' ');
    
    // Analyze hotel name, description, and amenities to determine theme
    if (
      name.includes('beach') || 
      name.includes('bay') || 
      name.includes('sea') || 
      name.includes('ocean') || 
      description.includes('beach') || 
      description.includes('ocean') ||
      description.includes('sea view') ||
      amenities.includes('beach') ||
      amenities.includes('pool')
    ) {
      setTheme(hotelThemes.beach);
    } else if (
      name.includes('grand') || 
      name.includes('palace') || 
      name.includes('luxury') || 
      name.includes('premium') ||
      name.includes('five star') ||
      description.includes('luxury') ||
      description.includes('exclusive') ||
      description.includes('elegant')
    ) {
      setTheme(hotelThemes.luxury);
    } else if (
      name.includes('historic') || 
      name.includes('heritage') || 
      name.includes('royal') || 
      name.includes('palace') ||
      name.includes('castle') ||
      description.includes('historic') ||
      description.includes('heritage') ||
      description.includes('century') ||
      description.includes('traditional')
    ) {
      setTheme(hotelThemes.historic);
    } else if (
      name.includes('city') || 
      name.includes('urban') || 
      name.includes('metro') || 
      name.includes('downtown') ||
      description.includes('city center') ||
      description.includes('heart of the city') ||
      description.includes('downtown')
    ) {
      setTheme(hotelThemes.urban);
    } else if (
      name.includes('mountain') || 
      name.includes('forest') || 
      name.includes('lake') || 
      name.includes('nature') ||
      name.includes('park') ||
      name.includes('garden') ||
      description.includes('mountain') ||
      description.includes('nature') ||
      description.includes('forest') ||
      description.includes('countryside')
    ) {
      setTheme(hotelThemes.nature);
    }
  }, [hotel]);

  // Get relevant offer details
  const hotelOfferId = offer?.hotel?.hotelId;
  const checkInDate = offer.offers?.[0]?.checkInDate;
  const checkOutDate = offer.offers?.[0]?.checkOutDate;
  const price = offer.offers?.[0]?.price?.total;
  const currency = offer.offers?.[0]?.price?.currency || 'USD';
  const paymentType = offer.offers?.[0]?.policies?.paymentType;

  // Calculate stay duration
  const stayDuration = (() => {
    if (checkInDate && checkOutDate) {
      const checkIn = new Date(checkInDate);
      const checkOut = new Date(checkOutDate);
      const diffTime = Math.abs(checkOut.getTime() - checkIn.getTime());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    }
    return 1;
  })();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    
    if (name.startsWith('guest.')) {
      const [_, field] = name.split('.');
      const updatedGuests = [...formData.guests];
      updatedGuests[0] = { ...updatedGuests[0], [field]: value };
      
      setFormData({
        ...formData,
        guests: updatedGuests
      });
    } else if (name.startsWith('payment.')) {
      const [_, field] = name.split('.');
      
      setFormData({
        ...formData,
        payment: {
          ...formData.payment,
          cardInfo: {
            ...formData.payment.cardInfo,
            [field]: value
          }
        }
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
    
    // Clear error for this field if exists
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: ''
      });
    }
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      [name]: checked
    });
    
    // Clear error for this field if exists
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: ''
      });
    }
  };

  const validateForm = (): boolean => {
    const newErrors: Record<string, string> = {};
    
    // Validate guest info
    if (!formData.guests[0].firstName) {
      newErrors['guest.firstName'] = 'First name is required';
    }
    
    if (!formData.guests[0].lastName) {
      newErrors['guest.lastName'] = 'Last name is required';
    }
    
    if (!formData.guests[0].email) {
      newErrors['guest.email'] = 'Email is required';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.guests[0].email)) {
      newErrors['guest.email'] = 'Valid email is required';
    }
    
    if (!formData.guests[0].phone) {
      newErrors['guest.phone'] = 'Phone number is required';
    } else if (!/^\+[1-9]\d{4,18}$/.test(formData.guests[0].phone)) {
      newErrors['guest.phone'] = 'Phone must start with + and country code';
    }
    
    // Validate payment info
    if (!formData.payment.cardInfo.cardNumber) {
      newErrors['payment.cardNumber'] = 'Card number is required';
    } else if (!/^\d{13,19}$/.test(formData.payment.cardInfo.cardNumber.replace(/\s/g, ''))) {
      newErrors['payment.cardNumber'] = 'Invalid card number';
    }
    
    if (!formData.payment.cardInfo.expiryDate) {
      newErrors['payment.expiryDate'] = 'Expiry date is required';
    } else if (!/^\d{4}-\d{2}$/.test(formData.payment.cardInfo.expiryDate)) {
      newErrors['payment.expiryDate'] = 'Format must be YYYY-MM';
    }
    
    if (!formData.payment.cardInfo.holderName) {
      newErrors['payment.holderName'] = 'Cardholder name is required';
    }
    
    if (!formData.payment.cardInfo.securityCode) {
      newErrors['payment.securityCode'] = 'Security code is required';
    } else if (!/^\d{3,4}$/.test(formData.payment.cardInfo.securityCode)) {
      newErrors['payment.securityCode'] = 'Invalid security code';
    }
    
    // Terms acceptance
    if (!formData.acceptTerms) {
      newErrors['acceptTerms'] = 'You must accept the terms and conditions';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    if (validateForm()) {
      onSubmit(formData);
    }
  };

  const formatCurrency = (amount: string | undefined) => {
    if (!amount) return '0';
    return parseFloat(amount).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  };
  
  // Format the payment policy description
  const paymentDescription = 
    paymentType === 'GUARANTEE' ? 'You will pay at the hotel' : 
    paymentType === 'DEPOSIT' ? 'A deposit will be charged to your card now' : 
    'Payment will be processed after booking';

  return (
    <div className="fixed inset-0 bg-black/80 backdrop-blur-sm z-50 overflow-y-auto">
      <div className="flex min-h-full items-center justify-center p-4 py-10">
        <div 
          className={`w-full max-w-4xl ${theme.bgColor} backdrop-blur-lg rounded-xl overflow-hidden shadow-xl relative animate-fadeIn border ${theme.borderColor}`}
          style={{animation: 'fadeIn 0.3s ease-out'}}
        >
          {/* Close button */}
          <button 
            onClick={onClose}
            className="absolute top-4 right-4 text-gray-400 hover:text-white p-2 rounded-full transition-all duration-200 hover:bg-white/10 z-10"
            aria-label="Close booking form"
          >
            <X className="w-5 h-5" />
          </button>
          
          {/* Header */}
          <div className={`${theme.headerBg} p-6 sm:p-8`}>
            <h2 className="text-2xl font-semibold text-white mb-3 flex items-center">
              <Lock className="w-5 h-5 mr-2 text-white" />
              <span className="mr-2">Secure Booking</span>
              <span className="text-sm bg-gray-800 rounded-full px-3 py-1 ml-2 text-gray-300">
                {theme.title}
              </span>
            </h2>
            
            <div className="flex flex-wrap gap-3 mb-2">
              <div className="flex items-center bg-gray-800 rounded-full px-3 py-1 text-white text-sm">
                <Calendar className="w-4 h-4 mr-1.5 text-gray-400" />
                {checkInDate && format(new Date(checkInDate), 'MMM dd')} - {checkOutDate && format(new Date(checkOutDate), 'MMM dd, yyyy')}
              </div>
              
              <div className="flex items-center bg-gray-800 rounded-full px-3 py-1 text-white text-sm">
                <User className="w-4 h-4 mr-1.5 text-gray-400" />
                {stayDuration} {stayDuration === 1 ? 'night' : 'nights'} stay
              </div>
            </div>
            
            <p className="text-gray-400 text-sm">{hotel.name}</p>
          </div>
          
          <div className="p-8">
            <div className="grid md:grid-cols-3 gap-8">
              {/* Main form content */}
              <div className="md:col-span-2">
                <form onSubmit={handleSubmit}>
                  {/* Guest Information */}
                  <div className={`${theme.cardBg} rounded-xl p-5 mb-6 border ${theme.borderColor}`}>
                    <h3 className="text-white text-lg font-medium mb-4 flex items-center">
                      <User className={`w-5 h-5 mr-2 ${theme.accentColor}`} />
                      Guest Information
                    </h3>
                    
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                      <div>
                        <label className="block text-sm text-gray-300 mb-1.5">Title</label>
                        <select
                          name="guest.title"
                          value={formData.guests[0].title}
                          onChange={handleInputChange}
                          className="w-full bg-gray-800 border border-gray-700 rounded-lg p-2.5 text-white transition-colors duration-200 focus:border-gray-600 focus:outline-none focus:ring-1 focus:ring-gray-600"
                        >
                          <option value="MR">Mr.</option>
                          <option value="MS">Ms.</option>
                          <option value="MRS">Mrs.</option>
                          <option value="DR">Dr.</option>
                        </select>
                      </div>
                      
                      <div className="hidden sm:block"></div>
                      
                      <div>
                        <label className="block text-sm text-gray-300 mb-1.5">First Name*</label>
                        <input
                          type="text"
                          name="guest.firstName"
                          value={formData.guests[0].firstName}
                          onChange={handleInputChange}
                          className="w-full bg-gray-800 border border-gray-700 rounded-lg p-2.5 text-white transition-colors duration-200 focus:ring-1 focus:outline-none focus:ring-gray-600"
                          required
                        />
                        {errors['guest.firstName'] && (
                          <p className="text-red-400 text-xs mt-1.5 flex items-center">
                            <AlertCircle className="w-3 h-3 mr-1" />
                            {errors['guest.firstName']}
                          </p>
                        )}
                      </div>
                      
                      <div>
                        <label className="block text-sm text-gray-300 mb-1.5">Last Name*</label>
                        <input
                          type="text"
                          name="guest.lastName"
                          value={formData.guests[0].lastName}
                          onChange={handleInputChange}
                          className="w-full bg-gray-800 border border-gray-700 rounded-lg p-2.5 text-white transition-colors duration-200 focus:ring-1 focus:outline-none focus:ring-gray-600"
                          required
                        />
                        {errors['guest.lastName'] && (
                          <p className="text-red-400 text-xs mt-1.5 flex items-center">
                            <AlertCircle className="w-3 h-3 mr-1" />
                            {errors['guest.lastName']}
                          </p>
                        )}
                      </div>
                      
                      <div>
                        <label className="block text-sm text-gray-300 mb-1.5">Email*</label>
                        <input
                          type="email"
                          name="guest.email"
                          value={formData.guests[0].email}
                          onChange={handleInputChange}
                          className="w-full bg-gray-800 border border-gray-700 rounded-lg p-2.5 text-white transition-colors duration-200 focus:ring-1 focus:outline-none focus:ring-gray-600"
                          required
                        />
                        {errors['guest.email'] && (
                          <p className="text-red-400 text-xs mt-1.5 flex items-center">
                            <AlertCircle className="w-3 h-3 mr-1" />
                            {errors['guest.email']}
                          </p>
                        )}
                      </div>
                      
                      <div>
                        <label className="block text-sm text-gray-300 mb-1.5">Phone*</label>
                        <input
                          type="tel"
                          name="guest.phone"
                          value={formData.guests[0].phone}
                          onChange={handleInputChange}
                          className="w-full bg-gray-800 border border-gray-700 rounded-lg p-2.5 text-white transition-colors duration-200 focus:ring-1 focus:outline-none focus:ring-gray-600"
                          placeholder="+12345678901"
                          required
                        />
                        {errors['guest.phone'] && (
                          <p className="text-red-400 text-xs mt-1.5 flex items-center">
                            <AlertCircle className="w-3 h-3 mr-1" />
                            {errors['guest.phone']}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  
                  {/* Payment Information */}
                  <div className={`${theme.cardBg} rounded-xl p-5 mb-6 border ${theme.borderColor}`}>
                    <h3 className="text-white text-lg font-medium mb-4 flex items-center">
                      <CreditCard className={`w-5 h-5 mr-2 ${theme.accentColor}`} />
                      Payment Details
                    </h3>
                    
                    <div className="mb-4 p-3 rounded-lg bg-gray-800 border border-gray-700">
                      <div className="flex items-center">
                        <span className="text-white text-sm mr-2">{paymentDescription}</span>
                        {paymentType === 'GUARANTEE' ? (
                          <span className="bg-gray-700 text-blue-400 px-2 py-0.5 rounded text-xs">
                            Pay at Hotel
                          </span>
                        ) : (
                          <span className="bg-gray-700 text-amber-400 px-2 py-0.5 rounded text-xs">
                            Pre-payment
                          </span>
                        )}
                      </div>
                    </div>
                    
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                      <div>
                        <label className="block text-sm text-gray-300 mb-1.5">Card Type*</label>
                        <select
                          name="payment.vendorCode"
                          value={formData.payment.cardInfo.vendorCode}
                          onChange={handleInputChange}
                          className="w-full bg-gray-800 border border-gray-700 rounded-lg p-2.5 text-white transition-colors duration-200 focus:ring-1 focus:outline-none focus:ring-gray-600"
                        >
                          {cardTypes.map(card => (
                            <option key={card.code} value={card.code}>{card.name}</option>
                          ))}
                        </select>
                      </div>
                      
                      <div>
                        <label className="block text-sm text-gray-300 mb-1.5">Card Number*</label>
                        <input
                          type="text"
                          name="payment.cardNumber"
                          value={formData.payment.cardInfo.cardNumber}
                          onChange={handleInputChange}
                          className="w-full bg-gray-800 border border-gray-700 rounded-lg p-2.5 text-white transition-colors duration-200 focus:ring-1 focus:outline-none focus:ring-gray-600"
                          placeholder="4111 1111 1111 1111"
                          required
                        />
                        {errors['payment.cardNumber'] && (
                          <p className="text-red-400 text-xs mt-1.5 flex items-center">
                            <AlertCircle className="w-3 h-3 mr-1" />
                            {errors['payment.cardNumber']}
                          </p>
                        )}
                      </div>
                      
                      <div>
                        <label className="block text-sm text-gray-300 mb-1.5">Expiry Date* (YYYY-MM)</label>
                        <input
                          type="text"
                          name="payment.expiryDate"
                          value={formData.payment.cardInfo.expiryDate}
                          onChange={handleInputChange}
                          className="w-full bg-gray-800 border border-gray-700 rounded-lg p-2.5 text-white transition-colors duration-200 focus:ring-1 focus:outline-none focus:ring-gray-600"
                          placeholder="2026-08"
                          required
                        />
                        {errors['payment.expiryDate'] && (
                          <p className="text-red-400 text-xs mt-1.5 flex items-center">
                            <AlertCircle className="w-3 h-3 mr-1" />
                            {errors['payment.expiryDate']}
                          </p>
                        )}
                      </div>
                      
                      <div>
                        <label className="block text-sm text-gray-300 mb-1.5">Card Holder Name*</label>
                        <input
                          type="text"
                          name="payment.holderName"
                          value={formData.payment.cardInfo.holderName}
                          onChange={handleInputChange}
                          className="w-full bg-gray-800 border border-gray-700 rounded-lg p-2.5 text-white transition-colors duration-200 focus:ring-1 focus:outline-none focus:ring-gray-600"
                          placeholder="JOHN SMITH"
                          required
                        />
                        {errors['payment.holderName'] && (
                          <p className="text-red-400 text-xs mt-1.5 flex items-center">
                            <AlertCircle className="w-3 h-3 mr-1" />
                            {errors['payment.holderName']}
                          </p>
                        )}
                      </div>
                      
                      <div>
                        <label className="block text-sm text-gray-300 mb-1.5">Security Code*</label>
                        <input
                          type="text"
                          name="payment.securityCode"
                          value={formData.payment.cardInfo.securityCode}
                          onChange={handleInputChange}
                          className="w-full bg-gray-800 border border-gray-700 rounded-lg p-2.5 text-white transition-colors duration-200 focus:ring-1 focus:outline-none focus:ring-gray-600"
                          placeholder="123"
                          required
                        />
                        {errors['payment.securityCode'] && (
                          <p className="text-red-400 text-xs mt-1.5 flex items-center">
                            <AlertCircle className="w-3 h-3 mr-1" />
                            {errors['payment.securityCode']}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  
                  {/* Special Requests */}
                  <div className={`${theme.cardBg} rounded-xl p-5 mb-6 border ${theme.borderColor}`}>
                    <h3 className="text-white text-lg font-medium mb-4">Special Requests</h3>
                    <textarea
                      name="specialRequests"
                      value={formData.specialRequests}
                      onChange={handleInputChange}
                      className="w-full bg-gray-800 border border-gray-700 rounded-lg p-2.5 text-white h-20 transition-colors duration-200 focus:ring-1 focus:outline-none focus:ring-gray-600"
                      placeholder="Any special requests for your stay?"
                    ></textarea>
                    <p className="text-gray-400 text-xs mt-2">
                      Special requests cannot be guaranteed but the hotel will do its best to meet your needs.
                    </p>
                  </div>
                  
                  {/* Terms and Conditions */}
                  <div className="mb-5">
                    <div className="flex items-start">
                      <div className="flex h-5 items-center">
                        <input
                          type="checkbox"
                          id="acceptTerms"
                          name="acceptTerms"
                          checked={formData.acceptTerms}
                          onChange={handleCheckboxChange}
                          className="h-4 w-4 rounded border-gray-600 bg-gray-700 text-gray-500 focus:ring-gray-600 focus:ring-offset-gray-800"
                        />
                      </div>
                      <label htmlFor="acceptTerms" className="ml-2 text-sm text-gray-300">
                        I agree to the hotel's terms and conditions, including cancellation policy and privacy policy.
                      </label>
                    </div>
                    {errors['acceptTerms'] && (
                      <p className="text-red-400 text-xs mt-1.5 flex items-center">
                        <AlertCircle className="w-3 h-3 mr-1" />
                        {errors['acceptTerms']}
                      </p>
                    )}
                  </div>
                  
                  {/* Submit Button */}
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="w-full bg-gray-800 hover:bg-gray-700 text-white py-3 rounded-lg font-medium transition-all duration-200 disabled:opacity-70 disabled:cursor-not-allowed flex items-center justify-center"
                  >
                    {isSubmitting ? (
                      <>
                        <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Processing...
                      </>
                    ) : (
                      <>
                        <Check className="mr-2 h-5 w-5" />
                        Complete Booking
                      </>
                    )}
                  </button>
                </form>
              </div>
              
              {/* Order Summary */}
              <div className="md:col-span-1">
                <div className={`${theme.cardBg} rounded-xl p-5 sticky top-8 border ${theme.borderColor}`}>
                  <h3 className="text-white text-lg font-medium mb-4">Booking Summary</h3>
                  
                  <div className="space-y-3 mb-5">
                    <div className="flex items-center text-sm">
                      <Calendar className={`w-4 h-4 mr-2 ${theme.accentColor} flex-shrink-0`} />
                      <div>
                        <span className="text-white">{checkInDate && format(new Date(checkInDate), 'MMM dd')} - {checkOutDate && format(new Date(checkOutDate), 'MMM dd, yyyy')}</span>
                        <span className="text-gray-400 text-xs block">
                          {stayDuration} {stayDuration === 1 ? 'night' : 'nights'}
                        </span>
                      </div>
                    </div>
                    
                    <div className="flex justify-between text-sm">
                      <span className="text-gray-300">Room:</span>
                      <span className="text-white">{offer.offers?.[0]?.room?.type || 'Standard Room'}</span>
                    </div>
                    
                    <div className="flex justify-between text-sm">
                      <span className="text-gray-300">Guests:</span>
                      <span className="text-white">{offer.offers?.[0]?.guests?.adults || 1} Adults</span>
                    </div>
                  </div>
                  
                  <div className="border-t border-gray-700 pt-4 mb-4 space-y-2">
                    <div className="flex justify-between text-sm">
                      <span className="text-gray-300">Rate:</span>
                      <span className="text-white">
                        {currency} {formatCurrency(offer.offers?.[0]?.price?.base)}
                      </span>
                    </div>
                    
                    {offer.offers?.[0]?.price?.base && offer.offers?.[0]?.price?.total && 
                     parseFloat(offer.offers[0].price.total) > parseFloat(offer.offers[0].price.base) && (
                      <div className="flex justify-between text-sm">
                        <span className="text-gray-300">Taxes & Fees:</span>
                        <span className="text-white">
                          {currency} {
                            formatCurrency(
                              (parseFloat(price || '0') - parseFloat(offer.offers?.[0]?.price?.base || '0')).toString()
                            )
                          }
                        </span>
                      </div>
                    )}
                  </div>
                  
                  <div className="border-t border-gray-700 pt-4 mb-5">
                    <div className="flex justify-between font-medium mb-2">
                      <span className="text-white">Total:</span>
                      <span className={`text-xl ${theme.accentColor}`}>
                        {currency} {formatCurrency(price)}
                      </span>
                    </div>
                    
                    <p className="text-xs text-gray-400">
                      {paymentType === 'GUARANTEE' 
                        ? 'No payment now - you will pay directly at the hotel.' 
                        : 'This amount will be charged to your card upon booking confirmation.'}
                    </p>
                  </div>
                  
                  {/* Cancellation policy */}
                  {offer.offers?.[0]?.policies?.cancellation?.deadline && (
                    <div className="mt-4 border-t border-gray-700 pt-4 text-xs text-gray-300">
                      <p className="font-medium text-white mb-1">Cancellation Policy:</p>
                      <p>
                        Free cancellation until{' '}
                        {format(new Date(offer.offers[0].policies.cancellation.deadline), 'MMM dd, yyyy')}
                      </p>
                      {offer.offers[0].policies.cancellation.amount && (
                        <p className="mt-1">
                          After this date, a fee of {currency} {formatCurrency(offer.offers[0].policies.cancellation.amount)} will apply.
                        </p>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          
          {/* Add animation styles */}
          <style>{`
            @keyframes fadeIn {
              from { opacity: 0; transform: translateY(10px); }
              to { opacity: 1; transform: translateY(0); }
            }
            .animate-fadeIn {
              animation: fadeIn 0.3s ease-out;
            }
          `}</style>
        </div>
      </div>
    </div>
  );
} 