import React, { useState, useEffect } from 'react';
import { 
  Star, MapPin, Wifi, Coffee, 
  Waves, Car, UtensilsCrossed, Dumbbell, 
  Users, Calendar, Phone, Mail, 
  Globe, ArrowLeft, Check, Clock, 
  BedDouble, Ban, Cigarette, PawPrint,
  CreditCard, Loader2, Umbrella, Building2, 
  Landmark, Mountain, Hotel, Info, Utensils
} from 'lucide-react';
import { format } from 'date-fns';
import { AmadeusHotel, HotelOfferSearch } from '../../../services/amadeus';
import HotelBookingForm, { BookingFormData } from './HotelBookingForm';
import BookingConfirmation from './BookingConfirmation';
import { createHotelBooking, BookingResponse } from '../services/hotelBookingService';

interface HotelDetailViewProps {
  hotel: AmadeusHotel;
  offers?: HotelOfferSearch[];
  onClose: () => void;
}

// Extended interface for hotel offer items with optional additional properties
interface ExtendedOfferItem {
  id: string;
  checkInDate: string;
  checkOutDate: string;
  rateCode: string;
  rateFamilyEstimated: {
    code: string;
    type: string;
  };
  room: {
    type: string;
    typeEstimated: {
      category: string;
      beds: number;
      bedType: string;
    };
    description: {
      text: string;
      lang: string;
    };
  };
  guests: {
    adults: number;
    childAges?: number[];
  };
  price: {
    currency: string;
    base: string;
    total: string;
    variations?: {
      average?: {
        base: string;
        total?: string;
      };
      changes?: Array<{
        startDate: string;
        endDate: string;
        base: string;
        total?: string;
      }>;
    };
    taxes?: Array<{
      code: string;
      amount: string;
      currency: string;
      included: boolean;
      description?: string;
    }>;
    markups?: Array<{
      amount: string;
    }>;
  };
  policies?: {
    guarantee?: {
      acceptedPayments?: {
        creditCards?: string[];
        methods?: string[];
      };
      description?: {
        text: string;
        lang: string;
      };
    };
    paymentType: string;
    cancellation?: {
      description?: {
        text: string;
        lang: string;
      };
      type?: string;
      deadline?: string;
      amount?: string;
      percentage?: string;
    };
    checkInOut?: {
      checkIn?: string;
      checkOut?: string;
      checkInDescription?: {
        text: string;
        lang: string;
      };
      checkOutDescription?: {
        text: string;
        lang: string;
      };
    };
    deposit?: {
      amount?: string;
      deadline?: string;
      description?: {
        text: string;
        lang: string;
      };
    };
  };
  self: string;
  // Additional properties that might be in some API responses
  boardType?: string; // Optional board type (ROOM_ONLY, BREAKFAST, etc.)
  description?: {
    text: string;
    lang: string;
  }; // Detailed offer description from single-offer endpoint
}

// Define hotel types with minimal dark themes
const hotelThemes = {
  beach: {
    bgColor: "bg-gray-900",
    headerBg: "bg-black",
    cardBg: "bg-gray-800/70",
    accentColor: "text-blue-400",
    borderColor: "border-gray-700",
    icon: <Umbrella className="w-5 h-5 mr-2 text-blue-400" />,
    name: "Beachfront"
  },
  urban: {
    bgColor: "bg-gray-900",
    headerBg: "bg-black",
    cardBg: "bg-gray-800/70",
    accentColor: "text-indigo-400",
    borderColor: "border-gray-700",
    icon: <Building2 className="w-5 h-5 mr-2 text-indigo-400" />,
    name: "Urban"
  },
  historic: {
    bgColor: "bg-gray-900",
    headerBg: "bg-black",
    cardBg: "bg-gray-800/70",
    accentColor: "text-amber-400",
    borderColor: "border-gray-700",
    icon: <Landmark className="w-5 h-5 mr-2 text-amber-400" />,
    name: "Historic"
  },
  nature: {
    bgColor: "bg-gray-900",
    headerBg: "bg-black",
    cardBg: "bg-gray-800/70",
    accentColor: "text-emerald-400",
    borderColor: "border-gray-700",
    icon: <Mountain className="w-5 h-5 mr-2 text-emerald-400" />,
    name: "Nature"
  },
  luxury: {
    bgColor: "bg-gray-900",
    headerBg: "bg-black",
    cardBg: "bg-gray-800/70",
    accentColor: "text-rose-400",
    borderColor: "border-gray-700",
    icon: <UtensilsCrossed className="w-5 h-5 mr-2 text-rose-400" />,
    name: "Luxury"
  },
  default: {
    bgColor: "bg-gray-900",
    headerBg: "bg-black",
    cardBg: "bg-gray-800/70",
    accentColor: "text-gray-400",
    borderColor: "border-gray-700", 
    icon: <Hotel className="w-5 h-5 mr-2 text-gray-400" />,
    name: "Hotel"
  }
};

// Mapping for amenity categories
const amenityToIcon = {
  WIFI: <Wifi className="w-4 h-4" />,
  POOL: <Waves className="w-4 h-4" />,
  RESTAURANT: <UtensilsCrossed className="w-4 h-4" />,
  FITNESS_CENTER: <Dumbbell className="w-4 h-4" />,
  PARKING: <Car className="w-4 h-4" />,
  BREAKFAST: <Coffee className="w-4 h-4" />,
  BAR: <Coffee className="w-4 h-4" />,
  ROOM_SERVICE: <UtensilsCrossed className="w-4 h-4" />,
  SPA: <Waves className="w-4 h-4" />,
  PET_FRIENDLY: <PawPrint className="w-4 h-4" />,
  NO_SMOKING: <Ban className="w-4 h-4" />
};

export default function HotelDetailView({ 
  hotel, 
  offers, 
  onClose
}: HotelDetailViewProps) {
  const [showAllAmenities, setShowAllAmenities] = useState(false);
  const [showBookingForm, setShowBookingForm] = useState(false);
  const [booking, setBooking] = useState<BookingResponse | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [theme, setTheme] = useState(hotelThemes.default);
  const [selectedOffer, setSelectedOffer] = useState<typeof firstOffer | null>(null);
  const [expandedRoomType, setExpandedRoomType] = useState<string | null>(null);
  
  // Get the first offer if available
  const firstOffer = offers?.[0]?.offers?.[0];
  const price = (selectedOffer || firstOffer)?.price?.total;
  const currency = (selectedOffer || firstOffer)?.price?.currency || 'USD';
  const checkInDate = (selectedOffer || firstOffer)?.checkInDate;
  const checkOutDate = (selectedOffer || firstOffer)?.checkOutDate;
  
  // Get all available offers across different room types
  const allAvailableOffers = offers?.[0]?.offers || [];
  
  // Group offers by room type for easier browsing
  const offersByRoomType = allAvailableOffers.reduce((acc, offer) => {
    const roomType = offer.room?.typeEstimated?.category || 'Standard Room';
    if (!acc[roomType]) {
      acc[roomType] = [];
    }
    acc[roomType].push(offer);
    return acc;
  }, {} as Record<string, typeof allAvailableOffers>);
  
  // Sort room types by price (cheapest first)
  const sortedRoomTypes = Object.keys(offersByRoomType).sort((a, b) => {
    const minPriceA = Math.min(...offersByRoomType[a].map(o => parseFloat(o.price.total)));
    const minPriceB = Math.min(...offersByRoomType[b].map(o => parseFloat(o.price.total)));
    return minPriceA - minPriceB;
  });
  
  // Determine hotel theme based on name or description
  useEffect(() => {
    if (!hotel?.name) return;
    
    const name = hotel.name.toLowerCase();
    const description = (hotel.description?.text || '').toLowerCase();
    const amenities = (hotel.amenities || []).map(a => a.toLowerCase()).join(' ');
    
    // Analyze hotel name, description and amenities to determine theme
    if (
      name.includes('beach') || 
      name.includes('bay') || 
      name.includes('sea') || 
      name.includes('ocean') || 
      description.includes('beach') || 
      description.includes('ocean') ||
      description.includes('sea view') ||
      amenities.includes('beach') ||
      amenities.includes('pool')
    ) {
      setTheme(hotelThemes.beach);
    } else if (
      name.includes('grand') || 
      name.includes('palace') || 
      name.includes('luxury') || 
      name.includes('premium') ||
      name.includes('five star') ||
      description.includes('luxury') ||
      description.includes('exclusive') ||
      description.includes('elegant')
    ) {
      setTheme(hotelThemes.luxury);
    } else if (
      name.includes('historic') || 
      name.includes('heritage') || 
      name.includes('royal') || 
      name.includes('palace') ||
      name.includes('castle') ||
      description.includes('historic') ||
      description.includes('heritage') ||
      description.includes('century') ||
      description.includes('traditional')
    ) {
      setTheme(hotelThemes.historic);
    } else if (
      name.includes('city') || 
      name.includes('urban') || 
      name.includes('metro') || 
      name.includes('downtown') ||
      description.includes('city center') ||
      description.includes('heart of the city') ||
      description.includes('downtown')
    ) {
      setTheme(hotelThemes.urban);
    } else if (
      name.includes('mountain') || 
      name.includes('forest') || 
      name.includes('lake') || 
      name.includes('nature') ||
      name.includes('park') ||
      name.includes('garden') ||
      description.includes('mountain') ||
      description.includes('nature') ||
      description.includes('forest') ||
      description.includes('countryside')
    ) {
      setTheme(hotelThemes.nature);
    }
  }, [hotel]);
  
  // Helper function to format cancellation date
  const formatCancellationDate = (dateTimeString: string) => {
    try {
      const date = new Date(dateTimeString);
      return format(date, 'MMM dd, yyyy HH:mm');
    } catch (error) {
      return dateTimeString;
    }
  };
  
  // Helper function to get readable rate type
  const getOfferRateType = (code: string) => {
    const rateTypes: Record<string, string> = {
      'PRO': 'Promo',
      'BAR': 'Best Rate',
      'COR': 'Corporate',
      'GOV': 'Government',
      'AAA': 'AAA',
      'BNB': 'B&B',
      'PKG': 'Package',
      'TVL': 'Travel',
      'SPC': 'Special',
      'WKD': 'Weekend',
      'CON': 'Convention',
      'SNR': 'Senior',
      'ARP': 'AARP',
      'SRS': 'Senior',
      'ROR': 'Room Only',
      'FAM': 'Family',
      'DAY': 'Day Rate',
      'RAC': 'Rack',
    };
    
    return rateTypes[code] || code;
  };
  
  // Format amenities for display
  const amenities = hotel.amenities || [];
  
  // Group amenities by category
  const amenityCategories = {
    essential: ['WIFI', 'PARKING', 'AIR_CONDITIONING', 'TELEVISION', 'SAFE'],
    dining: ['RESTAURANT', 'BREAKFAST', 'ROOM_SERVICE', 'BAR', 'KITCHEN', 'MINIBAR'],
    wellness: ['POOL', 'FITNESS_CENTER', 'SPA', 'HOT_TUB', 'SAUNA'],
    business: ['BUSINESS_CENTER', 'MEETING_ROOMS', 'CONFERENCE_FACILITIES'],
    family: ['CHILDCARE', 'KIDS_CLUB', 'PLAYGROUND', 'CRIBS'],
    accessibility: ['ACCESSIBLE_FACILITIES', 'WHEELCHAIR_ACCESSIBLE', 'ACCESSIBLE_BATHROOM'],
    other: [] as string[]
  };
  
  // Categorize amenities
  const categorizedAmenities = (amenities || []).reduce((acc, amenity) => {
    let placed = false;
    for (const [category, values] of Object.entries(amenityCategories)) {
      if (values.includes(amenity)) {
        if (!acc[category]) acc[category] = [];
        acc[category].push(amenity);
        placed = true;
        break;
      }
    }
    
    if (!placed) {
      if (!acc.other) acc.other = [];
      acc.other.push(amenity);
    }
    
    return acc;
  }, {} as Record<string, string[]>);
  
  // Room information
  const roomInfo = (selectedOffer || firstOffer)?.room;
  const bedOptions = hotel.roomTypes || [];
  
  // Format description
  const description = hotel.description?.text || 'Detailed description currently unavailable. Please contact the hotel for more information.';
  
  // Policies
  const paymentType = (selectedOffer || firstOffer)?.policies?.paymentType;
  const cancellationPolicy = (selectedOffer || firstOffer)?.policies?.cancellation;
  
  // Check-in/check-out times
  const checkInTime = hotel.policies?.checkIn?.time || 'Not specified';
  const checkOutTime = hotel.policies?.checkOut?.time || 'Not specified';

  // Calculate stay duration if dates available
  const stayDuration = (() => {
    if (checkInDate && checkOutDate) {
      const checkIn = new Date(checkInDate);
      const checkOut = new Date(checkOutDate);
      const diffTime = Math.abs(checkOut.getTime() - checkIn.getTime());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    }
    return null;
  })();

  // Handle opening the booking form
  const handleBookNow = () => {
    if (!selectedOffer) {
      // Maybe prompt user to select an offer first?
      // For now, just don't open the form.
      // Find the room options section and scroll to it
      const roomOptionsElement = document.getElementById('room-options-section');
      if (roomOptionsElement) {
        roomOptionsElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      return;
    }
    setShowBookingForm(true);
  };

  // Handle booking form submission
  const handleBookingSubmit = async (bookingData: BookingFormData) => {
    if (!offers?.[0] || !selectedOffer) return; // Ensure selectedOffer exists
    
    setIsSubmitting(true);
    try {
      const bookingOffers = { ...offers[0], offers: [selectedOffer] };
      const bookingResponse = await createHotelBooking(bookingOffers, bookingData);
      
      if (bookingResponse.success) {
        setBooking(bookingResponse);
        setShowBookingForm(false);
      } else {
        alert(`Booking failed: ${bookingResponse.message || 'Unknown error'}`);
      }
    } catch (error) {
      console.error('Error submitting booking:', error);
      alert('An error occurred while processing your booking. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle closing the booking process entirely
  const handleCloseBooking = () => {
    setShowBookingForm(false);
    setBooking(null);
    // Don't automatically close the detail view on booking close
    // onClose(); 
  };

  // If booking is confirmed, show confirmation screen instead
  if (booking) {
    return <BookingConfirmation booking={booking} onClose={handleCloseBooking} />;
  }

  // If booking form is showing, render the booking form
  if (showBookingForm && selectedOffer) { // Ensure selectedOffer exists before rendering form
    // Need to adjust HotelBookingForm to accept a single offer, not the whole HotelOfferSearch structure
    // For now, we'll pass the structure but ideally refactor HotelBookingForm later
    const bookingOfferStructure = { ...offers![0], offers: [selectedOffer] };
    return (
      <HotelBookingForm 
        hotel={hotel} 
        offer={bookingOfferStructure} // Pass the structure containing the selected offer
        onClose={() => setShowBookingForm(false)}
        onSubmit={handleBookingSubmit}
        isSubmitting={isSubmitting}
      />
    );
  }
  
  return (
    <div className="fixed inset-0 bg-black/90 backdrop-blur-lg z-50 overflow-y-auto font-sans">
      <div className="flex min-h-full items-start sm:items-center justify-center p-0 sm:p-4">
        {/* Main Modal Container */}
        <div className={`relative ${theme.bgColor} rounded-none sm:rounded-xl overflow-hidden max-h-[100vh] sm:max-h-[95vh] w-full max-w-4xl border-0 sm:border ${theme.borderColor}`}>
          
          {/* Close Button (Top Right) */}
          <button 
            onClick={onClose}
            className={`absolute top-2 right-2 z-30 bg-black/50 hover:bg-black/80 p-2 rounded-full text-gray-300 hover:text-white transition-colors`}
            aria-label="Close hotel details"
          >
            <ArrowLeft className="h-5 w-5" />
          </button>

          {/* Scrollable Content Area */}
          <div className="max-h-[calc(100vh-70px)] sm:max-h-[calc(95vh-70px)] overflow-y-auto pb-24">
            
            {/* NEW: Prominent Header Section */}
            <div className={`relative p-6 pt-12 sm:p-8 ${theme.headerBg} border-b ${theme.borderColor} text-center sm:text-left`}>
              {/* Background subtle pattern */}
              <div className="absolute inset-0 opacity-[0.03] bg-[url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIj48cmVjdCB3aWR0aD0iMSIgaGVpZHRoPSIxIiBmaWxsPSIjZmZmIiB4PSI1IiB5PSI1Ij48L3JlY3Q+PC9zdmc+')]"></div>
              
              {/* Hotel Name - Large & Creative */}
              <div className="flex items-center">
                <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-white via-gray-300 to-gray-400 mb-2 leading-tight">
                  {hotel.name}
                </h1>
              </div>
              
              {/* Location & Rating */}
              <div className="flex flex-wrap items-center justify-center sm:justify-start text-sm sm:text-base text-gray-400 mt-2 gap-2">
                <div className="flex items-center">
                  <MapPin className="h-4 w-4 mr-1.5 flex-shrink-0 text-gray-500" />
                  <span>{hotel.address?.cityName}, {hotel.address?.countryCode}</span>
                </div>
                {hotel.rating && (
                  <div className="flex items-center">
                    <span className="mx-2 text-gray-600 hidden sm:inline">|</span>
                    <Star className="h-4 w-4 mr-1 text-amber-400 fill-current" />
                    <span>{hotel.rating.toFixed(1)} Rating</span>
                  </div>
                )}
                {hotel.chainCode && (
                  <div className="flex items-center">
                    <span className="mx-2 text-gray-600 hidden sm:inline">|</span>
                    <Hotel className="h-4 w-4 mr-1 text-gray-500" />
                    <span>{hotel.chainCode}</span>
                  </div>
                )}
              </div>
            </div>

            {/* Key Info Bar (Optional - can be merged or removed if redundant) */}
            {/* <div className={`flex flex-wrap gap-x-4 gap-y-2 p-3 sm:p-4 border-b ${theme.borderColor} bg-black/30 text-sm`}> ... </div> */}

            {/* Main Content Sections */}
            <div className="p-4 sm:p-6 space-y-8">
              
              {/* Description Section */}
              <div>
                <h3 className="text-xl font-semibold text-white mb-3 flex items-center">
                  <Info className={`w-5 h-5 mr-2 ${theme.accentColor}`} /> About this hotel
                </h3>
                <p className="text-gray-300 text-sm leading-relaxed">{description}</p>
              </div>

              {/* Room Options Section */}
              <div id="room-options-section">
                <h3 className="text-xl font-semibold text-white mb-4 flex items-center">
                  <BedDouble className={`w-5 h-5 mr-2 ${theme.accentColor}`} /> Select Your Room & Rate
                </h3>
                {sortedRoomTypes.length > 0 ? (
                  <div className="space-y-5">
                    {sortedRoomTypes.map(roomType => (
                      <div key={roomType} className={`${theme.cardBg} rounded-lg border ${theme.borderColor} overflow-hidden shadow-lg`}>
                        <h4 className={`text-white font-semibold text-base p-3 bg-black/30 border-b ${theme.borderColor} flex justify-between items-center`}>
                          <span>{roomType.replace(/_/g, ' ')}</span>
                          <button 
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent selecting the offer
                              setExpandedRoomType(expandedRoomType === roomType ? null : roomType);
                            }}
                            className="text-xs bg-gray-700 hover:bg-gray-600 px-2 py-1 rounded text-gray-200"
                          >
                            {expandedRoomType === roomType ? 'Hide details' : 'Room details'}
                          </button>
                        </h4>
                        
                        {/* Room type expanded details section */}
                        {expandedRoomType === roomType && (
                          <div className="p-3 bg-black/20 border-b border-gray-700 text-xs text-gray-300">
                            <p className="mb-2">Select from these available rate options for <span className="text-white font-medium">{roomType.replace(/_/g, ' ')}</span>:</p>
                            
                            {/* Show common room features if available */}
                            {offersByRoomType[roomType][0]?.room?.type && (
                              <div className="mb-1">
                                <span className="text-gray-400">Room code:</span> {offersByRoomType[roomType][0].room.type}
                              </div>
                            )}
                            
                            {offersByRoomType[roomType][0]?.room?.typeEstimated?.beds && (
                              <div className="mb-1 flex items-center">
                                <BedDouble className={`w-3.5 h-3.5 mr-1 ${theme.accentColor}`} />
                                <span className="text-gray-400">Beds:</span> {offersByRoomType[roomType][0].room.typeEstimated.beds} {offersByRoomType[roomType][0].room.typeEstimated.bedType?.toLowerCase() || 'bed(s)'}
                              </div>
                            )}
                            
                            {/* Show board types available for this room type if any */}
                            {offersByRoomType[roomType].some(o => Boolean((o as ExtendedOfferItem).boardType)) && (
                              <div className="mb-1">
                                <span className="text-gray-400">Meal plans available:</span>{' '}
                                {Array.from(new Set(offersByRoomType[roomType]
                                  .filter(o => Boolean((o as ExtendedOfferItem).boardType))
                                  .map(o => (o as ExtendedOfferItem).boardType)
                                )).map(boardType => boardType?.replace(/_/g, ' ')).join(', ')}
                              </div>
                            )}
                            
                            <div className="mt-3 text-white text-sm">Available Rate Options:</div>
                            <div className="mt-1 grid grid-cols-2 gap-2">
                              {Array.from(new Set(offersByRoomType[roomType].map(o => o.rateFamilyEstimated?.code))).map(code => (
                                code && (
                                  <div key={code} className="bg-black/30 px-2 py-1 rounded">
                                    {getOfferRateType(code)}
                                  </div>
                                )
                              ))}
                            </div>
                          </div>
                        )}
                        
                        <div className="divide-y divide-gray-700/50">
                          {offersByRoomType[roomType].map(offer => (
                            <div 
                              key={offer.id} 
                              onClick={() => setSelectedOffer(offer)}
                              className={`${
                                selectedOffer?.id === offer.id 
                                  ? 'bg-gray-700/80 ring-2 ring-primary/70' 
                                  : 'bg-transparent hover:bg-gray-700/40 cursor-pointer'
                              } p-4 transition-all relative`}
                            >
                              <div className="flex flex-col sm:flex-row justify-between sm:items-start gap-3">
                                <div className="flex-grow">
                                  <div className="mb-2">
                                    {offer.rateFamilyEstimated?.code && (
                                      <span className="inline-block mb-1 px-2 py-0.5 bg-gray-600/50 rounded text-xs font-medium text-gray-200 mr-2">
                                        {getOfferRateType(offer.rateFamilyEstimated.code)}
                                      </span>
                                    )}
                                    <p className="text-sm text-white inline">
                                      {offer.room?.description?.text || 'Standard room offer'} 
                                    </p>
                                  </div>
                                  <div className="flex flex-wrap items-center gap-x-4 gap-y-1 text-xs text-gray-400 mb-2">
                                    {offer.room?.typeEstimated?.beds && (
                                      <span className="flex items-center whitespace-nowrap"><BedDouble className={`w-3.5 h-3.5 mr-1 ${theme.accentColor}`} />{offer.room.typeEstimated.beds} {offer.room.typeEstimated.bedType?.toLowerCase() || 'bed(s)'}</span>
                                    )}
                                    {offer.guests?.adults && (
                                      <span className="flex items-center whitespace-nowrap"><Users className={`w-3.5 h-3.5 mr-1 ${theme.accentColor}`} />{offer.guests.adults} {offer.guests.adults === 1 ? 'Guest' : 'Guests'}</span>
                                    )}
                                    {/* Safely check for boardType if present (from additional API data) */}
                                    {Boolean((offer as ExtendedOfferItem).boardType) && ( 
                                      <span className="flex items-center whitespace-nowrap">
                                        <Utensils className={`w-3.5 h-3.5 mr-1 ${theme.accentColor}`} />
                                        {(offer as ExtendedOfferItem).boardType?.replace(/_/g, ' ')}
                                      </span>
                                    )}
                                  </div>
                                  {offer.policies?.cancellation && (
                                    <div className="text-xs text-gray-400 flex items-center">
                                      <Calendar className={`w-3.5 h-3.5 mr-1 ${theme.accentColor}`} />
                                      {offer.policies.cancellation.deadline ? 
                                        <span className="text-emerald-400">Free cancellation until {formatCancellationDate(offer.policies.cancellation.deadline)}</span> : 
                                        'Cancellation policy applies'}
                                    </div>
                                  )}
                                </div>
                                <div className="text-left sm:text-right flex-shrink-0 mt-2 sm:mt-0">
                                  <div className={`text-xl font-bold ${theme.accentColor}`}>{offer.price.currency} {offer.price.total}</div>
                                  <div className="text-xs text-gray-400">
                                    {stayDuration ? `Total for ${stayDuration} nights` : 'per night'}
                                    {offer.price.base !== offer.price.total && ` (Base ${offer.price.base})`}
                                  </div>
                                  
                                  {/* Price Variations - Show for multi-night stays */}
                                  {stayDuration && stayDuration > 1 && (offer.price.variations?.changes?.length || 0) > 0 && (
                                    <div className="mt-2 text-xs text-gray-400">
                                      <button 
                                        onClick={(e) => {
                                          e.stopPropagation(); // Prevent selecting the offer when clicking this
                                          const el = e.currentTarget.nextElementSibling;
                                          if (el) {
                                            el.classList.toggle('hidden');
                                          }
                                        }}
                                        className="text-xs underline hover:text-white"
                                      >
                                        Show price breakdown
                                      </button>
                                      <div className="hidden mt-1 bg-black/30 p-2 rounded text-left">
                                        <p className="mb-1 text-gray-300">Price per night:</p>
                                        <ul className="space-y-1">
                                          {offer.price.variations?.changes?.map((change, idx) => (
                                            <li key={idx} className="flex justify-between">
                                              <span>{format(new Date(change.startDate), 'MMM dd')}</span>
                                              <span>{offer.price.currency} {change.base}</span>
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                              {selectedOffer?.id === offer.id && (
                                <div className={`absolute top-0 left-0 h-full w-1.5 bg-primary`}></div>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className={`${theme.cardBg} rounded-lg border ${theme.borderColor} p-4 text-center text-gray-400`}>
                    No specific room offers found for the selected dates. Prices may be available directly from the hotel.
                  </div>
                )}
              </div>

              {/* Amenities Section */}
              <div>
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-xl font-semibold text-white">Amenities</h3>
                  {amenities.length > 8 && (
                    <button 
                      onClick={() => setShowAllAmenities(prev => !prev)}
                      className={`${theme.accentColor} text-sm hover:underline font-medium`}
                    >
                      {showAllAmenities ? 'Show Fewer' : 'Show All'}
                    </button>
                  )}
                </div>
                <div className={`grid grid-cols-2 sm:grid-cols-3 gap-x-6 gap-y-3 text-sm text-gray-300`}>
                  {(showAllAmenities ? amenities : amenities.slice(0, 8)).map((amenity, index) => (
                    <div key={index} className="flex items-center">
                      <span className={`mr-2.5 ${theme.accentColor}`}>{amenityToIcon[amenity as keyof typeof amenityToIcon] || <Check className="w-4 h-4" />}</span>
                      {amenity.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, l => l.toUpperCase())}
                    </div>
                  ))}
                </div>
              </div>

              {/* Policies & Contact Section */}
              <div>
                <h3 className="text-xl font-semibold text-white mb-4">Details & Policies</h3>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 text-sm">
                  <div className={`${theme.cardBg} rounded-lg p-4 border ${theme.borderColor}`}>
                    <h4 className="font-semibold text-white text-sm mb-1.5 flex items-center">
                      <Clock className={`w-4 h-4 ${theme.accentColor} mr-1.5`} /> Check-in / Check-out
                    </h4>
                    <p className="text-xs text-gray-300">In: from {checkInTime} / Out: until {checkOutTime}</p>
                  </div>
                  {paymentType && (
                    <div className={`${theme.cardBg} rounded-lg p-4 border ${theme.borderColor}`}>
                      <h4 className="font-semibold text-white text-sm mb-1.5 flex items-center">
                        <CreditCard className={`w-4 h-4 ${theme.accentColor} mr-1.5`} /> Payment
                      </h4>
                      <p className="text-xs text-gray-300">
                        {paymentType === 'GUARANTEE' ? 'Pay at hotel' : paymentType === 'DEPOSIT' ? 'Deposit required' : 'Contact for details'}
                      </p>
                    </div>
                  )}
                  {(selectedOffer?.policies?.cancellation || cancellationPolicy) && (
                    <div className={`${theme.cardBg} rounded-lg p-4 border ${theme.borderColor}`}>
                      <h4 className="font-semibold text-white text-sm mb-1.5 flex items-center">
                        <Calendar className={`w-4 h-4 ${theme.accentColor} mr-1.5`} /> Cancellation
                      </h4>
                      <p className="text-xs text-gray-300">
                        {selectedOffer?.policies?.cancellation?.description?.text || 
                         (selectedOffer?.policies?.cancellation?.deadline ? 
                          `Free cancel until ${formatCancellationDate(selectedOffer.policies.cancellation.deadline)}` : 
                          (cancellationPolicy?.description?.text || 
                          (cancellationPolicy?.deadline ? `Policy applies, check deadline` : 'Policy applies')))}
                      </p>
                      
                      {/* Show cancellation fees if available */}
                      {(selectedOffer?.policies?.cancellation?.amount || selectedOffer?.policies?.cancellation?.percentage) && (
                        <div className="mt-2 text-xs">
                          {selectedOffer.policies.cancellation.amount && (
                            <p className="text-red-400">Cancellation fee: {selectedOffer.price.currency} {selectedOffer.policies.cancellation.amount}</p>
                          )}
                          {selectedOffer.policies.cancellation.percentage && (
                            <p className="text-red-400">Cancellation fee: {selectedOffer.policies.cancellation.percentage}% of total</p>
                          )}
                          {selectedOffer.policies.cancellation.type === 'FULL_STAY' && (
                            <p className="text-red-400 mt-1">Full stay amount will be charged for cancellation</p>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  
                  {/* Payment Methods */}
                  {selectedOffer?.policies?.guarantee?.acceptedPayments && (
                    <div className={`${theme.cardBg} rounded-lg p-4 border ${theme.borderColor}`}>
                      <h4 className="font-semibold text-white text-sm mb-1.5 flex items-center">
                        <CreditCard className={`w-4 h-4 ${theme.accentColor} mr-1.5`} /> Payment Methods
                      </h4>
                      <div className="text-xs text-gray-300">
                        {selectedOffer.policies.guarantee.acceptedPayments.creditCards && 
                         selectedOffer.policies.guarantee.acceptedPayments.creditCards.length > 0 && (
                          <div className="mt-1">
                            <span className="text-white">Cards accepted:</span> {selectedOffer.policies.guarantee.acceptedPayments.creditCards.join(', ')}
                          </div>
                        )}
                        {selectedOffer.policies.guarantee.acceptedPayments.methods && 
                         selectedOffer.policies.guarantee.acceptedPayments.methods.length > 0 && (
                          <div className="mt-1">
                            <span className="text-white">Payment methods:</span> {selectedOffer.policies.guarantee.acceptedPayments.methods.join(', ')}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {/* Deposit Requirements */}
                  {(selectedOffer as ExtendedOfferItem)?.policies?.deposit && (
                    <div className={`${theme.cardBg} rounded-lg p-4 border ${theme.borderColor}`}>
                      <h4 className="font-semibold text-white text-sm mb-1.5 flex items-center">
                        <CreditCard className={`w-4 h-4 ${theme.accentColor} mr-1.5`} /> Deposit Required
                      </h4>
                      <div className="text-xs text-gray-300">
                        {(selectedOffer as ExtendedOfferItem).policies?.deposit?.amount && (
                          <p className="mb-1">Amount: {selectedOffer?.price.currency || 'USD'} {(selectedOffer as ExtendedOfferItem).policies?.deposit?.amount}</p>
                        )}
                        {(selectedOffer as ExtendedOfferItem).policies?.deposit?.deadline && (
                          <p className="mb-1">Due by: {formatCancellationDate((selectedOffer as ExtendedOfferItem).policies?.deposit?.deadline || '')}</p>
                        )}
                        {(selectedOffer as ExtendedOfferItem).policies?.deposit?.description?.text && (
                          <p>{(selectedOffer as ExtendedOfferItem).policies?.deposit?.description?.text}</p>
                        )}
                      </div>
                    </div>
                  )}
                  {hotel.contact && (
                    <div className={`${theme.cardBg} rounded-lg p-4 border ${theme.borderColor} sm:col-span-2`}>
                      <h4 className="font-semibold text-white text-sm mb-2 flex items-center">
                        <Phone className={`w-4 h-4 ${theme.accentColor} mr-1.5`} /> Contact Hotel
                      </h4>
                      <div className="flex flex-wrap gap-x-5 gap-y-1 text-xs text-gray-300">
                        {hotel.contact.phone && <span className="flex items-center"><Phone className="w-3 h-3 mr-1"/>{hotel.contact.phone}</span>}
                        {hotel.contact.email && <span className="flex items-center"><Mail className="w-3 h-3 mr-1"/>{hotel.contact.email}</span>}
                        {hotel.contact.website && <a href={hotel.contact.website} target="_blank" rel="noopener noreferrer" className="flex items-center hover:text-white underline"><Globe className="w-3 h-3 mr-1"/>Website</a>}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              
            </div>
          </div>

          {/* Sticky Footer / Booking Action Bar */}
          <div className={`absolute bottom-0 left-0 right-0 p-3 sm:p-4 border-t ${theme.borderColor} bg-black/80 backdrop-blur-sm z-20 shadow-inner`}>
            <div className="flex items-center justify-between gap-4 max-w-4xl mx-auto">
              <div className="flex-grow overflow-hidden">
                {selectedOffer ? (
                  <>
                    <div className={`text-lg sm:text-xl font-bold ${theme.accentColor}`}>{selectedOffer.price.currency} {selectedOffer.price.total}</div>
                    <div className="text-xs text-gray-400 truncate">
                      Selected: {selectedOffer.room?.typeEstimated?.category?.replace(/_/g, ' ') || 'Room'} 
                      {stayDuration ? ` (${stayDuration} nights)` : ''}
                    </div>
                  </>
                ) : (
                  <div className="text-sm text-gray-300 font-medium">Please select a room & rate</div>
                )}
              </div>
              <button
                onClick={handleBookNow}
                disabled={!selectedOffer || isSubmitting}
                className="bg-primary hover:bg-primary/90 text-black font-semibold px-5 sm:px-6 py-3 rounded-lg transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex-shrink-0 text-sm sm:text-base"
              >
                {isSubmitting ? (
                  <Loader2 className="animate-spin h-5 w-5" />
                ) : (
                  'Continue to Book'
                )}
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
} 