import { AmadeusService, AmadeusActivity } from '../../../services/amadeus';
import { City, Tour, TourSearchParams, POPULAR_CITIES, MALDIVES_ISLANDS } from '../types';
import { searchLocations as mapboxSearchLocations, searchWithMapboxSearchBox } from '../../../utils/geocoding';

// Initialize the Amadeus service with the same credentials used in other components
const amadeusService = new AmadeusService(
  "zyQPNk4iZeGyOAlPoT6dArzsESJi1vOo",  // Production API Key
  "3SwjJ7dCPwxUMHoz",  // Production API Secret
  true  // Set to true for production
);

export interface SearchToursParams {
  city: City;
  startDate?: string;
  endDate?: string;
  participants?: number;
  filters?: {
    priceRange?: {
      min?: number;
      max?: number;
    };
    ratings?: number[];
    duration?: string[];
    categories?: string[];
  };
  page?: number;
  pageSize?: number;
  useSquareSearch?: boolean; // New parameter to control search method
  squareSize?: number; // Size of the square in km (default will be set in the function)
}

interface SearchToursResult {
  tours: Tour[];
  totalCount: number;
}

/**
 * Convert Amadeus activities to the Tour format
 */
function activitiesToTours(activities: AmadeusActivity[], cityName: string, countryName: string): Tour[] {
  return activities.map(activity => {
    // Parse duration from description or minimumDuration if available
    let durationText = activity.minimumDuration || '2-3 hours';
    
    // Extract categories based on activity type and description
    const categories = extractCategories(activity.shortDescription || '');
    
    // Extract highlights from description
    const highlights = extractHighlights(activity.description || '');
    
    return {
      id: activity.id,
      name: activity.name,
      description: activity.description || activity.shortDescription || '',
      shortDescription: activity.shortDescription,
      duration: durationText,
      price: {
        amount: activity.price?.amount || '0',
        currencyCode: activity.price?.currencyCode || 'USD'
      },
      rating: activity.rating ? parseFloat(activity.rating) : undefined,
      reviewCount: Math.floor(Math.random() * 100) + 5, // Placeholder
      location: {
        city: cityName,
        country: countryName,
        latitude: parseFloat(activity.geoCode.latitude),
        longitude: parseFloat(activity.geoCode.longitude)
      },
      highlights: highlights,
      images: activity.pictures || [
        // Default image if no images are provided
        'https://images.unsplash.com/photo-1568849676085-51415703900f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1173&q=80'
      ],
      bookingLink: activity.bookingLink,
      categories: categories,
      languages: ['EN'], // Default language
      minimumParticipants: 1,
      maximumParticipants: 20,
    };
  });
}

/**
 * Extract categories from description text using keywords
 */
function extractCategories(description: string): string[] {
  const categories = [];
  const lowerDesc = description.toLowerCase();
  
  if (lowerDesc.includes('culture') || lowerDesc.includes('museum') || lowerDesc.includes('art') || lowerDesc.includes('histor')) 
    categories.push('CULTURAL');
  
  if (lowerDesc.includes('hike') || lowerDesc.includes('trek') || lowerDesc.includes('climb') || lowerDesc.includes('adventure'))
    categories.push('ADVENTURE');
  
  if (lowerDesc.includes('nature') || lowerDesc.includes('wildlife') || lowerDesc.includes('animal') || lowerDesc.includes('park'))
    categories.push('NATURE');
  
  if (lowerDesc.includes('food') || lowerDesc.includes('culinary') || lowerDesc.includes('tasting') || lowerDesc.includes('cook'))
    categories.push('FOOD');
  
  if (lowerDesc.includes('histor') || lowerDesc.includes('ancient') || lowerDesc.includes('heritage') || lowerDesc.includes('monument'))
    categories.push('HISTORICAL');
  
  if (lowerDesc.includes('sight') || lowerDesc.includes('landmark') || lowerDesc.includes('tour') || lowerDesc.includes('explore'))
    categories.push('SIGHTSEEING');
  
  if (lowerDesc.includes('photo') || lowerDesc.includes('camera') || lowerDesc.includes('picture'))
    categories.push('PHOTOGRAPHY');
  
  if (lowerDesc.includes('private') || lowerDesc.includes('exclusive') || lowerDesc.includes('vip'))
    categories.push('PRIVATE');
  
  // Add at least one category if none were matched
  if (categories.length === 0) {
    categories.push('SIGHTSEEING');
  }
  
  return categories;
}

/**
 * Extract highlights from description text
 */
function extractHighlights(description: string): string[] {
  // Try to find bullet points in the description
  const bulletPointMatches = description.match(/<li>(.*?)<\/li>/g);
  if (bulletPointMatches) {
    return bulletPointMatches
      .map(match => match.replace(/<\/?li>/g, '').trim())
      .filter(highlight => highlight.length > 0)
      .slice(0, 5);
  }
  
  // If no bullet points, try to create highlights from sentences
  const sentences = description.split(/[.!?]+/).filter(s => s.trim().length > 15 && s.trim().length < 100);
  return sentences.slice(0, Math.min(5, sentences.length));
}

/**
 * Build filter parameters for the Amadeus API based on our application filters
 */
function buildApiFilters(filters?: SearchToursParams['filters']) {
  if (!filters) return {};
  
  const apiFilters: any = {};
  
  // Map price range
  if (filters.priceRange) {
    apiFilters.priceRange = filters.priceRange;
  }
  
  // Map ratings (take the lowest acceptable rating)
  if (filters.ratings && filters.ratings.length > 0) {
    // Sort ratings and take the lowest as minimum acceptable rating
    const sortedRatings = [...filters.ratings].sort();
    apiFilters.rating = sortedRatings[0];
  }
  
  return apiFilters;
}

/**
 * Create a bounding box around a coordinate point
 * @param latitude Central latitude
 * @param longitude Central longitude
 * @param sizeInKm Size of the area in kilometers (approximate)
 * @returns Bounding box coordinates
 */
function createBoundingBox(latitude: number, longitude: number, sizeInKm: number = 10) {
  // Approximate conversion: 1 degree ≈ 111 km
  const degreeChange = sizeInKm / 111;
  
  return {
    north: latitude + degreeChange,
    south: latitude - degreeChange,
    east: longitude + degreeChange,
    west: longitude - degreeChange
  };
}

/**
 * Search for tours based on the provided parameters
 */
export async function searchTours({
  city,
  startDate,
  endDate,
  participants = 1,
  filters,
  page = 1,
  pageSize = 9,
  useSquareSearch = true, // Default to square search as it's more effective
  squareSize = 20 // Default to 20km square area
}: SearchToursParams): Promise<SearchToursResult> {
  try {
    // Build filter parameters for the API
    const apiFilters = buildApiFilters(filters);
    
    // Get latitude and longitude from city
    const { latitude, longitude } = city;
    
    console.log(`Searching for tours in ${city.name} (${latitude}, ${longitude})`);
    
    let response;
    
    // Determine which search method to use
    if (useSquareSearch) {
      // Calculate bounding box for the area search
      const boundingBox = createBoundingBox(latitude, longitude, squareSize);
      
      console.log(`Using square search with bounds: N:${boundingBox.north}, S:${boundingBox.south}, E:${boundingBox.east}, W:${boundingBox.west}`);
      
      // Use square-based search
      response = await amadeusService.getActivitiesBySquare({
        north: boundingBox.north,
        south: boundingBox.south,
        east: boundingBox.east,
        west: boundingBox.west,
        pagination: {
          page,
          limit: pageSize
        },
        filters: apiFilters
      });
    } else {
      // Fallback to traditional radius search if specifically requested
      response = await amadeusService.getActivitiesByLocation(
        latitude,
        longitude,
        {
          pagination: {
            page,
            limit: pageSize
          },
          radius: 20, // 20km radius
          ...apiFilters
        }
      );
    }
    
    // Convert API response to Tour format
    const tours = activitiesToTours(response.data || [], city.name, city.country);
    
    // Further filtering on client side if needed
    let filteredTours = tours;
    
    // Filter by categories if specified
    if (filters?.categories && filters.categories.length > 0) {
      filteredTours = filteredTours.filter(tour => {
        // Check if any of the tour's categories match the filter categories
        return tour.categories?.some(category => 
          filters.categories?.includes(category)
        );
      });
    }
    
    // Filter by duration if specified
    if (filters?.duration && filters.duration.length > 0) {
      filteredTours = filteredTours.filter(tour => {
        const duration = tour.duration.toLowerCase();
        
        // Match duration categories based on the tour's duration text
        return filters.duration?.some(durationType => {
          switch(durationType) {
            case 'HALF_DAY':
              return duration.includes('hour') && !duration.includes('day');
            case 'FULL_DAY':
              return duration.includes('day') && !duration.includes('days');
            case 'MULTI_DAY_SHORT':
              return duration.includes('2 day') || duration.includes('3 day');
            case 'MULTI_DAY_MEDIUM':
              return /[4-7]\s*days/.test(duration);
            case 'MULTI_DAY_LONG':
              return /[8-9]\s*days/.test(duration) || duration.includes('week');
            default:
              return false;
          }
        });
      });
    }
    
    return {
      tours: filteredTours,
      totalCount: response.meta.count || filteredTours.length
    };
  } catch (error) {
    console.error('Error searching tours:', error);
    throw new Error('Failed to search for tours. Please try again later.');
  }
}

/**
 * Get a specific tour by ID
 */
export async function getTourById(tourId: string): Promise<Tour | null> {
  try {
    const activity = await amadeusService.getActivityById(tourId);
    
    if (!activity) {
      return null;
    }
    
    // Convert to Tour format (assuming activity location has city and country)
    const tours = activitiesToTours([activity], 'Unknown City', 'Unknown Country');
    return tours[0];
  } catch (error) {
    console.error('Error getting tour details:', error);
    return null;
  }
}

/**
 * Search for cities that match the query
 */
export async function searchCities(query: string): Promise<City[]> {
  try {
    console.log(`[City Search] Beginning search for "${query}"`);
    
    // First, check if the query matches any of our custom Maldives islands
    const normalizedQuery = query.toLowerCase().trim();
    
    // Priority check for Maldives islands since they're problematic with the API
    const maldivesMatches = MALDIVES_ISLANDS.filter((city: City) => 
      city.name.toLowerCase().includes(normalizedQuery) || 
      city.country.toLowerCase().includes(normalizedQuery)
    );
    
    if (maldivesMatches.length > 0) {
      console.log('[City Search] Found matching Maldives islands in custom database:', maldivesMatches);
      return maldivesMatches;
    }
    
    // Try Mapbox Search Box API (new approach) - the best for interactive search
    try {
      console.log('[City Search] Searching using Mapbox Search Box API...');
      
      // Determine if the search is related to Maldives
      const isMaldivesSearch = normalizedQuery.includes('maldiv') || 
        ['maafushi', 'male', 'hulhumale', 'atoll'].some(term => normalizedQuery.includes(term));
      
      // Use Mapbox Search Box with appropriate options
      const mapboxResults = await searchWithMapboxSearchBox(query, {
        limit: 5,
        // For Maldives-related searches, set Maldives as country and proximity
        ...(isMaldivesSearch ? { 
          country: 'MV',
          proximity: [73.5, 3.9] // Central Maldives coordinates
        } : {})
      });
      
      if (mapboxResults.length > 0) {
        console.log('[City Search] Found locations with Mapbox Search Box:', mapboxResults);
        
        // Filter out results with missing coordinates
        const validResults = mapboxResults.filter(
          loc => typeof loc.latitude === 'number' && typeof loc.longitude === 'number'
        );
        
        // Convert to City format
        const mappedResults = validResults.map(location => ({
          name: location.name,
          country: location.country,
          latitude: location.latitude,
          longitude: location.longitude,
        }));
        
        console.log('[City Search] Converted Mapbox results to City format:', mappedResults);
        
        if (mappedResults.length > 0) {
          return mappedResults;
        } else {
          console.log('[City Search] No valid results from Mapbox Search Box');
        }
      } else {
        console.log('[City Search] No results found with Mapbox Search Box');
      }
    } catch (searchBoxError) {
      console.error('[City Search] Mapbox Search Box error:', searchBoxError);
      // Continue to fallback options
    }
    
    // Try legacy Mapbox Geocoding API if Search Box failed
    try {
      console.log('[City Search] Falling back to Mapbox Geocoding...');
      const mapboxResults = await mapboxSearchLocations(query);
      
      if (mapboxResults.length > 0) {
        console.log('[City Search] Found locations with Mapbox Geocoding:', mapboxResults);
        
        // Convert to City format
        const mappedResults = mapboxResults.map(location => ({
          name: location.name,
          country: location.country,
          latitude: location.latitude,
          longitude: location.longitude,
        }));
        
        console.log('[City Search] Converted Mapbox results to City format:', mappedResults);
        return mappedResults;
      } else {
        console.log('[City Search] No results found with Mapbox Geocoding');
      }
    } catch (geocodingError) {
      console.error('[City Search] Mapbox geocoding failed:', geocodingError);
      // Continue to fallback options
    }
    
    // Then try the Amadeus API as last resort
    try {
      console.log('[City Search] Trying Amadeus API search...');
      // Use Amadeus to search for cities with the correct method
      const response = await amadeusService.searchLocations(query, 'CITY', 10);
      
      // If we got results from the API, convert them to our format
      if (response.data && response.data.length > 0) {
        const amadeusResults = response.data.map((city: any) => ({
          name: city.name,
          country: city.address.countryName || city.address.countryCode,
          latitude: city.geoCode.latitude,
          longitude: city.geoCode.longitude,
          iataCode: city.iataCode
        }));
        
        console.log('[City Search] Found results with Amadeus API:', amadeusResults);
        return amadeusResults;
      } else {
        console.log('[City Search] No results found with Amadeus API');
      }
    } catch (apiError) {
      console.error('[City Search] Error with Amadeus API search:', apiError);
      // Continue to fallback options
    }
    
    // Finally, fall back to popular cities
    console.log('[City Search] Falling back to popular cities filter');
    const popularResults = POPULAR_CITIES.filter((city: City) => 
      city.name.toLowerCase().includes(normalizedQuery) || 
      city.country.toLowerCase().includes(normalizedQuery)
    );
    
    console.log('[City Search] Filtered popular cities results:', popularResults);
    return popularResults;
  } catch (error) {
    console.error('[City Search] Error searching cities:', error);
    
    // On error, check both Maldives islands and popular cities
    const normalizedQuery = query.toLowerCase().trim();
    
    const maldivesMatches = MALDIVES_ISLANDS.filter((city: City) => 
      city.name.toLowerCase().includes(normalizedQuery) || 
      city.country.toLowerCase().includes(normalizedQuery)
    );
    
    const popularMatches = POPULAR_CITIES.filter((city: City) => 
      city.name.toLowerCase().includes(normalizedQuery) || 
      city.country.toLowerCase().includes(normalizedQuery)
    );
    
    // Combine results, prioritizing Maldives islands
    const fallbackResults = [...maldivesMatches, ...popularMatches];
    console.log('[City Search] Using combined fallback results:', fallbackResults);
    return fallbackResults;
  }
} 