import { lazy } from 'react';

// Auth Components
export const AuthCallback = lazy(() => import('../pages/auth/AuthCallback'));
export const ResetPassword = lazy(() => import('../pages/auth/ResetPassword'));
export const ForgotPassword = lazy(() => import('../pages/auth/ForgotPassword'));
export const Login = lazy(() => import('../pages/auth/Login'));
export const Register = lazy(() => import('../pages/auth/Register'));

// Public Pages
export const PublicHome = lazy(() => import('../pages/PublicHome'));
export const MapPage = lazy(() => import('../pages/MapPage'));
export const DestinationsPage = lazy(() => import('../pages/DestinationsPage'));
export const DestinationDetailsPage = lazy(() => import('../pages/DestinationDetailsPage'));
export const WhyAgenTrav = lazy(() => import('../pages/WhyAgenTrav'));
export const HotelMarketing = lazy(() => import('../pages/HotelMarketing'));
export const AgentsDirectory = lazy(() => import('../pages/agentsdirectory'));
export const PrivacyPolicy = lazy(() => import('../pages/PrivacyPolicy'));
export const HotelSearchPage = lazy(() => import('../pages/HotelSearchPage'));

// Admin Components
export const AdminLogin = lazy(() => import('../pages/admin/Login'));
export const AdminDashboard = lazy(() => import('../pages/admin/Dashboard'));
export const EnhanceProfiles = lazy(() => import('../pages/admin/EnhanceProfiles'));
export const CleanData = lazy(() => import('../pages/admin/CleanData'));
export const CitiesManagement = lazy(() => import('../pages/admin/cities'));
export const PageManagementMod = lazy(() => import('../pages/admin/components/PageManagementMod'));
export const AgentHub = lazy(() => import('../pages/admin/agent-hub'));

// Expo Components
export const ExpoPage = lazy(() => import('../pages/ExpoPage'));
export const ExpoEventPage = lazy(() => import('../pages/ExpoEventPage'));

// Dynamic Pages
export const DynamicPage = lazy(() => import('../pages/DynamicPage'));
export const CityAgents = lazy(() => import('../pages/cityAgents/index'));
export const AgentProfilePage = lazy(() => import('../pages/AgentProfilePage'));

// Hotel Components
export const HotelDashboard = lazy(() => import('../pages/hotel/Dashboard'));
export const CreateHotelProfile = lazy(() => import('../pages/hotel/CreateHotelProfile'));
export const HotelProfileCompletion = lazy(() => import('../pages/hotel/HotelProfileCompletion'));
export const HotelProfileEdit = lazy(() => import('../pages/hotel/HotelProfileEdit'));

// Gallery Components
export const GalleryLayout = lazy(() => import('../pages/hotel/gallery/GalleryLayout'));
export const Photos = lazy(() => import('../pages/hotel/gallery/Photos'));
export const UploadPhotos = lazy(() => import('../pages/hotel/gallery/UploadPhotos'));
export const Collections = lazy(() => import('../pages/hotel/gallery/Collections'));
export const GallerySettings = lazy(() => import('../pages/hotel/gallery/GallerySettings'));

// Agent Management Components
export const MyAgents = lazy(() => import('../pages/hotel/agents/MyAgents'));
export const DiscoverAgents = lazy(() => import('../pages/hotel/agents/DiscoverAgents'));
export const ConnectionRequests = lazy(() => import('../pages/hotel/agents/ConnectionRequests'));
export const AgentMessages = lazy(() => import('../pages/hotel/agents/AgentMessages'));
export const AgentNetwork = lazy(() => import('../pages/hotel/agents/AgentNetwork'));

// Sitemap Components
export const Sitemap = lazy(() => import('../components/Sitemap'));
export const SitemapPage = lazy(() => import('../pages/SitemapPage'));

// Tours Pages
export const MapViewPage = lazy(() => import('../pages/tours/MapView'));

export { default as IndexNowPage } from '../pages/IndexNow';
export { default as NotFoundPage } from '../pages/NotFoundPage'; 