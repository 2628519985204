import { X } from 'lucide-react';
import { cn } from '../../lib/utils';
import type { AmadeusActivity } from '../../services/amadeus';
import { ActivityCard } from '../ActivityCard';

interface ActivityPanelProps { 
  isOpen: boolean;
  onClose: () => void;
  activities?: AmadeusActivity[];
  cityName?: string;
}

export default function ActivityPanel({ 
  isOpen, 
  onClose, 
  activities, 
  cityName 
}: ActivityPanelProps) {
  if (!activities || !cityName) return null;

  return (
    <div className={cn(
      "absolute inset-y-0 right-0 w-full sm:w-[350px] bg-black/50 backdrop-blur-lg border-l border-white/10",
      "transform transition-transform duration-300 z-10",
      isOpen ? "translate-x-0" : "translate-x-full"
    )}>
      <div className="flex items-center justify-between p-4 border-b border-white/10">
        <h3 className="font-medium text-white/90">Activities in {cityName}</h3>
        <button 
          onClick={onClose}
          className="p-1.5 hover:bg-white/5 rounded-lg transition-colors text-white/70"
          aria-label="Close panel"
        >
          <X className="w-4 h-4" />
        </button>
      </div>
      
      <div className="overflow-y-auto h-[calc(100%-60px)] scrollbar-thin scrollbar-thumb-white/10 scrollbar-track-transparent">
        <div className="p-4 space-y-4">
          {activities.map(activity => (
            <ActivityCard 
              key={activity.id}
              activity={activity}
              compact
            />
          ))}
        </div>
      </div>
    </div>
  );
} 