import React from 'react';
import { MessageContent } from '@langchain/core/messages';
import { ChatAction, Message } from '../../types';
import { ActivityContext } from '../types/chatTypes';
import { handleError } from '../../../../lib/utils/error';
import { ModelService } from './modelService';
import { HotelService } from '../features/hotelService';
import { ItineraryService } from '../features/itineraryService';
import { webSearchService } from '../webSearchService';
import { 
  getLocationContext, 
  extractItineraryPreferences, 
  determineIntent 
} from '../utils/contextUtils';

/**
 * ChatService orchestrates the chat functionality
 */
export class ChatService {
  private modelService: ModelService;
  private hotelService: HotelService;
  private itineraryService: ItineraryService;
  private activityContext?: ActivityContext;
  private dispatch: React.Dispatch<ChatAction>;

  constructor(dispatch: React.Dispatch<ChatAction>) {
    this.dispatch = dispatch;
    
    try {
      this.modelService = new ModelService();
      this.hotelService = new HotelService();
      this.itineraryService = new ItineraryService();
    } catch (error) {
      console.error("Failed to initialize ChatService:", error);
      handleError(error, "Failed to initialize ChatService");
      throw error;
    }
  }

  /**
   * Set context about the current activity or location
   */
  setActivityContext(context: ActivityContext) {
    this.activityContext = context;
  }

  /**
   * Process a user message and generate a response
   */
  async processUserMessage(userMessage: Message, currentMessages: Message[]): Promise<void> {
    const query = userMessage.content;
    const lowerQuery = query.toLowerCase();
    const historyForModel = this.modelService.formatHistoryForModel(currentMessages);
    const fullContextQuery = [...currentMessages.slice(-3).map(m => m.content), query].join('\n').toLowerCase();

    console.log(`[ChatService] Processing message: "${query}"`);

    try {
      // Check if this query would benefit from web search
      const needsWebSearch = webSearchService.shouldUseWebSearch(query);
      console.log(`[ChatService] Query needs web search: ${needsWebSearch}`);

      // Determine intent
      const intent = determineIntent(query, fullContextQuery, needsWebSearch);
      console.log(`[ChatService] Determined Intent: ${intent}`);

      // Get location context
      const locationInQuery = getLocationContext(query);
      const locationInContext = getLocationContext(currentMessages.slice(-2).map(m => m.content).join(' '));
      const contextLocation = locationInQuery || locationInContext || this.activityContext?.cityName;
      console.log(`[ChatService] Location context: Query='${locationInQuery}', History='${locationInContext}', Final='${contextLocation}'`);

      // Execute based on intent
      switch (intent) {
        case 'hotel':
          await this.handleHotelIntent(query, historyForModel, contextLocation);
          break;

        case 'activity':
          await this.handleActivityIntent(query, historyForModel, contextLocation);
          break;

        case 'itinerary':
          await this.handleItineraryIntent(query, lowerQuery, historyForModel, fullContextQuery, contextLocation);
          break;

        case 'websearch':
          await this.handleWebSearchIntent(query, historyForModel);
          break;

        case 'general':
        default:
          await this.handleGeneralIntent(query, historyForModel, needsWebSearch);
          break;
      }
    } catch (error) {
      console.error(`[ChatService] Error processing message: "${query}"`, error);
      handleError(error, `Error processing user message: ${query}`);
      this.dispatchAssistantMessage("I'm sorry, I encountered an error processing that. Please try again.");
    }
  }

  /**
   * Handle hotel-related intent
   */
  private async handleHotelIntent(query: string, historyForModel: any[], contextLocation?: string): Promise<void> {
    console.log(`[ChatService] Executing 'hotel' action.`);
    
    if (!contextLocation) {
      console.log("[ChatService] Hotel intent, but no location found. Asking for location.");
      const result = await this.modelService.query(query, historyForModel);
      this.dispatchAssistantMessage(result);
    } else {
      console.log(`[ChatService] Hotel intent with location: ${contextLocation}. Searching...`);
      const searchingMsg = `Okay, searching for hotels in ${contextLocation} now...`;
      this.dispatchAssistantMessage(searchingMsg);
      await this.searchAndDispatchHotels(contextLocation);
    }
  }

  /**
   * Handle activity-related intent
   */
  private async handleActivityIntent(query: string, historyForModel: any[], contextLocation?: string): Promise<void> {
    console.log(`[ChatService] Executing 'activity' action.`);
    
    if (!contextLocation) {
      console.log("[ChatService] Activity intent, but no location found. Asking for location.");
      const result = await this.modelService.query(query, historyForModel);
      this.dispatchAssistantMessage(result);
    } else {
      console.log(`[ChatService] Activity intent with location: ${contextLocation}. Searching... (Not implemented)`);
      const searchingMsg = `Looking for activities in ${contextLocation}...`;
      this.dispatchAssistantMessage(searchingMsg);
      // TODO: Implement actual activity search call here
      this.dispatchAssistantMessage(`(Activity search for ${contextLocation} not implemented yet)`);
    }
  }

  /**
   * Handle itinerary-related intent
   */
  private async handleItineraryIntent(
    query: string, 
    lowerQuery: string, 
    historyForModel: any[], 
    fullContextQuery: string, 
    contextLocation?: string
  ): Promise<void> {
    console.log(`[ChatService] Executing 'itinerary' action.`);
    
    if (!contextLocation) {
      console.log("[ChatService] Itinerary intent, but no location found. Asking for location.");
      const result = await this.modelService.query(query, historyForModel);
      this.dispatchAssistantMessage(result);
    } else {
      console.log(`[ChatService] Itinerary intent with location: ${contextLocation}. Generating...`);
      // Only show searching message if the intent was explicitly itinerary in the current query
      if (/\b(itinerary|plan|schedule|trip for)\b/i.test(lowerQuery)) {
        const searchingMsg = `Okay, planning an itinerary for ${contextLocation}...`;
        this.dispatchAssistantMessage(searchingMsg);
      }
      const preferences = extractItineraryPreferences(fullContextQuery);
      console.log(`[ChatService] Extracted preferences for itinerary: ${preferences.join(', ')}`);
      await this.generateAndDispatchItinerary(contextLocation, preferences);
    }
  }

  /**
   * Handle web search intent
   */
  private async handleWebSearchIntent(query: string, historyForModel: any[]): Promise<void> {
    console.log(`[ChatService] Executing 'websearch' action.`);
    
    // Let the user know we're searching
    this.dispatchAssistantMessage("Let me search for current information about that...");

    // Define searchResults 
    let searchResults: string;

    try {
      // Set searching state to true
      this.dispatch({ type: 'SET_SEARCHING', isSearching: true });

      // Perform the web search with proper error handling
      try {
        searchResults = await webSearchService.search(query);
        console.log(`[ChatService] Web search completed. Results length: ${searchResults.length}`);
      } catch (searchError) {
        console.error("[ChatService] Web search failed:", searchError);
        searchResults = "I encountered a technical issue while searching for up-to-date information. I'll answer based on my existing knowledge instead.";
      }

      try {
        // Call the LLM with the search results as context
        const result = await this.modelService.query(
          `${query}\n\nIncorporate this web search information: ${searchResults}`,
          historyForModel
        );

        // Reset searching state
        this.dispatch({ type: 'SET_SEARCHING', isSearching: false });

        console.log("[ChatService] Generated response with web search results");
        this.dispatchAssistantMessage(result);
      } catch (llmError) {
        console.error("[ChatService] Error calling LLM with search results:", llmError);

        // Reset searching state
        this.dispatch({ type: 'SET_SEARCHING', isSearching: false });

        // Send a fallback response
        this.dispatchAssistantMessage(
          "I found some information online, but had trouble processing it. Let me answer based on what I know: " +
          "I recommend checking official travel websites, guidebooks, or local tourism offices for the most current information."
        );
      }
    } catch (error) {
      // Reset searching state on error
      this.dispatch({ type: 'SET_SEARCHING', isSearching: false });

      console.error("[ChatService] Critical error during web search flow", error);

      // Fall back to regular response
      try {
        const result = await this.modelService.query(query, historyForModel);
        this.dispatchAssistantMessage(result);
      } catch (fallbackError) {
        console.error("[ChatService] Even fallback response failed:", fallbackError);
        this.dispatchAssistantMessage(
          "I apologize, but I'm having technical difficulties at the moment. " +
          "Please try asking your question again in a moment."
        );
      }
    }
  }

  /**
   * Handle general intent
   */
  private async handleGeneralIntent(query: string, historyForModel: any[], needsWebSearch: boolean): Promise<void> {
    console.log(`[ChatService] Executing 'general' action (default).`);
    
    // Even for general queries, we might want to do a web search if it looks like
    // the query needs current information
    if (needsWebSearch) {
      console.log(`[ChatService] General intent, but query might benefit from web search.`);
      
      try {
        // Set searching state to true
        this.dispatch({ type: 'SET_SEARCHING', isSearching: true });
        
        // Don't tell user we're searching for general queries to avoid expectations
        // just in case the search fails
        const searchResults = await webSearchService.search(query);
        
        // Only use search results if we got meaningful content
        if (searchResults.length > 100) {
          console.log(`[ChatService] Using web search results for general query.`);
          
          const result = await this.modelService.query(
            `${query}\n\nIncorporate this web search information: ${searchResults}`, 
            historyForModel
          );
          
          // Reset searching state
          this.dispatch({ type: 'SET_SEARCHING', isSearching: false });
          
          this.dispatchAssistantMessage(result);
          return;
        } else {
          console.log(`[ChatService] Web search results too short, falling back to regular response.`);
          // Reset searching state
          this.dispatch({ type: 'SET_SEARCHING', isSearching: false });
        }
      } catch (error) {
        // Reset searching state on error
        this.dispatch({ type: 'SET_SEARCHING', isSearching: false });
        
        console.error("[ChatService] Error during web search for general query", error);
        // Fall through to regular response
      }
    }
    
    // Standard response without web search
    const result = await this.modelService.query(query, historyForModel);
    console.log("[ChatService] General chain invocation successful.");
    this.dispatchAssistantMessage(result);
  }

  /**
   * Format and dispatch an assistant message
   */
  private dispatchAssistantMessage(content: MessageContent, metadata?: Message['metadata']) {
    if (typeof content !== 'string') {
      console.warn("Received non-string content from AI, converting to string:", content);
      // Attempt to extract meaningful content if it's a complex object
      if (typeof content === 'object' && content !== null && 'content' in content && typeof content.content === 'string') {
        content = content.content;
      } else {
        content = JSON.stringify(content);
      }
    }
    
    const message: Message = {
      id: `asst-${Date.now()}-${Math.random().toString(16).substring(2, 8)}`,
      role: 'assistant',
      content: content as string, // Cast needed after check
      timestamp: Date.now(),
      metadata: metadata
    };
    
    console.log(`[ChatService] Dispatching assistant message. Length: ${content?.toString().length || 0}, Metadata: ${metadata ? JSON.stringify(metadata) : 'None'}`);
    this.dispatch({ type: 'ADD_MESSAGE', message });
    this.dispatch({ type: 'SET_TYPING', isTyping: false });
  }

  /**
   * Search for hotels and display results
   */
  private async searchAndDispatchHotels(location: string) {
    this.dispatch({ type: 'SET_TYPING', isTyping: true });
    
    try {
      const result = await this.hotelService.searchHotels(location);
      
      if (result.simplifiedHotels.length > 0) {
        this.dispatchAssistantMessage(result.conversationalResponse, {
          hotelResults: result.simplifiedHotels
        });
      } else {
        this.dispatchAssistantMessage(result.conversationalResponse);
      }
    } catch (error) {
      console.error(`[searchAndDispatchHotels] Error:`, error);
      handleError(error, `Hotel search failed for ${location}`);
      this.dispatchAssistantMessage(`Sorry, I encountered an error trying to find hotel details for ${location}. Please try again later.`);
    } finally {
      this.dispatch({ type: 'SET_TYPING', isTyping: false });
    }
  }

  /**
   * Generate an itinerary and display it
   */
  private async generateAndDispatchItinerary(location: string, preferences: string[]) {
    this.dispatch({ type: 'SET_TYPING', isTyping: true });
    
    try {
      const durationMatch = preferences.find(p => p.match(/(\d+)\s*day/i));
      const duration = durationMatch ? parseInt(durationMatch, 10) : 3; // Default to 3 days
      
      const itineraryResult = await this.itineraryService.generateItinerary(location, preferences);
      
      if (itineraryResult) {
        this.dispatchAssistantMessage(`Here's a possible ${duration}-day itinerary for ${location}:\n\n${itineraryResult}`);
      } else {
        this.dispatchAssistantMessage(`I couldn't generate an itinerary for ${location} right now. Maybe ask something more specific?`);
      }
    } catch (error) {
      console.error(`[generateAndDispatchItinerary] Error:`, error);
      handleError(error, `Itinerary generation failed for ${location}`);
      this.dispatchAssistantMessage(`Sorry, I had trouble creating an itinerary for ${location}.`);
    } finally {
      this.dispatch({ type: 'SET_TYPING', isTyping: false });
    }
  }
} 