import { ChatAnthropic } from '@langchain/anthropic';
import { 
  BaseMessage,
  SystemMessage as _SystemMessage, 
  HumanMessage, 
  AIMessage,
  MessageContent
} from '@langchain/core/messages';
import { 
  ChatPromptTemplate, 
  MessagesPlaceholder 
} from '@langchain/core/prompts';
import { 
  RunnableSequence
} from '@langchain/core/runnables';
import { handleError } from '../../../../lib/utils/error';

// The system prompt for the chatbot
export const SYSTEM_PROMPT = `You are Marco, a travel assistant. Your responses MUST be under 2 sentences.

Core Rules:
- Your response MUST NOT exceed 2 sentences.
- Be direct and friendly. Ask only one question at a time.
- Do NOT provide explanations or elaborations unless specifically asked.

Hotel/Tour Search Rules:
- If the user asks for hotels/tours and does NOT provide a location, ask ONLY for the location. Example: "Sure, which city are you interested in?"
- If the user asks for hotels/tours and DOES provide a location (like "hotels in Paris"), your response MUST be EXACTLY: "Okay, searching for hotels in [Location] now..." DO NOT ask for dates or any other information in this specific response.
- Wait for the actual search results which will be provided separately. ABSOLUTELY DO NOT invent results.

Quotation Rules:
- Follow the quotation workflow steps precisely.

General Interaction:
- Answer general travel questions concisely based on your knowledge.

Examples:
User: Find hotels in Rome
Marco: Okay, searching for hotels in Rome now...

User: Show me tours
Marco: Certainly! Which city are you interested in exploring tours for?`;

/**
 * ModelService handles communication with the language model
 */
export class ModelService {
  private model: ChatAnthropic;
  private prompt: ChatPromptTemplate;
  private chain: RunnableSequence;

  constructor() {
    if (!import.meta.env.VITE_ANTHROPIC_API_KEY) {
      throw new Error('ANTHROPIC_API_KEY is required');
    }

    this.model = new ChatAnthropic({
      anthropicApiKey: import.meta.env.VITE_ANTHROPIC_API_KEY,
      modelName: "claude-3-haiku-20240307",
      temperature: 0.5,
      maxTokens: 100
    });

    this.prompt = ChatPromptTemplate.fromMessages([
      ["system", SYSTEM_PROMPT],
      new MessagesPlaceholder("history"),
      ["human", "{input}"],
    ]);

    // Create a chain that we can invoke with { input, history }
    this.chain = RunnableSequence.from([
      {
        input: (input: { input: string, history: BaseMessage[] }) => input.input,
        history: (input: { input: string, history: BaseMessage[] }) => input.history,
      },
      this.prompt,
      this.model
    ]);
  }

  /**
   * Format history messages for the model
   */
  formatHistoryForModel(messages: any[]): BaseMessage[] {
    const historyLimit = 10;
    return messages.slice(-historyLimit).map(msg => {
      if (msg.role === 'user') {
        return new HumanMessage(msg.content);
      } else {
        return new AIMessage(msg.content);
      }
    });
  }

  /**
   * Send a query to the model
   */
  async query(input: string, historyMessages: any[]): Promise<MessageContent> {
    try {
      const history = this.formatHistoryForModel(historyMessages);
      const result = await this.chain.invoke({ input, history });
      return result.content;
    } catch (error) {
      console.error(`[ModelService] Error querying model:`, error);
      handleError(error, `Error in model query: ${input}`);
      throw error;
    }
  }
} 