import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight, Users, Star } from 'lucide-react';
import { Carousel, CarouselCard } from '../../../components/ui/apple-cards-carousel';
import { CityStats } from '../types';
import { optimizeImageUrl, IMAGE_SIZES } from '../services/imageService';

interface FeaturedCitiesSectionProps {
  cities: CityStats[];
  loading: boolean;
}

const FeaturedCitiesSection: React.FC<FeaturedCitiesSectionProps> = ({ cities, loading }) => {
  if (loading || cities.length === 0) {
    return null;
  }

  const cityCards = cities.map((city, index) => {
    // Create clean URL-safe version of city name
    const cleanCity = city.city.toLowerCase().replace(/[^a-z0-9]+/g, '-');
    
    // Optimize image URL
    const optimizedSrc = optimizeImageUrl(city.image.url, IMAGE_SIZES.CARD);

    return (
      <Link
        key={`${city.city}-${city.country}`}
        to={`/travel-agents/${cleanCity}`}
        className="block"
      >
        <CarouselCard
          card={{
            category: city.region,
            title: `${city.city}, ${city.country}`,
            src: optimizedSrc,
            content: (
              <div className="absolute inset-0 flex flex-col justify-end p-4 sm:p-6 lg:p-8 bg-gradient-to-t from-black/90 via-black/50 to-transparent">
                <div className="space-y-3 sm:space-y-4">
                  <div className="flex items-center justify-between">
                    <h3 className="text-xl sm:text-2xl lg:text-3xl font-medium text-white">
                      {city.city}
                    </h3>
                    {city.featuredCount > 0 && (
                      <span className="px-2 py-1 sm:px-3 sm:py-1.5 bg-white/10 backdrop-blur-sm text-white/90 text-[10px] sm:text-xs font-medium tracking-wide rounded-full">
                        {city.featuredCount} Featured
                      </span>
                    )}
                  </div>
                  
                  <div className="flex items-center gap-4 sm:gap-6 text-xs sm:text-sm text-white/60">
                    <div className="flex items-center">
                      <Users className="w-3 h-3 sm:w-4 sm:h-4 mr-1 sm:mr-1.5 opacity-50" />
                      <span>{city.agentCount} Agents</span>
                    </div>
                    {city.topRating > 0 && (
                      <div className="flex items-center text-yellow-400">
                        <Star className="w-3 h-3 sm:w-4 sm:h-4 mr-1 sm:mr-1.5" />
                        <span>{city.topRating.toFixed(1)}</span>
                      </div>
                    )}
                  </div>

                  <div className="transform translate-y-8 opacity-0 group-hover:translate-y-0 group-hover:opacity-100 transition-all duration-300">
                    <span className="inline-flex items-center text-white text-xs sm:text-sm">
                      View Travel Agents
                      <ArrowRight className="w-3 h-3 sm:w-4 sm:h-4 ml-1.5 sm:ml-2 opacity-50" />
                    </span>
                  </div>
                </div>
              </div>
            ),
          }}
          index={index}
        />
      </Link>
    );
  });

  return (
    <div className="w-full py-16 sm:py-20 lg:py-24 bg-gradient-to-b from-black via-neutral-950 to-black">
      <div className="max-w-7xl mx-auto px-3 sm:px-4">
        <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-3 sm:gap-4 mb-4 sm:mb-6">
          <div className="space-y-1 sm:space-y-2">
            <h2 className="text-xl sm:text-2xl lg:text-[32px] font-display font-medium tracking-tight text-white leading-none">
              Featured Cities
            </h2>
            <p className="text-white/60 text-sm sm:text-base font-light leading-relaxed max-w-[480px]">
              Discover expert travel agents in top cities worldwide
            </p>
          </div>
          <Link
            to="/travel-agents"
            className="group inline-flex items-center justify-center px-4 sm:px-8 py-2 sm:py-3 bg-white/[0.08] hover:bg-white/[0.12] text-white text-sm sm:text-base font-light tracking-wide rounded-lg sm:rounded-xl backdrop-blur-sm transition-all duration-300 active:scale-95 hover:scale-[1.02] w-full sm:w-auto"
          >
            View More Cities
            <ArrowRight className="ml-2 w-4 h-4 opacity-60 group-hover:opacity-100 group-hover:translate-x-0.5 transition-all duration-300" />
          </Link>
        </div>
      </div>
      <div className="mb-6 sm:mb-12 lg:mb-16">
        <Carousel items={cityCards} />
      </div>
    </div>
  );
};

export default FeaturedCitiesSection; 