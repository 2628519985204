import type { ProcessedData } from '../workers/publicHome/types';

// Create a type-safe wrapper for the Web Worker
export class HeavyDataLoader {
  private worker: Worker;
  private messageQueue: Map<string, {
    resolve: (value: ProcessedData) => void;
    reject: (reason?: any) => void;
  }> = new Map();

  constructor() {
    this.worker = new Worker(
      new URL('../workers/publicHome/worker.ts', import.meta.url),
      { type: 'module' }
    );

    this.worker.onmessage = (e) => {
      const { type, data } = e.data;
      switch (type) {
        case 'PROCESSED_DATA': {
          // Resolve the promise for this data processing request
          const resolver = this.messageQueue.get('PROCESS_DATA');
          if (resolver) {
            resolver.resolve(data as ProcessedData);
            this.messageQueue.delete('PROCESS_DATA');
          }
          break;
        }
        default:
          console.warn('Unknown message type from worker:', type);
      }
    };

    this.worker.onerror = (error) => {
      console.error('Worker error:', error);
      // Reject all pending promises
      this.messageQueue.forEach(resolver => {
        resolver.reject(error);
      });
      this.messageQueue.clear();
    };
  }

  async processData(agents: any[], visibleCountries: string[], imageData: any[]): Promise<ProcessedData> {
    return new Promise((resolve, reject) => {
      // Store the promise resolver
      this.messageQueue.set('PROCESS_DATA', { resolve, reject });

      // Send the data to the worker
      this.worker.postMessage({
        type: 'PROCESS_DATA',
        data: {
          agents,
          visibleCountries,
          imageData
        }
      });
    });
  }

  terminate() {
    this.worker.terminate();
  }
}

// Re-export types
export type { ProcessedData } from '../workers/publicHome/types'; 