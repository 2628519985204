import { useState } from 'react';
import { MapPin, Calendar } from 'lucide-react';
import { motion } from 'framer-motion';
import { addDays, startOfWeek, endOfWeek, addMonths, format } from 'date-fns';
import type { City } from '../types';
import { POPULAR_CITIES } from '../types';
import type { DateRange } from 'react-day-picker';
import { AnimatePresence } from 'framer-motion';

interface PopularDestinationsProps {
  onSelectCity: (city: City) => void;
  onQuickSearch: (city: City, dateRange: DateRange) => void;
}

export default function PopularDestinations({ onSelectCity, onQuickSearch }: PopularDestinationsProps) {
  // Sample images for popular cities
  const cityImages: Record<string, string> = {
    'New York': 'https://images.unsplash.com/photo-1496442226666-8d4d0e62e6e9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80',
    'London': 'https://images.unsplash.com/photo-1513635269975-59663e0ac1ad?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80',
    'Paris': 'https://images.unsplash.com/photo-1499856871958-5b9627545d1a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1220&q=80',
    'Tokyo': 'https://images.unsplash.com/photo-1536098561742-ca998e48cbcc?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2036&q=80',
    'Rome': 'https://images.unsplash.com/photo-1552832230-c0197dd311b5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1096&q=80',
    'Sydney': 'https://images.unsplash.com/photo-1506973035872-a4ec16b8e8d9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80',
  };

  const [hoveredCity, setHoveredCity] = useState<string | null>(null);

  // Get this weekend's dates (Friday to Sunday)
  const getWeekendDates = (): DateRange => {
    const today = new Date();
    const friday = startOfWeek(addDays(today, 5), { weekStartsOn: 5 });
    const sunday = endOfWeek(friday, { weekStartsOn: 5 });
    return { from: friday, to: sunday };
  };

  // Get next week's dates (3-day stay)
  const getNextWeekDates = (): DateRange => {
    const today = new Date();
    const nextWeekStart = addDays(today, 7);
    return {
      from: nextWeekStart,
      to: addDays(nextWeekStart, 2)
    };
  };

  // Get next month's dates (3-day stay)
  const getNextMonthDates = (): DateRange => {
    const today = new Date();
    const nextMonthStart = addMonths(today, 1);
    return {
      from: nextMonthStart,
      to: addDays(nextMonthStart, 2)
    };
  };

  return (
    <div className="space-y-4 sm:space-y-6">
      <div className="px-1">
        <h2 className="text-xl sm:text-2xl font-display font-semibold text-white relative inline-block pb-1 after:content-[''] after:absolute after:bottom-0 after:left-0 after:w-full after:h-[2px] after:bg-gradient-to-r after:from-primary after:to-transparent">
          Popular Destinations
        </h2>
        <p className="text-sm sm:text-base text-gray-400 mt-1">Discover hotels in these top destinations around the world</p>
      </div>
      
      <div className="grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 gap-3 sm:gap-6">
        {POPULAR_CITIES.map((city) => (
          <motion.div
            key={`${city.name}-${city.country}`}
            className="group cursor-pointer transition-all duration-300 transform hover:translate-y-[-4px] hover:shadow-xl rounded-xl overflow-hidden border border-gray-700/20 hover:border-gray-700/40"
            onClick={() => onSelectCity(city)}
            onMouseEnter={() => setHoveredCity(city.name)}
            onMouseLeave={() => setHoveredCity(null)}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            whileHover={{ y: -5 }}
            transition={{ duration: 0.3 }}
          >
            <div className="relative h-48 sm:h-64 rounded-lg overflow-hidden">
              {/* Background image */}
              <img
                src={cityImages[city.name] || 'https://images.unsplash.com/photo-1414609245224-afa02bfb3fda?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1169&q=80'}
                alt={city.name}
                className="w-full h-full object-cover group-hover:scale-110 transition-transform duration-700 ease-in-out"
                loading="lazy"
              />
              
              {/* Overlay */}
              <div className="absolute inset-0 bg-gradient-to-t from-black via-black/60 to-transparent opacity-80 group-hover:opacity-90 transition-opacity duration-300" />
              
              {/* Content */}
              <div className="absolute bottom-0 left-0 p-4 sm:p-6 w-full">
                <div className="flex items-center text-white mb-1 sm:mb-2">
                  <MapPin className="h-4 w-4 sm:h-5 sm:w-5 mr-1 sm:mr-2 text-primary" />
                  <h3 className="text-lg sm:text-2xl font-display font-semibold group-hover:text-primary transition-colors duration-300">{city.name}</h3>
                </div>
                <p className="text-xs sm:text-sm text-gray-300">{city.country}</p>
                
                <p className="text-xs sm:text-sm text-gray-300 mt-1 sm:mt-2 line-clamp-2 group-hover:text-white/90 transition-colors duration-300">{city.description}</p>
                
                {/* Quick Date Selection */}
                <AnimatePresence>
                  {hoveredCity === city.name && (
                    <motion.div
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: 10 }}
                      transition={{ duration: 0.2 }}
                      className="absolute inset-0 bg-gray-900/95 backdrop-blur-sm p-6 flex flex-col justify-center"
                    >
                      <div className="space-y-3">
                        <h4 className="text-white font-medium mb-4 flex items-center">
                          <Calendar className="h-5 w-5 text-primary mr-2" />
                          Select Dates
                        </h4>
                        
                        {/* This Weekend */}
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            const dates = getWeekendDates();
                            onQuickSearch(city, dates);
                          }}
                          className="w-full text-left px-4 py-3 bg-gray-800/50 hover:bg-gray-800 rounded-lg text-white transition-colors"
                        >
                          <div className="font-medium">This Weekend</div>
                          <div className="text-sm text-gray-400">
                            {format(getWeekendDates().from!, 'MMM d')} - {format(getWeekendDates().to!, 'MMM d')}
                          </div>
                        </button>

                        {/* Next Week */}
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            const dates = getNextWeekDates();
                            onQuickSearch(city, dates);
                          }}
                          className="w-full text-left px-4 py-3 bg-gray-800/50 hover:bg-gray-800 rounded-lg text-white transition-colors"
                        >
                          <div className="font-medium">Next Week</div>
                          <div className="text-sm text-gray-400">
                            {format(getNextWeekDates().from!, 'MMM d')} - {format(getNextWeekDates().to!, 'MMM d')}
                          </div>
                        </button>

                        {/* Next Month */}
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            const dates = getNextMonthDates();
                            onQuickSearch(city, dates);
                          }}
                          className="w-full text-left px-4 py-3 bg-gray-800/50 hover:bg-gray-800 rounded-lg text-white transition-colors"
                        >
                          <div className="font-medium">Next Month</div>
                          <div className="text-sm text-gray-400">
                            {format(getNextMonthDates().from!, 'MMM d')} - {format(getNextMonthDates().to!, 'MMM d')}
                          </div>
                        </button>

                        {/* Custom Dates */}
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            onSelectCity(city);
                          }}
                          className="w-full text-left px-4 py-3 bg-primary/10 hover:bg-primary/20 rounded-lg text-primary transition-colors"
                        >
                          <div className="font-medium">Custom Dates</div>
                          <div className="text-sm opacity-80">Select your own dates</div>
                        </button>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
                
                <div className="mt-2 sm:mt-4 inline-flex items-center px-3 py-1 bg-gradient-to-r from-primary/10 to-primary/5 border border-primary/20 rounded-full group-hover:from-primary/20 group-hover:to-primary/10 transition-all duration-300">
                  <span className="mr-1 text-primary font-medium text-xs sm:text-sm">Find hotels</span>
                  <svg className="w-3 h-3 sm:w-4 sm:h-4 text-primary transform group-hover:translate-x-1 transition-transform duration-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                  </svg>
                </div>
              </div>
            </div>
          </motion.div>
        ))}
      </div>
      
      <style>
        {`
          @keyframes fadeIn {
            0% { opacity: 0; transform: translateY(5px); }
            100% { opacity: 1; transform: translateY(0); }
          }
          .animate-fadeIn {
            animation: fadeIn 0.3s ease-out forwards;
          }
        `}
      </style>
    </div>
  );
} 