import { useState } from 'react';
import { X, Coffee, CreditCard, DollarSign, Globe, BedDouble, Utensils, UtensilsCrossed, Filter, Star, Wifi, Waves, Car, Dumbbell, CheckSquare } from 'lucide-react';

interface FilterOptionsProps {
  onFilterChange: (filters: FilterState) => void;
  initialFilters?: Partial<FilterState>;
}

export interface FilterState {
  boardType?: 'ROOM_ONLY' | 'BREAKFAST' | 'HALF_BOARD' | 'FULL_BOARD' | 'ALL_INCLUSIVE';
  paymentPolicy?: 'GUARANTEE' | 'DEPOSIT' | 'NONE';
  includeClosed?: boolean;
  priceRange?: {
    min?: number;
    max?: number;
  };
  language?: string;
  bestRateOnly?: boolean;
  ratings?: number[];
  amenities?: string[];
}

// Define common amenities for filtering
const COMMON_AMENITIES = [
  { code: 'SWIMMING_POOL', label: 'Pool', icon: <Waves className="w-3.5 h-3.5" /> },
  { code: 'WIFI', label: 'WiFi', icon: <Wifi className="w-3.5 h-3.5" /> },
  { code: 'PARKING', label: 'Parking', icon: <Car className="w-3.5 h-3.5" /> },
  { code: 'FITNESS_CENTER', label: 'Gym', icon: <Dumbbell className="w-3.5 h-3.5" /> },
  { code: 'RESTAURANT', label: 'Restaurant', icon: <UtensilsCrossed className="w-3.5 h-3.5" /> },
  { code: 'AIR_CONDITIONING', label: 'AC', icon: <CheckSquare className="w-3.5 h-3.5 opacity-70" /> },
];

export default function FilterOptions({ onFilterChange, initialFilters = {} }: FilterOptionsProps) {
  const [filters, setFilters] = useState<FilterState>({
    boardType: initialFilters.boardType,
    paymentPolicy: initialFilters.paymentPolicy || 'NONE',
    includeClosed: initialFilters.includeClosed || false,
    priceRange: initialFilters.priceRange || {},
    language: initialFilters.language || 'EN',
    bestRateOnly: initialFilters.bestRateOnly !== false,
    ratings: initialFilters.ratings || [],
    amenities: initialFilters.amenities || [],
  });

  const [priceMin, setPriceMin] = useState<string>(
    filters.priceRange?.min !== undefined ? filters.priceRange.min.toString() : ''
  );
  const [priceMax, setPriceMax] = useState<string>(
    filters.priceRange?.max !== undefined ? filters.priceRange.max.toString() : ''
  );
  
  // For mobile: add a state to track if filters are visible
  const [isFilterVisible, setIsFilterVisible] = useState<boolean>(false);
  // For sections expansion state
  const [expandedSections, setExpandedSections] = useState<{[key: string]: boolean}>({
    amenities: true,
    options: true,
    price: false,
    meal: false,
    payment: false,
  });

  const handleFilterChange = <K extends keyof FilterState>(key: K, value: FilterState[K]) => {
    const newFilters = { ...filters, [key]: value };
    setFilters(newFilters);
    onFilterChange(newFilters);
  };

  const handleToggleArrayFilter = (key: 'ratings' | 'amenities', value: number | string) => {
    const currentValues = filters[key] || [];
    let newValues;
    if (currentValues.includes(value as never)) {
      newValues = currentValues.filter(item => item !== value);
    } else {
      newValues = [...currentValues, value];
    }
    handleFilterChange(key, newValues as any);
  };
  
  const handleMinRatingChange = (minRating: number) => {
    const newRatings = [minRating];
    handleFilterChange('ratings', newRatings);
  };

  const handlePriceChange = () => {
    const min = priceMin ? parseInt(priceMin) : undefined;
    const max = priceMax ? parseInt(priceMax) : undefined;
    
    if ((min !== undefined && isNaN(min)) || (max !== undefined && isNaN(max))) {
      return; // Invalid input
    }
    
    const newPriceRange = { min, max };
    handleFilterChange('priceRange', newPriceRange);
  };

  const clearFilter = (key: keyof FilterState) => {
    let defaultValue: any;
    switch (key) {
      case 'paymentPolicy': defaultValue = 'NONE'; break;
      case 'includeClosed': defaultValue = false; break;
      case 'language': defaultValue = 'EN'; break;
      case 'bestRateOnly': defaultValue = true; break;
      case 'ratings': defaultValue = []; break;
      case 'amenities': defaultValue = []; break;
      case 'priceRange': defaultValue = {}; break;
      default: defaultValue = undefined;
    }
  
    const newFilters = { ...filters, [key]: defaultValue };
    if (key === 'priceRange') {
      setPriceMin('');
      setPriceMax('');
    }
    
    if (defaultValue === undefined) {
      delete newFilters[key];
    }

    setFilters(newFilters);
    onFilterChange(newFilters);
  };

  const toggleSection = (section: string) => {
    setExpandedSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  type BoardTypeOption = {
    value: FilterState['boardType'] & string;
    label: string;
    icon: React.ReactNode;
  };

  type PaymentPolicyOption = {
    value: FilterState['paymentPolicy'] & string;
    label: string;
    icon: React.ReactNode;
  };

  const BOARD_TYPES: BoardTypeOption[] = [
    { value: 'ROOM_ONLY', label: 'Room Only', icon: <UtensilsCrossed className="w-3 h-3" /> },
    { value: 'BREAKFAST', label: 'Breakfast', icon: <Coffee className="w-3 h-3" /> },
    { value: 'HALF_BOARD', label: 'Half Board', icon: <Utensils className="w-3 h-3" /> },
    { value: 'FULL_BOARD', label: 'Full Board', icon: <Utensils className="w-3 h-3" /> },
    { value: 'ALL_INCLUSIVE', label: 'All-Inclusive', icon: <Utensils className="w-3 h-3" /> }
  ];

  const PAYMENT_POLICIES: PaymentPolicyOption[] = [
    { value: 'NONE', label: 'All Payments', icon: <CreditCard className="w-3 h-3" /> },
    { value: 'GUARANTEE', label: 'Pay at Hotel', icon: <CreditCard className="w-3 h-3" /> },
    { value: 'DEPOSIT', label: 'Deposit Required', icon: <DollarSign className="w-3 h-3" /> }
  ];

  type LanguageOption = {
    value: FilterState['language'] & string;
    label: string;
  };

  const LANGUAGES: LanguageOption[] = [
    { value: 'EN', label: 'English' },
    { value: 'FR', label: 'French' },
    { value: 'DE', label: 'German' },
    { value: 'ES', label: 'Spanish' },
    { value: 'IT', label: 'Italian' },
    { value: 'ZH', label: 'Chinese' },
    { value: 'JA', label: 'Japanese' }
  ];
  
  // Count active filters
  const getActiveFilterCount = (): number => {
    let count = 0;
    if (filters.boardType) count++;
    if (filters.paymentPolicy && filters.paymentPolicy !== 'NONE') count++;
    if (filters.priceRange?.min !== undefined || filters.priceRange?.max !== undefined) count++;
    if (filters.language && filters.language !== 'EN') count++;
    if (filters.includeClosed) count++;
    if (filters.bestRateOnly === false) count++;
    if (filters.ratings && filters.ratings.length > 0) count++;
    if (filters.amenities && filters.amenities.length > 0) count++;
    return count;
  };

  // Mobile filter toggle button
  const MobileFilterButton = () => (
    <button
      onClick={() => setIsFilterVisible(!isFilterVisible)}
      className="fixed bottom-4 right-4 z-20 bg-black/80 backdrop-blur-md text-white rounded-full p-3 shadow-lg md:hidden flex items-center transform transition-transform active:scale-95 border border-white/10"
    >
      <Filter className="w-4 h-4 mr-1.5" />
      <span className="text-sm">{getActiveFilterCount() > 0 ? `Filters (${getActiveFilterCount()})` : 'Filters'}</span>
    </button>
  );

  // Collapsible section component
  const FilterSection = ({ 
    title, 
    sectionKey, 
    children, 
    showClear = false,
    onClear = () => {} 
  }: { 
    title: string, 
    sectionKey: string, 
    children: React.ReactNode,
    showClear?: boolean,
    onClear?: () => void
  }) => (
    <div className="mb-2 last:mb-0">
      <div 
        className="flex items-center justify-between cursor-pointer py-1.5 border-b border-white/5 mb-1.5"
        onClick={() => toggleSection(sectionKey)}
      >
        <h4 className="text-white/90 text-xs font-medium flex items-center">
          <span className={`transform transition-transform duration-200 ${expandedSections[sectionKey] ? 'rotate-90' : ''}`}>›</span>
          <span className="ml-1.5">{title}</span>
        </h4>
        {showClear && (
          <button 
            onClick={(e) => {
              e.stopPropagation();
              onClear();
            }}
            className="text-[10px] text-gray-400 hover:text-white transition-colors p-1"
          >
            Clear
          </button>
        )}
      </div>
      {expandedSections[sectionKey] && (
        <div className="mb-2 pl-1">
          {children}
        </div>
      )}
    </div>
  );

  return (
    <>
      {/* Mobile filter button */}
      <MobileFilterButton />
      
      {/* Filter container - full height to match map */}
      <div className={`
        ${isFilterVisible 
          ? 'fixed inset-0 z-30 bg-black/50 backdrop-blur-sm flex items-center justify-center md:relative md:bg-transparent md:backdrop-blur-none' 
          : 'hidden md:block'}
        h-full
      `}>
        <div className={`
          bg-black/80 backdrop-blur-md rounded-lg border border-white/10 overflow-hidden shadow-xl
          ${isFilterVisible 
            ? 'w-11/12 max-h-[90vh] md:w-full md:h-full' 
            : 'w-full h-full'}
          flex flex-col
        `}>
          {/* Mobile close button */}
          {isFilterVisible && (
            <div className="flex justify-between items-center p-2 border-b border-white/5 md:hidden">
              <h3 className="text-white text-sm font-medium">Filters</h3>
              <button 
                onClick={() => setIsFilterVisible(false)}
                className="text-gray-400 p-1 hover:text-white"
              >
                <X className="w-4 h-4" />
              </button>
            </div>
          )}
          
          <div className="p-3 overflow-y-auto flex-1 scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-transparent">
            <div className="flex justify-between items-center mb-2">
              <h3 className="text-white/90 text-xs font-medium uppercase tracking-wide">Filters {getActiveFilterCount() > 0 ? `(${getActiveFilterCount()})` : ''}</h3>
              <button 
                onClick={() => {
                  const resetState: FilterState = {
                    paymentPolicy: 'NONE',
                    includeClosed: false,
                    language: 'EN',
                    bestRateOnly: true,
                    ratings: [],
                    amenities: [],
                    priceRange: {}
                  };
                  setFilters(resetState);
                  setPriceMin('');
                  setPriceMax('');
                  onFilterChange(resetState);
                }}
                className="text-[10px] text-gray-400 hover:text-white transition-colors"
              >
                Reset All
              </button>
            </div>
            
            {/* Core Options Section */}
            <FilterSection title="Display Options" sectionKey="options">
              {/* Quick toggles */}
              <div className="space-y-2">
                {/* Best rate toggle */}
                <div className="flex items-center justify-between">
                  <span className="text-[11px] text-white/90">Best rate only</span>
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input 
                      type="checkbox"
                      className="sr-only peer"
                      checked={filters.bestRateOnly !== false}
                      onChange={(e) => handleFilterChange('bestRateOnly', e.target.checked)}
                    />
                    <div className="w-7 h-4 bg-gray-700/50 rounded-full peer-focus:ring-1 peer-focus:ring-white/10 transition peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:h-3 after:w-3 after:transition-all peer-checked:bg-primary/80"></div>
                  </label>
                </div>
                
                {/* Include sold out */}
                <div className="flex items-center justify-between">
                  <span className="text-[11px] text-white/90">Include sold out</span>
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      checked={!!filters.includeClosed}
                      onChange={(e) => handleFilterChange('includeClosed', e.target.checked)}
                      className="sr-only peer"
                    />
                    <div className="w-7 h-4 bg-gray-700/50 rounded-full peer-focus:ring-1 peer-focus:ring-white/10 transition peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:h-3 after:w-3 after:transition-all peer-checked:bg-primary/80"></div>
                  </label>
                </div>
                
                {/* Language */}
                <div className="mt-1">
                  <div className="flex items-center justify-between">
                    <span className="text-[11px] text-white/90">Language</span>
                    <div className="relative w-20">
                      <select
                        value={filters.language || 'EN'}
                        onChange={(e) => handleFilterChange('language', e.target.value)}
                        className="w-full py-0.5 px-2 bg-gray-800/50 text-white text-[11px] rounded border border-white/10 focus:outline-none appearance-none"
                      >
                        {LANGUAGES.map(lang => (
                          <option key={lang.value} value={lang.value}>{lang.label}</option>
                        ))}
                      </select>
                      <div className="absolute inset-y-0 right-0 flex items-center pr-1 pointer-events-none">
                        <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </FilterSection>
            
            {/* Rating Filter */}
            <FilterSection 
              title="Star Rating" 
              sectionKey="rating" 
              showClear={filters.ratings && filters.ratings.length > 0}
              onClear={() => clearFilter('ratings')}
            >
              <div className="flex space-x-1 py-1">
                {[1, 2, 3, 4, 5].map(rating => (
                  <button
                    key={rating}
                    onClick={() => handleMinRatingChange(rating)}
                    className={`flex items-center justify-center p-1 h-6 w-6 rounded transition-colors ${
                      filters.ratings?.includes(rating)
                        ? 'bg-amber-400/20 text-amber-300 border border-amber-500/30'
                        : 'bg-gray-800/70 text-gray-400 border border-gray-700/50 hover:border-gray-600'
                    }`}
                    title={`${rating} Star${rating > 1 ? 's' : ''}`}
                  >
                    <span className="text-[10px] font-medium">{rating}</span>
                    <Star className="w-2 h-2 ml-0.5 fill-current" />
                  </button>
                ))}
              </div>
            </FilterSection>
            
            {/* Amenities Filter */}
            <FilterSection 
              title="Amenities" 
              sectionKey="amenities"
              showClear={filters.amenities && filters.amenities.length > 0}
              onClear={() => clearFilter('amenities')}
            >
              <div className="flex flex-wrap gap-1 py-1">
                {COMMON_AMENITIES.map(amenity => (
                  <button
                    key={amenity.code}
                    onClick={() => handleToggleArrayFilter('amenities', amenity.code)}
                    className={`group flex items-center text-[10px] px-2 py-1 rounded transition-all ${
                      filters.amenities?.includes(amenity.code)
                        ? 'bg-primary/20 border border-primary/30 text-primary'
                        : 'bg-gray-800/70 border border-gray-700/50 text-gray-300 hover:border-gray-600'
                    }`}
                  >
                    <span className="mr-1 opacity-80">{amenity.icon}</span>
                    {amenity.label}
                  </button>
                ))}
              </div>
            </FilterSection>
            
            {/* Price Range */}
            <FilterSection 
              title="Price Range" 
              sectionKey="price"
              showClear={filters.priceRange?.min !== undefined || filters.priceRange?.max !== undefined}
              onClear={() => clearFilter('priceRange')}
            >
              <div className="bg-gray-800/50 rounded border border-white/5 mt-1">
                <div className="flex items-center p-1.5">
                  <div className="flex items-center flex-1">
                    <span className="text-gray-400 mr-1 text-[10px]">$</span>
                    <input
                      type="text"
                      placeholder="0"
                      value={priceMin}
                      onChange={(e) => setPriceMin(e.target.value)}
                      className="w-full bg-transparent text-white text-[11px] focus:outline-none"
                    />
                  </div>
                  <div className="mx-1 text-gray-500">-</div>
                  <div className="flex items-center flex-1">
                    <span className="text-gray-400 mr-1 text-[10px]">$</span>
                    <input
                      type="text"
                      placeholder="Max"
                      value={priceMax}
                      onChange={(e) => setPriceMax(e.target.value)}
                      className="w-full bg-transparent text-white text-[11px] focus:outline-none"
                    />
                  </div>
                  <button
                    onClick={handlePriceChange}
                    className="ml-1 px-2 py-1 bg-gray-700/80 hover:bg-gray-700 text-white rounded text-[10px] transition-colors border border-white/5"
                  >
                    Set
                  </button>
                </div>
              </div>
            </FilterSection>
            
            {/* Meal Plan */}
            <FilterSection 
              title="Meal Plan" 
              sectionKey="meal"
              showClear={filters.boardType !== undefined}
              onClear={() => clearFilter('boardType')}
            >
              <div className="flex flex-wrap gap-1 py-1">
                {BOARD_TYPES.map(option => (
                  <button
                    key={option.value}
                    onClick={() => handleFilterChange('boardType', option.value)}
                    className={`group flex items-center text-[10px] px-2 py-1 rounded transition-all ${
                      filters.boardType === option.value
                        ? 'bg-primary/20 border border-primary/30 text-primary'
                        : 'bg-gray-800/70 border border-gray-700/50 text-gray-300 hover:border-gray-600'
                    }`}
                  >
                    <span className="mr-1 opacity-80">{option.icon}</span>
                    {option.label}
                  </button>
                ))}
              </div>
            </FilterSection>
            
            {/* Payment Options */}
            <FilterSection 
              title="Payment Policy" 
              sectionKey="payment"
              showClear={filters.paymentPolicy !== 'NONE'}
              onClear={() => clearFilter('paymentPolicy')}
            >
              <div className="flex flex-wrap gap-1 py-1">
                {PAYMENT_POLICIES.map(option => (
                  <button
                    key={option.value}
                    onClick={() => handleFilterChange('paymentPolicy', option.value)}
                    className={`group flex items-center text-[10px] px-2 py-1 rounded transition-all ${
                      filters.paymentPolicy === option.value
                        ? 'bg-primary/20 border border-primary/30 text-primary'
                        : 'bg-gray-800/70 border border-gray-700/50 text-gray-300 hover:border-gray-600'
                    }`}
                  >
                    <span className="mr-1 opacity-80">{option.icon}</span>
                    {option.label}
                  </button>
                ))}
              </div>
            </FilterSection>
          </div>
          
          {/* Apply button for mobile view */}
          {isFilterVisible && (
            <div className="p-3 border-t border-white/5 md:hidden">
              <button
                onClick={() => setIsFilterVisible(false)}
                className="w-full bg-primary text-black py-2 rounded text-sm font-medium"
              >
                Apply Filters {getActiveFilterCount() > 0 && `(${getActiveFilterCount()})`}
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
} 