import React, { useEffect, useState } from 'react';
import { CheckCircle, Calendar, MapPin, CreditCard, Clock, ArrowRight, Umbrella, Building2, Landmark, Mountain, Trees, UtensilsCrossed } from 'lucide-react';
import { format } from 'date-fns';
import { BookingResponse } from '../services/hotelBookingService';

interface BookingConfirmationProps {
  booking: BookingResponse;
  onClose: () => void;
}

// Define hotel types with minimal dark themes
const hotelThemes = {
  beach: {
    bgColor: "bg-gray-900",
    cardBg: "bg-gray-800/70",
    accentColor: "text-blue-400",
    borderColor: "border-gray-700",
    icon: <Umbrella className="w-8 h-8 sm:w-10 sm:h-10 text-blue-400" />,
    name: "Beachfront"
  },
  urban: {
    bgColor: "bg-gray-900",
    cardBg: "bg-gray-800/70",
    accentColor: "text-indigo-400",
    borderColor: "border-gray-700",
    icon: <Building2 className="w-8 h-8 sm:w-10 sm:h-10 text-indigo-400" />,
    name: "Urban"
  },
  historic: {
    bgColor: "bg-gray-900",
    cardBg: "bg-gray-800/70",
    accentColor: "text-amber-400",
    borderColor: "border-gray-700",
    icon: <Landmark className="w-8 h-8 sm:w-10 sm:h-10 text-amber-400" />,
    name: "Historic"
  },
  nature: {
    bgColor: "bg-gray-900",
    cardBg: "bg-gray-800/70",
    accentColor: "text-emerald-400",
    borderColor: "border-gray-700",
    icon: <Mountain className="w-8 h-8 sm:w-10 sm:h-10 text-emerald-400" />,
    name: "Nature"
  },
  luxury: {
    bgColor: "bg-gray-900",
    cardBg: "bg-gray-800/70",
    accentColor: "text-rose-400",
    borderColor: "border-gray-700",
    icon: <UtensilsCrossed className="w-8 h-8 sm:w-10 sm:h-10 text-rose-400" />,
    name: "Luxury"
  },
  default: {
    bgColor: "bg-gray-900",
    cardBg: "bg-gray-800/70",
    accentColor: "text-gray-400",
    borderColor: "border-gray-700",
    icon: <CheckCircle className="w-8 h-8 sm:w-10 sm:h-10 text-gray-400" />,
    name: "Hotel"
  }
};

export default function BookingConfirmation({ booking, onClose }: BookingConfirmationProps) {
  // Get the first booking for display
  const hotelBooking = booking.hotelBookings[0];
  const [theme, setTheme] = useState(hotelThemes.default);
  
  // Determine hotel theme based on name or other properties
  useEffect(() => {
    if (!hotelBooking?.hotelName) return;
    
    const name = hotelBooking.hotelName.toLowerCase();
    
    if (name.includes('beach') || name.includes('bay') || name.includes('resort') || name.includes('spa') || name.includes('ocean') || name.includes('sea')) {
      setTheme(hotelThemes.beach);
    } else if (name.includes('grand') || name.includes('palace') || name.includes('ritz') || name.includes('luxury') || name.includes('premium')) {
      setTheme(hotelThemes.luxury);
    } else if (name.includes('historic') || name.includes('heritage') || name.includes('royal') || name.includes('palace') || name.includes('classic')) {
      setTheme(hotelThemes.historic);
    } else if (name.includes('city') || name.includes('metro') || name.includes('urban') || name.includes('downtown')) {
      setTheme(hotelThemes.urban);
    } else if (name.includes('mountain') || name.includes('forest') || name.includes('lake') || name.includes('park') || name.includes('nature')) {
      setTheme(hotelThemes.nature);
    }
  }, [hotelBooking]);
  
  // Add animation entrance effect
  useEffect(() => {
    const timer = setTimeout(() => {
      const element = document.getElementById('booking-confirmation');
      if (element) {
        element.style.opacity = '1';
        element.style.transform = 'translateY(0)';
      }
    }, 100);
    
    return () => clearTimeout(timer);
  }, []);
  
  if (!hotelBooking) {
    return (
      <div className="fixed inset-0 bg-black/80 backdrop-blur-sm z-50 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 py-10">
          <div 
            id="booking-confirmation"
            className={`w-full max-w-xl ${theme.bgColor} backdrop-blur-lg rounded-xl p-6 sm:p-8 text-center shadow-xl border ${theme.borderColor}`}
            style={{ opacity: 0, transform: 'translateY(10px)', transition: 'all 0.4s ease-out' }}
          >
            <div className={`rounded-full bg-gray-800 p-4 w-16 h-16 sm:w-20 sm:h-20 mx-auto mb-4 flex items-center justify-center`}>
              <CheckCircle className="w-8 h-8 sm:w-10 sm:h-10 text-primary" />
            </div>
            <h2 className="text-xl sm:text-2xl font-semibold text-white mb-2">Booking Confirmed!</h2>
            <p className="text-gray-300 mb-6">Your confirmation ID: {booking.id}</p>
            <p className="text-gray-400 mb-8">No booking details are available.</p>
            <button
              onClick={onClose}
              className="bg-gray-800 hover:bg-gray-700 text-white py-3 px-6 rounded-lg font-medium transition-all duration-200 flex items-center justify-center mx-auto"
            >
              Return to Search
              <ArrowRight className="ml-2 h-4 w-4" />
            </button>
          </div>
        </div>
      </div>
    );
  }
  
  // Format dates
  const checkInDate = hotelBooking.checkInDate 
    ? format(new Date(hotelBooking.checkInDate), 'MMM dd, yyyy') 
    : 'Not specified';
  
  const checkOutDate = hotelBooking.checkOutDate
    ? format(new Date(hotelBooking.checkOutDate), 'MMM dd, yyyy')
    : 'Not specified';
  
  // Calculate duration
  const stayDuration = (() => {
    if (hotelBooking.checkInDate && hotelBooking.checkOutDate) {
      const checkIn = new Date(hotelBooking.checkInDate);
      const checkOut = new Date(hotelBooking.checkOutDate);
      const diffTime = Math.abs(checkOut.getTime() - checkIn.getTime());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    }
    return null;
  })();

  return (
    <div className="fixed inset-0 bg-black/80 backdrop-blur-sm z-50 overflow-y-auto">
      <div className="flex min-h-full items-center justify-center p-3 py-6 sm:p-4 sm:py-10">
        <div 
          id="booking-confirmation"
          className={`w-full max-w-md sm:max-w-xl ${theme.bgColor} backdrop-blur-lg rounded-xl overflow-hidden shadow-xl border ${theme.borderColor}`}
          style={{ opacity: 0, transform: 'translateY(10px)', transition: 'all 0.4s ease-out' }}
        >
          {/* Confirmation Header */}
          <div className="bg-black p-6 sm:p-8 text-center">
            <div className={`rounded-full bg-gray-800 p-3 sm:p-4 w-16 h-16 sm:w-20 sm:h-20 mx-auto mb-3 sm:mb-4 flex items-center justify-center`}>
              {theme.icon}
            </div>
            <h2 className="text-xl sm:text-2xl font-semibold text-white mb-1">Booking Confirmed!</h2>
            <p className="text-gray-400">Thank you for your reservation</p>
            <div className="inline-block px-3 py-1 rounded-full bg-gray-800 text-gray-300 text-xs mt-2 font-medium">
              {theme.name} Experience
            </div>
          </div>
          
          {/* Confirmation Details */}
          <div className="p-5 sm:p-8">
            <div className={`${theme.cardBg} rounded-xl p-4 sm:p-5 mb-4 sm:mb-5 border ${theme.borderColor}`}>
              <div className="flex justify-between items-center mb-3">
                <h3 className="text-white font-medium">Confirmation Details</h3>
                <span className="bg-gray-800 text-blue-400 px-2 py-0.5 sm:px-2.5 sm:py-1 rounded-full text-xs border border-gray-700">
                  {hotelBooking.bookingStatus}
                </span>
              </div>
              
              <div className="text-xs sm:text-sm mb-2 flex items-center">
                <span className="text-gray-400 mr-2">Confirmation ID:</span>
                <span className="text-white font-medium tracking-wide">{booking.id}</span>
              </div>
              
              <div className="text-xs sm:text-sm flex items-center">
                <span className="text-gray-400 mr-2">Booking Reference:</span>
                <span className="text-white tracking-wide">{hotelBooking.confirmationNumber || hotelBooking.id}</span>
              </div>
            </div>
            
            <div className={`${theme.cardBg} rounded-xl p-4 sm:p-5 mb-4 sm:mb-5 border ${theme.borderColor}`}>
              <h3 className="text-white font-medium mb-3 sm:mb-4 flex items-center">
                <MapPin className={`w-4 h-4 mr-2 ${theme.accentColor}`} />
                Hotel Information
              </h3>
              
              <h4 className="text-base sm:text-lg text-white mb-1">{hotelBooking.hotelName}</h4>
              <p className="text-xs sm:text-sm text-gray-400 mb-3 sm:mb-4">
                {hotelBooking.roomType || 'Standard Room'}
              </p>
              
              <div className="flex items-start text-xs sm:text-sm text-gray-300 mb-3">
                <Calendar className={`w-4 h-4 mr-2 ${theme.accentColor} flex-shrink-0 mt-0.5`} />
                <div>
                  <div className="flex items-center">
                    <span className="text-white">{checkInDate}</span>
                    <ArrowRight className="mx-2 h-3 w-3 text-gray-400" />
                    <span className="text-white">{checkOutDate}</span>
                  </div>
                  {stayDuration && (
                    <span className="text-gray-400 text-xs">
                      ({stayDuration} {stayDuration === 1 ? 'night' : 'nights'})
                    </span>
                  )}
                </div>
              </div>
              
              <div className="flex items-start text-xs sm:text-sm text-gray-300">
                <Clock className={`w-4 h-4 mr-2 ${theme.accentColor} flex-shrink-0 mt-0.5`} />
                <div>
                  <div className="text-white">Check-in: After 3:00 PM</div>
                  <div className="text-white">Check-out: Before 12:00 PM</div>
                </div>
              </div>
            </div>
            
            <div className={`${theme.cardBg} rounded-xl p-4 sm:p-5 mb-4 sm:mb-6 border ${theme.borderColor}`}>
              <h3 className="text-white font-medium mb-3 sm:mb-4 flex items-center">
                <CreditCard className={`w-4 h-4 mr-2 ${theme.accentColor}`} />
                Payment Details
              </h3>
              
              <div className="flex justify-between mb-2 text-xs sm:text-sm">
                <span className="text-gray-300">Room Total:</span>
                <span className="text-white font-medium">
                  {hotelBooking.currency} {parseFloat(hotelBooking.total).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </span>
              </div>
              
              <div className="flex justify-between text-xs sm:text-sm">
                <span className="text-gray-300">Status:</span>
                <span className={`${hotelBooking.bookingStatus === 'CONFIRMED' ? 'text-green-400' : 'text-amber-400'}`}>
                  {hotelBooking.bookingStatus === 'CONFIRMED' ? 'Paid in Full' : 'Payment on Arrival'}
                </span>
              </div>
            </div>
            
            {/* Instructions */}
            <div className="text-xs sm:text-sm text-gray-300 mb-5 sm:mb-6 bg-gray-800 rounded-lg p-3 sm:p-4 border border-gray-700">
              <p className="mb-2">
                A confirmation email has been sent to your provided email address.
              </p>
              <p>
                Please present your confirmation ID at check-in. Contact the hotel directly
                for any special requests or changes to your booking.
              </p>
            </div>
            
            {/* Return Button */}
            <button
              onClick={onClose}
              className={`w-full bg-black text-white hover:bg-gray-800 py-3 rounded-lg font-medium transition-all duration-200 flex items-center justify-center`}
            >
              Return to Search
              <ArrowRight className="ml-2 h-4 w-4" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
} 