export enum RouteType {
  STATIC = 'static',
  DESTINATION = 'destination',
  CITY = 'city',
  AGENT = 'agent'
}

export interface RouteObject {
  path: string;
  type: RouteType;
  priority: number;
  changefreq: 'always' | 'hourly' | 'daily' | 'weekly' | 'monthly' | 'yearly' | 'never';
}

// Valid URL patterns for each route type
export const URL_PATTERNS: Record<RouteType, RegExp> = {
  [RouteType.STATIC]: /^\/(?:map|destinations|travel-agents|why-agentrav|hotel-marketing|privacy|terms)?$/,
  [RouteType.DESTINATION]: /^\/destination\/[a-z0-9-]+$/,
  [RouteType.CITY]: /^\/travel-agents\/[a-z0-9-]+$/,
  [RouteType.AGENT]: /^\/travel-agents\/[a-z0-9-]+\/[a-z0-9-]+$/
};

// Priority and change frequency defaults
export const ROUTE_DEFAULTS = {
  [RouteType.STATIC]: { priority: 1.0, changefreq: 'daily' as const },
  [RouteType.DESTINATION]: { priority: 0.9, changefreq: 'weekly' as const },
  [RouteType.CITY]: { priority: 0.8, changefreq: 'daily' as const },
  [RouteType.AGENT]: { priority: 0.8, changefreq: 'weekly' as const }
};

export interface DynamicRoutes {
  cityRoutes: RouteObject[];
  countryRoutes: RouteObject[];
  destinationRoutes: RouteObject[];
  agentProfileRoutes: RouteObject[];
}

export interface Agent {
  name: string;
  clean_city?: string;
  clean_country?: string;
} 