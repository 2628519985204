import { Link } from 'react-router-dom';
import { Mail, MapPin, Phone, Globe, Instagram, Facebook, FileText } from 'lucide-react';

export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-rich-black border-t border-gray-800">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 md:gap-12">
          {/* Brand Section - Updated Layout */}
          <div className="space-y-4">
            <Link to="/" className="inline-block" aria-label="AgenTrav Home">
              <div className="flex items-center gap-3">
                <img 
                  src="/images/herologo.svg" 
                  alt="" // Alt text handled by the link's aria-label
                  className="h-10 w-auto opacity-90 group-hover:opacity-100 transition-opacity"
                  aria-hidden="true" // Decorative image
                />
                <span className="text-xl font-display tracking-wider">
                  <span className="text-off-white">Agen</span>
                  <span className="font-light italic text-gray-400">Trav</span>
                </span>
              </div>
            </Link>
            <p className="text-gray-400 text-sm leading-relaxed max-w-xs">
              Connecting travel agents with discerning travelers for extraordinary journeys around the globe.
            </p>
          </div>

          {/* Quick Links */}
          <nav aria-label="Footer Navigation">
            <h3 className="text-lg font-display mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/" className="text-gray-400 hover:text-white">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/map" className="text-gray-400 hover:text-white">
                  Global Map
                </Link>
              </li>
              <li>
                <Link to="/destinations" className="text-gray-400 hover:text-off-white transition-colors">
                  Destinations
                </Link>
              </li>
              <li>
                <Link to="/why-agentrav" className="text-gray-400 hover:text-off-white transition-colors">
                  Why AgenTrav
                </Link>
              </li>
              <li>
                <Link to="/hotel-marketing" className="text-gray-400 hover:text-off-white transition-colors">
                  Hotel Marketing
                </Link>
              </li>
              <li>
                <Link to="/agent/login" className="text-gray-400 hover:text-off-white transition-colors">
                  Agent Login
                </Link>
              </li>
            </ul>
          </nav>

          {/* Contact Information */}
          <div>
            <h3 className="text-lg font-display mb-4">Contact Us</h3>
            <ul className="space-y-3">
              <li>
                <a 
                  href="mailto:Admin@agentrav.com"
                  className="flex items-center text-gray-400 hover:text-off-white transition-colors"
                  aria-label="Email us"
                >
                  <Mail className="w-4 h-4 mr-2" aria-hidden="true" />
                  Admin@agentrav.com
                </a>
              </li>
              <li>
                <a 
                  href="tel:+9609131623"
                  className="flex items-center text-gray-400 hover:text-off-white transition-colors"
                  aria-label="Call us"
                >
                  <Phone className="w-4 h-4 mr-2" aria-hidden="true" />
                  +960 913-1623
                </a>
              </li>
              <li className="flex items-center text-gray-400">
                <MapPin className="w-4 h-4 mr-2 flex-shrink-0" aria-hidden="true" />
                <address className="not-italic">
                  G.Meadow Male<br />Maldives
                </address>
              </li>
            </ul>
          </div>

          {/* Social & Legal */}
          <div>
            <h3 className="text-lg font-display mb-4">Connect</h3>
            <div className="flex space-x-4 mb-6">
              <a 
                href="https://www.instagram.com/agentravplatform/"
                target="_blank"
                rel="noopener noreferrer"
                className="p-2 bg-rich-black rounded-lg text-gray-400 hover:text-off-white transition-colors"
                aria-label="Follow us on Instagram"
              >
                <Instagram className="w-5 h-5" aria-hidden="true" />
              </a>
              <a 
                href="https://www.facebook.com/agentravplatform"
                target="_blank"
                rel="noopener noreferrer"
                className="p-2 bg-rich-black rounded-lg text-gray-400 hover:text-off-white transition-colors"
                aria-label="Follow us on Facebook"
              >
                <Facebook className="w-5 h-5" aria-hidden="true" />
              </a>
              <a 
                href="https://agentrav.com"
                target="_blank"
                rel="noopener noreferrer"
                className="p-2 bg-rich-black rounded-lg text-gray-400 hover:text-off-white transition-colors"
                aria-label="Visit our website"
              >
                <Globe className="w-5 h-5" aria-hidden="true" />
              </a>
            </div>
            <div className="space-y-2 text-sm">
              <p className="text-gray-500">© {currentYear} AgenTrav. All rights reserved.</p>
              <div className="space-x-4">
                <Link 
                  to="/privacy" 
                  className="text-gray-500 hover:text-gray-400 transition-colors"
                >
                  Privacy Policy
                </Link>
                <Link 
                  to="/terms" 
                  className="text-gray-500 hover:text-gray-400 transition-colors"
                >
                  Terms of Service
                </Link>
                <Link 
                  to="/sitemap" 
                  className="text-gray-500 hover:text-gray-400 transition-colors inline-flex items-center"
                >
                  <FileText className="w-4 h-4 mr-1" />
                  Sitemap
                </Link>
              </div>
              <p className="text-gray-500 text-xs mt-2">
                By using our services, you agree to our Privacy Policy and Terms of Service.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}