import { useState } from 'react';
import { X, Filter, Clock, Star, Tag, DollarSign, Users } from 'lucide-react';
import { CATEGORY_FILTERS, DURATION_FILTERS, RATING_FILTERS } from '../types';

interface FilterOptionsProps {
  onFilterChange: (filters: FilterState) => void;
  initialFilters?: Partial<FilterState>;
}

export interface FilterState {
  priceRange?: {
    min?: number;
    max?: number;
  };
  ratings?: number[];
  duration?: string[];
  categories?: string[];
  minParticipants?: number;
  maxParticipants?: number;
  languages?: string[];
}

export default function FilterOptions({ onFilterChange, initialFilters = {} }: FilterOptionsProps) {
  const [filters, setFilters] = useState<FilterState>({
    priceRange: initialFilters.priceRange || {},
    ratings: initialFilters.ratings || [],
    duration: initialFilters.duration || [],
    categories: initialFilters.categories || [],
    minParticipants: initialFilters.minParticipants,
    maxParticipants: initialFilters.maxParticipants,
    languages: initialFilters.languages || []
  });

  const [priceMin, setPriceMin] = useState<string>(
    filters.priceRange?.min !== undefined ? filters.priceRange.min.toString() : ''
  );
  const [priceMax, setPriceMax] = useState<string>(
    filters.priceRange?.max !== undefined ? filters.priceRange.max.toString() : ''
  );
  
  // For mobile: add a state to track if filters are visible
  const [isFilterVisible, setIsFilterVisible] = useState<boolean>(false);

  const handleFilterChange = (key: keyof FilterState, value: any) => {
    const newFilters = { ...filters, [key]: value };
    setFilters(newFilters);
    onFilterChange(newFilters);
  };

  const handlePriceChange = () => {
    const min = priceMin ? parseInt(priceMin) : undefined;
    const max = priceMax ? parseInt(priceMax) : undefined;
    
    if ((min !== undefined && isNaN(min)) || (max !== undefined && isNaN(max))) {
      return; // Invalid input
    }
    
    const newPriceRange = { min, max };
    handleFilterChange('priceRange', newPriceRange);
  };

  const clearFilter = (key: keyof FilterState) => {
    const newFilters = { ...filters };
    
    if (key === 'priceRange') {
      newFilters.priceRange = {};
      setPriceMin('');
      setPriceMax('');
    } else if (key === 'ratings') {
      newFilters.ratings = [];
    } else if (key === 'duration') {
      newFilters.duration = [];
    } else if (key === 'categories') {
      newFilters.categories = [];
    } else if (key === 'minParticipants') {
      delete newFilters.minParticipants;
    } else if (key === 'maxParticipants') {
      delete newFilters.maxParticipants;
    } else if (key === 'languages') {
      newFilters.languages = [];
    }
    
    setFilters(newFilters);
    onFilterChange(newFilters);
  };

  const LANGUAGES = [
    { value: 'EN', label: 'English' },
    { value: 'FR', label: 'French' },
    { value: 'DE', label: 'German' },
    { value: 'ES', label: 'Spanish' },
    { value: 'IT', label: 'Italian' },
    { value: 'ZH', label: 'Chinese' },
    { value: 'JA', label: 'Japanese' },
    { value: 'RU', label: 'Russian' },
    { value: 'AR', label: 'Arabic' }
  ];
  
  // Count active filters
  const getActiveFilterCount = (): number => {
    let count = 0;
    if (filters.ratings && filters.ratings.length > 0) count++;
    if (filters.duration && filters.duration.length > 0) count++;
    if (filters.categories && filters.categories.length > 0) count++;
    if (filters.priceRange?.min !== undefined || filters.priceRange?.max !== undefined) count++;
    if (filters.minParticipants !== undefined) count++;
    if (filters.maxParticipants !== undefined) count++;
    if (filters.languages && filters.languages.length > 0) count++;
    return count;
  };

  // Mobile filter toggle button
  const MobileFilterButton = () => (
    <button
      onClick={() => setIsFilterVisible(!isFilterVisible)}
      className="fixed bottom-4 right-4 z-20 bg-gradient-to-r from-primary to-primary/80 text-black rounded-full p-3 shadow-lg md:hidden flex items-center transform transition-transform hover:scale-105 active:scale-95"
    >
      <Filter className="w-5 h-5 mr-2" />
      <span className="font-medium">Filters {getActiveFilterCount() > 0 && `(${getActiveFilterCount()})`}</span>
    </button>
  );

  const handleCategoryToggle = (category: string) => {
    const newCategories = [...(filters.categories || [])];
    const index = newCategories.indexOf(category);
    
    if (index === -1) {
      newCategories.push(category);
    } else {
      newCategories.splice(index, 1);
    }
    
    handleFilterChange('categories', newCategories);
  };
  
  const handleDurationToggle = (duration: string) => {
    const newDurations = [...(filters.duration || [])];
    const index = newDurations.indexOf(duration);
    
    if (index === -1) {
      newDurations.push(duration);
    } else {
      newDurations.splice(index, 1);
    }
    
    handleFilterChange('duration', newDurations);
  };
  
  const handleRatingToggle = (rating: string) => {
    const newRatings = [...(filters.ratings || [])];
    const ratingNum = parseInt(rating);
    const index = newRatings.indexOf(ratingNum);
    
    if (index === -1) {
      newRatings.push(ratingNum);
    } else {
      newRatings.splice(index, 1);
    }
    
    handleFilterChange('ratings', newRatings);
  };
  
  const handleLanguageToggle = (language: string) => {
    const newLanguages = [...(filters.languages || [])];
    const index = newLanguages.indexOf(language);
    
    if (index === -1) {
      newLanguages.push(language);
    } else {
      newLanguages.splice(index, 1);
    }
    
    handleFilterChange('languages', newLanguages);
  };

  return (
    <>
      {/* Mobile filter button */}
      <MobileFilterButton />
      
      {/* Filter container - hidden on mobile until toggled */}
      <div className={`
        ${isFilterVisible 
          ? 'fixed inset-0 z-30 bg-black/50 backdrop-blur-sm flex items-center justify-center md:relative md:bg-transparent md:backdrop-blur-none md:block' 
          : 'hidden md:block'}
      `}>
        <div className={`
          bg-gradient-to-b from-gray-800/90 to-gray-900/90 rounded-xl shadow-lg border border-gray-700/30
          ${isFilterVisible 
            ? 'w-11/12 max-h-[90vh] overflow-y-auto mx-auto md:w-full' 
            : 'w-full'}
        `}>
          {/* Mobile close button */}
          {isFilterVisible && (
            <div className="flex justify-between items-center p-4 border-b border-gray-700/30 md:hidden">
              <h3 className="text-white text-lg font-medium">Filters</h3>
              <button 
                onClick={() => setIsFilterVisible(false)}
                className="text-gray-400 p-1 hover:text-white transition-colors"
              >
                <X className="w-5 h-5" />
              </button>
            </div>
          )}
          
          <div className="p-5">
            <div className="flex justify-between items-center mb-5 md:block">
              <h3 className="text-white text-base md:text-lg font-medium tracking-wide hidden md:block">Filter Results</h3>
              <button 
                onClick={() => {
                  setFilters({
                    priceRange: {},
                    ratings: [],
                    duration: [],
                    categories: [],
                    languages: []
                  });
                  setPriceMin('');
                  setPriceMax('');
                  onFilterChange({
                    priceRange: {},
                    ratings: [],
                    duration: [],
                    categories: [],
                    languages: []
                  });
                }}
                className="text-xs text-gray-400 hover:text-primary transition-colors duration-200"
              >
                Reset All
              </button>
            </div>
            
            {/* Price Range */}
            <div className="mb-6">
              <div className="flex items-center justify-between mb-3">
                <h4 className="text-white text-sm font-medium opacity-90">Price Range</h4>
                {(filters.priceRange?.min !== undefined || filters.priceRange?.max !== undefined) && (
                  <button 
                    onClick={() => clearFilter('priceRange')}
                    className="text-xs text-gray-400 hover:text-primary flex items-center transition-colors duration-200"
                  >
                    <X className="w-3 h-3 mr-1" />
                    Clear
                  </button>
                )}
              </div>
              <div className="p-1 bg-gradient-to-br from-[#1c2733]/90 to-[#1c2733]/70 rounded-lg border border-gray-700/30">
                <div className="flex flex-col sm:flex-row items-center">
                  <div className="flex items-center px-3 py-2 w-full">
                    <span className="text-gray-400 mr-1.5 text-xs">$</span>
                    <input
                      type="text"
                      placeholder="0"
                      value={priceMin}
                      onChange={(e) => setPriceMin(e.target.value)}
                      className="w-full bg-transparent text-white text-sm focus:outline-none focus:ring-1 focus:ring-primary/30 rounded px-1"
                    />
                  </div>
                  <div className="hidden sm:block h-6 w-px bg-gray-700/50 mx-1"></div>
                  <div className="flex items-center px-3 py-2 w-full">
                    <span className="text-gray-400 mr-1.5 text-xs">$</span>
                    <input
                      type="text"
                      placeholder="5000"
                      value={priceMax}
                      onChange={(e) => setPriceMax(e.target.value)}
                      className="w-full bg-transparent text-white text-sm focus:outline-none focus:ring-1 focus:ring-primary/30 rounded px-1"
                    />
                  </div>
                  <button
                    onClick={handlePriceChange}
                    className="w-full sm:w-auto ml-0 sm:ml-2 mr-0 sm:mr-1 mt-2 sm:mt-0 px-3 py-2 bg-gradient-to-r from-white/10 to-white/15 hover:from-white/15 hover:to-white/20 text-white rounded-md text-xs font-medium transition-all duration-200 border border-white/5"
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
            
            {/* Tour Categories */}
            <div className="mb-6">
              <div className="flex items-center justify-between mb-3">
                <h4 className="text-white text-sm font-medium opacity-90">Tour Type</h4>
                {filters.categories && filters.categories.length > 0 && (
                  <button 
                    onClick={() => clearFilter('categories')}
                    className="text-xs text-gray-400 hover:text-primary flex items-center transition-colors duration-200"
                  >
                    <X className="w-3 h-3 mr-1" />
                    Clear
                  </button>
                )}
              </div>
              <div className="flex flex-wrap gap-2">
                {CATEGORY_FILTERS.options.map(option => (
                  <button
                    key={option.value}
                    onClick={() => handleCategoryToggle(option.value)}
                    className={`group flex items-center text-xs px-3 py-2 rounded-full transition-all duration-200 ${
                      filters.categories?.includes(option.value)
                        ? 'bg-gradient-to-r from-primary to-primary/80 text-black font-medium shadow-md'
                        : 'bg-gradient-to-br from-[#1c2733]/90 to-[#1c2733]/70 text-gray-300 hover:from-[#1f2d3d] hover:to-[#1f2d3d]/80 border border-gray-700/30'
                    }`}
                  >
                    <Tag className={`w-3.5 h-3.5 mr-1.5 ${filters.categories?.includes(option.value) ? '' : 'group-hover:scale-110'} transition-transform duration-200`} />
                    <span>{option.label}</span>
                  </button>
                ))}
              </div>
            </div>
            
            {/* Tour Duration */}
            <div className="mb-6">
              <div className="flex items-center justify-between mb-3">
                <h4 className="text-white text-sm font-medium opacity-90">Duration</h4>
                {filters.duration && filters.duration.length > 0 && (
                  <button 
                    onClick={() => clearFilter('duration')}
                    className="text-xs text-gray-400 hover:text-primary flex items-center transition-colors duration-200"
                  >
                    <X className="w-3 h-3 mr-1" />
                    Clear
                  </button>
                )}
              </div>
              <div className="flex flex-wrap gap-2">
                {DURATION_FILTERS.options.map(option => (
                  <button
                    key={option.value}
                    onClick={() => handleDurationToggle(option.value)}
                    className={`group flex items-center text-xs px-3 py-2 rounded-full transition-all duration-200 ${
                      filters.duration?.includes(option.value)
                        ? 'bg-gradient-to-r from-primary to-primary/80 text-black font-medium shadow-md'
                        : 'bg-gradient-to-br from-[#1c2733]/90 to-[#1c2733]/70 text-gray-300 hover:from-[#1f2d3d] hover:to-[#1f2d3d]/80 border border-gray-700/30'
                    }`}
                  >
                    <Clock className={`w-3.5 h-3.5 mr-1.5 ${filters.duration?.includes(option.value) ? '' : 'group-hover:scale-110'} transition-transform duration-200`} />
                    <span>{option.label}</span>
                  </button>
                ))}
              </div>
            </div>
            
            {/* Ratings */}
            <div className="mb-6">
              <div className="flex items-center justify-between mb-3">
                <h4 className="text-white text-sm font-medium opacity-90">Rating</h4>
                {filters.ratings && filters.ratings.length > 0 && (
                  <button 
                    onClick={() => clearFilter('ratings')}
                    className="text-xs text-gray-400 hover:text-primary flex items-center transition-colors duration-200"
                  >
                    <X className="w-3 h-3 mr-1" />
                    Clear
                  </button>
                )}
              </div>
              <div className="flex flex-wrap gap-2">
                {RATING_FILTERS.options.map(option => {
                  const ratingValue = parseInt(option.value);
                  return (
                    <button
                      key={option.value}
                      onClick={() => handleRatingToggle(option.value)}
                      className={`group flex items-center text-xs px-3 py-2 rounded-full transition-all duration-200 ${
                        filters.ratings?.includes(ratingValue)
                          ? 'bg-gradient-to-r from-primary to-primary/80 text-black font-medium shadow-md'
                          : 'bg-gradient-to-br from-[#1c2733]/90 to-[#1c2733]/70 text-gray-300 hover:from-[#1f2d3d] hover:to-[#1f2d3d]/80 border border-gray-700/30'
                      }`}
                    >
                      <Star className={`w-3.5 h-3.5 mr-1.5 ${filters.ratings?.includes(ratingValue) ? 'fill-current' : ''} ${filters.ratings?.includes(ratingValue) ? '' : 'group-hover:scale-110'} transition-transform duration-200`} />
                      <span>{option.label}</span>
                    </button>
                  );
                })}
              </div>
            </div>
            
            {/* Languages */}
            <div className="mb-6">
              <div className="flex items-center justify-between mb-3">
                <h4 className="text-white text-sm font-medium opacity-90">Languages</h4>
                {filters.languages && filters.languages.length > 0 && (
                  <button 
                    onClick={() => clearFilter('languages')}
                    className="text-xs text-gray-400 hover:text-primary flex items-center transition-colors duration-200"
                  >
                    <X className="w-3 h-3 mr-1" />
                    Clear
                  </button>
                )}
              </div>
              <div className="flex flex-wrap gap-2">
                {LANGUAGES.map(option => (
                  <button
                    key={option.value}
                    onClick={() => handleLanguageToggle(option.value)}
                    className={`group flex items-center text-xs px-3 py-2 rounded-full transition-all duration-200 ${
                      filters.languages?.includes(option.value)
                        ? 'bg-gradient-to-r from-primary to-primary/80 text-black font-medium shadow-md'
                        : 'bg-gradient-to-br from-[#1c2733]/90 to-[#1c2733]/70 text-gray-300 hover:from-[#1f2d3d] hover:to-[#1f2d3d]/80 border border-gray-700/30'
                    }`}
                  >
                    <span>{option.label}</span>
                  </button>
                ))}
              </div>
            </div>
            
            {/* Apply button for mobile view */}
            <div className="mt-6 md:hidden">
              <button
                onClick={() => setIsFilterVisible(false)}
                className="w-full bg-gradient-to-r from-primary to-primary/80 text-black py-3 rounded-lg font-medium hover:from-primary/90 hover:to-primary/70 transition-all duration-200 shadow-md transform hover:translate-y-[-1px] active:translate-y-[1px]"
              >
                Apply Filters {getActiveFilterCount() > 0 && `(${getActiveFilterCount()})`}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
} 