import { AmadeusService, type AmadeusHotel, type HotelSearchParams, type HotelOfferSearch } from '../../../services/amadeus';
import { City, PopularCity } from '../types';
import { FilterState } from '../components/FilterOptions';
// import { AmadeusLocation } from '../../../services/amadeus'; // Assuming Location type exists - Removed if not exported

// Define a minimal type here if not exported from amadeus.ts
// Ideally, import the correct type from '../../../services/amadeus'
interface AmadeusLocation {
  name: string;
  iataCode?: string;
  address?: {
    countryCode?: string;
    // Add other address fields if needed
  };
  geoCode?: {
    latitude?: number;
    longitude?: number;
  };
  // Add other fields returned by the API if needed
}

// Type for special destination mapping
interface SpecialDestinations {
  [key: string]: PopularCity[];
}

// Initialize AmadeusService
const amadeusService = new AmadeusService(
  "zyQPNk4iZeGyOAlPoT6dArzsESJi1vOo",  // Production API Key
  "3SwjJ7dCPwxUMHoz",  // Production API Secret
  true  // Set to true for production
);

export interface SearchHotelsParams {
  city: City;
  checkIn?: string;
  checkOut?: string;
  adults?: number;
  rooms?: number;
  filters?: HotelSearchParams['filters'];
  page?: number;
  pageSize?: number;
}

interface SearchHotelsResult {
  hotels: AmadeusHotel[];
  totalCount: number;
}

/**
 * Search for hotels based on city/location and basic filters.
 * This function primarily retrieves a list of hotel properties.
 * Pricing and availability are fetched separately using getHotelOffers.
 */
export async function searchHotels({
  city,
  checkIn,
  checkOut,
  adults = 2,
  rooms = 1,
  filters,
  page = 1,
  pageSize = 9
}: SearchHotelsParams): Promise<SearchHotelsResult> {
  try {
    let response;
    const iataCode = city.iataCode || extractIATACode(city.name);

    // Check if this is a Maldives search
    const isMaldives = city.country.toLowerCase() === 'maldives';
    const isAtoll = city.name.toLowerCase().includes('atoll');
    
    // Determine appropriate search radius - use larger radius for Maldives atolls
    const searchRadius = (isMaldives && isAtoll) ? 50 : 5;

    // Prepare parameters for the underlying service methods
    const apiParams: HotelSearchParams = {
      checkInDate: checkIn || '', // Add empty string as fallback to satisfy the type system
      checkOutDate: checkOut || '', // Add empty string as fallback to satisfy the type system
      filters: {
        amenities: filters?.amenities,
        ratings: filters?.ratings
      }
    };

    if (iataCode) {
      console.log(`Searching hotels by city code: ${iataCode} (radius: ${searchRadius}km)`);
      // Pass radius directly to the service method
      response = await amadeusService.getHotelsByCity(iataCode, {
        ...apiParams,
        radius: searchRadius
      });
    } else if (city.latitude && city.longitude) {
      console.log(`Searching hotels by geocode: ${city.latitude}, ${city.longitude} (radius: ${searchRadius}km)`);
      // Pass radius directly to the service method
        response = await amadeusService.getHotelsByGeocode(
          city.latitude,
          city.longitude,
        {
          ...apiParams,
          radius: searchRadius
        }
      );
    } else {
      throw new Error('Cannot search hotels without IATA code or geocoordinates.');
    }

    // Assuming response structure { data: AmadeusHotel[], meta: { count: number } }
    const hotels = response?.data || [];
    const totalCount = response?.meta?.count || hotels.length; // Fallback if count is missing

    console.log(`Found ${totalCount} hotels, returning ${hotels.length}`);

    return {
      hotels,
      totalCount,
    };
  } catch (error) {
    console.error('Error searching hotels:', error);
    throw error;
  }
}

/**
 * Extract IATA code from city name if it looks like it contains one
 * Common IATA codes are 3 uppercase letters
 */
function extractIATACode(cityName: string): string | null {
  const match = cityName.match(/\(([A-Z]{3})\)/);
  return match ? match[1] : null;
}

/**
 * Get hotel offers for specific hotels
 */
export async function getHotelOffers(
  hotelIds: string[],
  checkIn: string,
  checkOut: string,
  adults: number = 2,
  rooms: number = 1,
  currency: string = 'USD',
  bestRateOnly: boolean = false,
  filters?: Partial<FilterState> // Add filters parameter
): Promise<Record<string, HotelOfferSearch[]>> {
  try {
    // Create an empty result object
    const offersByHotelId: Record<string, HotelOfferSearch[]> = {};
    
    // Limit the number of hotels to process to 20 to prevent excessive API requests
    const limitedHotelIds = hotelIds.slice(0, 20);
    
    // Process hotel IDs in batches of 5 to avoid URL size limitations and rate limits
    const BATCH_SIZE = 5;
    
    // Split hotel IDs into batches
    for (let i = 0; i < limitedHotelIds.length; i += BATCH_SIZE) {
      const batchIds = limitedHotelIds.slice(i, i + BATCH_SIZE);
      
      try {
        // Prepare request parameters including boardType if it exists
        const requestParams: any = {
          hotelIds: batchIds,
          checkInDate: checkIn,
          checkOutDate: checkOut,
          adults,
          roomQuantity: rooms,
          currency,
          bestRateOnly
        };
        
        // Add boardType if specified in filters
        if (filters?.boardType) {
          requestParams.boardType = filters.boardType;
        }
        
        const response = await amadeusService.getMultiHotelOffers(requestParams);
        
        // Check if response data is an array and process accordingly
        const offersData = Array.isArray(response.data) ? response.data : [response.data];
        
        // Add to the results object
        offersData.forEach(offer => {
          if (offer.hotel?.hotelId) {
            offersByHotelId[offer.hotel.hotelId] = [offer];
          }
        });

        // Add a small delay between batch requests to prevent rate limiting
        await new Promise(resolve => setTimeout(resolve, 100));
      } catch (batchError) {
        console.warn(`Error fetching offers for batch ${i / BATCH_SIZE + 1}:`, batchError);
        // Continue with next batch instead of failing completely
      }
    }
    
    return offersByHotelId;
  } catch (error) {
    console.error('Error fetching hotel offers:', error);
    throw error;
  }
}

/**
 * Get city suggestions based on query string using Amadeus Location API
 */
export async function searchLocations(query: string): Promise<City[]> {
  if (!query || query.length < 2) {
      return [];
    }

  // Special case handling for Maldives search
  const specialDestinations: SpecialDestinations = {
    "maldives": [
      {
        name: "Malé (MLE), Maldives",
        country: "Maldives",
        latitude: 4.1755, 
        longitude: 73.5093,
        iataCode: "MLE",
        description: "Capital city and main gateway to the Maldives"
      },
      {
        name: "North Malé Atoll, Maldives",
        country: "Maldives", 
        latitude: 4.2714,
        longitude: 73.5089,
        description: "Popular luxury resorts and diving spots"
      },
      {
        name: "South Malé Atoll, Maldives",
        country: "Maldives",
        latitude: 3.8513,
        longitude: 73.4444,
        description: "Serene beaches and exclusive island resorts"
      },
      {
        name: "Ari Atoll, Maldives",
        country: "Maldives",
        latitude: 3.8866,
        longitude: 72.8555,
        description: "Famous for whale sharks and manta ray sightings"
      },
      {
        name: "Baa Atoll, Maldives",
        country: "Maldives",
        latitude: 5.1478,
        longitude: 73.0651,
        description: "UNESCO Biosphere Reserve with exceptional marine life"
      }
    ],
    // Add other special destinations as needed
  };

  // Check if query matches any special destinations (case insensitive partial match)
  const normalizedQuery = query.toLowerCase();
  const matchedDestination = Object.keys(specialDestinations).find(key => 
    normalizedQuery.includes(key) || key.includes(normalizedQuery)
  );
  
  let results: City[] = [];
  
  // If we have a special case match, use those predefined options
  if (matchedDestination) {
    results = [...specialDestinations[matchedDestination]];
  }

  try {
    // Call searchLocations with the query string
    const response = await amadeusService.searchLocations(query);

    // Assuming the response structure is { data: AmadeusLocation[] }
    // Ensure data exists and is an array before processing
    const locationsData: AmadeusLocation[] = Array.isArray(response?.data) ? response.data : [];

    // Use a Map to prevent duplicates based on a unique key
    const uniqueLocations = new Map<string, AmadeusLocation>();
    locationsData.forEach(location => {
      // Use a more robust key, handling potential undefined geoCode
      const key = location.iataCode || (location.geoCode ? `${location.geoCode.latitude},${location.geoCode.longitude}` : location.name);
      if (key && !uniqueLocations.has(key)) {
        uniqueLocations.set(key, location);
      }
    });

    // Transform unique locations into the City interface
    const apiResults: City[] = Array.from(uniqueLocations.values()).map(location => ({
      name: location.name,
      country: location.address?.countryCode || 'Unknown',
      latitude: location.geoCode?.latitude || 0,
      longitude: location.geoCode?.longitude || 0,
      iataCode: location.iataCode,
    }));

    // For special cases, combine API results with predefined options
    // For normal searches, just use API results
    results = matchedDestination
      ? [...results, ...apiResults]
      : apiResults;
    
    // Deduplicate in case API returns some of our predefined options
    const uniqueResults = deduplicateResults(results);

    return uniqueResults.slice(0, 7); // Return limited results

  } catch (error) {
    console.error(`Error searching locations for "${query}":`, error);
    
    // If we have special case results, return those even if API fails
    if (matchedDestination) {
      return specialDestinations[matchedDestination];
    }
    
    // Otherwise fallback to static list or empty array
    return fallbackSearchCities(query);
  }
}

/**
 * Helper function to deduplicate cities based on name and coordinates
 */
function deduplicateResults(cities: City[]): City[] {
  const seen = new Set();
  return cities.filter(city => {
    const key = `${city.name}:${city.latitude}:${city.longitude}`;
    if (seen.has(key)) return false;
    seen.add(key);
    return true;
  });
}

/**
 * Fallback local search when Amadeus API fails
 * Note: This is a mock implementation as a backup only
 */
function fallbackSearchCities(query: string): Promise<City[]> {
  return new Promise((resolve) => {
    setTimeout(() => {
      // Mock data for city search
      const cities: City[] = [
        { name: 'New York', country: 'USA', latitude: 40.7128, longitude: -74.0060 },
        { name: 'London', country: 'UK', latitude: 51.5074, longitude: -0.1278 },
        { name: 'Paris', country: 'France', latitude: 48.8566, longitude: 2.3522 },
        { name: 'Tokyo', country: 'Japan', latitude: 35.6762, longitude: 139.6503 },
        { name: 'Rome', country: 'Italy', latitude: 41.9028, longitude: 12.4964 },
        { name: 'Sydney', country: 'Australia', latitude: -33.8688, longitude: 151.2093 },
        { name: 'Barcelona', country: 'Spain', latitude: 41.3851, longitude: 2.1734 },
        { name: 'Berlin', country: 'Germany', latitude: 52.5200, longitude: 13.4050 },
        { name: 'Dubai', country: 'UAE', latitude: 25.2048, longitude: 55.2708 },
        { name: 'Hong Kong', country: 'China', latitude: 22.3193, longitude: 114.1694 },
        { name: 'Amsterdam', country: 'Netherlands', latitude: 52.3676, longitude: 4.9041 },
        { name: 'Singapore', country: 'Singapore', latitude: 1.3521, longitude: 103.8198 },
        { name: 'Bangkok', country: 'Thailand', latitude: 13.7563, longitude: 100.5018 },
        { name: 'Istanbul', country: 'Turkey', latitude: 41.0082, longitude: 28.9784 },
        { name: 'Rio de Janeiro', country: 'Brazil', latitude: -22.9068, longitude: -43.1729 },
        { name: 'Cape Town', country: 'South Africa', latitude: -33.9249, longitude: 18.4241 },
        { name: 'Moscow', country: 'Russia', latitude: 55.7558, longitude: 37.6173 },
        { name: 'Los Angeles', country: 'USA', latitude: 34.0522, longitude: -118.2437 },
        { name: 'Miami', country: 'USA', latitude: 25.7617, longitude: -80.1918 },
        { name: 'Toronto', country: 'Canada', latitude: 43.6511, longitude: -79.3472 },
        { name: 'Vancouver', country: 'Canada', latitude: 49.2827, longitude: -123.1207 },
        { name: 'Madrid', country: 'Spain', latitude: 40.4168, longitude: -3.7038 },
        { name: 'Vienna', country: 'Austria', latitude: 48.2082, longitude: 16.3738 },
        { name: 'Seoul', country: 'South Korea', latitude: 37.5665, longitude: 126.9780 },
        { name: 'Cairo', country: 'Egypt', latitude: 30.0444, longitude: 31.2357 },
        { name: 'Sydney (SYD)', country: 'Australia', latitude: -33.8688, longitude: 151.2093 },
        { name: 'MCO (Orlando)', country: 'USA', latitude: 28.5383, longitude: -81.3792 },
        { name: 'CDG (Paris)', country: 'France', latitude: 49.0097, longitude: 2.5479 },
        { name: 'HAV (Havana)', country: 'Cuba', latitude: 23.1136, longitude: -82.3666 },
        { name: 'DXB (Dubai)', country: 'UAE', latitude: 25.2532, longitude: 55.3657 },
      ];
      
      const filtered = cities.filter(city => 
        city.name.toLowerCase().includes(query.toLowerCase()) || 
        city.country.toLowerCase().includes(query.toLowerCase())
      );
      
      resolve(filtered);
    }, 300);
  });
}

/**
 * Get city suggestions based on query string using Amadeus Location API
 * @deprecated Use searchLocations instead
 */
export const searchCities = searchLocations;

/**
 * Fallback search for Maldives when specific atoll/island search returns no results
 * Uses Male (MLE) as a central point with a very large radius
 */
export async function fallbackMaldivesSearch(
  checkIn?: string,
  checkOut?: string,
  adults = 2,
  rooms = 1,
  filters?: HotelSearchParams['filters'],
  page = 1,
  pageSize = 9
): Promise<SearchHotelsResult> {
  console.log('Using fallback search for Maldives resorts');
  
  try {
    // Use Male as the central point for search
    const maleCity: City = {
      name: "Malé International Airport (MLE), Maldives",
      country: "Maldives",
      latitude: 4.1755,
      longitude: 73.5093,
      iataCode: "MLE"
    };
    
    // Call the normal search but with a wider radius
    const result = await searchHotels({
      city: maleCity,
      checkIn,
      checkOut,
      adults,
      rooms,
      filters,
      page,
      pageSize
    });
    
    console.log(`Fallback search found ${result.totalCount} hotels in Maldives`);
    return result;
  } catch (error) {
    console.error('Error in fallback Maldives search:', error);
    throw error;
  }
}

/**
 * Check if a search is related to Maldives
 */
export function isMaldivesSearch(city: City): boolean {
  return city.country.toLowerCase() === 'maldives' || 
         city.name.toLowerCase().includes('maldives') ||
         city.name.toLowerCase().includes('malé') ||
         city.iataCode === 'MLE';
} 