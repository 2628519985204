import React, { memo } from 'react';
import { FeatureCardProps } from '../types';
import { Globe, Users, Building2, Compass, Sparkles, MessageSquare } from 'lucide-react';

// Memoized feature card component
const FeatureCard = memo(({ icon: Icon, title, description }: FeatureCardProps) => (
  <div className="group relative overflow-hidden rounded-2xl bg-white/[0.02] hover:bg-white/[0.04] border border-white/[0.05] transition-all duration-300">
    <div className="absolute inset-0 bg-gradient-to-br from-primary/5 via-transparent to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
    <div className="relative p-5 sm:p-6">
      <div className="mb-4">
        <div className="p-2.5 rounded-xl bg-white/[0.05] w-fit">
          <Icon className="w-5 h-5 text-white/70" />
        </div>
      </div>
      <h3 className="text-white text-lg font-medium mb-2">{title}</h3>
      <p className="text-white/50 text-sm font-light leading-relaxed">{description}</p>
    </div>
  </div>
));
FeatureCard.displayName = 'FeatureCard';

const FeatureSection: React.FC = () => {
  // Feature data
  const features = [
    {
      icon: Globe,
      title: 'Interactive Map',
      description: 'Explore travel agents and destinations on our global map'
    },
    {
      icon: Users,
      title: 'Expert Agents',
      description: 'Connect with verified travel specialists in 150+ countries'
    },
    {
      icon: Building2,
      title: 'Luxury Hotels',
      description: 'Discover and book premium accommodations worldwide'
    },
    {
      icon: Compass,
      title: 'Curated Tours',
      description: 'Browse 1000+ expertly crafted tours and experiences'
    },
    {
      icon: Sparkles,
      title: 'Travel Expo',
      description: 'Explore exclusive travel deals and showcased destinations'
    },
    {
      icon: MessageSquare,
      title: 'Marco AI',
      description: 'Get personalized travel recommendations from our AI assistant'
    }
  ];

  return (
    <div className="w-full py-12 sm:py-20 lg:py-24 bg-gradient-to-b from-black via-neutral-950 to-black">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-8 sm:mb-12">
          <h2 className="text-xl sm:text-4xl font-display font-medium tracking-tight text-white mb-3 sm:mb-4">
            Your Complete Travel Platform
          </h2>
          <p className="text-white/60 text-sm sm:text-lg font-light leading-relaxed max-w-[640px] mx-auto">
            Discover all the ways AgenTrav can enhance your travel experience
          </p>
        </div>
        
        <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
          {features.map((feature, index) => (
            <FeatureCard 
              key={index}
              icon={feature.icon}
              title={feature.title}
              description={feature.description}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FeatureSection; 