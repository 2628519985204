import { ImageSize } from '../types';

// Updated image sizes with better compression settings and more specific use cases
export const IMAGE_SIZES = {
  HERO: {
    width: 2400,
    height: 1350,
    quality: 92,
    format: 'webp' as const
  },
  CARD: {
    width: 800,
    height: 600,
    quality: 85,
    format: 'webp' as const
  },
  THUMBNAIL: {
    width: 400,
    height: 300,
    quality: 80,
    format: 'webp' as const
  },
  FEATURE_IMAGE: {
    desktop: {
      width: 960,
      height: 720,
      quality: 85,
      format: 'webp' as const
    },
    mobile: {
      width: 640,
      height: 480,
      quality: 80,
      format: 'webp' as const
    },
    tiny: {
      width: 30,
      height: 20,
      quality: 10,
      format: 'webp' as const
    }
  }
} as const;

// Update HERO_BACKGROUNDS to use raw URLs
export const HERO_BACKGROUNDS = [
  {
    url: "https://images.unsplash.com/photo-1579033461380-adb47c3eb938",
    position: "center"
  },
  {
    url: "https://images.unsplash.com/photo-1506197603052-3cc9c3a201bd",
    position: "center 40%"
  },
  {
    url: "https://images.unsplash.com/photo-1682687220742-aba13b6e50ba",
    position: "center 60%"
  },
  {
    url: "https://images.unsplash.com/photo-1682687221175-9eb73df644c7",
    position: "center"
  }
];

// Enhanced image optimization with modern formats and better compression
export const optimizeImageUrl = (url: string, { width, height, quality, format }: ImageSize): string => {
  if (!url) return url;
  
  if (url.includes('unsplash.com')) {
    // Unsplash optimization parameters
    const urlObj = new URL(url);
    urlObj.searchParams.set('w', width.toString());
    urlObj.searchParams.set('h', height.toString());
    urlObj.searchParams.set('q', quality.toString());
    urlObj.searchParams.set('fm', format);
    urlObj.searchParams.set('fit', 'crop');
    urlObj.searchParams.set('auto', 'format,compress');
    return urlObj.toString();
  }
  
  if (url.includes('cloudinary.com')) {
    // Cloudinary optimization
    return url.replace('/upload/', `/upload/f_${format},q_${quality},w_${width},h_${height},c_fill/`);
  }
  
  if (url.includes('imgix.net')) {
    // Imgix optimization
    return `${url}?w=${width}&h=${height}&q=${quality}&fm=${format}&fit=crop&auto=compress`;
  }

  return url;
}; 