import React, { useState, useRef, useEffect } from 'react';
import { Calendar as CalendarIcon, MapPin, Users, Search, X, Map as MapIcon } from 'lucide-react';
import { DateRange } from 'react-day-picker';
import { format, addDays } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { City } from '../types';
import { searchCities } from '../services/tourSearchService';

interface SearchFormProps {
  initialCity?: City;
  initialDateRange?: DateRange;
  initialParticipants?: number;
  onSearch: (params: {
    city: City;
    dateRange: DateRange | undefined;
    participants: number;
  }) => void;
}

export default function SearchForm({
  initialCity,
  initialDateRange,
  initialParticipants = 2,
  onSearch
}: SearchFormProps) {
  const navigate = useNavigate();
  const [city, setCity] = useState<City | null>(initialCity || null);
  const [dateRange, setDateRange] = useState<DateRange | undefined>(initialDateRange);
  const [participants, setParticipants] = useState(initialParticipants);
  
  // For city search dropdown
  const [isSearching, setIsSearching] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<City[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const searchResultsRef = useRef<HTMLDivElement>(null);
  
  // For calendar dropdown
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const calendarRef = useRef<HTMLDivElement>(null);
  
  // Handle document clicks to close dropdowns
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      // Close search results if click is outside
      if (
        searchInputRef.current && 
        searchResultsRef.current && 
        !searchInputRef.current.contains(event.target as Node) && 
        !searchResultsRef.current.contains(event.target as Node)
      ) {
        setIsSearching(false);
      }
      
      // Close calendar if click is outside
      if (
        calendarRef.current && 
        !calendarRef.current.contains(event.target as Node)
      ) {
        setIsCalendarOpen(false);
      }
    }
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  // Handle city search
  useEffect(() => {
    if (searchQuery.length < 2) {
      setSearchResults([]);
      setIsLoading(false);
      return;
    }
    
    console.log(`[SearchForm] Starting search for: "${searchQuery}"`);
    setIsLoading(true);
    
    const timer = setTimeout(async () => {
      try {
        console.log(`[SearchForm] Executing search for: "${searchQuery}"`);
        const results = await searchCities(searchQuery);
        console.log(`[SearchForm] Search complete, received ${results.length} results:`, results);
        
        // Ensure results are in the correct format
        const validResults = results.filter(city => 
          city && typeof city.name === 'string' && 
          typeof city.country === 'string' && 
          typeof city.latitude === 'number' && 
          typeof city.longitude === 'number'
        );
        
        if (validResults.length !== results.length) {
          console.warn(`[SearchForm] Filtered out ${results.length - validResults.length} invalid results`);
        }
        
        setSearchResults(validResults);
        
        // If there are results but they're not showing in the UI, let's log more details
        if (validResults.length > 0) {
          console.log(`[SearchForm] Search results ready to display:`, validResults);
        }
      } catch (error) {
        console.error('[SearchForm] Error searching cities:', error);
        setSearchResults([]);
      } finally {
        setIsLoading(false);
      }
    }, 300);
    
    return () => clearTimeout(timer);
  }, [searchQuery]);
  
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!city) {
      alert('Please select a city.');
      return;
    }
    
    onSearch({
      city,
      dateRange,
      participants
    });
  };
  
  const selectCity = (city: City | null) => {
    setCity(city);
    setIsSearching(false);
    setSearchQuery('');
  };
  
  const handleDateChange = (range: DateRange | undefined) => {
    setDateRange(range);
    if (range?.from && range?.to) {
      setIsCalendarOpen(false);
    }
  };
  
  const decrementParticipants = () => {
    if (participants > 1) {
      setParticipants(participants - 1);
    }
  };
  
  const incrementParticipants = () => {
    setParticipants(participants + 1);
  };
  
  const formatCityName = (city: City): string => {
    return `${city.name}, ${city.country}`;
  };
  
  const getFormattedDateRange = () => {
    if (!dateRange?.from) return 'Select dates';
    
    if (dateRange.to) {
      return `${format(dateRange.from, 'MMM d')} - ${format(dateRange.to, 'MMM d, yyyy')}`;
    }
    
    return format(dateRange.from, 'MMM d, yyyy');
  };
  
  const navigateToMapView = () => {
    if (!city) {
      alert('Please select a city first.');
      return;
    }
    
    const encodedLocation = encodeURIComponent(city.name);
    navigate(`/tours/map/${encodedLocation}`);
  };
  
  return (
    <div className="bg-gradient-to-b from-gray-800/70 to-gray-900/70 backdrop-blur-sm p-6 rounded-xl border border-gray-700/30 shadow-lg">
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {/* City Search */}
          <div className="relative">
            <label className="block mb-2 text-sm font-medium text-gray-300">Destination</label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <MapPin className="h-5 w-5 text-gray-400" />
              </div>
              
              <input
                ref={searchInputRef}
                type="text"
                placeholder="Where are you going?"
                value={city ? formatCityName(city) : searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                  setCity(null);
                  setIsSearching(true);
                }}
                onFocus={() => setIsSearching(true)}
                className="w-full pl-10 pr-12 py-3 bg-gradient-to-br from-[#1c2733]/90 to-[#1c2733]/70 text-white rounded-lg border border-gray-700/50 focus:outline-none focus:ring-1 focus:ring-primary/30 focus:border-primary/30"
              />
              
              {city && (
                <button
                  type="button"
                  onClick={() => selectCity(null)}
                  className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400 hover:text-white"
                >
                  <X className="h-5 w-5" />
                </button>
              )}
            </div>
            
            {/* City Search Results */}
            {isSearching && (
              <div 
                ref={searchResultsRef}
                className="absolute z-10 mt-2 w-full bg-gray-800 border border-gray-700 rounded-lg shadow-lg max-h-60 overflow-y-auto"
              >
                {isLoading ? (
                  <div className="p-4 text-center text-gray-400">
                    <span className="animate-pulse">Searching...</span>
                  </div>
                ) : searchResults.length > 0 ? (
                  <ul>
                    {searchResults.map((city, index) => (
                      <li key={`${city.name}-${city.country}-${index}`}>
                        <button
                          type="button"
                          onClick={() => {
                            console.log(`[SearchForm] Selected city:`, city);
                            selectCity(city);
                          }}
                          className="w-full px-4 py-3 text-left hover:bg-gray-700 flex items-center"
                        >
                          <MapPin className="h-5 w-5 mr-2 text-gray-400" />
                          <div>
                            <div className="text-white">{city.name}</div>
                            <div className="text-gray-400 text-sm">
                              {city.country}
                              {city.latitude && city.longitude && (
                                <span className="text-xs text-gray-500 ml-2">
                                  ({city.latitude.toFixed(2)}, {city.longitude.toFixed(2)})
                                </span>
                              )}
                            </div>
                          </div>
                        </button>
                      </li>
                    ))}
                  </ul>
                ) : searchQuery.length >= 2 ? (
                  <div className="p-4 text-center text-gray-400">
                    No results found. Try a different search.
                  </div>
                ) : (
                  <div className="p-4 text-center text-gray-400">
                    Type at least 2 characters to search
                  </div>
                )}
              </div>
            )}
          </div>
          
          {/* Date Range Picker */}
          <div className="relative">
            <label className="block mb-2 text-sm font-medium text-gray-300">When</label>
            <button
              type="button"
              onClick={() => setIsCalendarOpen(!isCalendarOpen)}
              className="w-full flex items-center px-4 py-3 bg-gradient-to-br from-[#1c2733]/90 to-[#1c2733]/70 text-white rounded-lg border border-gray-700/50 focus:outline-none focus:ring-1 focus:ring-primary/30"
            >
              <CalendarIcon className="h-5 w-5 mr-2 text-gray-400" />
              <span className={dateRange?.from ? 'text-white' : 'text-gray-400'}>
                {getFormattedDateRange()}
              </span>
            </button>
            
            {/* Calendar Dropdown */}
            {isCalendarOpen && (
              <div 
                ref={calendarRef}
                className="absolute z-10 mt-2 bg-gray-800 border border-gray-700 rounded-lg shadow-lg p-4"
              >
                <div className="flex justify-between mb-4">
                  <button
                    type="button"
                    onClick={() => handleDateChange({
                      from: new Date(),
                      to: addDays(new Date(), 7)
                    })}
                    className="px-3 py-1 text-xs bg-primary/10 text-primary rounded-full hover:bg-primary/20"
                  >
                    Next 7 days
                  </button>
                  <button
                    type="button"
                    onClick={() => handleDateChange({
                      from: addDays(new Date(), 14),
                      to: addDays(new Date(), 21)
                    })}
                    className="px-3 py-1 text-xs bg-primary/10 text-primary rounded-full hover:bg-primary/20"
                  >
                    In 2 weeks
                  </button>
                </div>
                
                {/* Calendar component would go here - we'll use a placeholder */}
                <div className="bg-gray-700/50 rounded-lg p-6 text-center">
                  <p className="text-gray-400">Calendar component would be integrated here</p>
                  <p className="text-gray-500 text-xs mt-2">For this example, please use the quick selection buttons</p>
                </div>
                
                <div className="flex justify-between mt-4">
                  <button
                    type="button"
                    onClick={() => setIsCalendarOpen(false)}
                    className="px-4 py-2 text-sm bg-gray-700 text-white rounded-lg hover:bg-gray-600"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    onClick={() => setIsCalendarOpen(false)}
                    className="px-4 py-2 text-sm bg-primary text-black rounded-lg hover:bg-primary/90"
                  >
                    Apply
                  </button>
                </div>
              </div>
            )}
          </div>
          
          {/* Participants */}
          <div>
            <label className="block mb-2 text-sm font-medium text-gray-300">Participants</label>
            <div className="flex items-center bg-gradient-to-br from-[#1c2733]/90 to-[#1c2733]/70 rounded-lg border border-gray-700/50">
              <button
                type="button"
                onClick={decrementParticipants}
                className="px-4 py-3 text-gray-400 hover:text-white"
              >
                −
              </button>
              <div className="flex-1 flex items-center justify-center">
                <Users className="h-5 w-5 mr-2 text-gray-400" />
                <span className="text-white">{participants} {participants === 1 ? 'person' : 'people'}</span>
              </div>
              <button
                type="button"
                onClick={incrementParticipants}
                className="px-4 py-3 text-gray-400 hover:text-white"
              >
                +
              </button>
            </div>
          </div>
        </div>
        
        {/* Search Button */}
        <div className="flex flex-col sm:flex-row gap-3">
          <button
            type="submit"
            className="flex-1 bg-gradient-to-r from-primary to-primary/80 text-black font-medium py-4 rounded-lg hover:from-primary/90 hover:to-primary/70 transition-all shadow-md flex items-center justify-center"
          >
            <Search className="h-5 w-5 mr-2" />
            Find Tours
          </button>
          
          <button
            type="button"
            onClick={navigateToMapView}
            className="sm:w-48 bg-gray-800 text-primary border border-primary/30 font-medium py-4 rounded-lg hover:bg-gray-700 transition-all shadow-md flex items-center justify-center"
          >
            <MapIcon className="h-5 w-5 mr-2" />
            View on Map
          </button>
        </div>
      </form>
    </div>
  );
} 