import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getRedirectPath } from '../utils/redirects';
import { createClient } from '@supabase/supabase-js';
import { generateAgentUrl, formatUrlSlug } from '../utils/agentUrl';

export default function RedirectHandler() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // First check standard redirects
    const redirectPath = getRedirectPath(location.pathname);
    if (redirectPath && redirectPath !== location.pathname) {
      // Use replace to avoid adding to browser history
      navigate(redirectPath, { 
        replace: true,
        state: { 
          from: location.pathname,
          isRedirect: true 
        }
      });
      return;
    }

    // Check for agent profile URLs that need to be updated to the new format
    const agentProfileMatch = location.pathname.match(/^\/travel-agents\/([^/]+)\/([^/]+)$/);
    if (agentProfileMatch) {
      const [_, agentName, cityName] = agentProfileMatch;
      
      // Decode URL components to handle special characters and spaces
      const decodedAgentName = decodeURIComponent(agentName);
      const decodedCityName = decodeURIComponent(cityName);
      
      // Check if this is already in the new format (contains an ID)
      if (!decodedAgentName.match(/-[a-zA-Z0-9]+$/)) {
        // This is an old format URL, look up the agent and redirect
        handleAgentRedirect(decodedAgentName, decodedCityName);
      }
    }
  }, [location.pathname, navigate]);

  // Function to handle agent redirects
  const handleAgentRedirect = async (agentName: string, cityName: string) => {
    try {
      console.log('Redirecting agent URL:', { agentName, cityName });
      
      const supabase = createClient(
        process.env.VITE_SUPABASE_URL!,
        process.env.VITE_SUPABASE_ANON_KEY!
      );

      // Clean up the agent name and city name for database query
      const cleanAgentName = agentName.replace(/[&.]/g, '').replace(/-/g, ' ');
      const cleanCityName = cityName.replace(/[&.]/g, '').replace(/-/g, ' ');

      console.log('Searching for agent with:', { cleanAgentName, cleanCityName });

      // Look up the agent by name and city with more flexible matching
      const { data: agents } = await supabase
        .from('agents')
        .select('*')
        .or(`name.ilike.%${cleanAgentName}%,name.ilike.%${agentName}%`)
        .or(`city.ilike.%${cleanCityName}%,clean_city.ilike.%${cleanCityName}%`)
        .limit(5);

      console.log('Found agents:', agents);

      if (agents && agents.length > 0) {
        // Find the best match by comparing similarity
        let bestMatch = agents[0];
        let highestScore = 0;
        
        for (const agent of agents) {
          // Calculate a simple similarity score
          const nameScore = calculateSimilarity(agent.name, agentName);
          const cityScore = calculateSimilarity(agent.city, cityName);
          const totalScore = nameScore + cityScore;
          
          if (totalScore > highestScore) {
            highestScore = totalScore;
            bestMatch = agent;
          }
        }
        
        // Generate the new URL format with string ID
        const newUrl = generateAgentUrl({
          ...bestMatch,
          id: String(bestMatch.id)
        });
        
        console.log('Redirecting to:', newUrl);
        
        // Only redirect if the URL is different
        if (newUrl !== location.pathname) {
          navigate(newUrl, {
            replace: true,
            state: {
              from: location.pathname,
              isRedirect: true
            }
          });
        }
      }
    } catch (error) {
      console.error('Error redirecting agent URL:', error);
    }
  };
  
  // Simple string similarity function (0-1 score)
  const calculateSimilarity = (str1: string = '', str2: string = ''): number => {
    const s1 = formatUrlSlug(str1);
    const s2 = formatUrlSlug(str2);
    
    if (!s1 || !s2) return 0;
    if (s1 === s2) return 1;
    
    // Check if one contains the other
    if (s1.includes(s2) || s2.includes(s1)) {
      return 0.8;
    }
    
    // Count matching characters
    let matches = 0;
    const longer = s1.length > s2.length ? s1 : s2;
    const shorter = s1.length > s2.length ? s2 : s1;
    
    for (let i = 0; i < shorter.length; i++) {
      if (longer.includes(shorter[i])) {
        matches++;
      }
    }
    
    return matches / longer.length;
  };

  return null;
} 