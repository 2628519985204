import { MapPin, X } from 'lucide-react';
import { useChat } from '../../contexts/ChatContext';
import { useNavigate } from 'react-router-dom';

interface ChatHeaderProps {
  cityName?: string;
  activitiesCount?: number;
}

export default function ChatHeader({ cityName, activitiesCount }: ChatHeaderProps) {
  const { state, dispatch } = useChat();
  const navigate = useNavigate();

  const handleExpandToFullPage = () => {
    // Navigate to the chat page with city context if available
    const path = cityName ? `/chat?city=${encodeURIComponent(cityName)}` : '/chat';
    navigate(path);
  };

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between px-4 py-3 border-b border-white/10">
        <div className="flex items-center space-x-3">
          <div className="flex items-center space-x-2">
            <div className="w-2 h-2 rounded-full bg-teal-400 animate-pulse" />
            <div className="flex flex-col leading-none">
              <h3 className="text-sm font-medium text-white/90">Marco</h3>
              <span className="text-xs text-white/60">by AgenTrav</span>
            </div>
          </div>

          {cityName && !state.isMinimized && (
            <div className="h-4 w-px bg-white/10 mx-2" />
          )}

          {cityName && !state.isMinimized && (
            <div className="flex items-center space-x-1.5 text-xs text-white/60">
              <MapPin className="w-3 h-3" />
              <span>{cityName}</span>
              {activitiesCount !== undefined && (
                <span className="text-teal-400">({activitiesCount})</span>
              )}
            </div>
          )}

          {state.isTyping && (
            <span className="text-xs text-white/50 ml-2">(exploring...)</span>
          )}
        </div>
        
        <div className="flex items-center space-x-1">
          {!state.isMinimized && (
            <button
              onClick={handleExpandToFullPage}
              className="p-1.5 hover:bg-white/5 rounded-md transition-colors"
              title="Expand to full page"
            >
              <svg className="w-4 h-4 text-white/70" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
              </svg>
            </button>
          )}
          
          <button
            onClick={() => dispatch({
              type: state.isMinimized ? 'MAXIMIZE_CHAT' : 'MINIMIZE_CHAT'
            })}
            className="p-1.5 hover:bg-white/5 rounded-md transition-colors"
            title={state.isMinimized ? "Maximize chat" : "Minimize chat"}
          >
            {state.isMinimized ? (
              <svg className="w-4 h-4 text-white/70" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5v-4m0 4h-4m4 0l-5-5" />
              </svg>
            ) : (
              <svg className="w-4 h-4 text-white/70" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            )}
          </button>
          
          <button
            onClick={() => dispatch({ type: 'CLOSE_CHAT' })}
            className="p-1.5 hover:bg-white/5 rounded-md transition-colors"
            title="Close chat"
          >
            <X className="w-4 h-4 text-white/70" />
          </button>
        </div>
      </div>
    </div>
  );
} 