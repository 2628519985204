import { BrowserRouter as Router, Routes as RouterRoutes, Route, Navigate } from 'react-router-dom';
import type { RouteObject } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { AuthProvider } from './contexts/AuthContext';
import { ToastProvider } from './components/providers/ToastProvider';
import ProtectedRoute from './components/ProtectedRoute';
import ProtectedAdminRoute from './components/ProtectedAdminRoute';
import Navigation from './components/Navigation';
import LoadingFallback from './components/LoadingFallback';
import { ErrorBoundary } from './components/ErrorBoundary';
import GoogleAnalytics from './components/GoogleAnalytics';
import RedirectHandler from './components/RedirectHandler';
import RedirectTester from './components/RedirectTester';
import * as Routes from './routes';
import { ChatProvider } from './contexts/ChatContext';
import HotelSearchPage from './pages/hotels';
import ToursPage from './pages/tours';
import PublicHome from './pages/PublicHome';

// Optimize component imports with named chunks
const AgentProfilePage = lazy(() => import(
  /* webpackChunkName: "agent-profile" */ './pages/AgentProfilePage/index'
));
const MapPage = lazy(() => import(
  /* webpackChunkName: "map-page" */ './pages/MapPage'
));
const ChatPage = lazy(() => import(
  /* webpackChunkName: "chat-page" */ './pages/chat'
));


// Helper function to render routes recursively
function renderRoutes(routes: RouteObject[]) {
  return routes.map((route) => (
    <Route
      key={route.path}
      path={route.path}
      element={
        route.element && (
          <ProtectedRoute>
            {route.element}
          </ProtectedRoute>
        )
      }
    >
      {route.children && renderRoutes(route.children)}
    </Route>
  ));
}

// Create a redirect component with error handling
function PasswordResetRedirect() {
  try {
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get('code');
    const tokenHash = searchParams.get('token_hash');
    const type = searchParams.get('type');
    
    if (code || tokenHash) {
      const redirectUrl = tokenHash 
        ? `/auth/callback?token_hash=${tokenHash}&type=${type || 'recovery'}&next=/reset-password`
        : `/auth/callback?token_hash=${code}&type=recovery&next=/reset-password`;
      
      window.location.replace(redirectUrl);
      
      return (
        <div className="min-h-screen bg-black flex items-center justify-center">
          <div className="text-center">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-accent mx-auto mb-4" />
            <p className="text-muted-foreground">Processing your request...</p>
            <p className="text-sm text-muted-foreground mt-2">
              If you're not redirected, <button 
                onClick={() => window.location.replace(redirectUrl)}
                className="text-accent underline"
              >
                click here
              </button>
            </p>
          </div>
        </div>
      );
    }
    
    if (window.location.pathname === '/') {
      return <PublicHome />;
    }

    return <Navigate to="/forgot-password" replace />;
    
  } catch (error) {
    console.error('Password reset redirect error:', error);
    return <Navigate to="/forgot-password" replace />;
  }
}

export default function App() {
  return (
    <HelmetProvider>
      <Helmet>
        <style>{`
          .loading-fallback {
            /* Critical CSS for loading state */
          }
          nav, main {
            /* Critical layout CSS */
          }
        `}</style>
        <link rel="preload" href="/fonts/Inter.woff2" as="font" type="font/woff2" crossOrigin="anonymous" />
        <link rel="preload" href="/hero-image.avif" as="image" />
      </Helmet>
      <ErrorBoundary fallback={<div className="min-h-screen flex items-center justify-center p-4 bg-black"><div className="text-white text-center"><h2 className="text-xl mb-2">Page Not Found</h2><p>There was an error loading this page.</p></div></div>}>
        <Router>
          <AuthProvider>
            <ChatProvider>
              <ToastProvider>
                <GoogleAnalytics />
                <RedirectHandler />
                <div id="app-root-container" className="min-h-screen bg-black flex flex-col">
                  <Navigation />
                  <main className="flex-grow">
                    <Suspense fallback={<LoadingFallback />}>
                      <RouterRoutes>
                        {/* Development Routes */}
                        {process.env.NODE_ENV === 'development' && (
                          <Route path="/test-redirects" element={<RedirectTester />} />
                        )}
                        
                        {/* Root route with password reset handling */}
                        <Route 
                          path="/" 
                          element={
                            <ErrorBoundary fallback={<div className="p-4 text-red-500">Failed to process password reset.</div>}>
                              <PasswordResetRedirect />
                            </ErrorBoundary>
                          } 
                        />
                        
                        {/* Auth Routes */}
                        <Route path="/auth/callback" element={<Routes.AuthCallback />} />
                        <Route path="/reset-password" element={<Routes.ResetPassword />} />
                        <Route path="/forgot-password" element={<Routes.ForgotPassword />} />
                        <Route path="/login" element={<Routes.Login />} />
                        <Route path="/register" element={<Routes.Register />} />
                        <Route path="/register/:userType" element={<Routes.Register />} />
                        <Route path="/agent/login" element={<Routes.Login userType="agent" />} />
                        <Route path="/hotel/login" element={<Routes.Login userType="hotel" />} />
                        
                        {/* Public Routes */}
                        <Route path="/" element={<PublicHome />} />
                        <Route path="/map" element={
                          <Suspense fallback={<LoadingFallback />}>
                            <MapPage />
                          </Suspense>
                        } />
                        <Route path="/chat" element={
                          <ErrorBoundary fallback={<div className="text-red-500 p-4">Error in this section.</div>}>
                            <Suspense fallback={<LoadingFallback />}>
                              <ChatPage />
                              <Route path="/chat/:conversationId" element={<ChatPage />} />
                            </Suspense>
                          </ErrorBoundary>
                        } />
                        <Route path="/hotels" element={
                          <Suspense fallback={<LoadingFallback />}>
                            <HotelSearchPage />
                          </Suspense>
                        } />
                        <Route path="/tours" element={
                          <Suspense fallback={<LoadingFallback />}>
                            <ToursPage />
                          </Suspense>
                        } />
                        <Route path="/tours/map/:location" element={
                          <Suspense fallback={<LoadingFallback />}>
                            <Routes.MapViewPage />
                          </Suspense>
                        } />
                        <Route path="/destinations" element={<Routes.DestinationsPage />} />
                        <Route path="/destination/:countrySlug" element={<Routes.DestinationDetailsPage />} />
                        <Route path="/why-agentrav" element={<Routes.WhyAgenTrav />} />
                        <Route path="/hotel-marketing" element={<Routes.HotelMarketing />} />
                        <Route path="/travel-agents" element={<Routes.AgentsDirectory />} />
                        <Route path="/privacy" element={<Routes.PrivacyPolicy />} />
                        
                        {/* Admin Routes */}
                        <Route path="/management-portal/auth" element={<Routes.AdminLogin />} />
                        <Route
                          path="/management-portal/dashboard"
                          element={
                            <ProtectedAdminRoute>
                              <Routes.AdminDashboard />
                            </ProtectedAdminRoute>
                          }
                        />
                        <Route
                          path="/management-portal/enhance-profiles"
                          element={
                            <ProtectedAdminRoute>
                              <Routes.EnhanceProfiles />
                            </ProtectedAdminRoute>
                          }
                        />
                        <Route
                          path="/management-portal/clean-data"
                          element={
                            <ProtectedAdminRoute>
                              <Routes.CleanData />
                            </ProtectedAdminRoute>
                          }
                        />
                        <Route
                          path="/management-portal/cities"
                          element={
                            <ProtectedAdminRoute>
                              <Routes.CitiesManagement />
                            </ProtectedAdminRoute>
                          }
                        />
                        <Route
                          path="/management-portal/agent-hub"
                          element={
                            <ProtectedAdminRoute>
                              <Routes.AgentHub />
                            </ProtectedAdminRoute>
                          }
                        />
                        <Route
                          path="/management-portal/pages"
                          element={
                            <ProtectedAdminRoute>
                              <Routes.PageManagementMod />
                            </ProtectedAdminRoute>
                          }
                        />
                        
                        {/* Sitemap Routes */}
                        <Route
                          path="/sitemap.xml"
                          element={
                            <ErrorBoundary fallback={<div className="p-4 text-red-500">Sitemap generation failed.</div>}>
                              <Routes.Sitemap />
                            </ErrorBoundary>
                          }
                        />
                        <Route path="/sitemap" element={<Routes.SitemapPage />} />
                        
                        {/* Expo Routes */}
                        <Route path="/expo" element={<Routes.ExpoPage />} />
                        <Route path="/expo/:eventId" element={<Routes.ExpoEventPage />} />
                        
                        {/* Content Pages with prefixes - Must come BEFORE agent profile routes */}
                        <Route 
                          path="/travel-agents/:city/guide/:slug" 
                          element={<Routes.DynamicPage />} 
                        />
                        <Route 
                          path="/travel-agents/:city/top/:slug" 
                          element={<Routes.DynamicPage />} 
                        />
                        <Route 
                          path="/travel-agents/:city/best/:slug" 
                          element={<Routes.DynamicPage />} 
                        />
                        
                        {/* City Guide Pages */}
                        <Route
                          path="/travel-agents/:city"
                          element={<Routes.CityAgents />}
                        />
                        
                        {/* Agent Profile Pages - Support both old and new URL formats 
                          * Old format: /travel-agents/[agent-name]/[city]
                          * New format: /travel-agents/[agent-name]-[id]/[city]
                          * The AgentProfilePage component handles both formats and redirects as needed
                          */}
                        <Route 
                          path="/travel-agents/:agentName/:city" 
                          element={
                            <Suspense fallback={<LoadingFallback />}>
                              <AgentProfilePage />
                            </Suspense>
                          } 
                        />
                        
                        {/* IndexNow Route */}
                        <Route 
                          path="/index-now" 
                          element={
                            <Suspense fallback={<LoadingFallback />}>
                              <Routes.IndexNowPage />
                            </Suspense>
                          } 
                        />
                        
                        {/* New primary agent route - ID only */}
                        <Route path="/agent/:agentId" element={<AgentProfilePage />} />

                        {/* SEO-friendly agent route with name slug */}
                        <Route path="/agent/:agentId/:nameSlug" element={<AgentProfilePage />} />

                        {/* Keep legacy route for backward compatibility */}
                        {/* This can be removed once redirects are fully tested */}
                        <Route path="/travel-agents/:nameSlug-:agentId/:citySlug" element={<AgentProfilePage />} />
                        
                        {/* Hotel Routes */}
                        <Route path="/hotel">
                          <Route
                            path="dashboard"
                            element={
                              <ProtectedRoute>
                                <Suspense fallback={<LoadingFallback />}>
                                  <Routes.HotelDashboard />
                                </Suspense>
                              </ProtectedRoute>
                            }
                          />
                          <Route
                            path="create-profile"
                            element={
                              <ProtectedRoute>
                                <Routes.CreateHotelProfile />
                              </ProtectedRoute>
                            }
                          />
                          <Route
                            path="complete-profile"
                            element={
                              <ProtectedRoute>
                                <Routes.HotelProfileCompletion />
                              </ProtectedRoute>
                            }
                          />
                          <Route
                            path="profile/edit"
                            element={
                              <ProtectedRoute>
                                <Routes.HotelProfileEdit />
                              </ProtectedRoute>
                            }
                          />
                          <Route path="gallery">
                            <Route
                              index
                              element={
                                <ProtectedRoute>
                                  <Routes.GalleryLayout />
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path="photos"
                              element={
                                <ProtectedRoute>
                                  <Routes.Photos />
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path="upload"
                              element={
                                <ProtectedRoute>
                                  <Routes.UploadPhotos />
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path="collections"
                              element={
                                <ProtectedRoute>
                                  <Routes.Collections />
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path="settings"
                              element={
                                <ProtectedRoute>
                                  <Routes.GallerySettings />
                                </ProtectedRoute>
                              }
                            />
                          </Route>
                          <Route
                            path="agents"
                            element={
                              <ProtectedRoute>
                                <Routes.MyAgents />
                              </ProtectedRoute>
                            }
                          />
                          <Route
                            path="discover-agents"
                            element={
                              <ProtectedRoute>
                                <Routes.DiscoverAgents />
                              </ProtectedRoute>
                            }
                          />
                          <Route
                            path="connection-requests"
                            element={
                              <ProtectedRoute>
                                <Routes.ConnectionRequests />
                              </ProtectedRoute>
                            }
                          />
                          <Route
                            path="messages"
                            element={
                              <ProtectedRoute>
                                <Routes.AgentMessages />
                              </ProtectedRoute>
                            }
                          />
                          <Route
                            path="network"
                            element={
                              <ProtectedRoute>
                                <Routes.AgentNetwork />
                              </ProtectedRoute>
                            }
                          />
                        </Route>
                        
                        {/* Keep catch-all route last */}
                        <Route path="*" element={
                          <ErrorBoundary fallback={<div className="min-h-screen flex items-center justify-center p-4 bg-black"><div className="text-white text-center"><h2 className="text-xl mb-2">Page Not Found</h2><p>There was an error loading this page.</p></div></div>}>
                            <Routes.NotFoundPage />
                          </ErrorBoundary>
                        } />
                      </RouterRoutes>
                    </Suspense>
                  </main>
                </div>
              </ToastProvider>
            </ChatProvider>
          </AuthProvider>
        </Router>
      </ErrorBoundary>
    </HelmetProvider>
  );
}