import { cleanUrl } from './url';
import { parseAgentUrl } from './agentUrl';

interface RedirectRule {
  pattern: RegExp;
  getRedirect: (matches: RegExpMatchArray) => string | null;
}

// Domain configuration
const PRIMARY_DOMAIN = 'agentrav.com';
const WWW_DOMAIN = `www.${PRIMARY_DOMAIN}`;

// Valid URL patterns that should not redirect
const validPatterns = [
  /^\/travel-agents$/,  // directory page
  /^\/travel-agents\/[^/]+$/,  // city page
  /^\/travel-agents\/[^/]+\/[^/]+$/,  // agent profile
  /^\/travel-agents\/[^/]+\/(guide|top|best)\/[^/]+$/,  // city content pages
  /^\/destinations$/,  // destination page
  /^\/destination\/[^/]+$/  // destination country page
];

const redirectRules: RedirectRule[] = [
  // Handle www to non-www redirects to maintain consistent domain
  {
    pattern: new RegExp(`^https?://${WWW_DOMAIN}(.*)$`),
    getRedirect: (matches) => `https://${PRIMARY_DOMAIN}${matches[1]}`
  },

  // Handle /country/* to /destination/* (per notepad requirements)
  {
    pattern: /^\/country\/([^/]+)$/i,
    getRedirect: (matches) => `/destination/${cleanUrl(matches[1])}`
  },

  // Handle /destinations/* to /destination/* (singular form)
  {
    pattern: /^\/destinations\/([^/]+)$/i,
    getRedirect: (matches) => `/destination/${cleanUrl(matches[1])}`
  },

  // Handle incorrect hierarchical URLs - redirect from /travel-agents/country/city/agent
  // to the correct /travel-agents/agent/city format
  {
    pattern: /^\/travel-agents\/([^/]+)\/([^/]+)\/([^/]+)$/i,
    getRedirect: (matches) => {
      // Don't redirect if it's a content page
      if (['guide', 'top', 'best'].includes(matches[2].toLowerCase())) {
        return null;
      }
      // Assume the structure is country/city/agent and convert to agent/city
      const city = cleanUrl(matches[2]);
      const agent = cleanUrl(matches[3]);
      return `/travel-agents/${agent}/${city}`;
    }
  },

  // Handle REVERSED agent/city URLs - if we detect city/agent format, flip it to agent/city
  // This is a critical rule based on your notepad requirements
  {
    pattern: /^\/travel-agents\/([^/]+)\/([^/]+)$/i,
    getRedirect: (matches) => {
      const first = matches[1].toLowerCase();
      const second = matches[2].toLowerCase();
      
      // Skip if it's already in the correct format
      // This is a heuristic: cities are usually shorter than agent names
      // and agent names often contain words like "travel", "tours", "agency", etc.
      // This is imperfect but helps with automated fixing
      const agentKeywords = ['travel', 'tours', 'agency', 'world', 'vacation', 'trip', 'holiday', 'adventure'];
      const isLikelyAgentFirst = agentKeywords.some(keyword => first.includes(keyword));
      const isLikelyAgentSecond = agentKeywords.some(keyword => second.includes(keyword));
      
      // If second part looks like an agent name and first looks like a city, swap them
      if (isLikelyAgentSecond && !isLikelyAgentFirst) {
        return `/travel-agents/${cleanUrl(second)}/${cleanUrl(first)}`;
      }
      
      return null; // Don't redirect if we can't confidently determine the order
    }
  },

  // Handle malformed agent URLs with dashes
  {
    pattern: /^\/travel-agents-([^-]+)-([^-]+)$/i,
    getRedirect: (matches) => {
      const agent = cleanUrl(matches[1]);
      const city = cleanUrl(matches[2]);
      return `/travel-agents/${agent}/${city}`;
    }
  },

  // Handle incorrect casing in travel-agents
  {
    pattern: /^\/Travel-[Aa]gents(\/?.*)$/i,
    getRedirect: (matches) => `/travel-agents${cleanUrl(matches[1])}`
  },

  // Handle URLs with query parameters by stripping them
  {
    pattern: /^(\/(?:travel-agents|destinations?|country)\/[^?]+)\?.*$/i,
    getRedirect: (matches) => matches[1]
  },

  // Handle trailing slashes
  {
    pattern: /^(.+)\/$/, 
    getRedirect: (matches) => matches[1]
  },

  // Redirect legacy agent URLs with city to new format
  // /travel-agents/[name]-[id]/[city] -> /agent/[id]/[name]
  {
    pattern: /^\/travel-agents\/(.+)-([a-zA-Z0-9]+)\/([^/]+)$/,
    getRedirect: (matches) => `/agent/${matches[2]}`
  },
  
  // Redirect old agent ID formats
  // /agents/[id] -> /agent/[id]
  {
    pattern: /^\/agents\/([a-zA-Z0-9]+)$/,
    getRedirect: (matches) => `/agent/${matches[1]}`
  },
  
  // Redirect any old URL pattern that includes agent name in path
  {
    pattern: /^\/travel-agents\/([^/]+)\/([^/]+)\/([^/]+)$/,
    getRedirect: (matches) => {
      // This requires a database lookup to find the agent ID
      // For now, redirect to the city page
      return `/travel-agents/${matches[2]}`;
    }
  }
];

export function getRedirectPath(path: string): string | null {
  // Remove trailing slashes and clean the path
  const cleanPath = path.replace(/\/+$/, '');

  // Check if it's already a valid pattern
  for (const pattern of validPatterns) {
    if (pattern.test(cleanPath)) {
      return null;
    }
  }
  
  // Check redirect rules
  for (const rule of redirectRules) {
    const matches = cleanPath.match(rule.pattern);
    if (matches) {
      const redirectPath = rule.getRedirect(matches);
      // Skip if the rule returns null (no redirect needed)
      if (redirectPath === null) {
        continue;
      }
      // Verify the redirect path is valid
      for (const pattern of validPatterns) {
        if (pattern.test(redirectPath)) {
          return redirectPath;
        }
      }
    }
  }
  
  // If no valid redirect found, redirect to closest valid path
  const parts = cleanPath.split('/').filter(Boolean);
  if (parts[0]?.toLowerCase() === 'travel-agents') {
    if (parts.length === 1) return '/travel-agents';
    if (parts.length === 2) return `/travel-agents/${cleanUrl(parts[1])}`;
    // Check if it's a city content page
    if (parts.length === 4 && ['guide', 'top', 'best'].includes(parts[2])) {
      return `/travel-agents/${cleanUrl(parts[1])}/${parts[2]}/${cleanUrl(parts[3])}`;
    }
    // Default to agent profile pattern
    if (parts.length >= 3) return `/travel-agents/${cleanUrl(parts[1])}/${cleanUrl(parts[2])}`;
  }
  if (parts[0]?.toLowerCase() === 'destinations' || parts[0]?.toLowerCase() === 'destination') {
    if (parts.length === 1) return '/destinations';
    if (parts.length >= 2) return `/destination/${cleanUrl(parts[1])}`;
  }
  
  return null;
}

export function shouldRedirect(path: string): boolean {
  return getRedirectPath(path) !== null;
}

// Test function with comprehensive test cases
export function testRedirects() {
  const testCases = [
    // Valid URLs (should not redirect)
    '/travel-agents',
    '/travel-agents/chennai',
    '/travel-agents/beautiful-ways-viaggi/grosseto',
    '/travel-agents/istanbul/guide/best-places-to-visit',
    '/travel-agents/paris/top/10-attractions',
    '/travel-agents/london/best/hotels',
    '/destinations',
    '/destination/malaysia',

    // URLs that should redirect
    '/country/malaysia',
    '/destinations/malaysia',
    '/travel-agents/Grosseto/Italy/beautiful-ways-viaggi',
    '/Travel-Agents/chennai',
    '/travel-agents-beautiful-ways-viaggi-grosseto',
    '/travel-agents/chennai/india/agent-name',
    '/travel-agents/city/country/agent-name',
    
    // City content pages (should NOT redirect)
    '/travel-agents/istanbul/guide/places',
    '/travel-agents/paris/top/attractions',
    '/travel-agents/london/best/restaurants',
    '/travel-agents/new-york/guide/museums',
    
    // Invalid city content pages (should be cleaned but not redirected to agent profiles)
    '/travel-agents/istanbul/guides/places',
    '/travel-agents/paris/top-places',
    '/travel-agents/london/best-places/visit/extra',
    
    // URLs with query parameters
    '/travel-agents/chennai?q=test',
    '/destination/malaysia?param=value',
    '/travel-agents/istanbul/guide/places?param=value',
    
    // Special characters and spaces
    '/travel-agents/New York/USA/Agent Name',
    '/travel-agents/São Paulo/Brazil/Travel Expert',
    '/travel-agents/São Paulo/guide/Best Places to Visit',
    '/destination/São Tomé and Príncipe'
  ];

  console.log('\n🧪 Testing URL redirects:');
  testCases.forEach(url => {
    const redirectPath = getRedirectPath(url);
    console.log(`${url} → ${redirectPath || 'No redirect (valid URL)'}`);
  });
}

// Run tests in development
if (process.env.NODE_ENV === 'development') {
  testRedirects();
}

// Add redirect rules for legacy patterns - these will be used by the Next.js config
export const REDIRECTS = [
  // Redirect www to non-www
  {
    source: '/:path*',
    has: [{ type: 'host', value: 'www.agentrav.com' }],
    destination: 'https://agentrav.com/:path*',
    permanent: true
  },
  // Redirect old country URLs
  {
    source: '/country/:country',
    destination: '/destination/:country',
    permanent: true
  },
  // Fix incorrect hierarchical structure
  {
    source: '/travel-agents/:country/:city/:agent',
    destination: '/travel-agents/:agent/:city',
    permanent: true
  },
  // Fix agent/city order if in wrong order
  {
    source: '/travel-agents/:city/:agent',
    destination: '/travel-agents/:agent/:city',
    permanent: true,
    has: [
      {
        type: 'header',
        key: 'x-automatic-redirect',
        value: 'true'
      }
    ]
  }
];

/**
 * Check if a URL should be redirected based on our rules
 * @param url Current URL path
 * @returns Redirect URL or null if no redirect needed
 */
export function getRedirectForUrl(url: string): string | null {
  for (const rule of redirectRules) {
    const matches = url.match(rule.pattern);
    if (matches) {
      try {
        return rule.getRedirect(matches);
      } catch (e) {
        console.error('Error processing redirect rule:', e);
      }
    }
  }
  return null;
}

/**
 * Extract agent ID from a URL (any format)
 * @param url URL to parse
 * @returns Agent ID or null
 */
export function extractAgentIdFromUrl(url: string): string | null {
  console.log('Extracting agent ID from URL:', url);
  
  // First, check for our new format
  if (url.startsWith('/agent/')) {
    const matches = url.match(/\/agent\/([a-zA-Z0-9]+)/);
    console.log('New format match:', matches);
    if (matches) return matches[1];
  }
  
  // Then check for old formats
  const parsedUrl = parseAgentUrl(url);
  console.log('Parsed URL result:', parsedUrl);
  if (parsedUrl.agentId) return parsedUrl.agentId;
  
  // Other old formats
  const agentIdMatches = url.match(/\/agents\/([a-zA-Z0-9]+)/);
  console.log('Other old format match:', agentIdMatches);
  if (agentIdMatches) return agentIdMatches[1];
  
  console.log('No agent ID found in URL');
  return null;
} 