import { createClient, AuthChangeEvent, Session, RealtimeChannel } from '@supabase/supabase-js';
import type { SupabaseClient as SupabaseClientType } from '@supabase/supabase-js';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;
export const ADMIN_EMAIL = import.meta.env.VITE_ADMIN_EMAIL || 'admin@agentrav.com';

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Missing Supabase configuration. Please check your environment variables.');
}

// Ensure URL has https:// prefix
const formattedUrl = supabaseUrl.startsWith('http') 
  ? supabaseUrl 
  : `https://${supabaseUrl}`;

// Get the site URL based on environment
const getSiteUrl = () => {
  const isProd = window.location.hostname === 'www.agentrav.com';
  return isProd 
    ? 'https://www.agentrav.com'
    : window.location.origin;
};

// Initialize Supabase client
export const supabase = createClient(
  import.meta.env.VITE_SUPABASE_URL || '',
  import.meta.env.VITE_SUPABASE_ANON_KEY || ''
);

// Export typed version for better type safety when needed
export type SupabaseInstance = typeof supabase;

// Only log auth state changes in development
supabase.auth.onAuthStateChange((event: AuthChangeEvent, session: Session | null) => {
  if (process.env.NODE_ENV === 'development') {
    console.log('[Auth State Change]', {
      event,
      hasSession: !!session,
      user: session?.user?.email
    });
  }
});

// Only log connection errors in development
supabase.channel('system').on('system', { event: 'error' }, (event) => {
  if (process.env.NODE_ENV === 'development') {
    console.error('Realtime connection error:', event);
  }
});

supabase.channel('system').on('system', { event: 'connected' }, () => {
  if (process.env.NODE_ENV === 'development') {
    console.log('Realtime connected successfully');
  }
});

// Add function to ensure admin session
export async function ensureAdminSession() {
  const { data: { session }, error } = await supabase.auth.getSession();
  
  if (error) {
    console.error('Session error:', error);
    throw new Error('Authentication error');
  }

  if (!session?.user) {
    throw new Error('No active session');
  }

  if (session.user.email !== ADMIN_EMAIL) {
    throw new Error('Unauthorized: Admin access required');
  }

  return session;
}

// Add function to check if current user is admin
export async function isAdmin(): Promise<boolean> {
  try {
    const { data: { session } } = await supabase.auth.getSession();
    return session?.user?.email === ADMIN_EMAIL;
  } catch (error) {
    console.error('Error checking admin status:', error);
    return false;
  }
}

// Add error handling utility
export function handleError(error: any): never {
  if (process.env.NODE_ENV === 'development') {
    console.error('Supabase error:', error);
  }

  // If it's a Supabase error with a message
  if (error?.message) {
    throw new Error(error.message);
  }

  // If it's a PostgreSQL error with details
  if (error?.details) {
    throw new Error(error.details);
  }

  // Generic error
  throw new Error('An unexpected error occurred');
}

// Add table name constants
export const TABLES = {
  POSTS: 'posts',
  AGENTS: 'agents',
  ENHANCEMENT_JOBS: 'enhancement_jobs',
  ENHANCEMENT_RESULTS: 'enhancement_results'
} as const;

// Add error handling for missing tables
export async function checkTableExists(tableName: string): Promise<boolean> {
  try {
    const { error } = await supabase
      .from(tableName)
      .select('*')
      .limit(1);
    
    // If there's a 404 error, table doesn't exist
    if (error?.code === '404') {
      console.error(`Table ${tableName} does not exist:`, error.message);
      return false;
    }
    
    return true;
  } catch (err) {
    console.error(`Error checking table ${tableName}:`, err);
    return false;
  }
}

// Add this to your existing error handling
export function handleSupabaseError(error: any, context: string): never {
  if (process.env.NODE_ENV === 'development') {
    console.error(`Supabase error in ${context}:`, error);
  }

  if (error?.code === '404') {
    throw new Error(`Resource not found: ${error.message}`);
  }

  if (error?.message) {
    throw new Error(error.message);
  }

  throw new Error('An unexpected error occurred');
}

// Add this function to check storage bucket
export const verifyLogoBucket = async () => {
  const { data: buckets, error } = await supabase.storage.listBuckets();
  console.log('Available buckets:', buckets);
  if (error) {
    console.error('Error checking buckets:', error);
    return false;
  }
  const logoBucket = buckets.find(b => b.name === 'logos');
  if (!logoBucket) {
    console.error('Logos bucket not found');
    return false;
  }
  return true;
};