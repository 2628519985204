import { RouteType } from '../types/routes';

export function cleanUrl(str: string): string {
  if (!str) return '';
  
  return str
    .toLowerCase()
    .trim()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')  // Remove diacritics
    .replace(/[^a-z0-9-]/g, '-')      // Replace non-alphanumeric with hyphens
    .replace(/-+/g, '-')              // Replace multiple hyphens with single
    .replace(/^-|-$/g, '');           // Remove leading/trailing hyphens
}

// Standardize domain
export function getCanonicalUrl(path: string): string {
  const baseUrl = 'https://agentrav.com'; // No www
  const normalizedPath = path.startsWith('/') ? path : `/${path}`;
  return `${baseUrl}${normalizedPath}`;
}

// Maximum allowed path segments for different route types
const MAX_PATH_DEPTH: Record<RouteType, number> = {
  [RouteType.AGENT]: 3,        // /travel-agents/agent-name/city
  [RouteType.CITY]: 2,         // /travel-agents/city
  [RouteType.DESTINATION]: 2,  // /destination/country
  [RouteType.STATIC]: 1        // /page-name
};

// Update URL patterns to focus on new canonical URLs
const URL_PATTERN_MAP: Record<RouteType, RegExp> = {
  [RouteType.AGENT]: /^agent\/[a-z0-9-]+\/[a-z0-9-]+$/,
  [RouteType.DESTINATION]: /^destination\/[a-z0-9-]+$/,
  [RouteType.CITY]: /^travel-agents\/[a-z0-9-]+$/,
  [RouteType.STATIC]: /^[a-z0-9-]+$/
};

// Validate URL format
export function isValidUrlFormat(path: string, type?: RouteType): boolean {
  if (!path) return false;
  
  // Check path depth
  const segments = path.split('/').filter(Boolean);
  if (type) {
    const maxDepth = MAX_PATH_DEPTH[type];
    if (maxDepth && segments.length > maxDepth) {
      return false;
    }
  } else if (segments.length > 3) { // Global maximum depth
    return false;
  }
  
  // If type is provided, check against specific pattern
  if (type) {
    const pattern = URL_PATTERN_MAP[type];
    return pattern.test(path.replace(/^\//, ''));
  }
  
  // Otherwise check against all patterns
  return Object.values(URL_PATTERN_MAP).some(pattern => 
    pattern.test(path.replace(/^\//, ''))
  );
}

export function validateAndCleanUrl(path: string, type: RouteType): string | null {
  const cleanedUrl = cleanUrl(path);
  if (!cleanedUrl) return null;
  
  const fullPath = type === RouteType.DESTINATION 
    ? `/destination/${cleanedUrl}`
    : type === RouteType.CITY 
      ? `/travel-agents/${cleanedUrl}`
      : type === RouteType.AGENT
        ? `/travel-agents/${cleanedUrl}` // Will be further processed in isValidUrlFormat
        : path;
      
  return isValidUrlFormat(fullPath, type) ? fullPath : null;
}

// Test URLs
const testUrls = [
  'jersey%20city',
  'new%20delhi',
  'george%20town',
  'kuala%20lumpur',
  'bayan%20lepas',
  'mustafapa%C5%9Fa%20k%C3%B6y%C3%BC',
  '%C5%9Fi%C5%9Fli',
  'i%CC%87zmir',
  'kuşadası',
  'gaziosmanpaşa'
];

// Run tests in development
if (process.env.NODE_ENV === 'development') {
  console.log('\n🧪 Testing URL cleaning:');
  testUrls.forEach(url => {
    console.log(`${url} → ${cleanUrl(url)}`);
  });
}

// Fix 3: Proper array index typing
export function cleanUrlPart(part: string): string {
  const cleaned = part
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^\w-]+/g, '-');

  // Explicit array index conversion
  const parts = cleaned.split('-');
  return parts
    .filter((_, index) => index < parts.length) // Proper index typing
    .join('-');
}

// Remove any validation for legacy agent URL patterns
export function validateUrlPath(path: string): boolean {
  // Normalize the path by removing leading/trailing slashes
  const normalizedPath = path.replace(/^\/|\/$/g, '');
  
  // Check against our defined patterns
  return Object.values(URL_PATTERN_MAP).some(pattern => 
    pattern.test(normalizedPath)
  );
}

export function cleanUrlSegment(segment: string): string {
  return segment
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^\w-]+/g, '-')
    .replace(/-+/g, '-')
    .replace(/^-|-$/g, '');
}